import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;
const Exercice = ({ads,setUser,socket, setNM, nM,user,connected}) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [teacher, setTeacher] = useState({});
    const [open, setOpen1] = useState(false);
    const [lExercice, setExercice] = useState([]);

    const [tab, setTab] = useState(0);
    useEffect(async() => {
        try { 
            const check= await axios.get(ads+'api/exercices/'+id);

            setExercice(check.data)
          
    
        }catch(e){
    
        }
    },[]);





    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center"}}>
                <h1>{lExercice.title}</h1>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </div>
      <Container>
        <div>
            Position de départ
            <div
      dangerouslySetInnerHTML={{__html:lExercice.start}}
    />
        </div>

        <div>
            Position d'arrivée
            <div
      dangerouslySetInnerHTML={{__html:lExercice.end}}
    />
        </div>
        <div>
            Contraintes
            <div
      dangerouslySetInnerHTML={{__html:lExercice.constraints}}
    />
        </div>
        <div>
            Attention
        </div>
        <div>
            Variante
        </div>
      </Container>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default Exercice