import React from 'react'
import styled from "styled-components";
import { useState,useEffect} from "react";
import Upload from './Upload';
import axios from "axios";
import Video from "./VideoMy";
import VV from "./VideoPrivate";

import Delete from "./Delete";

import { Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
width: 100%;
position: relative;

`;

const Title = styled.div`
font-size:30px;
font-weight:600;
text-align:center;
padding-top:50px;
padding-bottom:10px;
${mobile({marginTop:"50px"})};
`;


const Wrapper = styled.div`
margin-left: auto;
margin-right: auto;

position: relative;
display: grid;
${mobile({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"2vw"})}
${tablette({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "40vw 40vw",width:"84vw", padding:"0",gridGap:"4vw"})}
${computer({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "36vw 36vw",width:"76vw", padding:"0",gridGap:"4vw"})}   
`;
const Button = styled.button`
  border: none;
    display:block;
  margin-right:auto;
  margin-left:auto;

  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 40px;
margin-bottom: 50px;
  cursor: pointer;
  background-color: black;
  color: white;
`;
const Vid = styled.div`
margin-top: 200px;
`;
const Myvideos = ({ads,user}) => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [id, setId] = useState("");

    const [videos, setVideos] = useState([]);
    useEffect(async() => {

        try { 
           const res= (await axios.get(ads+'api/video/all/'+user._id)).data;
           setVideos(res.reverse());
        }catch(e){

        }
    },[]);
    const delete2= (id) =>{
        setOpen2(true);
        setId(id);
    }

    return (
        <>
        <div>
            <Container>
                <Title >
                    Mes Vidéos
                </Title>
            <Button onClick={()=>setOpen(true)}>Télécharger une nouvelle vidéo</Button>

            <Wrapper>

            {videos?.map((item) => (
            <div style={{position:"relative",marginRight:"10px"}} key={item._id}>

                <div onClick={() => delete2(item._id)}  style={{position:"absolute", zIndex:"3",top:"15px", right:"5px",backgroundColor:"white", borderRadius:"50%", height:"25px",width:"25px", display:"flex",justifyContent:"center",alignItems:"center",border:"1px solid black",cursor:"pointer"}}>
                <DeleteOutlineOutlinedIcon style={{fontSize:"17px"}}/>
                </div>
                <Link to={"/modifyvideo/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <VV ads={ads} item={item} key={item._id} owner={user}/>
                </Link>
            </div>
          ))}



                {/* {videos?.map((item) => (

                    <div style={{position:"relative",marginRight:"10px"}} key={item._id}>
            <div onClick={() => delete2(item._id)}  style={{position:"absolute", zIndex:"2",top:"65px", right:"15px",backgroundColor:"white", borderRadius:"50%", height:"25px",width:"25px", display:"flex",justifyContent:"center",alignItems:"center",border:"1px solid black",cursor:"pointer"}}>
                    <DeleteOutlineOutlinedIcon style={{fontSize:"17px"}}/>
                    </div>
                                                <Link to={"/modifyvideo/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                           
                           <Video item={item} key={item._id} />
                       </Link>
                    </div>

                        ))}  */}
            </Wrapper>

            </Container>
        </div>
        {open && <Upload user={user} ads={ads} setOpen={setOpen} />}
        {open2 && <Delete ads={ads} id={id} setOpen2={setOpen2} />}

    </>
    )
}

export default Myvideos
