import React from 'react'
import styled from 'styled-components'
import { Messages } from './Messages';
import Input from "./Input";
import io from "socket.io-client"
import { useState, useEffect, useRef } from "react";
import { mobile,tablette, computer } from "../responsive";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NewConversation from "./NewConversation";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import axios from "axios";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
flex:2;
width: 100%;
  height: 100%;
`;

const Online = styled.div`
height:9px;
width:9px;
border-radius: 50%;
bottom:10px;
border:1.5px solid white;
background-color: #25b525;
position:absolute;
bottom:3px;
`;
const Wrap = styled.div`
        height: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: black;
        border-bottom: solid 1px lightgray;
`;


const Button = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"35px",width:"150px", height:"50px", fontWeight:"600"})}
`;


const Chat=({nM,newImage,setNewImage,ads,online, setOpen2,setNM, socket,chats,setChats,isWritting,setIsWritting,connectedUsers,user,chatId,setChatId,setNewLastMessage,arrivalMessage, setArrivalMessages, setConnectedUsers})=> {
    //const [socket, setSocket] = useState(null);
//    const socket = useRef();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(undefined);
    const [picture, setPicture] = useState(undefined);
    const [isTipping, setIsTipping] = useState({isTipping:false,senderId:""});


    const handleSocketMessage = async (data) => {
        setArrivalMessages({
          text: data.text,
          senderId: data.senderId,
          date: Date.now()
        });
        await axios.post(ads+'api/chat/seenmessage/'+user._id+'/'+chatId.conversationId);
        socket.current.emit("messageSeen", {
          convId: chatId.conversationId,
          readerId: user._id,
          senderId: chatId.budyId,
          lastSeen: new Date()
        });
      };
      
      useEffect(() => {
        socket.current.on("getMessage", handleSocketMessage);
      }, []);

    
    // useEffect(async()=>{
        
    //     //socket.current = io("http://localhost:8080");

    //     //socket.current = io("ws://localhost:8900");
    //     socket.current.on("getMessage", data=>{
    //                 setArrivalMessages({
    //                     text:data.text,
    //                     senderId: data.senderId,
    //                     date: Date.now()
    //                 })
    //                 await axios.post(ads+'api/chat/seenmessage/'+user._id+'/'+chatId.conversationId)
    //                 socket.current.emit("messageSeen", {
    //                   convId:chatId.conversationId,
    //                   readerId: user._id,
    //                   senderId:chatId.budyId,
    //                   lastSeen:new Date()
    //           })
            


    //     })
    // },[])

 useEffect(()=>{

   // socket.current = io("ws://localhost:8900");
    socket?.current?.on("budyIsTipping", data=>{
        setIsTipping({isTipping:data.isTipping,senderId:data.senderId})
        // var U = [...isWritting];
        // for (let ii = 0; ii < isWritting.length; ii++) {
        //     if(data.senderId==U[ii].senderId){
        //         U[ii]={isTipping:data.isTipping,senderId:data.senderId}

        //     }
        // }
        // setIsWritting(U)
    })
},[])



    useEffect(async () => {

        if(chatId!=undefined){

            const profilepict=(await axios.get(ads+'api/users/'+chatId.budyId)).data
            setPicture(profilepict)

        }


     }, []);

    //  useEffect(()=>{

    //             setOnlineS(online.includes(chatId.budyId));

    //     },[online]
    //     )


// useEffect(()=>{
// socket?.on("welcome",message=>{
//     console.log(message)
// })
// },[socket])





    return (
        <>
        <Container>
        {chatId!=undefined?
            <Wrap>
                
                
                <div style={{alignItems:"center", display:"flex", marginLeft:"12px"}}>
                <ArrowBackIosIcon style={{marginRight:"20px"}} onClick={()=>window.location.reload()}/>
                <Link to={"/profile/"+chatId.budyId} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div style={{position:"relative", alignItems:"center", display:"flex"}}>

                <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginRight:"20px"}}
                        src={ads+picture?.profilePicture
                        }
                        alt=""
                    />
                    
                                  {online.includes(chatId.budyId)?
                                  <Online/>
                                :null}     
                </div>
                </Link>         

            <span style={{fontSize:"18px"}}>{picture?.username}</span>
                </div>

            <div >
<MoreVertOutlinedIcon/>
        </div>

            </Wrap>
            :null}
            {/* <Messages arrivalMessage={arrivalMessage} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>
            <Input setArrivalMessages={setArrivalMessages} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>
            */}
            
            <div style={{height:"100%"}}>
            {chatId!==undefined?
            <Messages newImage={newImage} setNewImage={setNewImage} ads={ads} isTipping={isTipping} arrivalMessage={arrivalMessage} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>

            :                <div style={{height:"100%", alignItems:"center", textAlign:"center",backgroundColor: "#ddddf7",height:"100%"}}>
            <div style={{paddingTop:"20vh"}}>
            <div style={{marginLeft:"auto",marginRight:"auto",height:"80px", width:"80px",borderRadius:"50%", border:"2.5px solid black", justifyContent:"center", display:"flex", alignItems:"center"}}>
            
            <EmailOutlinedIcon style={{fontSize:"35px" }}/>

            </div>
            </div>

            <br/>
            <span style={{marginLeft:"auto",marginRight:"auto", fontSize:"24px"}}>Select any conversation or send a new message</span>
            <br/>
            <Button onClick={() => setOpen(true)}>NEW MESSAGE</Button>
    </div>}
            <Input nM={nM} newImage={newImage} setNewImage={setNewImage} ads={ads} setNM={setNM} chats={chats} setChats={setChats}  connectedUsers={connectedUsers}  setArrivalMessages={setArrivalMessages} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>

            </div>
                

        </Container>
        {open && <NewConversation ads={ads} setOpen={setOpen} user={user} setSelected1={setSelected} selected1={selected}/>}

        </>
    )
}

export default Chat