import React from 'react'
import styled from "styled-components";
import { useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import { mobile,tablette, computer } from "../responsive";



const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  border-bottom: 1px solid black;
  ${mobile({width:"100%", justifyContent:"center",padding:"0px",height:"6vh"})};
  ${tablette({width:"100%", justifyContent:"center",padding:"0px",height:"6vh"})};

`;

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: 5vh;
  align-items: center;
  width: 84%;
  margin-left: auto;
  margin-right:auto;
  background-color: #fafafa;
  ${mobile({padding:"5px", height:"4vh", marginBottom:"20px"})};
  ${tablette({padding:"5px", height:"4vh", marginBottom:"35px"})};
  ${computer({padding:"5px", height:"8vh", marginBottom:"85px"})};

  
`;

const Text = styled.div`

  ${mobile({marginRight:"-7px"})};
  ${tablette({marginRight:"-7px"})};

  
`;

const Filteritem=styled.h4`
letter-spacing: 1.5px;
font-weight:700;
cursor: pointer;
display:flex;
align-items: center;
color: grey;
cursor:pointer;
&:hover {
    color:black;
}
${mobile({fontSize:"10px"})};
${tablette({fontSize:"11px"})};

`;

const FilteritemS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    ${mobile({fontSize:"10px"})};
    ${tablette({fontSize:"11px"})};


`;
const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
    ${mobile({fontSize:"8px",width:"auto", marginLeft:"5px",marginRight:"5px"})};
    ${tablette({fontSize:"11px",width:"auto", marginLeft:"8px",marginRight:"8px"})};

`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;
    ${mobile({fontSize:"8px",width:"auto", marginLeft:"5px",marginRight:"5px"})};
    ${tablette({fontSize:"11px",width:"auto", marginLeft:"8px",marginRight:"8px"})};

`;



const Filter = () => {
    const [hidden, setHidden] = useState(0);
    const [theArray, setTheArray] = useState([]);

    const show = (number) =>{
        hidden==number ? setHidden(s => 0) : setHidden(s => number);
    }

    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }

        return (
    <div>
        <Container>
            {(hidden!=1)?
            <Filteritem onClick={() => show(1)}>
            <Text>
            STYLE

            </Text>
            <ExpandMoreSharpIcon  style={{ marginLeft:"6px", fontSize:"16px"}}/>
            </Filteritem>
            : null}

            {(hidden==1)?
            <FilteritemS onClick={() => show(1)}>
            <Text>
            STYLE
            </Text>
            <ExpandLessSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
            </FilteritemS>
            : null}

            {(hidden!=2)?
            <Filteritem onClick={() => show(2)}>
            <Text>DIFFICULTY</Text>
            
            <ExpandMoreSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
            </Filteritem>
            : null}

            {(hidden==2)?
                <FilteritemS onClick={() => show(2)}>
                    <Text>DIFFICULTY</Text>
                
                <ExpandLessSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
                </FilteritemS>
            : null}

            {(hidden!=3)?
                <Filteritem onClick={() => show(3)}>
                    <Text>INTENSITY</Text>
                
                <ExpandMoreSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
                </Filteritem>
            : null}

            {(hidden==3)?
                <Filteritem onClick={() => show(3)}>
                    <Text>INTENSITY</Text>
                
                <ExpandLessSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>            
                </Filteritem>
            : null}

            {(hidden!=4)?
            <Filteritem onClick={() => show(4)}>
                <Text>DURATION</Text>
                
            <ExpandMoreSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
            </Filteritem>
            : null}

            {(hidden==4)?
            <Filteritem onClick={() => show(4)}>
                <Text>DURATION</Text>
                
            <ExpandLessSharpIcon style={{ marginLeft:"6px", fontSize:"16px"}}/>
            </Filteritem>
            : null}
        </Container>
                
        
        {(hidden==1)? 
        <Containerfilters >
            {theArray.includes(1)==false?
            <Filtername onClick={() => validate(1)}>
                ASHTANGA
            </Filtername>
            : null}

            {theArray.includes(1)==true?
            <FilternameS onClick={() => validate(1)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> ASHTANGA
            </FilternameS>
            : null}

            {theArray.includes(2)==false?
            <Filtername onClick={() => validate(2)}>
                HATHA
            </Filtername>
            : null}

            {theArray.includes(2)==true?
            <FilternameS onClick={() => validate(2)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> HATHA
            </FilternameS>
            : null}



            {theArray.includes(3)==false?
            <Filtername onClick={() => validate(3)}>
                KUNDALINI
            </Filtername>
            : null}

            {theArray.includes(3)==true?
            <FilternameS onClick={() => validate(3)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> KUNDALINI
            </FilternameS>
            : null}

            {theArray.includes(4)==false?
            <Filtername onClick={() => validate(4)}>
                RESTORATIVE
            </Filtername>
            : null}

            {theArray.includes(4)==true?
            <FilternameS onClick={() => validate(4)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> RESTORATIVE
            </FilternameS>
            : null}

            {theArray.includes(5)==false?
            <Filtername onClick={() => validate(5)}>
                PRENATAL
            </Filtername>
            : null}

            {theArray.includes(5)==true?
            <FilternameS onClick={() => validate(5)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> PRENATAL
            </FilternameS>
            : null}

            {theArray.includes(6)==false?
            <Filtername onClick={() => validate(6)}>
                VINYASA
            </Filtername>
            : null}

            {theArray.includes(6)==true?
            <FilternameS onClick={() => validate(6)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/> VINYASA
            </FilternameS>
            : null}

   
        </Containerfilters> : null}
        {(hidden==2)?<Containerfilters>

            {theArray.includes(7)==false?
            <Filtername onClick={() => validate(7)}>
                BEGINNER
            </Filtername>
            : null}

            {theArray.includes(7)==true?
            <FilternameS onClick={() => validate(7)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  BEGINNER
            </FilternameS>
            : null}

            {theArray.includes(8)==false?
            <Filtername onClick={() => validate(8)}>
                MODERATE
            </Filtername>
            : null}

            {theArray.includes(8)==true?
            <FilternameS onClick={() => validate(8)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  MODERATE
            </FilternameS>
            : null}

            {theArray.includes(9)==false?
            <Filtername onClick={() => validate(9)}>
                INTERMEDIATE
            </Filtername>
            : null}

            {theArray.includes(9)==true?
            <FilternameS onClick={() => validate(9)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  INTERMEDIATE
            </FilternameS>
            : null}

            {theArray.includes(10)==false?
            <Filtername onClick={() => validate(10)}>
                ADVANCED
            </Filtername>
            : null}

            {theArray.includes(10)==true?
            <FilternameS onClick={() => validate(10)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  ADVANCED
            </FilternameS>
            : null}


        </Containerfilters> : null}
        {(hidden==3)? <Containerfilters>


            {theArray.includes(11)==false?
            <Filtername onClick={() => validate(11)}>
                LEVEL 1
            </Filtername>
            : null}

            {theArray.includes(11)==true?
            <FilternameS onClick={() => validate(11)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  LEVEL 1
            </FilternameS>
            : null}

            {theArray.includes(12)==false?
            <Filtername onClick={() => validate(12)}>
                LEVEL 2
            </Filtername>
            : null}

            {theArray.includes(12)==true?
            <FilternameS onClick={() => validate(12)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  LEVEL 2
            </FilternameS>
            : null}



            {theArray.includes(13)==false?
            <Filtername onClick={() => validate(13)}>
                LEVEL 3
            </Filtername>
            : null}

            {theArray.includes(13)==true?
            <FilternameS onClick={() => validate(13)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  LEVEL 3
            </FilternameS>
            : null}

            {theArray.includes(14)==false?
            <Filtername onClick={() => validate(14)}>
                LEVEL 4
            </Filtername>
            : null}

            {theArray.includes(14)==true?
            <FilternameS onClick={() => validate(14)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  LEVEL 4
            </FilternameS>
            : null}
        </Containerfilters>: null}
        {(hidden==4)? <Containerfilters>

            {theArray.includes(15)==false?
            <Filtername onClick={() => validate(15)}>
                0-10 MINUTES
            </Filtername>
            : null}

            {theArray.includes(15)==true?
            <FilternameS onClick={() => validate(15)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  0-10 MINUTES
            </FilternameS>
            : null}

            {theArray.includes(16)==false?
            <Filtername onClick={() => validate(16)}>
                10-20 MINUTES
            </Filtername>
            : null}

            {theArray.includes(16)==true?
            <FilternameS onClick={() => validate(16)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  10-20 MINUTES
            </FilternameS>
            : null}


            {theArray.includes(17)==false?
            <Filtername onClick={() => validate(17)}>
                20-40 MINUTES
            </Filtername>
            : null}

            {theArray.includes(17)==true?
            <FilternameS onClick={() => validate(17)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  20-40 MINUTES
            </FilternameS>
            : null}

            {theArray.includes(18)==false?
            <Filtername onClick={() => validate(18)}>
                20-40 MINUTES
            </Filtername>
            : null}

            {theArray.includes(18)==true?
            <FilternameS onClick={() => validate(18)}>
            <DoneIcon style={{ marginRight:"3px", fontSize:"10px"}}/>  LONGER THAN 40 MINUTES
            </FilternameS>
            : null}

        </Containerfilters> : null}
        

    </div>
    )
}

export default Filter
