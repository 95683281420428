import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'


import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;
const Home = ({ads,setUser,socket, setNM, nM,user,connected}) => {

    const [teacher, setTeacher] = useState({});

    const [tab, setTab] = useState(0);



    return (

        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Feeds setUser={setUser} ads={ads} user={user} socket={socket}></Feeds>
            </ContainerRight>

        </div>

    )
}

export default Home
