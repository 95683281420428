import styled from 'styled-components'
import React from 'react'
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";


const Subscribe = styled.button`
margin-left:30px;
margin-right:3vw;

display:block;
    text-align: center;
   border: none;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: #8798ef;
  /* background-color: #5f4fbb; */

  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px",width:"auto",marginLeft:"auto", height:"auto",fontWeight:"500", backgroundColor:"white", border: "1.8px solid #8798ef", color:"#8798ef"})}
${tabletteLanding({fontSize:"17px", fontWeight:"600", padding:"8px", width:"200px", height:"45px"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"500", padding:"8px",width:"210px", height:"40px"})}

`;

const Subscribe1 = styled.button`
display:block;
    text-align: center;
   border: 1.8px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({display:"none"})}
${tabletteLanding({fontSize:"17px", fontWeight:"600",padding:"8px",width:"150px", height:"45px"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"500", padding:"8px", width:"150px", height:"40px"})}

`;




const Container = styled.div`
  height: 70px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
`;
const LL = styled.h2`
margin-left:3vw;
letter-spacing:-1px;
font-weight:800;
color:rgb(126 145 241);
font-size:35px;
width:auto;
${mobileLanding({fontSize:"30px"})}
${tabletteLanding({fontSize:"33px"})}

`;
const Announcement = () => {
    return (
        <Container>
                  
        <LL>
          <span style={{color:"rgb(26, 186, 185)"}}>Om</span>Care.</LL>
       

            <Link to={"/signin"} style={{marginLeft: "auto",color: 'inherit', textDecoration: 'inherit'}}>

            <Subscribe1>
                Connexion
            </Subscribe1>
            </Link>

            <Link to={"/becomepraticien"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Subscribe>
               Praticiens
            </Subscribe>
            </Link>

            {/* <div style={{marginLeft:"auto"}}>
                Connection
            </div> */}
        </Container>
    )
}

export default Announcement