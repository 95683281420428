import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components'
import axios from "axios";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteChat from "./DeleteChat";
import { useLocation } from "react-router-dom";
import ChatSmartphone from "./ChatSmartphone";

const Container = styled.div`
padding: 10px;
display: flex;
gap: 10px;
color: black;
cursor: pointer;

&:hover {
  background-color: #ddddf7;
}
`;

const Container1 = styled.div`
padding: 10px;
display: flex;
gap: 10px;
color: black;
cursor: pointer;
background-color: #ddddf7;


`;

const NewMessage = styled.div`
height:11px;
width:11px;
border-radius: 50%;
background-color: #7c74fd;

`;

const Online = styled.div`
height:9px;
width:9px;
border-radius: 50%;
bottom:10px;
border:1px solid white;
background-color: #25b525;
position:absolute;
`;


const Chats = ({nM,newImage,setNewImage,setNC,nC,ads,socket, setNM,setChats,chats,isWritting, connectedUsers,user, selected, chatId, setChatId,newLastMessage, arrivalMessage, setArrivalMessages}) => {
  const [online, setOnline] = useState(undefined);
  const [newM, setNewM] = useState([]);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [hovering, setHovering] = useState([]);


  useEffect( async() => {
    if(user.conversation){
        
        const pp=(await axios.get(ads+'api/chat/conversation/'+user._id)).data;
        pp.conversations.sort(function(a,b) {
            return b.last_send - a.last_send
        });


        console.log(pp.conversations)
        setChats(pp.conversations)

        if(id!=undefined){
            var Ho=[];
            var Chats=pp.conversations;
            for (let jj = 0; jj < Chats.length; jj++) {
                Ho[jj]=false
                console.log(Chats[jj].conversation_id)
              if(Chats[jj].conversation_id===id){
                Ho[jj]=true;
                setChatId({conversationId:id, budyId:Chats[jj].budy_id})
              }
            }
            setHovering(Ho);
        }
    }


},[])

useEffect( async() => {
 
  var newConv=true
  for (let ii = 0; ii < chats?.length; ii++) {
    if(nC.includes(chats[ii].budy_id)){
      newConv=false
    }
  }
  if(newConv&&chats!=undefined){
    window.location.reload();

  }else{
    if(nC?.length>10){
      setNC([])

    }
  }



},[nC])




useEffect(()=>{

    if(arrivalMessage!=null){
        if(chatId==undefined){
            if(arrivalMessage.senderId!==user._id){
                if (chats!==undefined){
                    var Chats=[...chats];
                    for (let jj = 0; jj < chats.length; jj++) {
                        if(chats[jj].budy_id===arrivalMessage.senderId){
                            Chats[jj].last_message=arrivalMessage.text;
                            Chats[jj].date=arrivalMessage.date;
                            const newLine=Chats.splice(jj, 1)[0]
                            Chats.splice(0, 0, newLine);
                            Chats[0].new=true;
                        }
                    }
                    setChats(Chats)
                    var Ho=[];
                    if(chatId!=undefined){
                      for (let jj = 0; jj < Chats.length; jj++) {
                        Ho[jj]=false
                      if(Chats[jj].conversation_id===chatId.conversationId){
                        Ho[jj]=true;
                      }
                    }
                    setHovering(Ho);
                    }

                }
            }
        }else{
            if(arrivalMessage.senderId!==user._id){
                if (chats!==undefined){
                    var Chats=[...chats];
                    for (let jj = 0; jj < chats.length; jj++) {
                        if(chats[jj].budy_id===arrivalMessage.senderId){
                            Chats[jj].last_message=arrivalMessage.text;
                            const newLine=Chats.splice(jj, 1)[0]
                            Chats.splice(0, 0, newLine);
                            Chats[0].new=true;

                        }
                    }
                    setChats(Chats)
                    var Ho=[];
                    for (let jj = 0; jj < Chats.length; jj++) {
                        Ho[jj]=false
                      if(Chats[jj].conversation_id===chatId.conversationId){
                        Ho[jj]=true;
                      }
                    }
                    setHovering(Ho);
                }
            } else if (arrivalMessage.senderId===user._id){
                if (chats!==undefined){
                    var Chats=[...chats];
                    for (let jj = 0; jj < chats.length; jj++) {
                        if(chats[jj].conversation_id===chatId.conversationId){
                            Chats[jj].last_message=arrivalMessage.text;
                            Chats[jj].date=arrivalMessage.date;

                            const newLine=Chats.splice(jj, 1)[0]
                            Chats.splice(0, 0, newLine);
                        }
                    }
                    setChats(Chats)
                    var Ho=[];
                    for (let jj = 0; jj < Chats.length; jj++) {
                        Ho[jj]=false
                      if(Chats[jj].conversation_id===chatId.conversationId){
                        Ho[jj]=true;
                      }
                    }
                    setHovering(Ho);
                }
            }
        }
        setArrivalMessages(null)
    }},[arrivalMessage]
    )

    useEffect(()=>{
        if(connectedUsers!=undefined){
            var O=[];
            for (let i = 0; i < connectedUsers.length; i++) {
                O.push(connectedUsers[i].userId)
            }
            setOnline(O);
            console.log(O)

        }
        },[connectedUsers]
        )




const [isHovering, setIsHovering] = useState(false);
const [open, setOpen] = useState(false);
const [open2, setOpen2] = useState(false);
const [chatIdS, setChatIdS] = useState(null);

const [selectedItem, setItem] = useState(false);


const deleteChat = async (item) =>{
    setItem(item)
    setOpen(true);

}
//   useEffect(() => {
//     const getChats = () => {
//       const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
//         setChats(doc.data());
//       });

//       return () => {
//         unsub();
//       };
//     };

//     currentUser.uid && getChats();
//   }, [currentUser.uid]);

  const handleSelect = async(u) => {

      setChatIdS({conversationId:u.conversation_id, budyId:u.budy_id})
      var Chats=[...chats];
      var Ho=[];
      var s=false;
      for (let jj = 0; jj < chats.length; jj++) {
        Ho[jj]=false;
        if(chats[jj].conversation_id===u.conversation_id){
            if(chats[jj].new=true){
              chats[jj].new=false;
              s=true
            }

            Ho[jj]=true;
        }
    }

    setChats(Chats)
    if(s){
      await axios.post(ads+'api/chat/seenmessage/'+user._id+'/'+u.conversation_id)
      socket.current.emit("messageSeen", {
        convId:u.conversation_id,
        readerId: user._id,
        senderId:u.budy_id,
        lastSeen:new Date()
})
      setNM(false);
    }
    setOpen2(true)
  };

  return (
    <>
    <div >
    {chats?.map((item,j) => (
        <div>
            
            <Container
              onClick={() => handleSelect(item)}
            >
                <div style={{position:"relative"}}>
                <img style={{width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover"}}src={ads+item.budy_profile_picture} alt="" />
              {online?.includes(item.budy_id)?
              <Online/>
              :null}
                </div>
                
              <div style={{marginLeft:"5px"}} >
                <span style={{fontSize: "18px",
                fontWeight: "500"}}>{item.budy_username}</span>
                <div style={{display:"flex", }}>
                {!item.new?
                <p style={{maxWidth:"60vw", overflow:"hidden", textOverflow:"ellipsis",fontSize: "15px",color: "black",fontWeight: "500", marginTop:"8px"}}>{item.last_message}</p>
                :
                <p style={{maxWidth:"60vw", overflow:"hidden", textOverflow:"ellipsis",fontSize: "15px",color: "black",fontWeight: "700", marginTop:"8px"}}>{item.last_message}</p>
                }
                
                </div>


              </div>

                 <div style={{marginLeft:"auto",  color:"grey"}}>
                 <CloseOutlinedIcon   onClick={() => deleteChat(item)}
                    style={{marginLeft:"auto", cursor:"pointer", fontSize:"18px"}}/>
                <div style={{marginTop:"14px"}}>
                    {item.new?
                <NewMessage/>
                        :null}
                </div>

                 </div>


            </Container>
            </div>
         ) )}
         </div>
         {open && <DeleteChat ads={ads} setOpen={setOpen} user={user} item={selectedItem}/>}
         {open2 && <ChatSmartphone nM={nM} newImage={newImage} setNewImage={setNewImage} ads={ads} online={online} setNM={setNM} setChats={setChats} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} connectedUsers={connectedUsers} socket={socket} chatId={chatIdS} chats={chats} setOpen2={setOpen2} user={user} item={selectedItem}/>}

         </>
  )
};

export default Chats;