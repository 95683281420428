import React from 'react'
import styled from "styled-components";
import { useState, useEffect} from "react";
import { mobile,tablette,computer } from "../responsive";
import DoneIcon from '@mui/icons-material/Done';

import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';

// const Containerfilters = styled.div`
//   display: flex;
//   padding: 20px;
//   margin-top: 10px;
//   justify-content: space-around;
//   height: 5vh;
//   align-items: center;
//   width: 83.5%;
//   margin-left: auto;
//   margin-right:auto;
//   background-color: #fafafa;
//   ${mobile({ justifyContent:"spaceBetween"})};

  
// `;

// const Container = styled.div`
//   z-index:10;
//   display: flex;
//   padding: 20px;
//   justify-content: space-around;
//   height: 10vh;
//   align-items: center;
//   width: 85.5%;
//   margin-left: auto;
//   margin-right:auto;
//   overflow-y: hidden;
//   ${mobile({ height:"2vh"})};
//   ${tablette({ height:"4vh"})};

// `;
const Container = styled.div`

  ${mobile({ display:"none"})};

`;

const FilteritemS =styled.h2`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    ${mobile({ fontSize:'13px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};

`;

const Filteritem =styled.h2`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    &:hover {
        color:black;
    }
    color:grey;
    ${mobile({ fontSize:'13px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};

`;
const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:70%;
    height: 40px;
    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    color: black;
    cursor:pointer;
    width:70%;
    height: 40px;
    align-items: center;

`;
const Options=styled.div`
display:flex;
align-items: center;
margin-top:-40px;
margin-bottom:40px;
${mobile({display:"none"})}

`;


const Containerfilters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0px;
  margin-top: 25px;
  height: auto;
  flex-wrap: wrap;
  margin-left:30px;
  ${mobile({
    gridTemplateColumns: "repeat(2, 1fr)",
    marginTop: "15px",
    marginBottom: "155px",
    paddingBottom: "40px",
  })}
`;
const Label = styled.label`
  font-size: 14px;
  margin-left:0px;
  ${mobile({marginLeft:"10px", width:"50px"})}

`;

const Tabs = ({theArray,setTheArray}) => {

const categories = ["Arthrose","Cheville","Contracture","Cou","Coude","Dos","Déchirure","Épaule","Enthésopathie","Étirements", "Entorse","Fibromyalgie","Fracture","Genou", "Gériatrie","Hanche","Nutrition","Parkinson","Poignet","Post Opératoire","Proprioception","Rééducation Fonctionnelle","Renforcement","Sclérose en plaques","Sport","Tendinopathie","Traumatologie","Troubles respiratoires","Yoga"]

    // useEffect(() => {
    //     setHidden(tab+1);

    //   }, [tab]);
    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }
        return (
        <Container>
 <Label>Catégories :</Label>


<Containerfilters >
{categories?.map((item,i) => (
    <div >
    {theArray.includes(i)==false?
    <Filtername onClick={() => validate(i)}>
        {item}
    </Filtername>
    : null}

    {theArray.includes(i)==true?
    <FilternameS onClick={() => validate(i)}>
    <DoneIcon style={{ marginRight:"3px", fontSize:"16px"}}/> {item}
    </FilternameS>
    : null}
</div>
))}
</Containerfilters> 
        </Container>
                
        
 

    )
}

export default Tabs
