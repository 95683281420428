import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const Label = styled.label`
  font-size: 18px;

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:50px;
  ${mobile({marginBottom:"100px"})}
  ${tablette({marginBottom:"70px"})}


`;


const ContainerB = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid lightgrey;
  border-radius:15px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
 padding-bottom:45px;
 ${mobile({width:"100%"})}

`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;

`;
const Top = styled.div`
width:90%;
margin-left:auto;
margin-top:45px;
margin-bottom:45px;
${tablette({marginTop:"5px"})}

${mobile({marginTop:"85px"})}
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
`;

const ButtonS = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;

  margin-right:20px;

  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({width:"35px", height:"35px", display:"flex", alignItems:"center", justifyContent:"center"})}
${tablette({display:"none"})}
${computer({display:"none"})}

`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;



  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({display:"none"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px", marginRight:"20px"})}
${computer({fontSize:"16px", padding:"8px", marginLeft:"55px",width:"200px", height:"50px"})}

`;
const MyPatients = ({ads,setUser,socket, setNM, nM,user,connected}) => {
    const navigate = useNavigate();

    const [teacher, setTeacher] = useState({});
    const [open, setOpen1] = useState(false);
    const [mesPatients, setMesPatients] = useState([]);

    const [tab, setTab] = useState(0);



    const location = useLocation();
    const id = location.pathname.split("/")[2];


    
    // Define options for formatting the date
    const options = {
      year: "numeric",
      month: "long", // "long" for full month name
      day: "numeric",
    };
    
    // Create a date formatter with the specified options
    const dateFormatter = new Intl.DateTimeFormat("fr-FR", options); // "fr-FR" for French locale
    

    useEffect(async() => {
        try { 
                var check= await axios.get(ads+'api/exercices/maseance/'+id);
                const date1 = new Date(check.data.date);
                const formattedDate = dateFormatter.format(date1);
                check.data.date = formattedDate
                setMesPatients(check.data)
          
    
        }catch(e){
    
        }
    },[]);
    const ModifySeance = async() => {
        localStorage.setItem('laseance',JSON.stringify(mesPatients));

        navigate("/modifyexercices/"+id);

    };


    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Top >

                
                <div style={{alignItems:"center", color:"rgb(52, 52, 67)"}}>
                    <div style={{display:"flex", alignItems:"baseline"}}>
                        <h1>La Séance du {mesPatients.date}</h1>
                        
                        <ButtonS onClick={() => ModifySeance()} >

                          <EditIcon style={{color:"white"}}/>
                        </ButtonS>

                        <Button onClick={() => ModifySeance()} >Modifier la séance</Button>
                    </div>


                <h3 style={{fontWeight:"500", marginTop:"10px"}}>Programmée à {mesPatients.heure_de_la_seance} h {mesPatients.minute_de_la_seance}</h3>
                </div>
                <div style={{display:"flex", alignItems:"center",color:"rgb(52, 52, 67)", marginTop:"10px"}}>
                    <h3 style={{fontWeight:"500"}}>Pour votre patient : </h3>
                    {/* <ProfilePicture src={ads+mesPatients.patientInfo?.profilePicture} /> */}
                    <h3 style={{marginLeft:"4px",fontWeight:"600",alignItems:"center", display:"flex", textDecoration: "underline"}}>{mesPatients.patientInfo?.name}</h3>
                </div>
                <h4 style={{marginTop:"50px", marginBottom:"10px"}}>
  Remarque générale :
</h4>

<div 
      dangerouslySetInnerHTML={{__html:mesPatients.description}}
    />
                </Top>



      <Container>
      <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center", display:"none", color:"rgb(52, 52, 67)"}}>
                <h1>Récapitulatif de la séance</h1>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </div>
                {mesPatients.seance?.map((person,i) => (
                    <div>
                    <div style={{display:"flex"}}>

                   
      <ContainerB>
       <div style={{fontWeight:"800",marginLeft: "auto",marginRight: "auto", marginTop:"40px", marginBottom:"15px"}}>
        BLOC {i+1} 
        </div> 
        <div style={{padding:"20px", fontWeight:"500"}}>
        Exercice : {person.title}
        </div> 
        <div style={{padding:"20px",fontWeight:"500"}}>
         Volume :       {person.series} séries de {person.rep} répétitions

        </div>
        <div style={{display:"flex",padding:"20px",fontWeight:"500"}}>

        {(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes!=""&&person.minutes!="0"&&person.minutes!=0)?
                <div>
                 Repos :   {person.minutes} minute(s) et {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                }
                       {(person.seconds==""||person.seconds==0||person.seconds=="0")&&(person.minutes!=""&&person.minutes!=0&&person.minutes!="0")?
                <div>
                   Repos :   {person.minutes} minute(s) de récupération entre les séries
                </div>:
                null
                
                }

{(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes==""||person.minutes==0||person.minutes=="0")?
                <div>
                  Repos :    {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                }
                 

        </div>
        {person.description?
        <div style={{display:"flex",padding:"20px", fontWeight:"500"}}>
        Remarque : <div style={{marginLeft:"7px"}}
      dangerouslySetInnerHTML={{__html:person.description}}
    />
        </div> :null}
      </ContainerB>

        </div>
        {i<mesPatients.seance?.length-1?
              <div  style={{ display: "flex", padding: "30px", justifyContent: "center", fontWeight: "500" }}>
                <div>Temps de repos :</div>
                {(mesPatients.minutes[i] !== "" && mesPatients.minutes[i] !== 0 && mesPatients.minutes[i] !== "0") && (mesPatients.seconds[i] !== "" && mesPatients.seconds[i] !== "0" && mesPatients.seconds[i] !== 0) ? (
                  <div style={{ marginLeft: "4px" }}>
                    {mesPatients.minutes[i]} minute(s) et {mesPatients.seconds[i]} secondes
                  </div>
                ) : null}
                {(mesPatients.minutes[i] === "" || mesPatients.minutes[i] === 0 || mesPatients.minutes[i] === "0") && (mesPatients.seconds[i] !== "" && mesPatients.seconds[i] !== 0 && mesPatients.seconds[i] !== "0") ? (
                  <div style={{ marginLeft: "4px" }}>
                    {mesPatients.minutes[i]} minute(s) de récupération entre les séries
                  </div>
                ) : null}
                {(mesPatients.minutes[i] !== "" && mesPatients.minutes[i] !== 0 && mesPatients.minutes[i] !== "0") && (mesPatients.seconds[i] === "" || mesPatients.seconds[i] === 0 || mesPatients.seconds[i] === "0") ? (
                  <div style={{ marginLeft: "4px" }}>
                    {mesPatients.seconds[i]} secondes de récupération entre les séries
                  </div>
                ) : null}
                <div></div>
              </div>
              :null}
            </div>
                ))}
                   {/* <Button onClick={DemarrerLaSeance}>
            DEMARRER
        </Button> */}
      </Container>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default MyPatients