import React from 'react'
import Feed from "./Feed";
import MentorItem from "../components/RecommendationItem";

import styled from "styled-components";
import { mobile,tablette ,computer} from "../responsive";
import { Link } from "react-router-dom";

import { useState, useEffect} from "react";
import axios from "axios";

const LargeContainer = styled.div`
margin-left: auto;
margin-right: auto;

justify-content: center;
display: flex;

${mobile({width:"100%", display:"block"})}
${tablette({width:"75vw", display:"block"})}
${computer({width:"100%"})}

`
const ContainerS = styled.div`
margin-left: auto;
margin-right: auto;

justify-content: center;
display: flex;
flex-direction:column;

${mobile({width:"100%"})}
${tablette({width:"75vw"})}
${computer({display:"none"})}

`


const Container = styled.div`
margin-left: auto;
margin-right: auto;

justify-content: center;
display: flex;
flex-direction:column;

${mobile({width:"100%"})}
${tablette({width:"75vw"})}
${computer({width:"600px"})}

`
const Suggestions = styled.div`
margin-left: auto;
margin-right: auto;
margin-top:50px;
justify-content: center;
flex-direction:column;

${mobile({display:"none"})}
${tablette({display:"none"})}
${computer({width:"300px"})}

`


const SuggestionsM = styled.div`
margin-left: auto;
margin-right: auto;
margin-top:50px;
justify-content: center;
flex-direction:column;

${mobile({width:"300px"})}
${tablette({width:"400px"})}
${computer({display:"none"})}

`



const NoFeed = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: 10vh;
  align-items: center;
  width: 85.5%;
  margin-left: auto;
  margin-right:0px;
  overflow-y: hidden;
  margin-bottom:50px;
  border-radius:10px;
  border:1px solid lightgrey;
  color: rgb(52, 52, 67);
  font-weight:600;
  ${mobile({  fontSize:"14px", width:"80vw",marginTop:"100px", marginRight:"auto", marginBottom:"0px"})};
  ${tablette({  fontSize:"18px", width:"70vw",marginTop:"80px",marginRight:"auto", marginBottom:"0px"})};
  ${computer({ fontSize:"18px", width:"520px",marginTop:"100px"})};

`;
const Voirp = styled.div`
${tablette({  marginTop:"35px"})};

`

const Items = styled.div`
margin-top: 30px;
padding:20px;
`
const Copyright = styled.div`
margin-top: 30px;
padding:20px;
text-align: center;
`

const Feeds = ({ads,user, socket, setUser}) => {
    const [feed, setFeed] = useState(undefined);
    const [teachers, setTeacher] = useState(undefined);

    useEffect(async() => {
        try { 

            //console.log((await axios.get('http://51.178.60.185/api/feed/'+user._id,Config)).data)
            var recommendations = (await axios.get(ads+'api/mentors/recommended/')).data
            const filteredUsers = recommendations.filter(a => a._id !== user._id);
            console.log(recommendations)
            setTeacher(filteredUsers)

            setFeed(((await axios.get(ads+'api/feed/'+user._id)).data));
        }catch(e){


        }
  
    },[])
    
    
    return (
<LargeContainer>
{feed?.length==0?
<NoFeed>
                Votre fil d'actualité est vide, suivez des utilisateurs pour l'enrichir.
            </NoFeed>:null}
            
            {feed?.length>0?
                <Container>
            
<div>
{feed?.map((item,i) => (
                <Feed setUser={setUser} ads={ads} item={item} key={i} user={user}/>
            ))}
</div>
</Container>

       
                    :
                    <ContainerS>
<SuggestionsM>
    <div style={{color: "rgb(52, 52, 67)", fontWeight:"600"}}>SUGGESTIONS POUR VOUS</div>
    
    {teachers?.map((item) => (
                    //<Link to={"/profile/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <MentorItem user={user} ads={ads} item={item} key={item._id} socket={socket} />
                    //</Link>
                ))}
    <Link to={"/mentors"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

    <Voirp style={{color: "rgb(52, 52, 67)", fontWeight:"600", fontSize:"18px",marginBottom: "100px"}}>Voir plus</Voirp>
    </Link>
</SuggestionsM>
</ContainerS>



                                }

<Suggestions>
    <div style={{color: "rgb(52, 52, 67)", fontWeight:"600"}}>SUGGESTIONS POUR VOUS</div>
    
    {teachers?.map((item) => (
                    //<Link to={"/profile/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <MentorItem user={user} ads={ads} item={item} key={item._id} socket={socket} />
                    //</Link>
                ))}


<Link to={"/mentors"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <div style={{color: "rgb(52, 52, 67)", fontWeight:"400", fontSize:"18px",marginTop: "15px",
    marginBottom: "15px",
    cursor: "pointer"
}}>Voir plus</div>
</Link>
</Suggestions>


</LargeContainer>  

    )
}

export default Feeds
