import React from 'react'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Mentors from '../components/Mentors'
import Message from '../components/Message'
import Messagebis from '../components/Messagebis'
import LandingTop from '../components/LandingTop'

import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import Slider from '../components/Slider'
import SliderMobile from '../components/SliderMobile'

import SliderMentors from '../components/SliderMentors'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';



const Home = ({ads}) => {

    return (
       <div>
            <LandingTop/>

            {/* <Announcement/> */}
            <SliderMobile ads={ads}/>

            <Slider ads={ads}/>
            {/* <Message/>
            <SliderMentors/> */}
            <Messagebis ads={ads}/>

            <Categories ads={ads}/>
            {/* <Mentors/> */}
            
            <Newsletter ads={ads}/>
            <Footer/>
    <FooterS/>
       </div>

    )
}

export default Home
