import React from 'react'
import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import TabSettings from '../components/TabsSettings'

import styled from "styled-components";
import Inputs from '../components/Inputs';
import ModifPassword from '../components/ModifPassword';
import BankAccount from '../components/BankAccount';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { mobile,tablette,computer } from "../responsive";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Wrapper = styled.div`
width: 100%;
height: 1vh;
position: relative;
`;

const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%", marginTop:"60px"})}
${computer({width:"100%"})}

`;
const FullDisplay=styled.div`
${tablette({display:"none"})};
${mobile({display:"none"})};
`;

const Container = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
  height: 15vh;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right:auto;
  background-color: #fafafa;

`;

// const Input = styled.div`
//   margin-top: 70px;
//   display: flex;
//   padding: 20px;
//   justify-content: space-around;
//   height: 10vh;
//   align-items: center;
//   width: 100%;
//   margin-left: auto;
//   margin-right:auto;
//   background-color: #fafafa;

// `;

  


function Params({ads,setUser,setNM, nM, socket, user,connected}) {

    const [videos, setVideos] = React.useState(["Editer mon profil","Modifier mon mot de passe","Banque & Abonnement"]);
    const [value, setValue] = React.useState(0);
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };



    return (
      <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
      <NavContainer >
      <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>




<TabSettings ads={ads} setTab={setTab}/>

{tab==0?
                      <Inputs ads={ads} user={user}/>
: 
null
}


{tab==1?
                      <ModifPassword user={user} ads={ads}/>

: 
null
}




{/* <FullDisplay >
<Box sx={{ width: 'auto',marginTop:"30px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  value={value} onChange={handleChange} 
 aria-label="basic tabs example"  centered>
        {videos?.map((item,j) => (
          <Tab sx={{ width: '100%' }} label={item} {...a11yProps(j)} />
        ))}
        </Tabs>
      </Box>
      
      {videos?.map((item,i) => (

      <TabPanel value={value} index={i} >
          {i==0?
                      <Inputs/>
: 
null
}
{i==1?
                      <ModifPassword/>

: 
null
}
{i==2?
                      <BankAccount/>

: 
null
}
      </TabPanel>
      ))}

    </Box>
</FullDisplay> */}

    </ContainerRight>

</div>
    )
}

export default Params
