import React from 'react'
import styled from 'styled-components'
import { Messages } from './Messages';
import Input from "./Input";
import io from "socket.io-client"
import { useState, useEffect, useRef } from "react";
import { mobile,tablette, computer } from "../responsive";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NewConversation from "./NewConversation";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import axios from "axios";
import { Link } from "react-router-dom";

const Container = styled.div`
${computer({width:"600px"})}
${tablette({flex:"2"})}

`;
const Wrap = styled.div`
        height: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: black;
        border-bottom: solid 1px lightgray;
`;


const Button = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"35px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"35px",width:"250px", height:"50px", fontWeight:"600"})}
`;


const Chat=({newImage,setNewImage,ads,nM,setNM, socket,chats,setChats,isWritting,setIsWritting,connectedUsers,user,chatId,setChatId,setNewLastMessage,arrivalMessage, setArrivalMessages, setConnectedUsers})=> {
    //const [socket, setSocket] = useState(null);
//    const socket = useRef();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(undefined);
    const [picture, setPicture] = useState(undefined);
    const [isTipping, setIsTipping] = useState({isTipping:false,senderId:""});







    
    useEffect(()=>{
        
        //socket.current = io("http://localhost:8080");

        //socket.current = io("ws://localhost:8900");
        socket.current.on("getMessage", data=>{
                    setArrivalMessages({
                        text:data.text,
                        senderId: data.senderId,
                        createdAt: Date.now(),
                        imageUrl:data.imageUrl
                    })
        })



        socket.current.on("getMyMessage", data=>{
            setArrivalMessages({
                text:data.text,
                senderId: user._id,
                createdAt: Date.now(),
                imageUrl:data.imageUrl
            })
        })
    },[])

 useEffect(()=>{

   // socket.current = io("ws://localhost:8900");
    socket?.current?.on("budyIsTipping", data=>{
        setIsTipping({isTipping:data.isTipping,senderId:data.senderId})
        // var U = [...isWritting];
        // for (let ii = 0; ii < isWritting.length; ii++) {
        //     if(data.senderId==U[ii].senderId){
        //         U[ii]={isTipping:data.isTipping,senderId:data.senderId}

        //     }
        // }
        // setIsWritting(U)
    })
},[])



    useEffect(async () => {

        if(chatId!=undefined){

            const profilepict=(await axios.get(ads+'api/users/'+chatId.budyId)).data
            setPicture(profilepict)

        }


     }, [chatId]);




// useEffect(()=>{
// socket?.on("welcome",message=>{
//     console.log(message)
// })
// },[socket])





    return (
        <>
        <Container>
        {chatId!=undefined?
            <Wrap>
                
                
                <div style={{alignItems:"center", display:"flex", marginLeft:"12px"}}>
                <Link to={"/profile/"+chatId.budyId} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginRight:"20px"}}
                        src={ads+picture?.profilePicture
                        }
                        alt=""
                    />
            </Link>

            <span style={{fontSize:"18px"}}>{picture?.username}</span>

                </div>

            <div >
<MoreVertOutlinedIcon/>
        </div>

            </Wrap>
            :null}
            {/* <Messages arrivalMessage={arrivalMessage} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>
            <Input setArrivalMessages={setArrivalMessages} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>
            */}
            
            <div style={{height:"100%"}}>
            {chatId!==undefined?
            <Messages setNewImage={setNewImage} newImage={newImage} ads={ads} isTipping={isTipping} arrivalMessage={arrivalMessage} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>

            :                <div style={{height:"100%", alignItems:"center", textAlign:"center",backgroundColor: "white",height:"100%"}}>
            <div style={{paddingTop:"20vh"}}>
            <div style={{marginLeft:"auto",marginRight:"auto",height:"80px", width:"80px",borderRadius:"50%", border:"2.5px solid black", justifyContent:"center", display:"flex", alignItems:"center"}}>
            
            <EmailOutlinedIcon style={{fontSize:"35px" }}/>

            </div>
            </div>

            <br/>
            <span style={{marginLeft:"auto",marginRight:"auto", fontSize:"24px"}}>Choisissez une conversation ou envoyez un nouveau message</span>
            <br/>
            <Button onClick={() => setOpen(true)}>NOUVEAU MESSAGE</Button>
    </div>}
            <Input setNewImage={setNewImage} nM={nM} newImage={newImage} ads={ads} setNM={setNM} chats={chats} setChats={setChats}  connectedUsers={connectedUsers}  setArrivalMessages={setArrivalMessages} socket={socket} user={user} chatId={chatId} setChatId={setChatId}/>

            </div>
                

        </Container>
        {open && <NewConversation ads={ads} setOpen={setOpen} user={user} setSelected1={setSelected} selected1={selected}/>}

        </>
    )
}

export default Chat