import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import Videos from "./Videos";
import SliderVideo from "./SliderVideo";
import VideoItem from "./VideoItem";
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";

const Container1 = styled.div`
  height: 50vh;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  width: 77vw;
`;

const Container = styled.div`


`;
const Container2 = styled.div`
  overflow: hidden;

height: 50vh;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  width: 72vw;
`;
const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33%;
  left: ${(props) => props.direction === "left" && "0px"};
  right: ${(props) => props.direction === "right" && "0px"};
  margin: auto;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -24}vw);
`;

const Slide = styled.div`
  height: 55vh;
  display: flex;
  background-color: white;
  overflow-y: hidden;
  width: 90%;
  justify-content: space-around;
`;

const ImgContainer = styled.div`
  height: 100%;
  flex: 1;
`;

const Image = styled.img`
  height: 80%;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 50px;
  margin-top: -80px;
`;

const Title = styled.h1`
  font-size: 70px;
`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

const Series  = styled.div`
margin-top: 40px;
${tablette({marginTop:"40px"})};

`
const First  = styled.h4`
color: #a5a3a3;
letter-spacing: 2px;
font-weight:800;
text-align: center;
`

const Second  = styled.h2`
    font-size: 40px;
    letter-spacing: 1.5px;
    text-align: center;

`

const SliderVideos = ({ ads,Video }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [nbSeries, setNb] = useState(Video.videosDetail.length-3);
  const [number, setNumber] = useState(0);

  const handleClick = (direction) => {
    if (direction === "left") {
      setNumber(number+1)
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : nbSeries);
    } else {
      setNumber(number-1)

      setSlideIndex(slideIndex < nbSeries ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>
      <div style={{height:"120px"}}>
      <Series>
                <First>
                    CLASSES FROM THE SERIES
                </First>
                <Link to={"/seriesoverview/"+Video._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                  <Second>
                      {Video.title}
                  </Second>
                </Link>
            </Series>
      </div>
<Container1>
{number!=0?
<Arrow direction="left" onClick={() => handleClick("left")}>

        <ArrowLeftOutlined style={{color:"white"}}/>
      </Arrow>
      :null}
      <Container2>
       <Wrapper ads={ads} slideIndex={slideIndex}> 



       {Video.videosDetail.map((item,n) => (
            <a href={"/classes/"+Video._id+"/"+n} style={{ color: 'inherit', textDecoration: 'inherit'}}>
              <VideoItem ads={ads} item={item} key={item._id} />
            </a>
      ))}



        {/* {sliderItems.map((item) => ( */}

        {/* {Video.creatorId?
            <SliderVideo Items={Video}></SliderVideo>
          </Slide>
:null} */}


        
        {/* } */}
      </Wrapper>
      </Container2>
      {Video.videosDetail.length>3?
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined style={{color:"white"}}/>
      </Arrow>:
      null}
</Container1>

    </Container>
  );
};

export default SliderVideos;