import React from 'react'
import styled from "styled-components";


const Desc = styled.p`
  width: 100%;
  margin: 50px 0px;
  font-size: 19px;
  letter-spacing: 1.5px;
  font-weight: 500;
  text-align: center;
`;
const Title = styled.p`
  width: 100%;
  margin: 60px 0px;
  margin-bottom: -25px;
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 700;
  text-align: center;
`;
const Wrapper = styled.div`
  height: 100%;
  text-align: center;
  align-items: center;
  flex: 1;
`;
const Container = styled.div`
  width: 100%;
  height: 30vh;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  
`;


const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
`;
const Message = () => {
    return (
        <Container>
            <Wrapper>
                <Title>
                    FIND YOUR MENTOR AND LET YOUR JOURNEY BEGIN
                </Title>
                <Desc>
                WHATEVER YOUR LEVEL AND AMBITIONS, YOU WILL FIND THE MENTOR THAT WILL GUIDE YOU THROUGH YOUR PATH TO SUCCESS.
                </Desc>
                <Button>VIEW MENTORS</Button>
            </Wrapper>
        </Container>
    )
}

export default Message
