import styled from "styled-components";
import { popularVideos } from "../data";
import Article from "./Article";
import { useState,useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5vw;
  width:70vw;
  ${mobile({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"2vw"})}
  ${tablette({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "40vw 40vw",width:"84vw", padding:"0",gridGap:"4vw"})}

`;

const Titre = styled.h1`
  font-weight:900;
  text-align: center;
  font-size: 40px;
  margin-top:60px;
  letter-spacing: 2.5px;

`;

const Articles = ({ads,owner,user}) => {
  const [articles, setArticles] = useState([]);
  const [status, setStatus] = useState(undefined);

  useEffect(async() => {
    try { 

         const res= (await axios.get(ads+'api/article/favorite/all/'+owner._id)).data;
         setArticles(res.reverse());



      if(user!="unknown"){
        if(user.subscribe.includes(owner._id)){
          setStatus("subscriber")
        }else if(user.follow.includes(owner._id)){
          setStatus("follower")
        }
      }


    }catch(e){

    }
},[owner]);

  return (
    <div>
        {/* <Titre>
          ARTICLES
        </Titre> */}
        <Container>

          {articles.map((item) => (
            <Link to={"/article/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Article ads={ads} item={item} key={item.id} owner={{username:item.creatorUsername,profilePicture:item.creatorProfilePicture}} status={status}/>
            </Link>
          ))}
        </Container>
    </div>

  );
};

export default Articles;