import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DeleteClass from "./DeleteClass";
import { Link } from "react-router-dom";


const Container = styled.div`
z-index:3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 400px;
  height: auto;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h1`
  text-align: center;
  margin-top:30px;
`;


const Yes = styled.button`
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
`;

const No = styled.button`
  border: solid 1px black;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: white;
  color: black;
  margin-right:50px;
`;


const ModifyClasse = ({user, ads, setOpen2, Video,N}) => {
    const [open3, setOpen3] = useState(false);









  return (
    <>

    <Container>
      <Wrapper>

      <Link to={"/modifyclass/"+Video._id+"/"+N} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<div style={{textAlign:"center",height:"60px",borderBottom:"1px solid grey", alignItems:"center",display:"flex", justifyContent:"center", cursor:"pointer"}}>
    Editer
</div>
</Link>
<div onClick={() => setOpen3(true)} style={{textAlign:"center",height:"60px",borderBottom:"1px solid grey", alignItems:"center",display:"flex", justifyContent:"center",cursor:"pointer"}}>
    Supprimer
</div>
<div onClick={() => setOpen2(false)} style={{textAlign:"center",height:"60px", alignItems:"center",display:"flex", justifyContent:"center",cursor:"pointer"}}>
    Fermer
</div>
      </Wrapper>
    </Container>
    {open3 && <DeleteClass user={user} ads={ads} item={Video} setOpen3={setOpen3} N={N}/>}
    </>
  );
};

export default ModifyClasse;