import styled from "styled-components";
import { Link } from "react-router-dom";




const Login = () => {
    const date = "06/04/23";
    const appName="OmCare";
    const entreprise="OmTech Innovations"
    const professeur="Praticien";
    const eleve="Patient"
    const professeurs="Praticiens";
    const eleves="Patients"
    const siteWeb="https://www.omcare.fr"
    const entrepriseFull = "OmTech Innovations S.A.S.U au capital social de 500 euros, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 950 886 986, dont le siège social est situé au 229 rue Saint-Honoré, 75001, Paris"
  
  return (
<div style={{marginLeft:"15px", marginRight:"15px"}}>

<div style={{borderBottom:"1px solid grey", marginBottom:"50px", paddingBottom:"30px", paddingTop:"10px"}}>

<h1>
  Mentions Légales
  </h1>

</div>
Le présent site internet www.omcare.fr est édité et exploité par {entrepriseFull}.
<br/>
<br/>
Vous pouvez nous joindre par courrier électronique à l'adresse support@omcare.fr.
<br/>
<br/>
Le directeur de la publication est Monsieur Léonard TOROSSIAN, Président de OmTech Innovations.

<br/>
<br/>
Ce site est hébergé par la société OVH SAS dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France.
          </div>
  );
};

export default Login;