
  import styled from "styled-components";
  import { mobile,tablette,computer } from "../responsive";
  import { useState, useEffect} from "react";

  const Info = styled.div`
    width: 100%;
    ${computer({height: "120px"})}

    position: relative;
  
    /* text-align: center;
    align-items: center; */
    cursor: pointer;

  `;
  
  const Container = styled.div`
    margin-top: 50px;;
    width: 32.5vw;
    height: 350px;
    ${mobile({width:"96vw", height:"auto",marginTop:"5px", marginBottom:"30px"})}
    ${tablette({width:"40vw", height:"30vw",marginTop:"5px"})}

    justify-content: center;
    background-color: white;
    position: relative;
    &:hover ${Info}{
      opacity: 1;
    }
  `;
  

  
  const Image = styled.img`
    width:100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    ${mobile({height: "54vw"})}
    ${tablette({height: "22.5vw"})}

  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;
  const Title = styled.h1`
  position: relative;
  align-items: start;
  font-size: 20px;

  color:black;
`;
const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-around;
height: 18.28vw;
align-items: center;
width: 100%;
${mobile({height: "54vw"})}
${tablette({height: "22.5vw"})}


background-color: black;

`;

const Lock  = styled.div`
background-color: #5f4fbb;
padding:0px;
position:absolute;
color:white;
border-radius: 60px;
font-size: 18px;
font-weight: 400;

width:auto;
${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"0px"})}
${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"0px"})}
${computer({width:"auto",  fontSize: "14px", padding:"5px", marginTop:"5px"})}

`
const Lock2  = styled.div`
  background-color: #5f4fbb;
  padding:0px;
  z-index:2;
  position:absolute;
  color:white;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 10px; /* position the left edge of the element at the middle of the parent */
  width:auto;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"10px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"10px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"10px"})}

`
const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  z-index:2;
  position:absolute;
  color:#5f4fbb;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 10px; /* position the left edge of the element at the middle of the parent */
  width:172px;
  ${mobile({width:"auto",  fontSize: "10px", padding:"5px", bottom:"10px"})}
  ${tablette({width:"auto",  fontSize: "12px", padding:"5px", bottom:"10px"})}
  ${computer({width:"auto",  fontSize: "14px", padding:"8px", bottom:"10px"})}

`
const Profilepict = styled.img`
width:45px;
height: 45px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;

`;
  const Article = ({ ads,item, owner,status }) => {
    const [style, setStyle] = useState([]);


    useEffect(async() => {
      try { 

          const ss=["ASHTANGA","HATHA","KUNDALINI","RESTORATIVE","PRENATAL","VINYASA"];
          var Style=[]
          for (let jj = 0; jj < item.style.length; jj++) {
              Style.push(ss[item.style[jj]]);
          }
          setStyle(Style);

      }catch(e){


      }

  },[])



    return (
      <Container>

<Wrapper>
<Image src={ads+item.thumbnails[0]} />
{item.privacy=="Subscribers"&&status!="subscriber"?
        <Lock2>
          Subscribers
        </Lock2>
        :null
        }
        {(item.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
        <Lock1>
          Followers
        </Lock1>
        :null
        }
</Wrapper>
<Info>
          <div style={{display:"flex", marginTop:"15px"}}>
          <Profilepict src={ads+owner.profilePicture}/> 

<Title>
{item.title}
<div style={{color:"grey",fontWeight:"400", fontSize:"16px", marginBottom:"10px"}}>
{owner.username}
</div>

{style?.map((item) => (
<Lock>
{item}
</Lock>
))}


</Title>
          </div>
          
        </Info>
      </Container>
    );
  };
  
  export default Article;