import styled from "styled-components";

  
const Info = styled.div`
width: 100%;
height: 20%;
position: relative;
text-align: center;
align-items: center;
cursor: pointer;

`;

const Container = styled.div`
width: 20vw;
height: 350px;
margin: 2vw;
justify-content: center;
background-color: #f5fbfd;
position: relative;
cursor: pointer;
&:hover ${Info}{
  opacity: 1;
}
`;

const Circle = styled.div`
width: 5vw;
height: 5vw;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
margin-top: 85px;
margin-left:7.5vw;
`;

const Play  = styled.div`
margin: 0 auto ;
margin-top: 39%;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;

`

const Image = styled.img`
width:100%;
height: 280px;
object-fit: cover;
z-index: 2;
`;

const Icon = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
background-color: white;
display: flex;
align-items: center;
justify-content: center;
margin: 10px;
transition: all 0.5s ease;
&:hover {
  background-color: #e9f5f5;
  transform: scale(1.1);
}
`;
const Title = styled.h1`
position: relative;
align-items: center;
font-size: 20px;
left: 50%;
top:40%;
transform: translate(-50%, -50%);
color:black;
`



const VideoItem = ({ads, item }) => {
    return (

      <Container>
        <Circle>
            <Play/>
        </Circle>
        <Image src={ads+item.thumbnails[0]} />
        <Info>
            <Title>
            {item.title}
            </Title>
        </Info>
      </Container>
    );
  };
export default VideoItem;