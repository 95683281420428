import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import styled from "styled-components";
import { mobile,tablette, computer } from "../responsive";
import './App.css';



const Button1 = styled.button`
margin-left: auto;
margin-right: auto;
margin-top:30px;
display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"16px", padding:"8px",width:"100px", height:"auto"})}
${tablette({fontSize:"16px", padding:"8px",width:"100px", height:"auto"})}
${computer({fontSize:"18px", padding:"8px",width:"100px", height:"auto", fontWeight:"600"})}

`; 

const CheckoutForm = ({ads}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showSpin, setshowSpin] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }



      const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: ads+'verifytea',
        },
      });
  
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button1 onClick={() =>setshowSpin(true)} disabled={!stripe}>Envoyer</Button1>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
      {showSpin?
<div style={{display:"flex",justifyContent:"center", marginTop:"30px"}}>
<div class="sk-chase">
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
</div>
</div>
:null}
    </form>
  )
};

export default CheckoutForm;