import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";
//import { LocalizationProvider } from '@mui/pickers';
import { Editor } from '@tinymce/tinymce-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";
import { useNavigate } from 'react-router-dom';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;
const Container = styled.div`
display: flex;
flex-direction: column;
border: 1px solid lightgrey;
border-radius:15px;
width: 80%;
margin-left: auto;
margin-right: auto;
${mobile({width:"100%",border:"none", marginBottom:"100px"})}

`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
`;




const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;

const Input = styled.input`
  font-size: 23px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  border-radius: 6px;
  width:70px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
  ${mobile({fontSize:"13px"})};


`;




const Top = styled.div`
width:90%;
margin-left:auto;
margin-top:45px;
margin-bottom:45px;
alignItems:center;
color:rgb(52, 52, 67);
display:flex;
${mobile({marginTop:"85px", marginLeft:"20px"})}

`;


const Exercices = ({ads,setUser,socket, setNM, nM,user,connected}) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [value, setValue] = useState("");
    const [open, setOpen1] = useState(false);
    const [lesExercices, setLesExercices] = useState(JSON.parse(localStorage.getItem('exercices')));
    const [videoSaved,setVideoSave]= useState([]);
    const [minutes, setMinutes] = useState("00");
    const [hours, setHours] = useState("18");
    const [tab, setTab] = useState(0);
    const Minutes =  JSON.parse(localStorage.getItem('minutes'))
    const Seconds =  JSON.parse(localStorage.getItem('seconds'))
    const patient =  JSON.parse(localStorage.getItem('patientId'))
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState("");
    const editorRef = useRef(null);

    const selectVideo = (number) =>{
        videoSaved.includes(number) ? setVideoSave(videoSaved.filter(item => item !== number)) : setVideoSave((arr) =>[...arr, number]);
    }

    
    const handleMinutesChange = (desc) => {
        setMinutes(desc)
    };
  
    // Fonction pour gérer les changements dans le champ des secondes
    const handleHourChange = (desc) => {
        setHours(desc)

    };
    const changeDescription = (desc)=>{
      setDescription(desc);

  }

    const EnvoieLaSeance = async() => {
      if(value.$d==undefined){
        setError(true)
        return
      }else{
        setError(false)
      }


        const check= await axios.post(ads+'api/exercices/createseance',
        {
            "seance":lesExercices,
            "minutes":Minutes,
            "seconds":Seconds,
            "praticien":user._id,
            "patient":patient,
            "date": Date.parse(value.$d),
            "heure_de_la_seance":hours,
            "minute_de_la_seance":minutes,
            "description":description
    
        }
        
        );

            const notif= await axios.post(ads+'api/notifications/create-seance',
        {
            "userId":patient,
            "friendId":user._id,
        }
        
        );
        setShow(true)
    };






    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Top>
                <h1>Finalisation</h1>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </Top>
      <Container>
      <h3 style={{padding:"20px"}}>Quand se fera la séance ?</h3>
      <div style={{display:"flex", padding:"20px", alignItems:"center"}}>
        <h4 style={{marginRight:"10px"}}>Jour :</h4>
      

<LocalizationProvider 

    dateAdapter={AdapterDayjs} 
    adapterLocale="fr">

{/* <LocalizationProvider dateAdapter={AdapterDayjs} dateAdapter={DateFnsUtils} locale={frLocale}> */}
<DatePicker         onChange={(newValue) => {
    setValue(newValue); 
    console.log(newValue.$d);}} />
</LocalizationProvider>
      </div>



      <div style={{display:"flex", padding:"20px", alignItems:"center"}}>
      <h4 style={{marginRight:"10px"}}>Heure :</h4>

 
      <div style={{display:"flex"}}>
      <div style={{display:"flex"}}>
 
      <Input
          value={hours}
        type="texte"
        name="minute"
        onChange={(e) => handleHourChange(e.target.value)}
      />
      <div style={{marginLeft:"15px", marginRight:"15px", display:"flex", alignItems:"center"}}>h</div>
      
      </div>
 
 
       <div>
         <Input
           type="texte"
           value={minutes}
           onChange={(e) => handleMinutesChange(e.target.value)}
         />
       </div>
 </div>
 </div>

 <h3 style={{padding:"20px"}}>Remarque générale sur la séance : </h3>
<div style={{display:"flex", justifyContent:"center"}}>
<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        value={description}

        init={{
          placeholder: "Description",
          height: 400,
          width:'80%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
        onEditorChange={changeDescription}
      />
</div>

        {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"40px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> Vous devez choisir une date pour cette séance.
            </div>
                : null}

 {show?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"20px", marginBottom:"50px"}}>
            < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Séance envoyée !
        </div>:        <Button onClick={EnvoieLaSeance}>
            ENVOYER
        </Button>
}

      </Container>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default Exercices