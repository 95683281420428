
  import styled from "styled-components";
  import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
  import { useState} from "react";

  const Info = styled.div`
    width: 100%;
    height: 20%;
    position: relative;
    text-align: center;
    align-items: center;
    cursor: pointer;

  `;
  
  const Container = styled.div`
    margin-top: 50px;;
    width: 25vw;
    height: 350px;

    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
    &:hover ${Info}{
      opacity: 1;
    }
  `;
  
  const Circle = styled.div`
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid white;
    position: absolute;
    margin-top: 18%;
    margin-left:10vw;
  `;

const Play  = styled.div`
    margin: 0 auto ;
    margin-top: 42%;
    position: relative;  
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5em 0 0.5em 1em;
    border-color: transparent transparent transparent white;
    
  `
  
  const Image = styled.img`
    width:100%;
    height: 80%;
    object-fit: cover;
    z-index: 2;
  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;
  const Title = styled.h1`
  position: relative;
  align-items: center;
  font-size: 20px;
  left: 50%;
  top:40%;
  transform: translate(-50%, -50%);
  color:black;
`
  
  const VideoMy = ({ ads,item }) => {
    const [open, setOpen] = useState(false);

    return (
      <Container>
        <Circle>
            <Play/>
        </Circle>

        <Image src={ads+item.thumbnails[0]} />
        <Info>
            <Title>
            {item.title}
            </Title>
        </Info>
      </Container>
    );
  };
  
  export default VideoMy;