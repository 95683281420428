import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Dot = styled.div`
  background-color: #8da4f1;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.25rem;
  animation: ${fadeIn} 1s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.33s;
  }

  &:nth-child(3) {
    animation-delay: 0.66s;
  }
`;

const LoadingDots = () => (
  <div style={{display:"flex", alignItems:"center", height:"100%"}}>
    <Dot />
    <Dot />
    <Dot />
  </div>
);

export default LoadingDots;
