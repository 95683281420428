import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  ${computerLanding({height:"80vh"})};
  height: max(70vh,70vw);
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobileLanding({display:"none"})};
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
  ${computerLanding({backgroundColor:"black",color:"white"})};

`;

const Wrapper = styled.div`
  ${computerLanding({height:"80vh"})};

  height: max(70vh,70vw);
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  ${computerLanding({height:"80vh"})};

  height: max(70vh,70vw);
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
  ${tabletteLanding({alignItems:"baseline"})};

`;

const ImgContainer = styled.div`
${computerLanding({height:"80vh"})};
  height: max(70vh,70vw);
  width: 100%;
  position:relative;
  ${tabletteLanding({height:"50vh"})};

`;





const Image = styled.img`
  height: 100%;
  width:50vw;
  ${tabletteLanding({width:"100vw"})};

  object-fit: cover;

`;

const InfoContainer = styled.div`
  padding: 50px;
  ${computerLanding({marginTop:"0px"})};

  margin-top: -80px;
`;

const InfoContainer1 = styled.div`
display:content;
  ${mobileLanding({display:"none"})};
  ${computerLanding({display:"none"})};

`;

const Title = styled.h1`
  font-size: 35px;
  ${tabletteLanding({fontSize:"28px", textAlign:"center", marginTop:"30px", width:"85%", marginLeft:"auto", marginRight:"auto"})};

`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3px;
  text-align:center;
  ${tabletteLanding({margin:"30px 0px"})};
  ${computerLanding({textAlign:"left", width:"95%"})};

`;


const Button = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"65px",width:"55%", height:"45px", marginLeft:"auto", marginRight:"auto",marginBottom:"4vh"})}
${computerLanding({marginBottom:"40px",marginTop:"50px",letterSpacing:"0.9px",fontSize:"17px", fontWeight:"600", padding:"8px", width:"200px", height:"40px"})}

`;
const Slider = ({ads}) => {
  const loc="uploads/stat"

  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined style={{color:"inherit"}}/>
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {sliderItems.map((item) => (
          <Slide bg={item.bg} key={item.id}>
            <ImgContainer>
              <Image src={ads+loc+item.img} />

              <InfoContainer1>
              <Title>{item.title}</Title>
              <Desc>{item.desc}</Desc>
            </InfoContainer1>
            </ImgContainer>
            <InfoContainer>
              <Title>{item.title}</Title>
              <Desc>{item.desc}</Desc>
              <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Button>S'INSCRIRE</Button>
              </Link>
            </InfoContainer>
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

export default Slider;