import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import { CheckOutlined } from "@mui/icons-material";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;

`;

const Wrapper = styled.div`
  ${mobile({width:"100%", height:"100vh"})}

  width: 350px;
  height: 60vh;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 100px;
object-fit: cover;



`;
const Profilepicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  margin-left:20px;
  margin-right:20px;


`;
const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"13px"})}
text-align: center;

${tablette({fontSize:"14px"})}
${computer({fontSize:"14px"})}


`;

const Choose= styled.div`
height:20px;
width:20px;
border-radius: 50%;
border: 2px solid black;
background-color: white;
margin-left: auto;
margin-right: 20px;
cursor: pointer;

`;

const Chose= styled.div`
height:20px;
width:20px;
border-radius: 50%;
border: 2px solid #5f4fbb;
background-color:#5f4fbb;
margin-left: auto;
margin-right: 20px;
cursor: pointer;

`;

const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"15px",width:"65%", height:"50px"})}

`;

const Button = styled.button`
position: absolute;
right:10px;
display:block;
    text-align: center;
   border: none;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
:disabled{
    background-color:#a5b8f5f0;
    cursor: auto;
  }
`;
const Iinput = styled.input`
width: 80%;
border: none;
outline: none;
color: #2f2d52;
font-size: 18px;

&::placeholder {
  color: lightgray;
}
`;
const Validatesubscription = ({ads, setOpen, user, selected1, setSelected1 }) => {
  const [follow, setFollow] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const [search, setSearch] = useState(false);
  const [searched, setSearched] = useState(false);

  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();


  useEffect(async () => {
    
    var FF=[];
    const S = (await axios.get(ads+'api/users/subscriptionsinfo/'+user._id)).data
    const F = (await axios.get(ads+'api/users/followingsinfo/'+user._id)).data;
    for (let i = 0; i < F.length; i++) {
      if(F[i].teacher==false){
        FF.push(F[i])
      }
    }
    
    const Fo = (await axios.get(ads+'api/users/followersinfo/'+user._id)).data;
    const So = (await axios.get(ads+'api/users/subscribersinfo/'+user._id)).data;

    var CC=S.concat(FF)

    for (let ii = 0; ii < Fo.length; ii++) {
      var ino = false;
      for (let jj = 0; jj < CC.length; jj++) {
        
        if(Fo[ii].follower_id==CC[jj].id){
          ino=true
        }
      }
      if(ino==false){
        CC.push({id:Fo[ii].follower_id,username:Fo[ii].budy_username,profilePicture:Fo[ii].follower_profile_picture})
      }
    }

    for (let ii = 0; ii < So.length; ii++) {
      var ino = false;
      for (let jj = 0; jj < CC.length; jj++) {
        
        if(So[ii].subscriber_id==CC[jj].id){
          ino=true
        }
      }
      if(ino==false){
        CC.push({id:So[ii].subscriber_id,username:So[ii].budy_username,profilePicture:So[ii].subscriber_profile_picture})
      }
    }



    setFollow(CC);
    document.body.style.overflow = 'hidden';
    document.body.style.overflow = 'unset';
 }, []);

 const saveSelection = async () =>{
     var S =[];
    for (let i = 0; i < selected.length; i++) {
        S.push(follow[selected[i]])
    }
    setSelected1(S);
    setOpen(false);
    await axios.post(ads+'api/chat/conversation/'+user._id+"/"+S[0].id)
    console.log(S)
 }

 const removeNumber= (i)=>{
    var index = selected.indexOf(i);
    var Selected = [...selected];
    if (index > -1) {
      Selected.splice(index, 1);
    }

    setSelected(Selected)
 }

 const searchUser=(name)=>{
    var results = [];
    console.log(follow)
      // Loop through the input array
        for (var i = 0; i < follow.length; i++) {
    // Check if the current string contains the substring
            if (follow[i].username.toLowerCase().includes(name.toLowerCase())) {
      // If it does, add it to the results array
                results.push(follow[i]);
            }
        }
    setSearched(results)
 }



 const addNumber= async (i)=>{
    var Selected = [...selected];
    Selected.push(i)
    setSelected(Selected)


    

    const Conv=await axios.post(ads+'api/chat/conversation/'+user._id+"/"+follow[[i]].id)
    window.location.href ="/chat/"+Conv.data.conversationId
 }
  

  return (
    <Container>
        <Wrapper>
            <div style={{borderBottom:"1px solid lightgrey", height:"70px", display:"flex", alignItems:"center", position:"relative"}}>
                <CloseOutlinedIcon onClick={() => setOpen(false)} style={{cursor:"pointer",position:"absolute", left:"5px"}}/>
               <span style={{marginLeft:"auto",marginRight:"auto"}}>Nouveau Message</span> 
               <Button onClick={() => saveSelection()} disabled={selected.length===0}>Suivant</Button>
               
            </div>
            <div style={{display:"flex", height:"70px",alignItems:"center", borderBottom:"1px solid lightgrey", }}>
                
               <span style={{marginLeft:"10px", marginRight:"10px"}}>À :</span>     
                 <Iinput 
        type="text"
        placeholder="Recherche"
        onChange={(e) => {
            searchUser(e.target.value);
            setSearch(true)
            }}
       // value={text}
      />
            </div>
            {search?
            <div style={{overflowY: "scroll",height:"100%"}}>
                {searched?.map((item,i) => (
                <div style={{display:"flex", height:"70px", position:"relative", alignItems:"center"}}>
                    <Profilepicture src={ads+item.profilePicture}/>

                    {item.username}
                    {selected.includes(i)?
                    <Chose onClick={() => removeNumber(i)}/>:
                    <Choose onClick={() => addNumber(i)}/>
    }

                </div>
                ))}
            </div>
            :
            <div style={{overflowY: "scroll",height:"100%"}}>

            {follow?.map((item,i) => (
                <div style={{display:"flex", height:"70px", position:"relative", alignItems:"center"}}>
                    <Profilepicture src={ads+item.profilePicture}/>

                    {item.username}
                    {selected.includes(i)?
                    <Chose onClick={() => removeNumber(i)}/>:
                    <Choose onClick={() => addNumber(i)}/>
    }

                </div>
                ))}
                </div>
                }

        {/* <div style={{position:"relative", height:"170px"}}>
        <Picture src={ads+owner.coverPicture}/>
        <Profilepicture src={ads+owner.profilePicture}/>
        <h3 style={{position:"absolute", top:"110px", left:"150px"}}>
            {owner.username}
        </h3>
        </div>
        <h4 style={{ color:"#8a96a3", marginLeft:"20px"}}>
            SUBSCRIBE AND GET THESE BENEFITS:
        </h4>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"20px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Full acces to this user's content
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Direct message with this user
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Cancel your subscription at any time
        </div>
        <Subscribe onClick={() => Checkout()}>
          
                {currency=="U.S. Dollar"?
                <Text >{"CHECKOUT $" +price}</Text>
                :null}
                {currency=="Euro"?
                <Text >{"CHECKOUT "+price+ "€"}</Text>
                :null}
                </Subscribe>
                <div style={{borderTop: "1.5px solid #ececec", marginTop:"20px", height:"40px", alignItems:"center", display:"flex",float:"right"}}>
                    <h4 onClick={() => setOpen(false)} style={{cursor:"pointer",color:"#5f4fbb", float:"right", marginLeft:"auto", marginRight:"20px"}}>
                    CLOSE
                    </h4>
                    
                </div> */}
        </Wrapper>

    </Container>
  );
};

export default Validatesubscription;



