import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { mobile,tablette,computer } from "../responsive";
import * as tus from 'tus-js-client'
import './App.css';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import { useState, useEffect} from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Cate from './Cate'
import Cate2 from './Cate2'

import CateS from './CateS'
const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({marginTop:"60px"})};

`;



const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
`;


const Info1=styled.div`
display:flex;
align-items:center;
justify-content: center;
margin-right:15px;
`



const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
  ${mobile({marginLeft:"5px"})}

`;
const Label = styled.label`
  font-size: 18px;
`;
const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;

`;
const Button = styled.button`
  width: 200px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({marginBottom:"60px"})};

`;

const Option = styled.option``;

const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
margin-bottom:40px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;






const InputContainer1 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;



const Postparameters = ({ads,user}) => {
    const navigate = useNavigate();
    const [progress, setProgress] =useState(0);
    const [progress1, setProgress1] =useState(0);
    const [startDownloading, setStartDownloading] =useState(false);

    const [showSuccess,setSuccess]=useState(false)
    const [showSuccess1,setSuccess1]=useState(false)

    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState(undefined);
    const [miniature, setMiniature] = useState(undefined);

    const [cover, setCover] = useState(undefined);
    const [fileType, setFileType] = useState(null);
    const [videoInfo, setVideoInfo] = useState(undefined);
    const [noMiniature, setNoMiniature] = useState(false);

    const [video, setVideo] = useState(undefined);
    const [privacy, setPrivacy] = useState("Public");
    const [theArray, setTheArray] = useState([]);
    const [theArray1, setTheArray1] = useState([]);

    const editorRef = useRef(null);
    const editorRef1 = useRef(null);
    const editorRef2 = useRef(null);

    const showD = useRef();
    const [isVisible, setVisibility] = useState("");

    const [filters, setFilters] = useState({
                
        ["diff"]: "Beginner",
        ["intens"]: "Level 1",
    
      });
    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }
    
    CircularProgressWithLabel.propTypes = {
      /**
       * The value of the progress indicator for the determinate variant.
       * Value between 0 and 100.
       * @default 0
       */
      value: PropTypes.number.isRequired,
    };

    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }

    useEffect(async() => {
        try { 

            setCreator(user);
        }catch(e){


        }
  
    },[])

    useEffect(() => {
      if(showSuccess&&showSuccess1){
        setTimeout(function () {
          navigate('/profile/'+user._id);
        }, 3000);
      }

  },[showSuccess,showSuccess1])


  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
    
  };

    const handleUpload = async (e)=>{
  
      // const formData = new FormData()
      // formData.append('profile', video, video.name)
      const res = (await axios.post(ads+"api/video/bunny/"+user._id)).data
      setVideoInfo({videoId:res.videoId,collectionId: res.collectionId})
  
  
      // const formDataThumb = new FormData()
      // formDataThumb.append('profile', img, img.name)
      // const thum = await axios.post(ads+"api/video/thumbnails/"+res._id,formDataThumb,
      // {   
      //   onUploadProgress: progressEvent => {
      //     setProgress2((progressEvent.loaded / progressEvent.total)*100)
      //       console.log(progressEvent.loaded / progressEvent.total);
      //   }
      // }
      // )


  
  
      
      await axios.put(ads+'api/video/'+res._id,{"description":editorRef.current.getContent()});
      
      // if (title != ''){
      //     await axios.put(ads+'api/video/'+res._id,{"title":title});
      // }
    //   if (duration != undefined){
    //     await axios.put(ads+'api/video/'+res._id,{"duration":duration});
    // }
      // await axios.put(ads+'api/video/'+res._id,{"style":theArray, "difficulty":filters.diff,"intensity": filters.intens});
    
  
  
  // Create a new tus upload
  var upload = new tus.Upload(video, {
    endpoint: "https://video.bunnycdn.com/tusupload",
    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
    headers: {
        AuthorizationSignature: res.key, // SHA256 signature (library_id + api_key + expiration_time + video_id)
        AuthorizationExpire: res.time, // Expiration time as in the signature,
        VideoId: res.videoId, // The guid of a previously created video object through the Create Video API call
        LibraryId: res.libraryId,
    },
    metadata: {
        filetype: video.type,
        title: video.name,
        collection: res.collectionId
    },
    onError: function (error) {
      if (window.confirm(`Upload failed because: ${error}\nDo you want to retry?`)) {
        upload.start()
      }
     },
    onProgress: function (bytesUploaded, bytesTotal) {
              setProgress1((bytesUploaded / bytesTotal)*100)
    //         console.log(progressEvent.loaded / progressEvent.total);
     },
    onSuccess: function () { 
      setSuccess(true)
      if(showSuccess1){
        console.log("1")
        setTimeout(function () {
          console.log("3")
        }, 3000);
      }
      
    }
  })
  
  // Check if there are any previous uploads to continue.
  upload.findPreviousUploads().then(function (previousUploads) {
    // Found previous uploads so we select the first one. 
    if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0])
    }
  
    // Start the upload
    upload.start()
  })
  var course = {
    "description":editorRef.current.getContent(),
    "creatorId":user._id,
    "privacy":privacy,
    "videoId":res.videoId,
    "bunnyId":res.collectionId,
    "epingle":""
  }
  if(isVisible){
    course = {
      "description":editorRef.current.getContent(),
      "creatorId":user._id,
      "privacy":privacy,
      "videoId":res.videoId,
      "bunnyId":res.collectionId,
      "epingle":"0"
    }
  }
  

  const id = (await axios.post(ads+"api/post/"+user._id,course)).data
  
  const formDataThumb = new FormData()
  formDataThumb.append('profile', miniature, miniature.name)
  const thum = await axios.post(ads+"api/post/thumbnails/"+id._id,formDataThumb,
  {   
    onUploadProgress: progressEvent => {
      setProgress((progressEvent.loaded / progressEvent.total)*100)
        console.log(progressEvent.loaded / progressEvent.total);
    }
  })
  setSuccess1(true)
  if(showSuccess){
    setTimeout(function () {
      navigate('/profile/'+user._id);
    }, 3000);
  }


    }




    const createPost= async()=>{
    //   if(fileType=='video'&&(miniature==undefined||null)){
    //     setNoMiniature(true)
    //     return
    //   }
    //   setStartDownloading(true)

    // if(fileType=='image'){
    //   setSuccess(true)

    //   var course = {
    //     "description":editorRef.current.getContent(),
    //     "creatorId":user._id,
    //     "privacy":privacy,
    //     "epingle":""
    //   }
    //   if(isVisible){
    //     course = {
    //       "description":editorRef.current.getContent(),
    //       "creatorId":user._id,
    //       "privacy":privacy,
    //       "epingle":"0"
    //     }
    //   }

    //   const id = (await axios.post(ads+"api/post/"+user._id,course)).data
    //   const formDataThumb = new FormData()
    //   formDataThumb.append('profile', img, img.name)
    //   const thum = await axios.post(ads+"api/post/thumbnails/"+id._id,formDataThumb,
    //   {   
    //     onUploadProgress: progressEvent => {
    //       setProgress((progressEvent.loaded / progressEvent.total)*100)
    //         console.log(progressEvent.loaded / progressEvent.total);
    //     }
    //   })
    //   setSuccess1(true)
    //   setTimeout(function () {
    //     navigate('/profile/'+user._id);
    //   }, 3000);


    // }


  
        var course = {
          "start":editorRef.current.getContent(),
          "end":editorRef1.current.getContent(),
          "constraints":editorRef2.current.getContent(),
          "cate1":theArray,
          "cate2":theArray1,
          "title":title
        }

  
        const id = (await axios.post(ads+"api/exercices/create",course))
        // const formDataThumb = new FormData()
        // formDataThumb.append('profile', img, img.name)
        // const thum = await axios.post(ads+"api/post/thumbnails/"+id._id,formDataThumb,
        // {   
        //   onUploadProgress: progressEvent => {
        //     setProgress((progressEvent.loaded / progressEvent.total)*100)
        //       console.log(progressEvent.loaded / progressEvent.total);
        //   }
        // })
        // setSuccess1(true)
        // setTimeout(function () {
        //   navigate('/profile/'+user._id);
        // }, 3000);
  
  
      

    // if(fileType=='video'){

    //   handleUpload();

    //   }

    }



    


    return (
        
        <Container>
            <h2 style={{textAlign:"center"}}>
    Création d'un exercice
</h2>
{startDownloading?
  
    <div>
      {fileType=="video"?
      <div>
        <div style={{display:"flex", justifyContent:"center", marginTop:"40px"}}>
          <Info1>Téléchargement de votre miniature :</Info1>

          <CircularProgressWithLabel value={progress} />
        </div>
        <div style={{display:"flex", justifyContent:"center", marginTop:"40px"}}>
          <Info1>Téléchargement de votre vidéo :</Info1>
          <CircularProgressWithLabel value={progress1} />
        </div>
        </div>
        :null}
              {fileType=="image"?
      <div>
        <div style={{display:"flex", justifyContent:"center", marginTop:"40px"}}>
          <Info1>Téléchargement de votre image :</Info1>

          <CircularProgressWithLabel value={progress} />
        </div>
 
        </div>
        :null}
        {showSuccess1&&showSuccess?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"20px",marginBottom:"20px"}}>
            < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Création de l'exercice réussie !
        </div>:null
}
    </div>


    : 
            <Wrapper2>

            <div style={{display:"flex", alignItems:"center"}}>
<Label>Sélectionnez votre média :</Label>

<InputT
  type="file"
  accept="image/*,video/*"
  onChange={(e) => {
    setImg(e.target.files[0])
    if (e.target.files[0].type.startsWith('image/')) {
      setFileType('image');
    } else if (e.target.files[0].type.startsWith('video/')) {
      setFileType('video');
      setVideo(e.target.files[0])
    } else {
      setFileType('other');
    }
  }}
/>


</div>


{fileType=='video'?
            <div style={{display:"flex", alignItems:"center"}}>
            <Label>Sélectionnez votre miniature :</Label>
            
            <InputT
              type="file"
              accept="image/*"
              onChange={(e) => {
                setMiniature(e.target.files[0])
              }}
            />
            
            
            </div>
  :null}



<Input
          type="text"
          placeholder="Titre"
          name="title"
          onChange={(e) => setTitle(e.target.value)}
        />


<Label>Description position de départ :</Label>


<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />

<br/>


<Label>Description position de fin :</Label>


<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef1.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />

<br/>


<Label>Description des contraintes :</Label>


<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef2.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />

<br/>


<Options >





        <Label>Difficulé:</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"45px"}}>Intensité:</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>



        </Options>

        <Cate theArray={theArray} setTheArray={setTheArray}/>
        <Cate2 theArray={theArray1} setTheArray={setTheArray1}/>


        <OptionsS >
<CateS theArray={theArray} setTheArray={setTheArray}/>




        <Label>Difficulté:</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label >Intensité:</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>



        </OptionsS>












{user.teacher?
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de Confidentialité :</Label>
            <Select name="difficulty" onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
            <Option>Subscribers</Option>
          </Select>
</div>:
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de Confidentialité :</Label>
            <Select name="difficulty" onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
          </Select>
</div>}

<br/>
<div style={{display:"flex", alignItems:"center"}}>

            <Label>Épingler ce poste :</Label>
            <InputContainer1>


<label className="switch" >
    <input type="checkbox" checked={isVisible} onClick={() => setVisibility(!isVisible)}/>
    <span className="slider"></span>
</label>


</InputContainer1>
</div>

            </Wrapper2>

}
{!startDownloading?
<div style={{ marginTop:"30px", marginBottom:"20px"}}>
{(noMiniature==true)?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"-30px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> Vous devez choisir une miniature.
            </div>
                : null}
<Button onClick={createPost}>PUBLIER</Button>



</div>

:null}



           
        </Container>
        
    )
}

export default Postparameters
