import React, { useRef } from 'react';
import { useState, useEffect} from "react";
import './App.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import { mobile,tablette, computer } from "../responsive";

import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: auto;
  align-items: left;
  width: 45vw;
  margin-left: auto;
  margin-right:auto;
  background-color:white;
  flex-direction:column;
  border:1px solid lightgray;
  margin-top:30px;
margin-bottom:40px;
${mobile({border:"none", width:"auto", padding:"5px", marginTop:"20px", paddingBottom:"90px"})};


`;



const InputContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
  position:"relative";
`;


const InputContainer2 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  width: 50%;
  
`;



const Input = styled.input`
  border: none;
  outline: none;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 500;
  ${mobile({width:"100%"})};

`;

const Try=styled.aside `
    width: 20%;
    text-align: right;
    align-items: center;
  `;
const Label = styled.label`
font-size: 18px;
`;


const Select = styled.select`
  padding: 10px;
  margin-left:-5px;
  font-size: 16px;
`;
const Option = styled.option``;
// const Button = styled.button`
//   width: auto;
//   border: none;
//   padding: 15px 15px;
//   background-color:black ;
//   color: white;
//   cursor: pointer;
//   font-weight: 700;
//   font-size: 15px;
//   margin-left: auto;
//   margin-right: auto;
 
//   margin-bottom: 30px;
//   :disabled{
//     background-color: darkgray;
//     cursor: auto;
//   }
// `;
const InputContainer1 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;
const Button = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"12px", padding:"8px",width:"auto", height:"auto", marginTop:"20px"})}
${tablette({fontSize:"13px", padding:"8px",width:"auto", height:"auto", marginTop:"20px"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto", marginTop:"20px"})}

`;


const Button1 = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 700;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"16px", padding:"8px",width:"150px", height:"auto"})}
${tablette({fontSize:"16px", padding:"8px",width:"150px", height:"auto"})}
${computer({fontSize:"18px", padding:"8px",width:"150px", height:"auto", fontWeight:"600"})}
:disabled{
    background-color:#b3c9f9;
    cursor: auto;
  }
`;

const BankAccount = ({ads,user, setUser}) => {
    const navigate = useNavigate();
    const [priceValidated, setPriceValidated] = useState(false);

    
    const [Month, setMonth] = useState("");
    const [ThreeMonth, setThreeMonth] = useState("");
    const [SixMonth, setSixMonth] = useState("");
    const [Year, setYear] = useState("");
    const [currency, setCurrency] = useState("");
    const [free, setFree] = useState(true);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error, setError] = useState("");
    const [isdisable, setIsdisable] = useState(true);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(null);
    const [showSpin, setshowSpin] = useState(false);

    const [isVisible, setVisibility] = useState(user.freeSub);

    const editorRef = useRef(null);
    useEffect(async() => {



        const myToken=await axios.get(ads+'api/checkout/status/'+user._id);
        const pp=(await axios.get(ads+'api/checkout/subscriptionprice/'+user._id)).data;
        setMonth(pp.oneMonth) 
        setThreeMonth(pp.threeMonth) 
        setSixMonth(pp.sixMonth) 
        setYear(pp.oneYear) 
        setCurrency(pp.currency) 
        setStatus(myToken.data)
        
        if(myToken.data==true){
          setIsdisable(false)
        }
    },[])

    const  SaveModif= async (e) =>{
        e.preventDefault();

        try{


          const myToken=await axios.post(ads+'api/checkout/createaccount/'+user._id);
          window.location = myToken.data.url;

          console.log(myToken);
        //  localStorage.setItem('userDetails',JSON.stringify(myToken.data));
        //  navigate('/profile');
        }catch(e) {
            setError(s=>e.response.data);
        }    
      }

      const changeCurrency=  (e) =>{
        setCurrency(e.target.value);


      }
      const  SaveSubscription= async (e) =>{
        e.preventDefault();
        const prices = {
          "oneM":Month,
          "threeM":ThreeMonth,
          "sixM":SixMonth,
          "oneY":Year,
          "currency":currency,
          "free":isVisible

        }
        try{
          const myToken=await axios.post(ads+'api/checkout/savesubscriptionprice/'+user._id,prices);
          setUser(myToken.data);
          setError("");
          setPriceValidated(true);


        }catch(e){
          setError(s=>e.response.data);
          setPriceValidated(false);


        }

      }


      const  ResumeModif= async (e) =>{
        e.preventDefault();
        setshowSpin(true)
        try{


          const myToken=await axios.post(ads+'api/checkout/resumecreateaccount/'+user._id);
          window.location = myToken.data.url;

        //  localStorage.setItem('userDetails',JSON.stringify(myToken.data));
        //  navigate('/profile');
        }catch(e) {
            setError(s=>e.response.data);
        }    
      }


      const  createAccount= async (e) =>{
        e.preventDefault();
        setshowSpin(true)

        try{


          const myToken=await axios.post(ads+'api/checkout/createaccount/'+user._id);
          window.location = myToken.data.url;
        //  localStorage.setItem('userDetails',JSON.stringify(myToken.data));
        //  navigate('/profile');
        }catch(e) {
            setError(s=>e.response.data);
        }    
      }
      
    return (
        <div>
            
<Container>

{status==true?
<div style={{  alignItems:"center", marginTop:"40px"}}>
    <div style={{textAlign:"center", alignItems:"center"}}>
        {/* Your payment account is active, you can receive payments */}
        Votre compte est actif, vous pouvez recevoir des paiements
    </div>
<Button onClick={ResumeModif}>MODIFIER MON COMPTE</Button>

</div>:
null
}
{status==false?
    <div style={{display:"block", display:"flex", flexDirection:"column", marginTop:"40px"}}>
    <div style={{textAlign:"center"}}>
        {/* You need to complete your account to enable payments */}
        Vous devez complèter votre compte pour recevoir des paiements
    </div>
    <Button onClick={ResumeModif}>COMPLÈTER</Button>

</div>:
null
}
{showSpin?
<div style={{marginLeft:"auto", marginRight:"auto", marginTop:"30px"}}>
<div class="sk-chase">
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
</div>
</div>
:null}
{status=="empty"?
    <div style={{display:"block", flexDirection:"column"}}>
    <div style={{textAlign:"center"}}>
     Créez un compte paiement pour recevoir des paiements de la part de vos élèves.
    {/* You do not have payment account, please create an account to receive payments. */}
    </div>
    <Button style={{marginTop:"20px"}} onClick={createAccount}>CRÉER UN COMPTE</Button>

</div>:
null
}



                {/* <Button onClick={SaveModif}>CREATE AN ACCOUNT</Button>



                <Button onClick={GetModif}>GET ACCOUNT</Button>
 */}



<div style={{fontSize:"18px",color:"grey",fontWeight:"500",letterSpacing:"1px", marginTop:"80px"}}>
  Prix de l'abonnement à votre compte
    {/* Subscription price */}
</div>


<div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                    {/* Subscribing is Free */}
                    Abonnement gratuit
                    </div>
                </Try>

                <InputContainer1>


                <label className="switch" >
                    <input type="checkbox" checked={isVisible} onClick={() => {
                      if(!isVisible){
                        setMonth("") 
                        setThreeMonth("") 
                        setSixMonth("") 
                        setYear("") 
                      }

                      setVisibility(!isVisible)
                     }}/>
                    <span className="slider"></span>
                </label>



                </InputContainer1>
                
                </div>
<div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>



<Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Devise
                        </div>
                    </Try>
                    <InputContainer2>
            <Select name="diff" value={currency} onChange={(e) => changeCurrency(e)}>
            <Option>Euro</Option>
            <Option>U.S. Dollar</Option>
          </Select>
          </InputContainer2>

          </div>
<div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Tarif pour 1 mois
                        </div>
                    </Try>
                    
                    <InputContainer>
                    {
                      currency=="U.S. Dollar"?
                      <span style={{marginRight:"5px"}}>$</span>
:null
                    }
                    <Input value={Month} onChange={(e) => 
                    {setMonth(e.target.value) 
                      if(status==true&&Number(e.target.value)>0){
                        setVisibility(false)}
                      if(Number(e.target.value)<=0&&Number(ThreeMonth)<=0&&Number(SixMonth)<=0&&Number(Year)<=0){
                        setVisibility(true)
                      }
                      
                      }}></Input>
                    {
                      currency=="Euro"?
                      <span style={{marginLeft:"auto"}}> €</span>

:null
                    }
                    </InputContainer>
                </div>
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Tarif pour 3 mois
                        </div>
                    </Try>

                    <InputContainer>
                    {
                      currency=="U.S. Dollar"?
                      <span style={{marginRight:"5px"}}>$</span>
:null
                    }
                    <Input value={ThreeMonth} onChange={(e) => {
                      setThreeMonth(e.target.value)
                    
                      if(status==true&&Number(e.target.value)>0){
                        setVisibility(false)}
                      if(Number(e.target.value)<=0&&Number(Month)<=0&&Number(SixMonth)<=0&&Number(Year)<=0){
                        setVisibility(true)
                      }
                      
                      }
                    }></Input>
                    {
                      currency=="Euro"?
                      <span style={{marginLeft:"auto"}}> €</span>

:null
                    }
                    </InputContainer>
                </div>
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Tarif pour 6 mois
                        </div>
                    </Try>

                    <InputContainer>
                    {
                      currency=="U.S. Dollar"?
                      <span style={{marginRight:"5px"}}>$</span>
:null
                    }
                    
                    <Input value={SixMonth} onChange={(e) => {
                      setSixMonth(e.target.value)
                      if(status==true&&Number(e.target.value)>0){
                        setVisibility(false)}
                      if(Number(e.target.value)<=0&&Number(ThreeMonth)<=0&&Number(Month)<=0&&Number(Year)<=0){
                        setVisibility(true)
                      }
                      
                      }
                    
                    }></Input>
                    {
                      currency=="Euro"?
                      <span style={{marginLeft:"auto"}}> €</span>

:null
                    }
                    
                    </InputContainer>
                </div>
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px", marginBottom:"40px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Tarif pour 1 an
                        </div>
                    </Try>
                    
                    <InputContainer>
                    {
                      currency=="U.S. Dollar"?
                      <span style={{marginRight:"5px"}}>$</span>
:null
                    }
                    <Input value={Year} onChange={(e) => {
                      setYear(e.target.value)
                      if(status==true&&Number(e.target.value)>0){
                        setVisibility(false)}
                      if(Number(e.target.value)<=0&&Number(ThreeMonth)<=0&&Number(SixMonth)<=0&&Number(Month)<=0){
                        setVisibility(true)
                      }
                      
                      }}></Input>
                    {
                      currency=="Euro"?
                      <span style={{marginLeft:"auto"}}> €</span>

:null
                    }
                    </InputContainer>
                </div>

                <Button1 disabled={isdisable} onClick={SaveSubscription}>ENREGISTRER</Button1>
                {(error!="")?
          <div style={{color:"red",textAlign:"center",alignItems:"center",marginTop:"20px", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
              < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
          </div>
              : null}
                              {priceValidated?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", marginTop:"20px",display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Tarifs Sauvegardés
            </div>
                : null}

        </Container>
        </div>
    )
}

export default BankAccount




