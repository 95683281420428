import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';

import { useNavigate } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import { CheckOutlined } from "@mui/icons-material";
import ChooseSubscription from "./ChooseSubscription";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;

`;

const Wrapper = styled.div`
  ${mobile({width:"100%"})}

  width: 450px;
  height: auto;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 100px;
object-fit: cover;



`;
const Profilepicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  position:absolute;
  border: 1px solid #f2f9fb;
  top:50px;
  left:20px;



`;
const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"13px"})}
float:left;
margin-left:7px;
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px"})}


`;
// const Subscribe = styled.button`
// margin-left: auto;
// margin-right: auto;

// display:block;
//     text-align: center;
//    border: none;
//   background-color: #5f4fbb;
//   color: white;
//   cursor: pointer;
//   width:auto;
//   padding: 20px;
//   border-radius: 50px;
//   height:auto;
//     align-items: center;
//     transition:transform .2s ease;
// :hover {
//   transform:scale(1.03);
// }
// ${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
// ${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
// ${computer({fontSize:"16px", padding:"8px", marginTop:"15px",width:"65%", height:"50px"})}

// `;

const Text1 = styled.h2`
letter-spacing: 2px;
${mobile({fontSize:"13px"})}
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px", marginRight:"2vw"})}

margin-right:7px;
float:right;
word-spacing: 0.5px;


`;


const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"35px",width:"75%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"35px",width:"75%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"35px",width:"250px", height:"45px"})}

`;

const FollowUser = ({ads,Year,SixMonth, ThreeMonth,currency, Month, setOpen, owner, customer, Frequency, socket,user}) => {
  const [img, setImg] = useState(undefined);
  const [frequency, setFrequency] = useState(undefined);

  const [imgURL, setImgUrl] = useState(undefined);
  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();

const price="1";
  useEffect(() => {
      if(Frequency=="one_month"){
          setFrequency(" per month")
      }
      if(Frequency=="three_month"){
        setFrequency(" per 3 months")
    }
    if(Frequency=="six_month"){
        setFrequency(" per 6 months")
    }
    if(Frequency=="one_year"){
        setFrequency(" per year")
    }
    document.body.style.overflow = 'hidden';
    document.body.style.overflow = 'unset';
 }, []);



 const Checkout= async()=>{
        setOpen1(true)
        // const fol = await axios.put('http://51.178.60.185/api/connections/subscribe/'+customer._id,
        // {
        //      id:owner._id
        //  });

        // localStorage.setItem('userDetails',JSON.stringify(fol.data));

        // window.location.reload();

 }

  




  return (
    <>
    <Container>
        <Wrapper>
        <div style={{position:"relative", height:"170px"}}>
        <Picture src={ads+owner.coverPicture}/>
        <Profilepicture src={ads+owner.profilePicture}/>
        <h3 style={{position:"absolute", top:"110px", left:"150px"}}>
            {owner.username}
        </h3>
        </div>
        <h4 style={{ color:"#8a96a3", marginLeft:"20px"}}>
           {/* SUBSCRIBE TO THIS TEACHER TO ALLOW PRIVATE CHAT */}
           ABONNEZ-VOUS A CE PROFESSEUR POUR LUI ENVOYER DES MESSAGES
        </h4>
        {/* <div style={{ display:"flex", marginLeft:"20px", marginTop:"20px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Full acces to this user's content
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Direct message with this user
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"rgb(99 91 255)", marginRight:"10px"}}/> Cancel your subscription at any time
        </div> */}
        {/* <Subscribe onClick={() => Checkout()}>
          
                {currency=="U.S. Dollar"?
                <Text >{"CHECKOUT $" +price}</Text>
                :null}
                {currency=="Euro"?
                <Text >{"CHECKOUT "+price+ "€"}</Text>
                :null}
                </Subscribe> */}

                <Subscribe style={{display:"flex",textAlign:"center", justifyContent:"center"}} onClick={() => Checkout()}>
                <Text style={{fontSize:"inherit"}}>S'ABONNER</Text>

                </Subscribe>

                <div style={{borderTop: "1.5px solid #ececec", marginTop:"35px", height:"40px", alignItems:"center", display:"flex",float:"right"}}>
                    <h4 onClick={() => setOpen(false)} style={{cursor:"pointer",color:"#8798ef", float:"right", marginLeft:"auto", marginRight:"20px"}}>
                    FERMER
                    </h4>
                    
                </div>
        </Wrapper>

    </Container>
            {open1 && <ChooseSubscription ads={ads} user={user} socket={socket}  owner={owner}  setOpen={setOpen1} />}
        

            </>
  );
};

export default FollowUser;



