import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components'
import Navbar1 from './Navbar1'
import Chats from './Chats'
import { mobile,tablette, computer } from "../responsive";

const Container = styled.div`
    ${computer({width:"300px"})}
    ${tablette({flex:"1"})}


border-right:1px solid #3e3c61;
background-color: white;
`;

const Sidebar=({socket,setNC,nC,ads,setNM, chats,setChats, isWritting, user, chatId, setChatId, newLastMessage,arrivalMessage, setArrivalMessages, connectedUsers})=> {
    const [selected, setSelected] = useState(undefined);

    return (
        <Container>
            <Navbar1 ads={ads} user={user} setSelected={setSelected} selected={selected}/>
            <Chats socket={socket} setNC={setNC} nC={nC} ads={ads} setNM={setNM} chats={chats} setChats={setChats} isWritting={isWritting} connectedUsers={connectedUsers} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} newLastMessage={newLastMessage} chatId={chatId} setChatId={setChatId} user={user} selected={selected}/>
        </Container>
    )
}

export default Sidebar