import React from 'react'
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useState, useEffect,useRef} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";
import { Editor } from '@tinymce/tinymce-react';
import Cate from './CateB'
import CateS from './CateSB'

const Container = styled.div`
  padding: 0px;
  height: auto;
  align-items: center;
  margin-left: 0;
  margin-right:0;
  margin-top:30px;
  width:100%;
  ${mobile({width:"100%", padding:"0", marginTop:"80px"})};

`;



const Wrapper1 = styled.div`
    width:100%;
    height: auto;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
  ${tablette({fontSize:"13px", width:"95%"})};
  ${mobile({fontSize:"13px"})};
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  ${mobile({fontSize:"13px"})};

  ${tablette({fontSize:"18px"})};


`;
const Label = styled.label`
  font-size: 18px;
`;
const Label1 = styled.label`
  font-size: 18px;
  ${mobile({display:"none"})};

`;

const Th = styled.div`
border:1px solid black;
padding:40px;
width:640px;
${tablette({width:"500px"})}
${mobile({width:"60vw"})}
`;



const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width:80%;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;
${mobile({width: "60vw", height:"33.9vw"})}
${tablette({width: "500px", height:"282px"})}

`;
const Button = styled.button`
  width: 30%;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;

const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;


const Options1=styled.div`
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
  font-size:17px;
`;
const Option = styled.option`
height:18px;
`;

const Courseparameters = ({user,ads, Video }) => {
    const navigate = useNavigate();

    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState(undefined);
    const [video, setVideo] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [theArray, setTheArray] = useState([]);
    const [imgURL, setImgUrl] = useState(undefined);
    const [videoSaved,setVideoSave]= useState(0);
    const [showButton,setShowButton]= useState(true);
    const [thechecked, setcheck] = useState([]);
    const [newT, setT] = useState(true);
    const [privacy, setPrivacy] = useState("Public");
    const editorRef = useRef(null);


    const showD = useRef();
    const showB = useRef();
    const showD1 = useRef();

    const [progress, setProgress] = React.useState(0);



    function handleChange(e) {
      const value = e.target.value;
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
        if(jj==value){
          FF[jj]=true
        }else{
          FF[jj]=false
        }
      }
      setcheck(FF);
      setVideoSave(value);
      if(newT==true){
        setT(false)
      }
    }

    function handleNew(e){
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {

          FF[jj]=false
        
      }
      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true);

    }


    const handleFilters = (e) => {
        const value = e.target.value;
        setFilters({
          ...filters,
          [e.target.name]: value,
        });
        
      };
    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }






    const changePP=(e)=>{
      setImg(e.target.files[0]);
      setImgUrl(URL.createObjectURL(e.target.files[0]))
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
          FF[jj]=false
        }

      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true)
      // if(e.target.files[0].size>1000000){
      //     setTooLarge(true);
      // }else{
      //     setTooLarge(false);
      // }
      // setshowImage(true)
    }



    useEffect(async() => {
        try { 
            const Creator=(await axios.get(ads+'api/users/'+Video.creatorId)).data;
            setCreator(Creator);
            setTitle(Video.title);
            setDescription(Video.description)
            setFilters({
                
              ["diff"]: Video.difficulty,
              ["intens"]: Video.intensity,

            });
            setTheArray(Video.style)

            var CC=[true];
            for (let ii = 1; ii < Video.thumbnails.length; ii++) {
              CC[ii]=false}
            setcheck(CC)

        }catch(e){


        }
  
    },[])

    const createCourse= async ()=>{

      var Thumb;

      if(videoSaved==Video.thumbnails.length){
        showB.current.style.display="none";
        showD.current.style.display="block";
        showD1.current.style.display="block";
        const formData = new FormData();
        formData.append('profile', img, img.name);
        await axios.post(ads+'api/video/thumbnails/'+Video._id, formData,
        {   
          onUploadProgress: progressEvent => {
            setProgress((progressEvent.loaded / progressEvent.total)*100)
          }
        });
        const Video1=(await axios.get(ads+'api/video/'+Video._id)).data;
        Thumb=[Video1.thumbnails[Video1.thumbnails.length-1]].concat(Video.thumbnails)
      }else{
        Video.thumbnails=[Video.thumbnails[videoSaved]].concat(Video.thumbnails);
        
        Video.thumbnails.splice(Number(videoSaved)+1,1)


        Thumb=Video.thumbnails
      }


      const savedClasse=(await axios.post(ads+'api/classe/'+Video.creatorId,
      {

              "creatorId":Video.creatorId,
              "videoId": Video._id,
              "bunnyId":Video.bunnyId,
              "title":title,
              "description":editorRef.current.getContent(),
              "difficulty":filters.diff,
              "intensity": filters.intens,
              "style":theArray,
              "thumbnails":Thumb,
              "privacy":privacy,
              "duration":Video.duration


            
      }))
      navigate('/profile/'+user._id);
    }


    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }
      
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };
    const updateVideo= async () =>{

            // if (description != ''){
            //     await axios.put(ads+'api/video/'+Video._id,{"description":description});
            // }
            // if (title != ''){
            //     await axios.put(ads+'api/video/'+Video._id,{"title":title});
            // }
            // if(video!=undefined){
            //     const formData = new FormData()
            //     formData.append('profile', video, video.name)
            //     const res1 = await axios.put("http://51.178.60.185/api/video/video/"+Video._id,formData,{   
            //         onUploadProgress: progressEvent => {
            //             console.log(progressEvent.loaded / progressEvent.total);
            //         }
            //       })
            // }
            // if(img!=undefined){
            //     const formDataThumb = new FormData()
            //     formDataThumb.append('profile', img, img.name)
            //     const thum = await axios.post("http://51.178.60.185/api/video/thumbnails/"+Video._id,formDataThumb)
            // }
            navigate('/selectcourse2/'+Video._id);
        }
    


    return (
        
        <Container>
            <h2 style={{textAlign:"center"}}>
            Étape 2 : Définissez les caractéristiques de votre Cours
</h2>
            <Wrapper2>
            <Label>Titre :</Label>

<Input
value={title}
  type="text"
  placeholder="Titre"
  name="title"
  onChange={(e) => setTitle(e.target.value)}
/>
<br/>
<Label>Description :</Label>
<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={description}

        init={{
          placeholder: "Bio",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />
{/* <Desc
value={description}

          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => setDescription(e.target.value)}
        /> */}
<br/>
<div style={{display:"flex", alignItems:"center"}}>
<Label>Sélectionnez votre miniature :</Label>




</div>
{Video.thumbnails?.map((item,i) => (
<Th  key={i}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" 
      
      value={i}
      onChange={(e) => handleChange(e)}
      checked={thechecked[i]} 
      
      
      />
    </div>
<Image  src={ads+item} />

</Th>

))}
{imgURL?
  <div style={{border:"1px solid black", padding:"40px",width:"640px"}}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" checked={newT} onChange={(e) => handleNew(e)} />
    </div>
<Image src={imgURL}/>
</div>

:null}
<div style={{display:"flex", alignItems:"center"}}>
<Label>Télécharger une miniature :</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => changePP(e)}
  
/>
</div>
<br/>


<OptionsS >

<CateS theArray={theArray} setTheArray={setTheArray}/>

<div style={{marginTop:"10px"}}>
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>
          </div>
          <div style={{marginTop:"30px"}}>
          <Label >Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>

          </div>

        </OptionsS>

<Options>
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Difficulté :</Label>
            <Select name="difficulty" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"40px"}}>Intensité :</Label>
            <Select name="intensity" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>
</div>
</Options>
<br/>

<Cate theArray={theArray} setTheArray={setTheArray}/>



        <br/>
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de confidentialité :</Label>
            <Select name="privacy" onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
            <Option>Subscribers</Option>
          </Select>
</div>
            </Wrapper2>






<div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}} ref={showB}>
<Button onClick={createCourse} >PUBLIER</Button>
</div>


<div style={{display:"flex", marginTop:"10px", marginBottom:"20px", justifyContent:"center",display:"none",textAlign:"center"}} ref={showD}>
  Chargement
</div>

<div style={{display:"flex", marginTop:"10px", marginBottom:"80px", justifyContent:"center",display:"none",textAlign:"center"}} ref={showD1}>
<CircularProgressWithLabel value={progress} />
</div>



           
        </Container>
        
    )
}

export default Courseparameters
