import React from 'react'
import styled from "styled-components";
import { mobile,tablette,computer } from "../responsive";
import { useState, useEffect} from "react";

import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';

const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  margin-top: 10px;
  justify-content: space-around;
  height: 5vh;
  align-items: center;
  width: 83.5%;
  margin-left: auto;
  margin-right:auto;
  background-color: #fafafa;

`;

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: 10vh;
  align-items: center;
  width: 85.5%;
  margin-left: auto;
  margin-right:auto;
  overflow-y: hidden;
  ${mobile({ height:"2vh"})};
  ${tablette({ height:"4vh"})};
`;

const FilteritemS =styled.h1`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    ${mobile({ fontSize:'12px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};
`;

const Filteritem =styled.h1`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    &:hover {
        color:black;
    }
    color:grey;
    ${mobile({ fontSize:'12px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};

`;
const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: grey;
    cursor:pointer;
    &:hover {
        color:black;
    }
`;




const Tabs = ({tab,setTab}) => {
    const [hidden, setHidden] = useState(tab+1);
    useEffect(() => {
        setHidden(tab+1);

      }, [tab]);
    const show = (number) =>{
        setHidden(s => number);
        const nt = number-1
        setTab(nt)
    }

        return (
    <div>
        <Container>
            {(hidden!=1)?
            <Filteritem onClick={() => show(1)}>
            PUBLICATIONS
            </Filteritem>
            : null}

            {(hidden==1)?
            <FilteritemS onClick={() => show(1)}>
            PUBLICATIONS
            
            </FilteritemS>
            : null}

            {(hidden!=5)?
            <Filteritem onClick={() => show(5)}>
            FAVORIS
           
            </Filteritem>
            : null}

            {(hidden==5)?
                <FilteritemS onClick={() => show(5)}>
                FAVORIS
                
                </FilteritemS>
            : null}


        </Container>
                
        
 

    </div>
    )
}

export default Tabs
