import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Container = styled.div`
z-index:3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 800px;
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 5px;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h2`
  text-align: center;
  margin-top:30px;
`;










const Yes = styled.button`
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  border: 1px solid #8798ef;
  background-color: #8798ef;
  color: white;
  border-radius: 30px;
`;

const No = styled.button`
   border: 1px solid #8798ef;
  background-color: white;
  color: #8798ef;
    padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: white;
  margin-right:50px;
  border-radius: 30px;

`;


const DeletePost = ({ ads,setOpen, item, user}) => {
    const navigate = useNavigate();
const deleteP = async()=>{

   await axios.delete(ads+'api/chat/conversation/'+user._id+'/'+item.budy_id)
    window.location.reload();



}








  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpen(false)}>
          <CloseOutlinedIcon style={{ cursor:"pointer", fontSize:"18px", color:"grey"}}/>
        </Close>
        <Title>Voulez-vous vraiment supprimer votre conversation avec {item.budy_username} ?</Title>
        <div style={{display:"flex",justifyContent:"center"}}>
        <No onClick={() => setOpen(false)}>No</No>
<Yes onClick={() => deleteP()}>Yes</Yes>
        </div>

      </Wrapper>
    </Container>
  );
};

export default DeletePost;