import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import { Editor } from '@tinymce/tinymce-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import { CheckOutlined } from "@mui/icons-material";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import './App.css';



const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;

`;

const Wrapper = styled.div`
  ${mobile({width:"100%"})}

  width: 450px;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius:10px;

`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;


const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 100px;
object-fit: cover;



`;



const Teapict= styled.img`
width: 100px;
height: 100px;
object-fit: cover;




`;
const Profilepicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  top:50px;
  left:20px;



`;
const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"13px"})}
text-align: center;

${tablette({fontSize:"14px"})}
${computer({fontSize:"14px"})}


`;
const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;
display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
font-weight:700;
${mobile({fontSize:"16px", padding:"8px", marginTop:"25px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"25px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"35px",width:"150px", height:"40px"})}
:disabled{
    background-color:#b3c9f9;
    cursor: auto;
  }
`;

const Follow3 = styled.button`
    border: 1px solid #8a96a3;
  background-color: white;
  color: #8798ef;
  cursor: pointer;
  border-radius: 50%;
  width:40px;
  height: 40px;
  margin-left:10px;
  z-index:2;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"13px", padding:"8px", left:"55%", top:"20px"})}
${tablette({fontSize:"13px", padding:"8px", left:"55%", top:"20px"})}
${computer({fontSize:"13px",  left:"520px", top:"20px"})}

`;

const Follow4 = styled.button`
    border: 1px solid #8a96a3;
  background-color:#8798ef ;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width:40px;
  height: 40px;
  margin-left:10px;
  z-index:2;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"13px", padding:"8px", left:"55%", top:"20px"})}
${tablette({fontSize:"13px", padding:"8px", left:"55%", top:"20px"})}
${computer({fontSize:"13px",  left:"520px", top:"20px"})}

`;

const InputContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"0px" })}

`;
const Input = styled.input`
  flex: 1;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
filter: none;
width: 60%;
margin-left: auto;
margin-right: auto;
`;

const Validatesubscription = ({ ads,socket,setOpen, owner, user, currency }) => {
  const [img, setImg] = useState(undefined);
  const [selected, setSelected] = useState(1);
  const [number, setNumber] = useState(null);
  const [showSpin, setshowSpin] = useState(false);
  const [isdisable, setIsdisable] = useState(false);
  const [email, setEmail] = useState("");

  const [imgURL, setImgUrl] = useState(undefined);
  const [show, setShow] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();

  const editorRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
 }, []);



 const Checkout= async()=>{
    setIsdisable(true)
    setshowSpin(true)

 
    const check=await axios.post(ads+'api/patients/invite/'+owner._id, {"email":email});
        setShow(true)
        setshowSpin(false)
    //window.location = check.data.url;
 }




  




  return (
    <Container>
        <Wrapper>
            <div style={{height:"80px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"20px"}}>
               
               <span style={{fontSize:"17px", fontWeight:"500", marginLeft:"5px"}}>
               Entrez l'adresse email de votre patient

               </span>
            </div>


        <Form>
          <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
        </Form>



        <Subscribe disabled={isdisable} onClick={() => Checkout()}>
         ENVOYER
                </Subscribe>
                {showSpin?
                    <div style={{marginLeft:"auto", marginRight:"auto", marginTop:"30px"}}>
                    <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    </div>
                    </div>
                    :null}

{show?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"20px",marginBottom:"20px"}}>
            < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Invitation envoyée !
        </div>:null
}



                <div style={{borderTop: "1.5px solid #ececec", marginTop:"20px", height:"40px", alignItems:"center", display:"flex",float:"right"}}>
                    <h4 onClick={() => setOpen(false)} style={{cursor:"pointer",color:"#8798ef", float:"right", marginLeft:"auto", marginRight:"20px"}}>
                    FERMER
                    </h4>
                    
                </div>
        </Wrapper>

    </Container>
  );
};

export default Validatesubscription;



