import { css } from "styled-components";

export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 480px) {
      ${props}
    }
  `;
};

export const tablette = (props) => {
    return css`
      @media only screen and (min-width:481px) and (max-width: 1100px) {
        ${props}
      }
    `;
  };

export const computer = (props) => {
    return css`
      @media only screen and (min-width: 1100px) {
        ${props}
      }
    `;
  };

  export const computerLanding = (props) => {
    return css`
      @media only screen and (min-width: 1001px) {
        ${props}
      }
    `;
  };


  export const mobileLanding = (props) => {
    return css`
      @media only screen and (max-width: 680px) {
        ${props}
      }
    `;
  };

  export const tabletteLanding = (props) => {
    return css`
      @media only screen and (min-width:681px) and (max-width: 1000px) {
        ${props}
      }
    `;
  };