import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components'
import Navbar1 from './Navbar1'
import ChatsS from './ChatsS'

const Container = styled.div`
flex:1;
background-color: white;
height:100%;
`;

const Sidebar=({nM, newImage,setNewImage,setNC,nC,ads,socket, setNM, chats,setChats, isWritting, user, chatId, setChatId, newLastMessage,arrivalMessage, setArrivalMessages, connectedUsers})=> {
    const [selected, setSelected] = useState(undefined);

    return (
        <Container>
            <Navbar1 ads={ads} user={user} setSelected={setSelected} selected={selected}/>
            <ChatsS nM={nM} newImage={newImage} setNewImage={setNewImage} setNC={setNC} nC={nC} ads={ads} socket={socket} setNM={setNM} chats={chats} setChats={setChats} isWritting={isWritting} connectedUsers={connectedUsers} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} newLastMessage={newLastMessage} chatId={chatId} setChatId={setChatId} user={user} selected={selected}/>
        </Container>
    )
}

export default Sidebar