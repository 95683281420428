import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";


const Container = styled.div`
z-index:7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 600px;
  height: auto;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h1`
  text-align: center;
height:120px;
align-items: center;
display: flex;
justify-content: center;
`;

const Input = styled.input`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  z-index: 999;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;
const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
`;







const Label = styled.label`
  font-size: 14px;
`;
const Publish = ({ setOpen, user }) => {
  const [img, setImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");




  const navigate = useNavigate()

//   const handleChange = (e) => {
//     setInputs((prev) => {
//       return { ...prev, [e.target.name]: e.target.value };
//     });
//   };

//   const handleTags = (e) => {
//     setTags(e.target.value.split(","));
//   };




useEffect(() => {
  document.body.style.overflow = 'hidden';
  return ()=> document.body.style.overflow = 'unset';
}, []);







  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpen(false)}>X</Close>
        <Title>
          Que voulez-vous publier ?
          {/* What Do You Want To Publish? */}
          </Title>
        {user.teacher?
        <Link to="/selectseries" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <div style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"80px",alignItems:"center", cursor:"pointer", color:"teal",fontWeight:"700"}}>
            Une nouvelle Série
        </div>
        </Link>
        :null}
        {user.teacher?
        <Link to="/selectcourse" style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <div style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"80px",alignItems:"center", cursor:"pointer", color:"#F65E36",fontWeight:"700"}}>
            Un nouveau Cours
        </div>
        </Link>
        :null}
        {user.teacher?
        <Link to="/createarticle" style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <div style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"80px",alignItems:"center", cursor:"pointer", color:"#833FEB",fontWeight:"700"}}>
           Un nouvel Article
        </div>
        </Link>
        :null}
        <Link to="/createpost" style={{ color: 'inherit', textDecoration: 'inherit'}}>

<div style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"80px",alignItems:"center", cursor:"pointer", color:"#00008B",fontWeight:"700"}}>
   Un nouveau Post
</div>
</Link>


        
      </Wrapper>
    </Container>
  );
};

export default Publish;