import React from 'react'
import styled from "styled-components";
import { useState } from "react";
import { mobile,tablette,computer } from "../responsive";




const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: 10vh;
  align-items: center;
  width: 85.5%;
  margin-left: auto;
  margin-right:auto;
  overflow-y: hidden;
  margin-bottom:50px;
  border:1px solid lightgrey;
  ${mobile({  fontSize:"14px", width:"50vw",marginTop:"60px"})};
  ${tablette({  fontSize:"18px", width:"50vw",marginTop:"80px", marginBottom:"80px"})};
  ${computer({ fontSize:"18px", width:"50vw",marginTop:"100px"})};

`;




const PrivateAccount = () => {


        return (
        <Container>
{/* This account is private, follow to see their posts. */}
Ce compte est privé, suivez cet utilisateur pour voir ses Posts.
        </Container>
                
        
 

    )
}

export default PrivateAccount
