import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { mobile, computer,tablette } from "../responsive";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';

const Container = styled.div`
  width: 100vw;

  min-height: calc(100vh - 63px);
  /* background: linear-gradient(
      rgba(104,146,181, 0.5),
      rgba(104,146,181, 0.5)
    ),
    url("./signup.svg")
      center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  ${mobile({ background:"none",minHeight: "calc(100vh - 100px)" })};
  ${tablette({ alignItems:"center" })};

  ${computer({ alignItems:"center" })};
`;

const Wrapper = styled.div`
  padding: 20px;
  border-radius:10px;
  border:1px solid lightgrey;

  background-color: white;
  ${mobile({ width:"100%", borderRadius:"0px",border:"none"})};
  ${tablette({ width:"450px", marginTop:"40px", marginBottom:"40px" })};
  ${computer({ width:"450px", marginTop:"40px", marginBottom:"40px" })};

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align:center;
  margin-bottom:30px;
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"60px" })}

`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  background-color:#8798ef;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
  margin-bottom: 30px;
  :disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
const ButtonR = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color:white ;
  border: 1px solid teal;
  color: teal;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:0px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;


const Login = ({ads,setUser, setConnected}) => {
  const navigate = useNavigate();
  const [isdisable, setIsdisable] = useState(true);

  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const LogMe = async (e) =>{
    e.preventDefault();
    setIsdisable(true);
    setError("");
    try{
       const myToken=await axios.post(ads+'api/mail/forgotpassword',{ "email":username});
        setShow(true)
       //   localStorage.setItem('user',JSON.stringify(myToken.data));
    //   localStorage.setItem('userDetails',JSON.stringify(myToken.data));
    //   setUser(myToken);
    //   setConnected(true);
      
    }catch(e) {
        setError(s=>e.response.data);
    }    
  }


  return (
    <div>
    <Container>
      <Wrapper>
      <Title>
        <h2 style={{letterSpacing: "-1.0px", fontWeight:"900", color:"rgb(126 145 241)", fontSize:"40px"}}>
          <span style={{color:"rgb(26, 186, 185)"}}>Om</span>Care.</h2>
        </Title>
        <Title>
        <h5 style={{letterSpacing: "1.0px", fontWeight:"500"}}>
        {/* Enter your email address, and we'll send you a link to get back into your account. */}
        Veuillez entrer votre adresse email, nous vous enverrons un lien pour réinitialiser votre mot de passe.
         </h5>
        </Title>

        <Form>
          <Input placeholder="Email" onChange={(e) => {
            setUsername(e.target.value);
            if(e.target.value!=""){
              setIsdisable(false)
            }else{
              setIsdisable(true)
            }}}/>
          <Button disabled={isdisable} onClick={LogMe} >ENVOYER</Button>


        </Form>
        {show?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Email Envoyé
            </div>
                : null}
        {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}
        {/* <div style={{display:"flex", flexDirection:"column"}}>
          <Link to="/signup" style={{margin: "5px 0px",fontSize: "12px",color:"black"}}>DO NOT YOU REMEMBER YOUR PASSWORD?</Link>
          <Link to="/signup" style={{margin: "5px 0px",fontSize: "12px",color:"black"}}>DON'T HAVE AN ACCOUNT? SIGN UP</Link>
          </div> */}

      </Wrapper>
    </Container>
            <Footer/>
            <FooterS/>
    
            </div>
  );
};

export default Login;