import Classe from "./pages/Classe";
import ClasseSeries from "./pages/ClasseSeries";
import Article from "./pages/Article";
import Home from "./pages/Home";
import Mentors from "./pages/Mentors";
import SaveCard from "./pages/SaveCard";
import PayAndSave from "./pages/PayAndSave";
import PaymentstatusSub from "./pages/PaymentstatusSub";
import PayTeaAndSave from "./pages/PayTeaAndSave";
import PaymentstatusTea from "./pages/PaymentstatusTea";
import ConditionsGenerales from "./pages/ConditionsGenerales";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
import ContratType from "./pages/ContratType";
import MentionsLegales from "./pages/MentionsLegales";
import MyPatients from "./pages/MyPatients";

import Paymentstatus from "./pages/Paymentstatus";
import InvitationSignUp from "./pages/InvitationSignUp";

import Landing from "./pages/Landing";
import LandingProf from "./pages/LandingProf";

import Profile from "./pages/Profile";
import NewProfile from "./pages/NewProfile";

import Teacherprofile from "./pages/Teacherprofile";

import Signup from "./pages/Signup";
import SigninSimple from "./pages/Signino";

import Signin from "./pages/Signin";
import SigninError from "./pages/SigninError";

import SigninO from "./pages/signinoauth";

import Params from "./pages/Params";
import Modifyvideo from "./pages/Modifyvideo";

import ModifyPost from "./pages/ModifyPost";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Videos from "./pages/Videos";
import Exercices from "./pages/Exercices";
import Exercice from "./pages/Exercice";

import Selectcourses from "./pages/Selectcourses";
import Selectcourses1 from "./pages/Selectcourses1";
import Selectcourses2 from "./pages/Selectcourses2";
import Selectseries from "./pages/Selectseries";
import Selectseries1 from "./pages/Selectseries1";
import Selectseries11 from "./pages/Selectseries11";
import Createarticle from "./pages/Createarticle";
import Createpost from "./pages/Createpost";
import Chat from "./pages/Chat";
import ParamsBank from "./pages/ParamsBank";
import ValidatePraticien from "./pages/ValidatePraticien";
import CreateExercice from "./pages/CreateExercice";
import SelectExercices from "./pages/SelectExercices";
import SelectExercices2 from "./pages/SelectExercices2";
import SelectExercices3 from "./pages/SelectExercices3";
import SelectExercices4 from "./pages/SelectExercices4";
import Patient from "./pages/Patient";
import MesSeances from "./pages/MesSeances";
import MaSeance from "./pages/MaSeance";
import LaSeance from "./pages/LaSeance";

import Training from "./pages/Training";


import Selectseries2 from "./pages/Selectseries2";
import SeriesOverview from "./pages/SeriesOverview";
import Modifyclass from "./pages/Modifyclass";
import ModifySeries from "./pages/ModifySeries";
import ModifyArticle from "./pages/ModifyArticle";
import ModifyExercices from "./pages/ModifyExercices";
import ModifyExercices2 from "./pages/ModifyExercices2";
import ModifyExercices3 from "./pages/ModifyExercices3";
import ModifyExercices4 from "./pages/ModifyExercices4";

import ModifyClassOrderM from "./pages/ModifyClassOrderM";
import ModifyClassOrder from "./pages/ModifyClassOrder";
import Verifyemail from "./pages/Verifyemail";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import io from "socket.io-client"

import AddClasses from "./pages/AddClasses";
import AddClasses2 from "./pages/AddClasses2";

import axios from "axios";
import { useState, useEffect,useRef } from "react";
require('dotenv').config()

const Protected = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
  return <Navigate to="/signin" replace />;
  }
  return children;
  };

const Protected1 = ({ isLoggedIn,userId, children }) => {
    if (isLoggedIn) {
    return <Navigate to={"/profile/"+userId} replace />;
    }
    return children;
    };

const App = () => {
  const socket = useRef();
  const ads = "https://www.omcare.fr/";
  //const ads = "";
  const [user, setUser] = useState(null);
  const [connected, setConnected] = useState(false);
  const [chatId, setChatId] = useState(undefined);
  const [nM, setNM] = useState(false);
  const [nC, setNC] = useState([]);

  const [connectedUsers, setConnectedUsers] = useState(null)
  const [loaded, setLoaded] = useState(false);



  useEffect(()=>{
        
    socket.current = io("https://www.omcare.fr/");
    //socket.current = io("http://localhost:8080");

    //socket.current = io("ws://localhost:8900");
    socket.current.on("getMessage", data=>{
            setNM(true)
            var nc1=[...nC];
            nc1.push(data.senderId);
            console.log(nc1)
            console.log(data)
            setNC(nc1);
    })

    socket.current.on("getUsers", users=>{
      setConnectedUsers(users)


  })
  socket.current.on("user-joined", data=>{
    console.log(data.message)


})

},[])

  useEffect(async() => {
    try { 
      //const tempuser = JSON.parse(localStorage.user);
      // var Config = {headers: {
      //     token: "Bearer " + tempuser.accessToken
      //     }
      // }
      const user1=(await axios.get(ads+'api/users/account/me')).data;
      if(user1.user=="unknown"){
        setConnected(false)
        setUser("unknown");
        setLoaded(true)
        console.log("unknown")
      }else{
        setUser(user1); 
        setConnected(true)
        socket.current.emit("addUser", user1._id)
        socket.current.emit("addUserRoom", user1._id)

        setLoaded(true)
      }


    }catch(e){
      setConnected(false)
      setUser("unknown");
      setLoaded(true)


    }
},[])
  return loaded? (
    <Router>
      <Routes>
      {/* <Route path="/" element={<Protected1 isLoggedIn={connected} userId={user._id}>
        <Signin ads={ads} socket={socket} setUser={setUser} setConnected={setConnected}/>
        </Protected1>}/> */}

      <Route path="/" element={<Protected1 isLoggedIn={connected} userId={user._id}>
        <Landing ads={ads} />
        </Protected1>}/>

      {/* <Route path="/" element={<Landing ads={ads}/>}/> */}

      <Route path="/becomepraticien" element={<Protected1 isLoggedIn={connected} userId={user._id}>
        <LandingProf ads={ads} />
        </Protected1>}/>

          <Route path="/home" element={
            <Protected isLoggedIn={connected}>
              <Home ads={ads} setUser={setUser} setNM={setNM} nM={nM}  socket={socket} user={user} connected={connected}/>
            </Protected>} 
          />
        

        
          <Route path="/chat/:id" element={<Protected isLoggedIn={connected}>
            <Chat setNC={setNC} nC={nC} ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setNM={setNM} nM={nM} socket={socket} chatId={chatId} setChatId={setChatId} user={user} connected={connected}/>
            </Protected>} />
        
          <Route path="/chat" element={<Protected isLoggedIn={connected}>
            
          <Chat setNC={setNC} nC={nC} ads={ads}  connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setNM={setNM} nM={nM} socket={socket} chatId={chatId} setChatId={setChatId} user={user} connected={connected}/>
          </Protected>} />
          <Route path="/exercices" element={<Exercices ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/selectexercices" element={<SelectExercices ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/modifyexercices/:id" element={<ModifyExercices ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/modifyexercices2" element={<ModifyExercices2 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/modifyexercices3" element={<ModifyExercices3 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/modifyexercices4" element={<ModifyExercices4 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />

          <Route path="/selectexercices2" element={<SelectExercices2 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/selectexercices3" element={<SelectExercices3 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/selectexercices4" element={<SelectExercices4 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/messeances" element={<MesSeances ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/maseance/:id" element={<MaSeance ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/training/:id" element={<Training ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/laseance/:id" element={<LaSeance ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />

          <Route path="/mespatients" element={<MyPatients ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/patient/:id" element={<Patient ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />

          <Route path="/exercice/:id" element={<Exercice ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
          <Route path="/createexercice" element={<CreateExercice ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />

          <Route path="/invitationpatient/:id1/:id2" element={<Protected1 isLoggedIn={connected} userId={user._id}>
        <InvitationSignUp ads={ads} setUser={setUser} setConnected={setConnected}/>
        </Protected1>}/>

        <Route path="/mentors" element={<Mentors ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>} />
        
        {/* <Route path="/stripe" element={<Stripe/>} /> */}
        
          <Route path="/savecard" element={<Protected isLoggedIn={connected}>
          <SaveCard ads={ads} setNM={setNM} setUser={setUser} nM={nM} socket={socket} user={user} connected={connected}/>
          </Protected>} />
        

        <Route path="/verifysub" element={<Protected isLoggedIn={connected}>
        <PaymentstatusSub setUser={setUser} ads={ads} user={user} socket={socket}/>
        </Protected>} />

        <Route path="/verifytea" element={<Protected isLoggedIn={connected}>
          <PaymentstatusTea user={user} ads={ads} socket={socket}/>
          </Protected>} />
        
        <Route path="/banksubscription" element={
        <Protected isLoggedIn={connected}>
          <ParamsBank ads={ads} setNM={setNM} setUser={setUser} nM={nM} socket={socket} user={user} connected={connected}/>
          </Protected>} />




        <Route path="/signin" element={<Protected1 isLoggedIn={connected} userId={user._id}>

        <Signin ads={ads} socket={socket} setUser={setUser} setConnected={setConnected}/>
        </Protected1>}/>

        <Route path="/signinoauth" element={<SigninO ads={ads} socket={socket} user={user} setUser={setUser} setConnected={setConnected}/>}/>
        <Route path="/signino" element={<SigninSimple ads={ads} socket={socket} user={user} setUser={setUser} setConnected={setConnected}/>}/>
        <Route path="/signinerror" element={<SigninError ads={ads} socket={socket} user={user} setUser={setUser} setConnected={setConnected}/>}/>


        <Route path="/signup" element={<Protected1 isLoggedIn={connected} userId={user._id}>
        <Signup ads={ads} setUser={setUser} setConnected={setConnected}/>
        </Protected1>}/>
        <Route path="/conditionsgenerales" element={<ConditionsGenerales ads={ads}/>}/>
        <Route path="/politiqueconfidentialite" element={<PolitiqueConfidentialite ads={ads}/>}/>
        <Route path="/contrattype" element={<ContratType ads={ads}/>}/>
        <Route path="/mentionslegales" element={<MentionsLegales ads={ads}/>}/>

        <Route path="/forgotpassword" element={<Forgotpassword ads={ads}/>}/>
        <Route path="/resetpassword/:id1/:id2" element={<Resetpassword ads={ads}/>}/>

        <Route path="/paymentstatus" element={
          <Protected isLoggedIn={connected}>
        <Paymentstatus ads={ads} user={user}/>
        </Protected>}/>

        <Route path="/payands" element={
        <Protected isLoggedIn={connected}><PayAndSave ads={ads} user={user}/>
        </Protected>}/>

        
        <Route path="/payandt" element={
        <Protected isLoggedIn={connected}><PayTeaAndSave user={user} ads={ads}/>
        </Protected>}/>

        <Route path="/verifyemail" element={<Verifyemail ads={ads}/>}/>

        <Route path="/seriesoverview/:id" element={
        <SeriesOverview  ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected} />}/>


        <Route path="/myprofile" element={
        <Protected isLoggedIn={connected}>
        <Profile ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected} />
        </Protected>
        }/>

        <Route path="/validatepraticien" element={
    
        <ValidatePraticien setConnected={setConnected} ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected} />

        }/>



        {/* <Route path="/profil/:id" element={<Teacherprofile ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket} user={user} connected={connected}/>}/> */}
        <Route path="/profile/:id" element={<NewProfile ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/>}/>
        {/* <Route path="/profile/:id" element={<NewProfile ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket} user={user} connected={connected}/>}/> */}
        {/* <Route  path="/profile/:id/" element={<NewProfile ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket} user={user} connected={connected}/>}/> */}

        <Route path="/article/:id" element={
         <Article ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/>}/>

        <Route path="/classe/:id" element={
         <Classe ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/>}/>

        <Route path="/classes/:id/:n" element={
         <ClasseSeries ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/>}/>

        <Route path="/settings" element={
         <Protected isLoggedIn={connected}><Params ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}  /></Protected>}/>

        <Route path="/videos" element={
         <Protected isLoggedIn={connected}><Videos ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/modifyvideo/:id" element={
         <Protected isLoggedIn={connected}><Modifyvideo ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/modifyclass/:id/:n" element={
         <Protected isLoggedIn={connected}><Modifyclass ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>
        
        <Route path="/modifyseries/:id" element={
         <Protected isLoggedIn={connected}><ModifySeries ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/modifyarticle/:id" element={
        <Protected isLoggedIn={connected}><ModifyArticle ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/modifyclasseorder/:id" element={
         <Protected isLoggedIn={connected}><ModifyClassOrder ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/addclassesorder/:id" element={
         <Protected isLoggedIn={connected}><ModifyClassOrderM ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

        <Route path="/addclasses/:id" element={
         <Protected isLoggedIn={connected}><AddClasses ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>

          <Route path="/addclasses2/:id" element={
         <Protected isLoggedIn={connected}><AddClasses2 ads={ads} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/></Protected>}/>



        <Route path="/modifypost/:id" element={
        <Protected isLoggedIn={connected}><ModifyPost ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket} connected={connected}/></Protected>}/>

        <Route path="/selectcourse" element={
         <Protected isLoggedIn={connected}><Selectcourses ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/selectcourse1/:id" element={
         <Protected isLoggedIn={connected}><Selectcourses1 ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/selectcourse2/:id" element={
         <Protected isLoggedIn={connected}><Selectcourses2 ads={ads} user={user} connected={connected}/></Protected>}/>

        <Route path="/selectseries" element={
         <Protected isLoggedIn={connected}><Selectseries  ads={ads}setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/selectseries1/:id" element={
         <Protected isLoggedIn={connected}><Selectseries1 ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/selectseries11/:id" element={
         <Protected isLoggedIn={connected}><Selectseries11  ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/selectseries2/:id" element={
         <Protected isLoggedIn={connected}><Selectseries2 ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>



        <Route path="/createarticle" element={
         <Protected isLoggedIn={connected}><Createarticle ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

        <Route path="/createpost" element={
         <Protected isLoggedIn={connected}><Createpost ads={ads} setNM={setNM} nM={nM} setUser={setUser} user={user} socket={socket}  connected={connected}/></Protected>}/>

      </Routes>
    </Router>
  ): null;
};

export default App;
