import React, { useRef } from 'react';
import { useState, useEffect} from "react";
import './App.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import { mobile,tablette, computer } from "../responsive";

import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: auto;
  align-items: left;
  width: 45vw;
  margin-left: auto;
  margin-right:auto;
  background-color:white;
  flex-direction:column;
  border:1px solid lightgrey;
  margin-top:30px;
margin-bottom:40px;
${mobile({border:"none", width:"auto", padding:"0", marginTop:"0", paddingBottom:"20px"})};
${tablette({width:"70vw", padding:"0", marginTop:"0", paddingBottom:"20px"})};
${computer({width:"65vw", padding:"0", marginTop:"0", paddingBottom:"20px"})};

`;



const InputContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: auto;
`;


const InputContainer2 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;

const InputContainer1 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;

const Input = styled.input`
  border: none;
  outline: none;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 500;
  width:auto;
  ${computer({width:"400px"})};

`;

const Try=styled.aside `
    width: 20%;
    text-align: right;
    align-items: center;
  `;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: rgb(128 146 239 / 66%);
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"250px", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"250px", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"300px", height:"50px"})}

`;




const Inputs = ({user,ads}) => {
    const navigate = useNavigate();
    const [theFirstName, setFirstName] = useState("");
    const [theLastName, setLastName] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error, setError] = useState("");
    const [isdisable, setIsdisable] = useState(true);
    const [show, setShow] = useState(false);


    const editorRef = useRef(null);
    useEffect(() => {
        if(cPassword!=''){
          if (theLastName === cPassword) {
            setShowErrorMessage(false);
            setIsdisable(false);

        } else {
            setShowErrorMessage(true)
        }
        }

  
    })

    const  SaveModif= async (e) =>{
        e.preventDefault();

        try{


          const myToken=await axios.post(ads+'api/auth/changepassword',
          { 
            "userId":user._id,
            "oldPassword":theFirstName, 
            "newPassword":theLastName,
            "cPassword":cPassword

         });
         setShow(true);

        }catch(e) {
            setError(s=>e.response.data);
        }    
      }


    return (
        <div>
            
<Container>
    
                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                    <Try>
                        <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Ancien mot de passe
                        </div>
                    </Try>
                    
                    <InputContainer>
                    <Input value={theFirstName} type="password" onChange={(e) => setFirstName(e.target.value)}></Input>
                    </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                        Nouveau mot de passe
                    </div>
                </Try>

                <InputContainer>
                <Input value={theLastName} type="password" onChange={(e) => setLastName(e.target.value)}></Input>
                </InputContainer>
                </div>

                <div style={{display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
                <Try>
                    <div  style={{display:"flex", alignItems:"center",color:"grey",fontWeight:"500",letterSpacing:"1px",float:"right"}}>
                    Confirmez votre nouveau mot de passe
                    </div>
                </Try>

                
                <InputContainer>
                <Input value={cPassword} type="password" onChange={(e) => setCPassword(e.target.value)}></Input>
                </InputContainer>
                </div>
                {showErrorMessage  ? <div style={{textAlign:"center", marginTop:"15px"}}> Passwords do not match </div> : ''}
                {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"20px",marginBottom:"0px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}
                <Button onClick={SaveModif} disabled={isdisable}>MODIFIER</Button>

                {show?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Mot de passe modifié
            </div>
                : null}


        </Container>
        </div>
    )
}

export default Inputs




