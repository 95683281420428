import React from 'react'
import styled from "styled-components";
import { cover } from "../data";
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import { useState, useEffect} from "react";
import axios from "axios";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModifyClasse from './ModifyClasse';
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";
import ChooseSubscription from "./ChooseSubscription";
import LogYou from "./LogYou";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DeleteCom from '../components/DeleteComClass'
import CloseIcon from '@mui/icons-material/Close';

const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({padding:"0px", marginTop:"62px"})};

`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: auto;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right:auto;
  position:relative;
  ${mobile({display:"none"})};

`;


const WrapperS = styled.div`
  display: flex;
  justify-content: space-around;
  height: auto;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right:auto;
  position:relative;
  ${tablette({display:"none"})};
  ${computer({display:"none"})};
`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 40px;
    ${mobile({display:"none"})};

`;



const Wrapper1S = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 10px;
    ${tablette({display:"none"})};
    ${computer({display:"none"})};
`;
const Image = styled.img`
width:70vw;
height: 39.55vw;
object-fit: cover;
${mobile({width:"100vw", height:"56.5vw"})};
${tablette({width:"77vw", height:"43.5vw"})};

cursor: pointer;
`;

const Titre1 = styled.div`
    width:70vw;
    margin-top: 30px;
    font-size: 40px;
    letter-spacing: 1.1px;
    margin-right:auto;
    margin-left: auto;
    display: flex;
    ${mobile({marginLeft:"20px", fontSize:"23px", paddingTop:"0px", marginTop:"10px"})};
    ${tablette({width:"77vw", fontSize:"30px", paddingTop:"0px", marginTop:"20px"})};

`;

const ProfilePictCom = styled.img`
width:30px;
height: 30px;
object-fit: cover;
border-radius: 50%;
margin-right: 20px;
margin-left: 10px;
${mobile({marginLeft:"20px"})};

`;
const Author = styled.div`
  width:70vw;
  margin-left:auto;
  margin-right:auto;
  display: flex;
  align-items: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  cursor: pointer;
  ${mobile({marginLeft:"20px", fontSize:"18px", paddingTop:"20px", marginTop:"0px",  fontWeight: "500", marginBottom:"20px"
})};

`;

const Overview= styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
  background-color: #fafafa;
  height: auto;
  justify-content: space-around;
  padding:20px;
  width:90%;
  margin-left:auto;
  margin-right:auto;
  ${tablette({marginTop:"40px"})};

  ${mobile({display:"none"})};

`;
const OverviewS= styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;

  align-items: baseline;
  margin-top: 0px;
  font-size: 20px;
  font-weight: 600;
  background-color: #fafafa;
  height: auto;
  justify-content: space-around;
  padding:10px;
  width:90vw;
  ${tablette({display:"none"})};
${computer({display:"none"})};
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  /* border-top: 2px solid #ececec; */
  justify-content: space-around;
`;
const Profilepict = styled.img`
width:70px;
height: 70px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;
${tablette({width:"50px", height:"50px"})};
${mobile({width:"60px", height:"60px", marginLeft:"20px"})};

`;


const Item=styled.h4`
letter-spacing: 2px;
font-weight:600;
display:flex;
flex-direction: column;
align-items: center;
color: grey;
${mobile({fontSize:"12px"})};



`;


const Style=styled.h4`
letter-spacing: 2px;
font-weight:500;
display:flex;
color: black;
margin-top:10px;
font-size: 18px;




`;

const Explainations=styled.div`
font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top:30px;
${tablette({marginLeft:"auto", width:"70vw", marginRight:"auto"})};
${computer({marginLeft:"auto", width:"70vw", marginRight:"auto"})};

${mobile({marginLeft:"20px", fontSize:"16px", marginBottom:"90px"})};




`

const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
${mobile({fontSize:"11px", marginTop:"5px"})};

`
const Info1=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
justify-content: center;
${mobile({fontSize:"11px", marginTop:"5px"})};

`
const Circle = styled.div`
width: 100px;
height: 100px;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
cursor: pointer;
align-items: center;
  display:flex;
z-index:2;
${mobile({height: "70px", width:"70px"})}
${tablette({height: "100px", width:"100px"})}

`;
const Input1 = styled.input`
  margin: 10px 10px 0px 0px;
  padding: 10px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: none;
  width: 100%;
  outline: none;

  :focus {
    outline: none;
}
`;
const Play  = styled.div`
margin: 0 auto ;
margin-top: 0;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;
cursor: pointer;
${mobile({marginTop: "0px"})}
${tablette({marginTop: "0px"})}

`
const Follow = styled.button`
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  border-radius: 30px;

  cursor: pointer;
  width:auto;
  height: auto;
  font-size: 10px;
  margin-left:0px;
  padding:10px;
  margin-right: 30px;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"15px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px",height:"40px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700",  padding:"5px", height:"30px"

})}`
;
const Subscribe = styled.button`
    text-align: center;
    border: none;
  background-color: #8798ef;
  color: white;
  border-radius: 50px;

  cursor: pointer;
  width:auto;
  padding: 10px;
  font-size:10px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"15px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px", height:"40px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", height:"30px", padding:"5px"
})};`;

const Desc=styled.div`
font-size:15px;
margin-bottom:30px;
letter-spacing:0.9px;
line-height: 21px;
${computer({fontSize:"17px", lineHeight:"23px"})};

`;

const WrapperSS=styled.div`
display: flex;
width:100%;
align-items: center;
margin-top:20px;
margin-bottom: 30px;
margin-top:20px;
font-weight: 600;
${computer({display:"none"})};
${tablette({display:"none"})};

`
const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  position:absolute;
  color:white;
  cursor:pointer;

  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"250px",display:"flex", justifyContent:"center", fontSize: "14px", padding:"5px", bottom:"0px", fontWeight:"600"})}
  ${tablette({width:"300px",  display:"flex", justifyContent:"center",fontSize: "14px", padding:"10px", bottom:"10px",fontWeight:"600"})}
  ${computer({width:"400px",  display:"flex", justifyContent:"center",fontSize: "19pxpx", padding:"10px", bottom:"40px",fontWeight:"600"})}

`

const Auth=styled.div`
  ${mobile({display:"none"})}
  width:50vw;
  display:block; 
  margin-left:auto;
  margin-right:auto;
`;
const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  position:absolute;
  cursor:pointer;
  color:#5f4fbb;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:172px;
  ${mobile({width:"230px", display:"flex",justifyContent:"center", fontSize: "11px", padding:"5px", bottom:"20px"})}
  ${tablette({width:"auto",  fontSize: "15px", padding:"5px", bottom:"20px"})}
  ${computer({width:"auto",  fontSize: "20px", padding:"14px", bottom:"20px"})}
`
const OwnerInfo=styled.div`
  ${mobile({display:"none"})};
  margin-top:50px;
  margin-bottom:20px;
  ${tablette({width:"77vw", marginBottom:"0"})}

`;

const Button2 = styled.button`
margin-top: 0px;
border: none;
height: 35px;
padding: 10px 15px;
color: white;
background-color: #8798ef;
margin-left: auto;
margin-right: 20px;
cursor: pointer;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
const Div1 = styled.div`
display:flex;
margin-top:20px;
width:70vw;
margin-left:auto;
margin-right:auto;
${mobile({width:"90vw", paddingBottom:"10px"})}
${tablette({width:"77vw"})}
`;
const DivInput = styled.div`

width:70vw;

${mobile({width:"90vw"})}
${tablette({width:"77vw"})}
`;
const Div2 = styled.div`

width:70vw;

${mobile({width:"90vw"})}
${tablette({width:"77vw"})}
`;


const Lock3  = styled.div`
  background-color: #8798ef;
  padding:0px;
  position:absolute;
  color:white;
  cursor:pointer;

  border-radius: 60px;
  font-size: 18px;
  font-weight: 450;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"90%",display:"flex", justifyContent:"center", fontSize: "14px", padding:"5px", bottom:"10px", fontWeight:"600"})}
  ${tablette({width:"350px",  display:"flex", justifyContent:"center",fontSize: "14px", padding:"10px", bottom:"10px",fontWeight:"600"})}
  ${computer({width:"450px",  display:"flex", justifyContent:"center",fontSize: "19pxpx", padding:"10px", bottom:"40px",fontWeight:"600"})}

`




const ClasseV = ({ ads,socket,Video, N, user, setUser } ) => {
    const [creator, setCreator] = useState({});
    const [duration, setDuration] = useState("");
    const [style, setStyle] = useState([]);
    const [open2, setOpen2] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open3, setOpen3] = useState(false);

    const [status, setStatus] = useState(undefined);



    const [open4, setOpen4] = useState(false);
    const [comId, setComId] = useState("");
  
    const [larger, setLarger] = useState(false);
    const [liked, setLiked] = useState(false);
    const [favorited, setFavorited] = useState(false);
    const [commentaire, setCommentaire] = useState("");
    const [comments, setComments] = useState([]);
    const [postDate, setPostDate]= useState("");
    const [likesNumber, setLikesNumber] = useState(Video.likesNumb);
    const [isdisable, setIsdisable] = useState(true);




    const open = (item) => {

        setOpen2(true)
    }
    function fancyTimeFormat(duration)
    {   
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
    
        if (hrs > 0) {
            ret += "" + hrs + " h " + (mins < 10 ? "0" : "");
        }
    
        ret += "" + mins + " min " + (secs < 10 ? "0" : "");
        ret += "" + secs+ " sec ";
        return ret;
    }

const startSub = async()=>{
  if(user=="unknown"){
    setOpen3(true)
    return
}
  if(user.freeSub){
    const body =  {
      buyer:user._id
    }
    const check=await axios.post(ads+'api/checkout/subscription/free/'+Video.creatorId, body);
    window.location.reload();
  }else{
    setOpen1(true)

  }
}

    const follow = async () =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      if(user._id!=undefined){
          const fol = (await axios.post(ads+'api/connections/follow/'+user._id,
          {
              id:Video.creatorId
          })).data
          socket.current.emit("newFollower", {follower:user._id, following:Video.creatorId})

          //setFollower(true);
          window.location.reload();
      }

  }



    useEffect(async() => {
      if(user!="unknown"){
        setLiked(Video.likesId.includes(user._id));
        setFavorited(user.favoriteCours.includes(Video._id));
        if(user.subscribe.includes(Video.creatorId)){
          setStatus("subscriber")
        }else if(user.follow.includes(Video.creatorId)){
          setStatus("follower")
        }
      }
        try { 
          const date = new Date(Video.createdAt);
          const monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
          const monthName1 = monthNames[date.getMonth()];
          setPostDate(date.getDate() +" "+ monthName1+" "+ date.getFullYear())

            setDuration(fancyTimeFormat(Video.duration));

            const Creator=(await axios.get(ads+'api/users/'+Video.creatorId)).data;
            setCreator(Creator);
            const ss=["Arthrose","Cheville","Contracture","Cou","Coude","Dos","Déchirure","Épaule","Enthésopathie","Étirements", "Entorse","Fibromyalgie","Fracture","Genou", "Gériatrie","Hanche","Nutrition","Parkinson","Poignet","Post Opératoire","Proprioception","Rééducation Fonctionnelle","Renforcement","Sclérose en plaques","Sport","Tendinopathie","Traumatologie","Troubles respiratoires","Yoga"];
            var Style=[]
            for (let ii = 0; ii < Video.style.length; ii++) {
                Style.push(ss[Video.style[ii]]);
            }
            setStyle(Style);


              const res= (await axios.get(ads+'api/comments/class/'+Video._id)).data;
                res.sort(function(a,b) {
                  return new Date(b.date) - new Date(a.date)
              });

              setComments(res)
        }catch(e){


        }
  
    },[])


    const likeIt = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/likes/class/'+Video._id+'/'+user._id)).data;
      setLiked(true)
      setLikesNumber(likesNumber+1)
    }
    const dislikeIt = async() =>{
      const res= (await axios.put(ads+'api/likes/class/'+Video._id+'/'+user._id)).data;
      setLiked(false)
      setLikesNumber(likesNumber-1)
    
    }
    
    const favoriteIt = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/favorites/class/'+Video._id+'/'+user._id)).data;
      setFavorited(true)
      var user1=user
      user1.favoriteCours.push(Video._id)
      setUser(user1)
    }
    
    const sendComment = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/comments/class/'+Video._id+'/'+user._id,
      {
        comment:commentaire
      })).data;
      var com1= [...comments]
      com1=[{username:user.username,userId:user._id, profilePicture:user.profilePicture, commentaire: commentaire,_id:res._id}].concat(comments)
      setComments(com1)
      setCommentaire("");
    }
    
    const deleteCom = (id) =>{
      setComId(id)
      setOpen4(true)
    
    }
    
    const deFavoriteIt = async() =>{

      const res= (await axios.put(ads+'api/favorites/class/'+Video._id+'/'+user._id)).data;
      setFavorited(false)
      var user1=user
      const index = user1.favorites.indexOf(Video._id);
      if (index > -1) { // only splice array when item is found
          
          user1.favoriteCours.splice(index, 1); // 2nd parameter means remove one item only
      }
      setUser(user1)
    
    }




    return (
        <>
        <Container>
            {Video.bunnyId!=""&&window.screen.width>480?
            <div>
            {Video.status==4?
            <Wrapper1>
            <iframe src={"https://iframe.mediadelivery.net/embed/"+process.env.REACT_APP_BUNNY_ID+"/"+Video.bunnyId+"?autoplay=true"} 
                 
                 style={{border: "none",position:"relative",height:"39.37vw", width: "70vw",display:"block",margin: "0 auto"}} 
                 loading="lazy" 
                 // allow={{accelerometer, gyroscope, autoplay, encryptedMedia, pictureInPicture}} 
                 allowfullscreen="true">
                 </iframe>
            </Wrapper1>
            :
            
            
            <Wrapper>

<Image src={ads+Video.thumbnails[0]} />
<Circle>
    <Play/>
</Circle>

    <Lock3>
      {/* Sign in to watch this class */}
      Veuillez patienter, la vidéo est en cours d'encodage
    </Lock3>



</Wrapper>
            
            }
            </div>
            :
            
            <Wrapper>

            <Image src={ads+Video.thumbnails[0]} />
            <Circle>
                <Play/>
            </Circle>
            {Video.privacy=="Public"&&user=="unknown"?
              <Link to={"/signin"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <Lock >
                  {/* Sign in to watch this class */}
                  Connectez-vous pour regarder ce cours
                </Lock>
              </Link>
        :null
        }

            {Video.privacy=="Subscribers"&&status!="subscriber"&&user._id!=Video.creatorId?
        <Lock onClick={() => startSub()}>
          {/* Subscribe to this mentor to watch this class */}
          Abonnez-vous pour regarder ce cours
        </Lock>
        :null
        }
        {(user._id!=Video.creatorId&&Video.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
        <Lock1 onClick={() => follow()}>
          {/* Follow this mentor to watch this class */}
          Suivez ce Praticien pour regarder ce cours
        </Lock1>
        :null
        }
            </Wrapper>
            }


{Video.bunnyId!=""&&window.screen.width<480?
            <div>
            {Video.status==4?
<Wrapper1S>
<iframe src={"https://iframe.mediadelivery.net/embed/"+process.env.REACT_APP_BUNNY_ID+"/"+Video.bunnyId+"?autoplay=true"} 
     
     style={{border: "none",position:"relative",height:"56.22vw", width: "100vw",display:"block"}} 
     loading="lazy" 
     // allow={{accelerometer, gyroscope, autoplay, encryptedMedia, pictureInPicture}} 
     allowfullscreen="true">
     </iframe>
</Wrapper1S>
            :
            
            
            <WrapperS>

<Image src={ads+Video.thumbnails[0]} />
<Circle>
    <Play/>
</Circle>

    <Lock3>
      {/* Sign in to watch this class */}
      Veuillez patienter, la vidéo est en cours d'encodage
    </Lock3>



</WrapperS>
            
            }
            </div>
:

<WrapperS>

<Image src={ads+Video.thumbnails[0]} />
<Circle>
    <Play/>
</Circle>

{Video.privacy=="Public"&&user=="unknown"?
        <Link to={"/signin"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <Lock >
          {/* Sign in to watch this class */}
          Connectez-vous pour regarder ce cours

        </Lock>
        </Link>
        :null
        }

{Video.privacy=="Subscribers"&&status!="subscriber"?
<Lock onClick={() => startSub()}>
{/* Subscribe to this user to watch this class */}
Abonnez-vous pour regarder ce cours

</Lock>
:null
}
{(Video.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
<Lock1 onClick={() => follow()}>
{/* Follow this user to watch this class */}
Suivez ce Praticien pour regarder ce cours

</Lock1>
:null
}
</WrapperS>
}


            <Titre1>
                {Video.title}
                {Video.creatorId==user._id?
                <div style={{alignItems:"center", marginLeft:"30px", cursor:"pointer"}} onClick={()=>open(Video)}>
                <MoreHorizIcon />

                </div>
                :null
                }

            </Titre1>



            <Div1>
    {liked?
    <FavoriteOutlinedIcon onClick={()=>dislikeIt()} style={{marginRight:"5px", color:"#ff696e", cursor:"pointer"}}/>
:
    <FavoriteBorderIcon onClick={()=>likeIt()} style={{marginRight:"5px", cursor:"pointer"}}/>

  }
  {Number(likesNumber)>1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aimes</span>
:
null
  }
    {Number(likesNumber)==1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aime</span>
:
null
  }
      {Number(likesNumber)==0?
    <span style={{marginRight:"20px", width:"30px"}}></span>
:
null
  }
<ChatOutlinedIcon style={{marginRight:"5px", cursor:"pointer"}}/>
{comments?.length==1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaire</span>
:null}
{comments?.length>1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaires</span>
:null}
{favorited?
  <BookmarkAddedIcon onClick={()=>deFavoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>
:
<BookmarkAddOutlinedIcon onClick={()=>favoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>

}


  </Div1>




            <OwnerInfo>
<Author>
    <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Profilepict src={ads+creator.profilePicture}/> 
    </Link>
    <div>
    <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

{creator.username}
</Link>
    <div style={{marginTop:"5px",color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
    </div>

            {user._id!=Video.creatorId?
            <div style={{display:"flex", marginLeft:"40px"}}>
               {status!="follower"&&status!="subscriber"?
                              <Follow onClick={() => follow()}>
                              FOLLOW
                    </Follow> :
                    null}

               {status!="subscriber"?
                <Subscribe onClick={() => startSub()}>
                S'ABONNER

            </Subscribe>:
            null
               }
               </div>
               :null}
            </Author>
 
</OwnerInfo>

            <WrapperSS>
<Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Profilepict src={ads+creator.profilePicture}/> 
</Link>
<div >
<Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

{creator.username}
</Link>
{user._id!=Video.creatorId?
<div style={{display:"flex", marginTop:"5px"}}>
   {status!="follower"&&status!="subscriber"?
                  <Follow onClick={() => follow()}>
                  SUIVRE
        </Follow> :
        null}
   {status!="subscriber"?
    <Subscribe onClick={() => startSub()}>
    S'ABONNER

</Subscribe>:
null
   }
   </div>
  : null}
   </div>


   
</WrapperSS>





            
{/* <Auth >
    
            <Style>
                Author: 
                <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <span style={{textDecoration:"underline"}}>{creator.username}</span> 
                </Link>
            </Style>
</Auth> */}


<OverviewS>
                <Item>DURÉE
                    <Info>
                      <TimerSharpIcon style={{marginRight: "5px",fontSize:"15px"}}/>  {duration}
                    </Info>
                </Item>

                <Item>CATÉGORIES
                    <Info1>
                    {style?.map((item) => (
                        <div>
                            {item}
                        </div>
                          ))}
                    </Info1>
                </Item>
            </OverviewS>

            <OverviewS>
            <Item>DIFFICULTÉ
                    <Info>
                    <div class="intensityContainer"  style={{marginRight: "9px"}}>
                        <div class="levelIntensity">
                            <span width="13px" height="13px" display="inline-block" fill="primary" class="intensity">
                            {Video.difficulty=="For All"?
<GroupsOutlinedIcon style={{fontSize:"18px"}}/>

:null}
{Video.difficulty=="Intermediate"?
                                <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="15px" width="15px">
                                    <path d="M3.038 31.403v-8.78a1.5 1.5 0 10-3 0v8.78a1.5 1.5 0 003 0zM12.938 31.4V15.588a1.5 1.5 0 00-3 0V31.4a1.5 1.5 0 003 0zM22.911 31.42V8.47a1.5 1.5 0 10-3 0V31.42a1.5 1.5 0 103 0z"></path>
                                    <path d="M32.904 31.438V1.644a1.5 1.5 0 00-3 0v29.794a1.5 1.5 0 003 0z" opacity=".2"></path>
                                </svg>
:null}
{Video.difficulty=="Beginner"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">

                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2" opacity="0.200000003"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3" opacity="0.200000003"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4" opacity="0.200000003"></path>

                                </svg>
:null}
{Video.difficulty=="Moderate"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">


                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3" opacity="0.200000003"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4" opacity="0.200000003"></path>

                                </svg>
:null}
{Video.difficulty=="Advanced"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">


                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4"></path>

                                </svg>
                                :null}

                            </span>
                    </div>
    
                                        
                                    </div>      {Video.difficulty}
                    </Info>
                </Item>
                <Item>INTENSITÉ
                    <Info>
                     <div class="level">

                                    <div class="difficultyContainer"  style={{marginRight: "9px"}}>
                                        <div class="levelIntensity">
                                            <span width="13px" height="13px" display="inline-block" fill="primary" class="intensity">
                                                
                                            {Video.intensity=="Level 4"?
                          
                                                
                                                <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="15px" width="15px"><path d="M20.892 29.874h-8.78a1.5 1.5 0 000 3h8.78a1.5 1.5 0 100-3zM24.409 19.974H8.596a1.5 1.5 0 000 3H24.41a1.5 1.5 0 000-3zM27.977 10.001H5.028a1.5 1.5 0 100 3h22.949a1.5 1.5 0 100-3zM31.4.008H1.605a1.5 1.5 0 100 3H31.4a1.5 1.5 0 100-3z"></path>
                                                </svg>
                                                                                :null}
{Video.intensity=="Level 2"?

                                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">


<g id="Intensity-2—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3" opacity="0.200000003"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</svg>
:null}

{Video.intensity=="Level 3"?
<svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">


<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Intensity-3—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</g>
</svg>
                                :null}


{Video.intensity=="Level 1"?
<svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="15px" width="15px">


<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Intensity-1—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2" opacity="0.200000003"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3" opacity="0.200000003"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</g>
</svg>                                               
                                   :null}
                                             
                                                </span>
                                        </div>
                                        
                                    </div>
                                </div>   {Video.intensity}
                    </Info>                   
                </Item>
            </OverviewS>




















            <Overview>
                <Item>DURÉE
                    <Info>
                      <TimerSharpIcon style={{marginRight: "5px",fontSize:"20px"}}/>  {duration}
                    </Info>
                </Item>
                <Item>DIFFICULTÉ
                    <Info>
                    <div class="intensityContainer"  style={{marginRight: "9px"}}>
                        <div class="levelIntensity">
                            <span width="16px" height="16px" display="inline-block" fill="primary" class="intensity">
                            {Video.difficulty=="For All"?
<GroupsOutlinedIcon style={{fontSize:"27px"}}/>

:null}
{Video.difficulty=="Intermediate"?
                                <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="20px" width="20px">
                                    <path d="M3.038 31.403v-8.78a1.5 1.5 0 10-3 0v8.78a1.5 1.5 0 003 0zM12.938 31.4V15.588a1.5 1.5 0 00-3 0V31.4a1.5 1.5 0 003 0zM22.911 31.42V8.47a1.5 1.5 0 10-3 0V31.42a1.5 1.5 0 103 0z"></path>
                                    <path d="M32.904 31.438V1.644a1.5 1.5 0 00-3 0v29.794a1.5 1.5 0 003 0z" opacity=".2"></path>
                                </svg>
:null}
{Video.difficulty=="Beginner"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">

                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2" opacity="0.200000003"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3" opacity="0.200000003"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4" opacity="0.200000003"></path>

                                </svg>
:null}
{Video.difficulty=="Moderate"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">


                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3" opacity="0.200000003"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4" opacity="0.200000003"></path>

                                </svg>
:null}
{Video.difficulty=="Advanced"?

                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">


                                <path d="M3,31.7260202 L3,22.9461329 C3,22.1177057 2.32842712,21.4461329 1.5,21.4461329 C0.671572875,21.4461329 -4.23138169e-16,22.1177057 -4.4408921e-16,22.9461329 L-6.66133815e-16,31.7260202 C-6.87084855e-16,32.5544473 0.671572875,33.2260202 1.5,33.2260202 C2.32842712,33.2260202 3,32.5544473 3,31.7260202 Z" id="Line"></path>
                                <path d="M12.9,31.7237572 L12.9,15.9109621 C12.9,15.082535 12.2284271,14.4109621 11.4,14.4109621 C10.5715729,14.4109621 9.9,15.082535 9.9,15.9109621 L9.9,31.7237572 C9.9,32.5521843 10.5715729,33.2237572 11.4,33.2237572 C12.2284271,33.2237572 12.9,32.5521843 12.9,31.7237572 Z" id="Line-Copy-2"></path>
                                <path d="M22.8733333,31.7430416 L22.8733333,8.7944609 C22.8733333,7.96603377 22.2017605,7.2944609 21.3733333,7.2944609 C20.5449062,7.2944609 19.8733333,7.96603377 19.8733333,8.7944609 L19.8733333,31.7430416 C19.8733333,32.5714688 20.5449062,33.2430416 21.3733333,33.2430416 C22.2017605,33.2430416 22.8733333,32.5714688 22.8733333,31.7430416 Z" id="Line-Copy-3"></path>
                                <path d="M32.8662222,31.761412 L32.8662222,1.96712327 C32.8662222,1.13869614 32.1946493,0.467123268 31.3662222,0.467123268 C30.5377951,0.467123268 29.8662222,1.13869614 29.8662222,1.96712327 L29.8662222,31.761412 C29.8662222,32.5898391 30.5377951,33.261412 31.3662222,33.261412 C32.1946493,33.261412 32.8662222,32.5898391 32.8662222,31.761412 Z" id="Line-Copy-4"></path>

                                </svg>
                                :null}

                            </span>
                    </div>
    
                                        
                                    </div>      {Video.difficulty}
                    </Info>
                </Item>
                <Item>INTENSITÉ
                    <Info>
                     <div class="level">

                                    <div class="difficultyContainer"  style={{marginRight: "9px"}}>
                                        <div class="levelIntensity">
                                            <span width="16px" height="16px" display="inline-block" fill="primary" class="intensity">
                                                
                                            {Video.intensity=="Level 4"?
                          
                                                
                                                <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="20px" width="20px"><path d="M20.892 29.874h-8.78a1.5 1.5 0 000 3h8.78a1.5 1.5 0 100-3zM24.409 19.974H8.596a1.5 1.5 0 000 3H24.41a1.5 1.5 0 000-3zM27.977 10.001H5.028a1.5 1.5 0 100 3h22.949a1.5 1.5 0 100-3zM31.4.008H1.605a1.5 1.5 0 100 3H31.4a1.5 1.5 0 100-3z"></path>
                                                </svg>
                                                                                :null}
{Video.intensity=="Level 2"?

                                                <svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">


<g id="Intensity-2—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3" opacity="0.200000003"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</svg>
:null}

{Video.intensity=="Level 3"?
<svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">


<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Intensity-3—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</g>
</svg>
                                :null}


{Video.intensity=="Level 1"?
<svg  viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" height="20px" width="20px">


<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Intensity-1—Outlined-Copy" fill="#000000" fill-rule="nonzero">
<g id="Group-17-Copy-8" transform="translate(-0.544912, -1.819812)">
<g id="Group" transform="translate(17.082573, 17.996362) rotate(-90.000000) translate(-17.082573, -17.996362) translate(0.082573, 0.996362)">
<path d="M3.30220611,21.3548111 L3.30220611,12.5749238 C3.30220611,11.7464967 2.63063323,11.0749238 1.80220611,11.0749238 C0.973778984,11.0749238 0.302206108,11.7464967 0.302206108,12.5749238 L0.302206108,21.3548111 C0.302206108,22.1832383 0.973778984,22.8548111 1.80220611,22.8548111 C2.63063323,22.8548111 3.30220611,22.1832383 3.30220611,21.3548111 Z" id="Line"></path>
<path d="M13.2022061,24.871265 L13.2022061,9.05846996 C13.2022061,8.23004283 12.5306332,7.55846996 11.7022061,7.55846996 C10.873779,7.55846996 10.2022061,8.23004283 10.2022061,9.05846996 L10.2022061,24.871265 C10.2022061,25.6996921 10.873779,26.371265 11.7022061,26.371265 C12.5306332,26.371265 13.2022061,25.6996921 13.2022061,24.871265 Z" id="Line-Copy-2" opacity="0.200000003"></path>
<path d="M23.1755394,28.4391578 L23.1755394,5.49057709 C23.1755394,4.66214997 22.5039666,3.99057709 21.6755394,3.99057709 C20.8471123,3.99057709 20.1755394,4.66214997 20.1755394,5.49057709 L20.1755394,28.4391578 C20.1755394,29.267585 20.8471123,29.9391578 21.6755394,29.9391578 C22.5039666,29.9391578 23.1755394,29.267585 23.1755394,28.4391578 Z" id="Line-Copy-3" opacity="0.200000003"></path>
<path d="M33.1684283,31.8620118 L33.1684283,2.0677231 C33.1684283,1.23929597 32.4968555,0.567723099 31.6684283,0.567723099 C30.8400012,0.567723099 30.1684283,1.23929597 30.1684283,2.0677231 L30.1684283,31.8620118 C30.1684283,32.690439 30.8400012,33.3620118 31.6684283,33.3620118 C32.4968555,33.3620118 33.1684283,32.690439 33.1684283,31.8620118 Z" id="Line-Copy-4" opacity="0.200000003"></path>
</g>
</g>
</g>
</g>
</svg>                                               
                                   :null}
                                             
                                                </span>
                                        </div>
                                        
                                    </div>
                                </div>   {Video.intensity}
                    </Info>                   
                </Item>
                <Item>CATÉGORIES
                    <Info1>
                    {style?.map((item) => (
                        <div>
                            {item}
                        </div>
                          ))}
                    </Info1>
                </Item>
            </Overview>




            <div style={{width:"70vw", display:"none", marginLeft:"auto",marginRight:"auto"}}>

            <Author>
            <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

               <Profilepict src={"http://51.178.60.185/"+creator.profilePicture}/>
            </Link>
            <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                {creator.username}
            </Link>
               <div style={{display:"flex", marginLeft:"40px"}}>
               {status!="follower"&&status!="subscriber"?
                              <Follow>
                              <h2 style={{letterSpacing: "2.5px"}}>SUIVRE</h2>
                    </Follow> :
                    null}
               {status!="subscriber"?
                <Subscribe>
                <h2 style={{letterSpacing: "2.5px"}}>S'ABONNER'</h2>

            </Subscribe>:
            null
               }
               </div>


              

            </Author>
            </div>

                <Explainations>
                <div
      dangerouslySetInnerHTML={{__html:Video.description}}
    />
                    
                   </Explainations>
                   <DivInput style={{borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec",display:"flex", marginTop:"10px", paddingBottom:"10px", backgroundColor:"white", paddingTop:"10px", marginLeft:"auto", marginRight:"auto"}}>
<Input1 style={{marginTop:"0px"}} placeholder="Ajouter un commentaire :-)" value={commentaire} 
onChange={(e) => {
  setCommentaire(e.target.value); 
  if(e.target.value!=""){
    setIsdisable(false)
  }else{
  setIsdisable(true)
}}}/>
<Button2 disabled={isdisable} onClick={()=>sendComment()}
        >Publier</Button2>
</DivInput>
<div style={{marginBottom:"80px"}}>
  {comments?.map((item) => (

 <Div2 style={{ marginLeft:"auto", marginRight:"auto",marginTop:"10px", paddingBottom:"10px",borderBottom:"1px solid #ececec", color:"grey",display:"flex"}}>
      <ProfilePictCom src={ads+item.profilePicture}/>  

  <div style={{ }}>
    <div style={{display:"flex"}}>
        <span style={{fontWeight:"600", marginRight:"6px", color:"black"}}>
        {item.username}
        </span>
        <div style={{marginBottom:"1px",display:"flex", alignItems:"end",  marginLeft:"0px", fontSize:"13px"}}>
      {item.delai}
      </div>

    </div>



    <div style={{display:"flex"}}>
    <span style={{color:"black", marginTop:"5px", width:"63vw"}}>
        {item.commentaire}
      </span>
    {user._id==item.userId?
      <CloseIcon style={{marginLeft:"auto",  color:"grey", cursor:"pointer"}} onClick={()=>deleteCom(item._id)}/>
      :null}
    </div>


    </div>
</Div2>
 ))}
 </div>         
        </Container>
        {open2 && <ModifyClasse ads={ads} user={user} setOpen2={setOpen2} Video={Video} N={N}/>}
        {open1 && <ChooseSubscription ads={ads} user={user} socket={socket}  owner={Video.creatorId}  setOpen={setOpen1} />}
        {open3 && <LogYou   setOpen={setOpen3} />}
        {open4 && <DeleteCom  ads={ads} setOpen={setOpen4} item={Video} user={user} comId={comId}/>}

        </>
    )
}

export default ClasseV
