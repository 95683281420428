import React from 'react'
import Footer from '../components/Footer'
import { useLocation } from "react-router-dom";
import styled from 'styled-components'

import { useState, useEffect} from "react";
import Navbar from '../components/Navbar'
import Modifvideo from '../components/Modifvideo';
import PostparametersM from '../components/PostparametersM';
import { mobile,tablette,computer } from "../responsive";
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;


const ModifyPost = ({ads,nM,setNM, setUser,socket,user,connected}) => {

    useEffect(async() => {
        try { 



        }catch(e){


        }
  
    },[])
    return (
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>


<NavContainer >
<Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        

                    {connected?
                                        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
:null
                    }
                </BottomNav>
            <PostparametersM ads={ads} user={user}/>
            </ContainerRight >

</div>

    )
}

export default ModifyPost
