import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 500px;
  margin-left:60px;
`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
`;

const Label = styled.label`
  font-size: 18px;

`;
const Input = styled.input`
  font-size: 23px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  border-radius: 6px;
  width:70px;
  border: 1.5px solid #b3c9f9;
  outline:2.5px solid #b3c9f9;

  ${mobile({fontSize:"13px"})};


`;

const Button = styled.button`
width: 40%;
display:block;
border: none;
padding: 15px 5px;
border-radius: 50px;
background-color: #8798ef;
color: white;
cursor: pointer;
font-weight: 700;
font-size: 18px;
margin-left: auto;
margin-right: auto;
margin-top:60px;
margin-bottom: 30px;
:disabled{
  background-color: #036e6ead;
  cursor: auto;
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px", marginBottom:"100px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;
const Top = styled.div`
width:90%;
margin-left:auto;
margin-top:45px;
margin-bottom:45px;
alignItems:center;
color:rgb(52, 52, 67);
display:flex;
${mobile({marginTop:"85px", marginRight:"auto"})}

`;

const Exercices = ({ads,setUser,socket, setNM, nM,user,connected}) => {
    const navigate = useNavigate();

    const [teacher, setTeacher] = useState({});
    const [open, setOpen1] = useState(false);
    const [lesExercices, setLesExercices] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [seconds, setSeconds] = useState([]);
    const [videoSaved,setVideoSave]= useState([]);

    const [tab, setTab] = useState(0);


    const Up = (i) =>{
        if (i <= 0 ) {
            // i is out of bounds or doesn't need shifting
            return;
        }
        var exo =[...lesExercices]
        const temp = exo[i];
        exo[i] = exo[i - 1];
        exo[i - 1] = temp;
        setLesExercices(exo)

    }
    const Down = (i) =>{
        if (i >= lesExercices.length-1) {
            // i is out of bounds or doesn't need shifting
            return;
        }
        var exo =[...lesExercices]

        const temp = exo[i];
        exo[i] = exo[i + 1];
        exo[i +1 ] = temp;
        setLesExercices(exo)
    }
        // Fonction pour gérer les changements dans le champ des minutes
        const handleMinutesChange = (desc,i) => {
            console.log(i)
            var min = [...minutes]
            min[i]=desc
            setMinutes(min);
            
        };
      
        // Fonction pour gérer les changements dans le champ des secondes
        const handleSecondsChange = (desc,i) => {
            if (desc > 60) {
                desc = 60;
                return
              }
              var sec=[...seconds]
              sec[i]=desc
            setSeconds(sec);

        };
        const EnvoieLaSeance = async() => {
            localStorage.setItem('exercices',JSON.stringify(lesExercices));

            localStorage.setItem('minutes',JSON.stringify(minutes));
            localStorage.setItem('seconds',JSON.stringify(seconds));
            navigate("/selectexercices4");

        };
    useEffect(async() => {
        try { 
            setLesExercices(JSON.parse(localStorage.getItem('exercices')));
            var min=[];
            var sec=[]
            for (let ii = 0; ii < JSON.parse(localStorage.getItem('exercices')).length; ii++) {
                sec[ii]=0;
                min[ii]=2;
            }
            setMinutes(min)
            setSeconds(sec)
            // const check= await axios.get(ads+'api/exercices/all');
            // const ss=["Arthrose","Cheville","Contracture","Cou","Coude","Dos","Déchirure","Épaule","Enthésopathie","Étirements", "Entorse","Fibromyalgie","Fracture","Genou", "Gériatrie","Hanche","Nutrition","Parkinson","Poignet","Post Opératoire","Proprioception","Rééducation Fonctionnelle","Renforcement","Sclérose en plaques","Sport","Tendinopathie","Traumatologie","Troubles respiratoires","Yoga"];
            // const ss1=["Renforcement", "Étirements", "Équilibre", "Mobilité", "Coordination"];

            // for (let ii = 0; ii < check.data.length; ii++) {
            //     const replacedArray = check.data[ii].zone.map(number => ss[number]);
            //     const resultString = replacedArray.join(', ');
            //     check.data[ii].zone=resultString ;
            //     const replacedCat = check.data[ii].categorie.map(number => ss1[number]);
            //     const resultCat = replacedCat.join(', ');
            //     check.data[ii].categorie=resultCat ;
            // }

            // setLesExercices(check.data)
          
    
        }catch(e){
    
        }
    },[]);


    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Top >
                <h1>Récapitulatif de la séance</h1>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </Top>
                {lesExercices.map((person,i) => (
                    <div>
                    <div style={{display:"flex", justifyContent:"center"}}>

                   
      <Container>
       <div style={{padding:"20px"}}>
        Exercice {i+1} : {person.title}
        </div> 
        <div style={{padding:"20px"}}>
                {person.series} séries de {person.rep} répétitions

        </div>
        <div style={{display:"flex",padding:"20px"}}>

        {(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes!=""&&person.minutes!="0"&&person.minutes!=0)?
                <div>
                    {person.minutes} minute(s) et {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                }
                        {(person.seconds==""||person.seconds==0||person.seconds=="0")&&(person.minutes!=""&&person.minutes!=0&&person.minutes!="0")?
                <div>
                    {person.minutes} minute(s) de récupération entre les séries
                </div>:
                null
                
                }

{(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes==""||person.minutes==0||person.minutes=="0")?
                <div>
                    {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                }
                 

        </div>
      </Container>

      <ArrowCircleLeftIcon onClick={()=>Up(i)} style={{transform: `rotate(90deg)`,fontSize: "35px",color: "rgb(126, 145, 241)", cursor:"pointer", marginLeft:"30px"}}></ArrowCircleLeftIcon>
      <ArrowCircleLeftIcon onClick={()=>Down(i)} style={{transform: `rotate(270deg)`,fontSize: "35px",color: "rgb(126, 145, 241)",cursor:"pointer"}}></ArrowCircleLeftIcon>
      </div>
      <Label style={{justifyContent:"center", display:"flex", padding:"20px"}}>Temps de repos entre les blocs :</Label>




      
      <div style={{display:"flex",justifyContent:"center", marginBottom:"20px"}}>
      <div>
      <Input
          value={minutes[i]}
        type="number"
        name="minute"
        onChange={(e) => handleMinutesChange(e.target.value,i)}
      />
      <label style={{marginLeft:"5px", marginRight:"15px"}}>minute(s)  </label>

      </div>
      
      
            <div>
            <Input
                type="number"
                value={seconds[i]}
                onChange={(e) => handleSecondsChange(e.target.value,i)}
              />
              <label style={{marginLeft:"5px"}}>seconde(s) </label>

            </div>
            </div>
            </div>
                ))}
                <Button onClick={EnvoieLaSeance}>
            Suivant
        </Button>
      </ContainerRight>

</div>
</>
)
}

export default Exercices