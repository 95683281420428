import React, { useContext, useEffect, useState, useRef } from "react";
import styled from 'styled-components'
import { Message1 } from './Message1'
import { Message2 } from './Message2'

import axios from "axios";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { mobile,tablette, computer } from "../responsive";

const Container = styled.div`
background-color: #ddddf7;
padding: 10px;
overflow-y: scroll;
`;

export const Messages = ({newImage,ads,isTipping,arrivalMessage, user, chatId,setChatId,socket}) => {
    const [messages, setMessages] = useState(undefined);
    const [budyPicture, setBudyPicture] = useState("");
    const [justSaw, setJustSaw] = useState("");
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [containerHeight, setContainerHeight] = useState('calc(100% - 190px)'); // initial height of container is 100% - 190px



    // useEffect(() => {
    //     const handleResize = () => {
    //       const { innerHeight } = window;
    //       const isKeyboardOpen = window.innerHeight < innerHeight;
    //       setIsKeyboardOpen(isKeyboardOpen);
    
    //       // If keyboard is open, scroll to the bottom of the page
    //       if (isKeyboardOpen) {
    //         window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    //       }
    //     };
    
    //     window.addEventListener('resize', handleResize);
    
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []);

      useEffect(()=>{
         
        
           scrollRef?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'})
            setKeyboardOpen(false)
   },[isKeyboardOpen])

   useEffect(()=>{
         
if(newImage){
    setContainerHeight('calc(100% - 500px)')}
    else{
     setContainerHeight('calc(100% - 190px)')}

},[newImage])

    const scrollRef = useRef()
    const scrollRef1 = useRef()

    const Item={senderId:chatId.budyId, text:"Is writting..."}
    
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const chatRef = useRef(null);
    

    const [startIndex, setStartIndex] = useState(0);





    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(()=>{

        socket?.current?.on("messageSaw", data=>{
            console.log(data)
     
         if(chatId.conversationId===data.convId){
           setJustSaw(new Date(data.lastSeen))
         }

        })
     },[])



    
    const handleResize = () => {
    //   if(window.innerHeight == window.outerHeight){
        setTimeout(()=>scrollRef?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'}),100)

        

      //}

    };

    useEffect(async () => {

        if(chatId!=undefined){
            const msg=(await axios.get(ads+'api/chat/messages1/'+chatId.conversationId)).data

                // setMessages(msg.conversation);
                setMessages(msg.reverse());
                const msg1=(await axios.get(ads+'api/chat/messages/'+chatId.conversationId)).data

            const profilepict=(await axios.get(ads+'api/users/'+chatId.budyId)).data
            setBudyPicture(profilepict.profilePicture)
            setJustSaw(new Date(msg1.lastSeen[chatId.budyId]))
        }


     }, [chatId]);
     useEffect(() => {
        if(startIndex>1){
            axios.get(`${ads}api/chat/messages1/${chatId.conversationId}?skip=${startIndex}?limit=${startIndex+20}`)
            .then(res => {
              setMessages(prevMessages => [...res.data.reverse(),...prevMessages]);
            })
            .catch(err => {
              console.error(err);
            });
        }

      }, [startIndex]);


     useEffect(()=>{
         
         if(messages!=undefined&&messages.length>0&&chatId!=undefined&&startIndex==0){
            scrollRef?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'})

         }
    },[messages])


    
    useEffect(()=>{
         
        if(isTipping.isTipping==true&&chatId.budyId==isTipping.senderId){
           scrollRef1?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'})

        }
   },[isTipping])

    useEffect(()=>{
        
        if(arrivalMessage!=null){
            if(arrivalMessage.senderId===chatId.budyId||arrivalMessage.senderId===user._id){
                setMessages((prev)=>[...prev, arrivalMessage])
            }
            if(window.screen.width<=480){
                setTimeout(()=>scrollRef?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'}),100)
            }else{
                scrollRef?.current?.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'start'})

            }

        }},[arrivalMessage]
        )



        const handleScroll = (e) => {
            const element = e.target;
            if (element.scrollTop === 0) {
              setStartIndex(prevIndex => prevIndex + 20);
            }
          }

    return (
        <Container style={{height:containerHeight}}onScroll={handleScroll}>
            {messages?.map((item) => (
                <div style={{scrollMargin:"20px"}} ref={scrollRef}>
                    <Message1 justSaw={justSaw} ads={ads} budyId={chatId.budyId} budyPicture={budyPicture} user={user} message={item}/>

                </div>
            ))}

            {
                isTipping.isTipping&&chatId.budyId==isTipping.senderId?
                <div style={{scrollMargin:"20px"}} ref={scrollRef1}>
                <Message2 ads={ads} budyPicture={budyPicture} user={user} message={Item}/>

            </div>
            :null
            }
        </Container>
    )
}
