import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobileLanding,tabletteLanding, computer,computerLanding } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  ${mobileLanding({paddingTop:"0px"})};
  ${tabletteLanding({display:"none"})};
  ${computerLanding({display:"none"})};

`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
`;

const ImgContainer = styled.div`
  height: 45vh;

`;

const Image = styled.img`
  height: 45vh;
  object-fit: cover;
  ${mobileLanding({width:"-webkit-fill-available"})};

  
`;

const InfoContainer = styled.div`
  padding: 50px;
  margin-top: 0px;
  background-color: #${(props) => props.bg};

`;

const Title = styled.h1`
  font-size: 60px;
  ${mobileLanding({fontSize:"20px", textAlign:"center"})};
`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", lineHeight:"19px", marginTop:"20px", marginBottom:"40px"})};

`;




const ButtonC = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: transparent;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px",fontWeight:"600", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"45px"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;

const Button = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: #8798ef;
  /* background-color: #5f4fbb; */

  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px",fontWeight:"600", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"45px"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;



const Slider = ({ads}) => {
    const loc="uploads/stat"
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };

  return (
    <Container>

        {sliderItems.map((item,i) => (
          <div>
            <InfoContainer bg={item.bg} key={item.id}>
              <Title>{item.title}</Title>
              <Desc>{item.desc}</Desc>
              {i==0?
                          <Link to={"/signin"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <ButtonC>SE CONNECTER</ButtonC>
              </Link>
:null
              }
            <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

              <Button>S'INSCRIRE</Button>
              </Link>
            </InfoContainer>
            <ImgContainer>
              <Image src={ads+loc+item.img} />
            </ImgContainer>

            </div>
        ))}

    </Container>
  );
};

export default Slider;