import styled from "styled-components";
import { mentors } from "../data";
import MentorItem from "./MentorItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  width: 100vw;
  
`;

const Mentors = ({ads}) => {
  return (
    <Container>
      {mentors.map((item) => (
        <MentorItem ads={ads} item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Mentors;