import styled from 'styled-components'
import React from 'react'



const Container = styled.div`
  height: 40px;
  background-color: teal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
`;

const Announcement = () => {
    return (
        <Container>
            New Deal! Get 10% Off on your First Subscription!
        </Container>
    )
}

export default Announcement
