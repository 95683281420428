import React, { useRef } from 'react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

import { useState, useEffect} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";
import Cate from './CateB'
import CateS from './CateSB'

const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:0px;
    ${mobile({width:"100%", padding:"0", marginTop:"0px"})};

`;

const Options1=styled.div`
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
  ${tablette({fontSize:"13px"})};
  ${mobile({fontSize:"13px"})};
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  ${mobile({fontSize:"13px"})};


`;
const Label = styled.label`
  font-size: 18px;

`;
const LabelS = styled.div`
  font-size: 18px;
  ${mobile({display:"none"})};

`;
const Wrapper2 = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  ${mobile({padding:"0px"})};

`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;
${mobile({width: "60vw", height:"33.9vw"})}
${tablette({width: "500px", height:"282px"})}


`;
const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;
const Th = styled.div`
border: 1px solid black;
padding: 40px;
${computer({width:"640px"})}
${tablette({width:"500px"})}
${mobile({width:"60vw"})}

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
`;
const Option = styled.option``;

const CourseparametersSeries = ({user,ads,newThumb, setNewThumb,IMG,setIMG,Video, Number, setVideos, Videos, selectedThumb, setThumb} ) => {
    const navigate = useNavigate();
    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState(undefined);
    const [video, setVideo] = useState(Video);
    const [filters, setFilters] = useState({});
    const [theArray, setTheArray] = useState([]);
    const [thechecked, setcheck] = useState([]);
    const [newT, setT] = useState(true);
    const [videoSaved,setVideoSave]= useState(0);
    const [imgURL, setImgUrl] = useState(undefined);
    const editorRef = useRef(null);
    const [privacy, setPrivacy] = useState("Public");

    function handleChange(e) {
      const value = e.target.value;
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
        if(jj==value){
          FF[jj]=true
        }else{
          FF[jj]=false
        }
      }
      setcheck(FF);
      setVideoSave(value);
      if(newT==true){
        setT(false)
      }
      selectedThumb[Number][0]=value;
      setThumb(selectedThumb)
    }
    const onChange = (content) => {
      console.log(content);
    }
    const changePP=(e)=>{
      setImg(e.target.files[0]);
      setImgUrl(URL.createObjectURL(e.target.files[0]))

      IMG[Number]= e.target.files[0];
      setIMG(IMG)  
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {
          FF[jj]=false
        }
      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true)
      selectedThumb[Number][0]=Video.thumbnails.length;
      setThumb(selectedThumb)
      // if(e.target.files[0].size>1000000){
      //     setTooLarge(true);
      // }else{
      //     setTooLarge(false);
      // }
      // setshowImage(true)
    }

    function handleNew(e){
      var FF=[];
      for (let jj = 0; jj < Video.thumbnails.length; jj++) {

          FF[jj]=false
        
      }
      setcheck(FF);
      setVideoSave(Video.thumbnails.length);
      setT(true);
      selectedThumb[Number][0]=Video.thumbnails.length;
      setThumb(selectedThumb)
    }

    const handleFilters = (e) => {
        const value = e.target.value;
        setFilters({
          ...filters,
          [e.target.name]: value,
        });
        

        var vv = video;
        if(e.target.name=="diff"){
          vv.difficulty= value;
        }
        if(e.target.name=="intens"){
          vv.intensity= value;
        }
        setVideo(vv);
        var V = Videos;
        V[Number]=vv;
        setVideos(V);
      };


    const changePrivacy=(e)=>{
      setPrivacy(e);
      var vv = video;
      vv.privacy=e;
      setVideo(vv);
      var V = Videos;
      V[Number]=vv;
      setVideos(V);
    }
    const changeDescription = (desc)=>{
        setDescription(desc);
        var vv = video;
        vv.description= desc;
        setVideo(vv);
        var V = Videos;
        V[Number]=vv;
        setVideos(V);
    }
    const changeTitle = (desc)=>{

        setTitle(desc);
        var vv = video;
        vv.title= desc;
        setVideo(vv);
        var V = Videos;

        V[Number]=vv;
        setVideos(V);
    }




    useEffect(async() => {
        try { 

            setCreator(user);
            setTitle(Video.title);
            setDescription(Video.description)
            setFilters({
                
                ["diff"]: Video.difficulty,
                ["intens"]: Video.intensity,

              });
              setTheArray(Video.style)
              var CC=[];
              for (let ii = 0; ii < Video.thumbnails.length; ii++) {
                if(ii==selectedThumb[Number][0]){
                  CC[ii]=true;
                }else{
                  CC[ii]=false
                }
                }
              setcheck(CC)
              console.log(selectedThumb[Number])

              if(IMG[Number]){
                setImg(IMG[Number]);
                setImgUrl(URL.createObjectURL(IMG[Number]))
                if(selectedThumb[Number][0]==Video.thumbnails.length){
                  setT(true)

                }else{
                  setT(false)

                }
              }
        }catch(e){


        }
  
    },[])
    useEffect(() => {
   
      var u = theArray;

      var vv = video;
      vv.style=u;
      setVideo(vv);
      var V = Videos;
      V[Number]=vv;
      setVideos(V);


  
},[theArray])


    const createCourse= ()=>{
      const course = {
        "title":title,
        "description":description,
        "difficulty":filters.difficulty,
        "intensity": filters.intensity,
        "style":theArray
      }
      localStorage.setItem('course',JSON.stringify(course));
      navigate('/selectcourse2/'+Video._id);

    }



    const updateVideo= async () =>{

            // if (description != ''){
            //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"description":description});
            // }
            // if (title != ''){
            //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"title":title});
            // }
            // if(video!=undefined){
            //     const formData = new FormData()
            //     formData.append('profile', video, video.name)
            //     const res1 = await axios.put("http://51.178.60.185/api/video/video/"+Video._id,formData,{   
            //         onUploadProgress: progressEvent => {
            //             console.log(progressEvent.loaded / progressEvent.total);
            //         }
            //       })
            // }
            // if(img!=undefined){
            //     const formDataThumb = new FormData()
            //     formDataThumb.append('profile', img, img.name)glob={videos}
            //     const thum = await axios.post("http://51.178.60.185/api/video/thumbnails/"+Video._id,formDataThumb)
            // }
            navigate('/selectcourse2/'+Video._id);
        }
    


    return (
        
        <Container>
            <Wrapper2>
            <Label>Titre :</Label>

<Input
value={title}
  type="text"
  placeholder="Titre"
  name="title"
  onChange={(e) => changeTitle(e.target.value)}
/>
<br/>
<Label>Description :</Label>

{/* <Desc
value={description}

          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => changeDescription(e.target.value)}
        /> */}
{/* 
<Editor
        onInit={(evt, editor) => editorRef.current = editor}
        value={description}

        init={{
          
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'emoticons'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          '|emoticons|'+
          'removeformat | help',
          content_style: 'body { font-family:Urbanist, sans-serif; font-size:18px,font-weight:400,letter-spacing: 15px;line-height: 1.6; }'


        }}
        onEditorChange={changeDescription}

      /> */}

<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        value={description}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
        onEditorChange={changeDescription}
      />
<br/>
<div style={{display:"flex", alignItems:"center"}}>
<Label>Sélectionnez votre miniature :</Label>


</div>

{Video.thumbnails?.map((item,i) => (
<Th  key={i}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" 
      
      value={i}
      onChange={(e) => handleChange(e)}
      checked={thechecked[i]} 
      
      
      />
    </div>
<Image src={ads+item} />

</Th>

))}

{imgURL?
  <div style={{border:"1px solid black", padding:"40px",width:"640px"}}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" checked={newT} onChange={(e) => handleNew(e)} />
    </div>
<Image src={imgURL}/>
</div>

:null}

<div style={{display:"flex", alignItems:"center"}}>
<Label>Télécharger une nouvelle miniature :</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => changePP(e)}
/>
</div>
<br/>


<Options 
>
  
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>

          <Label style={{marginLeft:"40px"}}>Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>
</Options>







<br/>

<OptionsS >

<CateS theArray={theArray} setTheArray={setTheArray}/>


<div style={{marginTop:"10px"}}>
        <Label>Difficulté :</Label>
            <Select name="diff" value={filters.diff} onChange={handleFilters}>
            <Option>Beginner</Option>
            <Option>Moderate</Option>
            <Option>Intermediate</Option>
            <Option>Advanced</Option>
            <Option>For All</Option>

          </Select>
          </div>
          <div style={{marginTop:"30px"}}>
          <Label >Intensité :</Label>
            <Select name="intens" value={filters.intens} onChange={handleFilters}>
            <Option>Level 1</Option>
            <Option>Level 2</Option>
            <Option>Level 3</Option>
            <Option>Level 4</Option>

          </Select>

          </div>

        </OptionsS>

 <Cate  theArray={theArray} setTheArray={setTheArray}/>

        <div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de confidentialité :</Label>
            <Select name="privacy" onChange={(e) => changePrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
            <Option>Subscribers</Option>
          </Select>
</div>
            </Wrapper2>










           
        </Container>
        
    )
}

export default CourseparametersSeries
