import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Deletesubscription from "./Deletesubscription";

import { Link } from "react-router-dom";

import axios from "axios";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;
`;

const Wrapper = styled.div`
  width: 600px;
  height: auto;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 300px;
object-fit: cover;



`;


const Scrollable= styled.div`
overflow-y:scroll;
height:500px;
`;
const Profilepicture = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  margin-left:20px;
  margin-right:20px;


`;

const Follow = styled.button`
    border: 1px solid black;
    border-radius:10px;
  background-color: white;
  color: black;
  cursor: pointer;
  width:auto;
  height: auto;
  padding:10px;

  margin-left:auto;
:hover {
}

`;


const UploadCP = ({ ads,setOpen, user}) => {
  const [img, setImg] = useState(undefined);
  const [teacher, setTeacher] = useState(undefined);

  const [imgURL, setImgUrl] = useState(undefined);
  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate()
  const [followers,setFollowers]=useState(null);
  const [open1, setOpen1] = useState(false);

  const buttonRef = useRef();


  useEffect( () => {

    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
 }, []);
 useEffect( async() => {
  const Followers = (await axios.get(ads+'api/users/subscriptionsinfo/'+user._id)).data
  setFollowers(Followers);


}, []);
  const handleChange = (e) => {
    setInputs((prev) => {                       
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };

  const onButton=()=>{
     buttonRef.current.click()
    
  }

  const deleteSub = (i) =>{
    setTeacher(followers[i]);
    setOpen1(true)
  }




  return (
    <>
    <Container>
      <Wrapper>
        <Title>MY SUBSCRIPTIONS</Title>
        <Scrollable >
        {followers?.map((item,i) => (
                <div style={{display:"flex", height:"70px",width:"90%", position:"relative", alignItems:"center", fontSize:"20px"}}>
                <Link to={"/profile/"+item.id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <div style={{display:"flex", height:"70px",width:"auto", position:"relative", alignItems:"center", fontSize:"20px"}}>

                    <Profilepicture src={ads+item.profilePicture}/>

                    {item.username}

                </div>
                </Link>
                <Follow onClick={() => deleteSub(i)}>DELETE</Follow>
                </div>
                ))}
                </Scrollable>
        <div onClick={() => setOpen(false)} style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"60px",alignItems:"center", cursor:"pointer", color:"black",fontWeight:"400"}}>
            Close
        </div>
      </Wrapper>
    </Container>
            {open1 && <Deletesubscription ads={ads} setOpen={setOpen1} owner={teacher} customer={user} />}
            </>
  );
};

export default UploadCP;



