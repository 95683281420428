import React from 'react'
import ClasseV1 from '../components/ClasseV1'
import Footer from '../components/Footer'
import { useLocation } from "react-router-dom";

import { useState, useEffect} from "react";
import axios from "axios";
import Navbar from '../components/Navbar'
import SliderVideos from '../components/SliderVideos'
import Selectcourses from './Selectcourses';
import styled from "styled-components";




const Selectcourses2 = ({ads,user,connected}) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [video, setVideo] = useState({});
    
    useEffect(async() => {
        try { 

            const Video=(await axios.get(ads+'api/video/'+id)).data;
            setVideo(Video);

        }catch(e){


        }
  
    },[])
    return (
       <div>
           
            <Navbar user={user} connected={connected}/>
            {video.bunnyId?
            <ClasseV1 Video={video}/>
            :null
        }



            <Footer/>
       </div>

    )
}

export default Selectcourses2
