export const sliderItems = [
  {
      id: 1,
      img: "/patient01.jpg" ,
      title: "PROFITEZ DE CONSEILS ET RECOMMANDATIONS PROVENANT DIRECTEMENT DE VOS PRATICIENS",

      title1: "GET AN EXCLUSIVE INTERACTION WITH YOUR FAVORITE YOGA TEACHER",
      desc: "SUIVEZ LES INSTRUCTIONS VENANT DIRECTEMENT DE PROFESSIONNELS DE SANTÉ, AMÉLIORER VOTRE ROUTINE ET EPANOUISSEZ-VOUS EN TOUTE SÉRÉNITÉ.",

      desc1: "INTERACT DIRECTLY VIA MESSAGE OR BOOK AN ONLINE COURSE.",
      bg: "fcf1ed",
    },
  {
    id: 2,
    img: "/patient02.jpeg",
    title: "RETROUVEZ DU CONTENU SPÉCIFIQUE À VOS BESOINS FACILEMENT ET À TOUT MOMENT.",
    desc: "PROFITEZ D'UN CONTENU RICHE CRÉÉ PAR VOS PRATICIENS QUAND VOUS VOULEZ ET OÙ VOUS VOULEZ.",

    desc1: "FIND COURSES AND SERIES CREATED BY YOUR FAVORITE YOGA TEACHER TO ACHIEVE YOUR BEST SELF.",
    bg: "f5fafd",
  },
  {
    id: 3,
    img: "/patient03.jpg",
    title: "REJOIGNEZ UNE COMMUNAUTÉ PORTÉE PAR LES VALEURS DE LA SANTÉ ET DU BIEN-ÊTRE.",
    title1: "BE IMMERSED IN THE YOGA FLOW",
    desc: "VENEZ PARTAGER ENTHOUSIASME, MOTIVATION ET BIENVEILLANCE POUR ATTEINDRE VOS OBJECTIFS ET VOUS FAIRE PLAISIR.",

    desc1: "JOIN THE AMAZING COMMUNITY THAT SHARES ENTHOUSIASM AND MOTIVATION TO ACHIEVE YOUR GOALS.",
    bg: "fbf0f4",
  },
];
export const categories = [
  {
    id: 1,
    img: "/patient1.jpg",
    title: "ARTHROSE",
  },
  {
    id: 2,
    img: "/patient4.jpg",
    title: "COU",
  },
  {
    id: 3,
    img: "/patient3.jpg",
    title: "DOS",
  },

];

export const categories2 = [


  {
    id: 1,
    img: "/patient2.jpg",
    title: "ENTORSE",
  }
,
{
id: 3,
img: "/patient5.jpg",
title: "ÉTIREMENTS",
},
{
id: 2,
img: "/patient6.jpg",
title: "HANCHE",
}
];

export const categories3 = [


  {
    id: 3,
    img: "/patient7.jpg",
    title: "NUTRITION",
  }
,
  {
    id: 2,
    img: "/prenat_redim.jpg",
    title: "PRÉNATAL",
  },
  {
    id: 1,
    img: "/patient8.jpg",
    title: "RENFORCEMENT",
  }
];


export const categories4 = [
  {
    id: 1,
    img: "/patient1.jpg",
    title: "ARTHROSE",
  },
  {
    id: 2,
    img: "/patient4.jpg",
    title:  "COU",
  },


];

export const categories5 = [

  {
    id: 3,
    img: "/patient3.jpg",
    title: "DOS",
  },
  {
    id: 1,
    img: "/patient2.jpg",
    title: "ENTORSE",
  }
,

];
export const categories6 = [
{
  id: 3,
  img: "/patient5.jpg",
  title: "ÉTIREMENTS",
},
{
  id: 2,
  img: "/patient6.jpg",
  title:"HANCHE" ,
}
]
export const categories7 = [



  {
    id: 2,
    img: "/patient7.jpg",
    title: "NUTRITION",
  },
  {
    id: 1,
    img: "/prenat_redim.jpg",
    title: "PRÉNATAL",
  }
];

export const mentors= [
  {
    id: 1,
    img: "/ash2.jpg",
    title: "AGNES GORGUES",
  },
  {
    id: 2,
    img: "/ash1.svg",
    title: "KANYE WEST",
  },
  {
    id: 3,
    img: "/tuto2.svg",
    title: "MAITRE YOGI",
  },
];

export const cover =[
  {
    img:"/cover01.jpg",
    title: 'Jon Snow',
    description:'ola'
  }
]


export const popularVideos = [
  {
    id:1,
    img:"/t01.jpg",
  },
  {
    id:2,
    img:"/t02.jpg",
  },
  {
    id:3,
    img:"/t03.jpg",
  },
  {
    id:4,
    img:"/t04.jpg",
  },
  {
    id:5,
    img:"/t05.jpg",
  },
  {
    id:6,
    img:"/t06.jpg",
  }
]