
  import styled from "styled-components";
  import { mobile,tablette,computer } from "../responsive";
  import { useState, useEffect} from "react";
  import axios from "axios";
  import { Link } from "react-router-dom";
  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
  import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
  import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
  import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
  import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
  import CloseIcon from '@mui/icons-material/Close';
  import DeleteCom from '../components/DeleteCom'


  const Info = styled.div`
    width: 100%;
    position: relative;
    align-items: center;
    cursor: pointer;
    margin-top:20px;
    margin-bottom:20px;

    ${mobile({marginBottom:"100px"})}
    
  `;
  
  const Container = styled.div`

    margin-top: 50px;;
    width: 100%;
    height: auto;
    border: 1px solid #ececec;
    ${mobile({height:"99.5%", width:"99.5%"})}

  `;

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-around;
height: 18.28vw;
align-items: center;
width: 100%;

background-color: black;

`;
  

  
  const Image = styled.img`
      ${tablette({height:"75vw", width:"75vw"})}
      ${mobile({height:"99.5vw", width:"99.5vw"})}

    width:600px;
    height: 600px;
    object-fit: cover;
    z-index: 2;
  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;
  const Title = styled.h1`

font-size: 17px;
  font-weight: 600;
  padding-left:20px;

  color:black;
`

const Description = styled.h1`

font-size: 17px;
  font-weight: 400;
margin-left:20px;
  color:black;
  margin-top:15px;
  line-height:22px;
`


const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  
`;
const Profilepict = styled.img`
width:40px;
height: 40px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-left: 20px;
margin-right: 20px;


`;
const Div1 = styled.div`
display:flex;
padding-left:20px;
margin-bottom:20px;
align-items: center;
${mobile({width:"90vw", paddingBottom:"10px"})}
${tablette({width:"90%"})}
`;

const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  filter:none;

  position:absolute;
  color:white;
  cursor:pointer;
  
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"220px", display:"flex",justifyContent:"center", fontSize: "12px", padding:"5px", bottom:"70px"})}
  ${tablette({width:"270px", display:"flex",justifyContent:"center", padding:"10px", bottom:"70px"})}
  ${computer({width:"auto",  fontSize: "17px", padding:"14px", bottom:"70px"})}

`
const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  position:absolute;
  color:black;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  bottom:30px;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:172px;
`;

const Input1 = styled.input`
  margin: 10px 10px 0px 0px;
  padding: 10px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: none;
  width: 100%;
  outline: none;

  :focus {
    outline: none;
}
`;

const ProfilePictCom = styled.img`
width:30px;
height: 30px;
object-fit: cover;
border-radius: 50%;
margin-right: 20px;
margin-left: 10px;
${mobile({marginLeft:"20px"})};

`;

const ComSpan= styled.span`
${mobile({width:"70vw"})};
${tablette({width:"63vw"})};
${computer({width:"500px"})};

`
const Div2 = styled.div`


${mobile({width:"90vw"})}
${tablette({width:"77vw"})}
`;
const DivInput = styled.div`


${mobile({width:"90vw"})}
${tablette({width:"77vw"})}
`;

const Button2 = styled.button`
margin-top: 0px;
border: none;
height: 35px;
padding: 10px 15px;
color: white;
background-color: #8798ef;
margin-left: auto;
margin-right: 20px;
cursor: pointer;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
  const Feed = ({ads, item, user,setUser }) => {
      console.log(item)
    const [author, setAuthor] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [postDate, setPostDate]= useState("");
    const [showComments, setShowComments]=useState(false);
    const [liked, setLiked] = useState(item.likesId.includes(user._id));
    const [favorited, setFavorited] = useState(user.favoritePosts.includes(item._id));
    const [commentaire, setCommentaire] = useState("");
    const [comments, setComments] = useState([]);
    const [likesNumber, setLikesNumber] = useState(item.likesNumb);
    const [isdisable, setIsdisable] = useState(true);
    const [open1, setOpen1] = useState(false);

    const [open4, setOpen4] = useState(false);

    const [comId, setComId] = useState("");




    const startSub = async(id)=>{
      if(user.freeSub){
        const body =  {
          buyer:user._id
        }
        const check=await axios.post(ads+'api/checkout/subscription/free/'+id, body);
        window.location.reload();
      }else{
        //setOpen1(true)
    
      }
    }

    const likeIt = async() =>{
      const res= (await axios.post(ads+'api/likes/posts/'+item._id+'/'+user._id)).data;
      setLiked(true)
      setLikesNumber(likesNumber+1)
    }
    const dislikeIt = async() =>{
      const res= (await axios.put(ads+'api/likes/posts/'+item._id+'/'+user._id)).data;
      setLiked(false)
      setLikesNumber(likesNumber-1)
    
    }
    
    const favoriteIt = async() =>{
      const res= (await axios.post(ads+'api/favorites/posts/'+item._id+'/'+user._id)).data;
      setFavorited(true)
      var user1=user
      user1.favoritePosts.push(item._id)
      setUser(user1)
    }
    
    const sendComment = async() =>{
      const res= (await axios.post(ads+'api/comments/posts/'+item._id+'/'+user._id,
      {
        comment:commentaire
      })).data;
      var com1= [...comments]
      com1=[{username:user.username,userId:user._id, profilePicture:user.profilePicture, commentaire: commentaire,_id:res._id}].concat(comments)
      setComments(com1)
      setCommentaire("");
    }
    
    const deleteCom = (id) =>{
      setComId(id)
      setOpen4(true)
    
    }
    
    const deFavoriteIt = async() =>{
      const res= (await axios.put(ads+'api/favorites/posts/'+item._id+'/'+user._id)).data;
      setFavorited(false)
      var user1=user
      const index = user1.favorites.indexOf(item._id);
      if (index > -1) { // only splice array when item is found
          
          user1.favoritePosts.splice(index, 1); // 2nd parameter means remove one item only
      }
      setUser(user1)
    
    } 



    useEffect(async() => {
        try {
          const date = new Date(item.createdAt);
          const monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
          const monthName1 = monthNames[date.getMonth()];
          setPostDate(date.getDate() +" "+ monthName1+" "+ date.getFullYear()) 



          const res= (await axios.get(ads+'api/comments/posts/'+item._id)).data;
          res.sort(function(a,b) {
            return new Date(b.date) - new Date(a.date)
        });
        
          setComments(res)


            const u = (await axios.get(ads+'api/users/'+item.creatorId)).data
            if(user.subscribe.includes(u._id)){
                setStatus("subscriber")
              }
            setAuthor(u);

        }catch(e){


        }
  
    },[])
    return (
      <>
      <Container>
    <Link to={"/profile/"+author?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <Author>
            <Profilepict src={ads+author?.profilePicture}/> 
            <div>
{author?.username}

<div style={{marginTop:"5px",color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
</div>
        </Author>
    </Link>
        <div style={{position:"relative"}}>
        <Image src={ads+item.thumbnails[0]} />
        {item.privacy=="Subscribers"&&status!="subscriber"?
        <Lock onClick={() => startSub(item.creatorId)}>
          Subscribe to see this post
        </Lock>
        :null
        }

        </div>


        <Info>
          <Div1>
    {liked?
    <FavoriteOutlinedIcon onClick={()=>dislikeIt()} style={{marginRight:"5px", color:"#ff696e", cursor:"pointer"}}/>
:
    <FavoriteBorderIcon onClick={()=>likeIt()} style={{marginRight:"5px", cursor:"pointer"}}/>

  }
  {Number(likesNumber)>1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aimes</span>
:
null
  }
    {Number(likesNumber)==1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aime</span>
:
null
  }
      {Number(likesNumber)==0?
    <span style={{marginRight:"20px", width:"30px"}}></span>
:
null
  }
<ChatOutlinedIcon onClick={() => setShowComments(!showComments)} style={{marginRight:"5px", cursor:"pointer"}}/>
{comments?.length==1?
  <span onClick={() => setShowComments(!showComments)} style={{marginRight:"20px"}}>{comments?.length} Commentaire</span>
:null}
{comments?.length>1?
  <span onClick={() => setShowComments(!showComments)} style={{marginRight:"20px"}}>{comments?.length} Commentaires</span>
:null}
{favorited?
  <BookmarkAddedIcon onClick={()=>deFavoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>
:
<BookmarkAddOutlinedIcon onClick={()=>favoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>

}


  </Div1>

<div style={{display: showComments ? 'block' : 'none',maxHeight:"400px", overflowY:"scroll"}}>


  <DivInput style={{width:"auto",borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec",display:"flex", marginTop:"10px", paddingBottom:"10px", backgroundColor:"white", paddingTop:"10px", marginLeft:"auto", marginRight:"auto"}}>
<Input1 style={{width:"auto",marginTop:"0px"}} placeholder="Ajouter un commentaire :-)" value={commentaire} 
onChange={(e) => {
  setCommentaire(e.target.value); 
  if(e.target.value!=""){
    setIsdisable(false)
  }else{
  setIsdisable(true)
}}}/>
<Button2 disabled={isdisable} onClick={()=>sendComment()}
        >Publier</Button2>
</DivInput>
<div style={{marginBottom:"20px"}}>
  {comments?.map((item) => (

 <Div2 style={{ width:"auto",marginTop:"10px", paddingBottom:"10px",borderBottom:"1px solid #ececec", color:"grey",display:"flex"}}>
      <ProfilePictCom src={ads+item.profilePicture}/>  

  <div style={{ }}>
    <div style={{display:"flex"}}>
        <span style={{fontWeight:"600", marginRight:"6px", color:"black"}}>
        {item.username}
        </span>
        <div style={{marginBottom:"1px",display:"flex", alignItems:"end",  marginLeft:"0px", fontSize:"13px"}}>
      {item.delai}
      </div>

    </div>



    <div style={{display:"flex"}}>
    <ComSpan style={{color:"black", marginTop:"5px"}}>
        {item.commentaire}
      </ComSpan>
    {user._id==item.userId?
      <CloseIcon style={{marginLeft:"auto",  color:"grey", cursor:"pointer"}} onClick={()=>deleteCom(item._id)}/>
      :null}
    </div>


    </div>
</Div2>
 ))}
 </div>       

 </div>






        <Link to={"/profile/"+author?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Title>
            {author?.username}
            </Title>
        </Link>
            <Description>
            <div
      dangerouslySetInnerHTML={{__html:item.description}}
    />
            </Description>
        </Info>
      </Container>
          {open4 && <DeleteCom  ads={ads} setOpen={setOpen4} item={item} user={user} comId={comId}/>}

          </>
    );
  };
  
  export default Feed;