import React from 'react'
import ClasseV from '../components/ClasseV'
import Footer from '../components/Footer'
import { useLocation } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import { useState, useEffect} from "react";
import axios from "axios";
import Navbar from '../components/Navbar'
import SliderVideos from '../components/SliderVideos'
import styled from 'styled-components'
import VV from '../components/VideosSeries'


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const ClasseSeries = ({ads,setUser,setNM, nM, socket, user,connected}) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const n = location.pathname.split("/")[3];

    const [video, setVideo] = useState({});
    const [series, setSeries] = useState({});
    const [teacher, setTeacher] = useState({});
    const [status, setStatus] = useState(undefined);


    useEffect(async() => {
        try { 

            const Series=(await axios.get(ads+'api/series/'+id)).data;
            var Video = Series.videosDetail[n]
            const Video1=(await axios.get(ads+'api/classe/'+Series.videosDetail[n]._id)).data;
            Video.likesId=Video1.likesId;
            Video.likesNumb=Video1.likesNumb;
            Video.comments=Video1.comments;
            Video.updatedAt=Video1.updatedAt
            Video.createdAt=Video1.createdAt

            Video.status=Video1.status

            setSeries(Series);

            const teacher=(await axios.get(ads+'api/users/'+Video.creatorId)).data;

            setTeacher(teacher);
            setVideo(Video);
            if(user.subscribe.includes(teacher._id)){
                setStatus("subscriber")
              }else if(user.follow.includes(teacher._id)){
                setStatus("follower")
              }
            
        }catch(e){


        }
  
    },[])
    return (
       
        <div style={{display:"flex", width:"100%"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    {connected?
                        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
                    :null}
                </BottomNav>
            {video._id?
            <ClasseV ads={ads} Video={video} N={n} user={user}/>
            :null
        }

            {video._id?
                <VV ads={ads} Video={series} owner={teacher} status={status}/>
            :null}

            </ContainerRight>
       </div>

    )
}

export default ClasseSeries
