import styled from "styled-components";
import { Link } from "react-router-dom";
import "../components/App1.css";

const H1 = styled.h1`
margin-bottom:20px;
margin-top:20px;
`;

const H2 = styled.h2`
margin-bottom:15px;
margin-top:15px;
`;
const H3 = styled.h3`
margin-bottom:10px;
margin-top:10px;
`;

const H4 = styled.h4`
margin-bottom:10px;
margin-top:10px;
`;

const Login = () => {

	const date = "06/04/23";
	const appName="OmCare";
	const entreprise="OmTech Innovations"
	const professeur="Praticien";
	const eleve="Patient"
	const professeurs="Praticiens";
	const eleves="Patients"
	const siteWeb="https://www.omcare.fr"
	const entrepriseFull = "OmTech Innovations S.A.S.U au capital social de 500 euros, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 950 886 986, dont le siège social est situé au 229 rue Saint-Honoré, 75001, Paris"
  



  
  return (
<div style={{marginLeft:"15px", marginRight:"15px"}}>

<div style={{borderBottom:"1px solid grey", marginBottom:"50px", paddingBottom:"30px", paddingTop:"10px"}}>

          <H1>
            Politique de confidentialité et de protection des données
            </H1>
          <div>
            Version du {date}
            </div>
</div>
<H2>
1. Introduction
</H2>
<H3 >
   1.1 Identité et coordonnées de l'organisme responsable du traitement informatique des données personnelles 
    </H3>
	En sa qualité de responsable de traitement, la société {entrepriseFull} s'engage à vous informer de ses pratiques concernant la collecte, 
	l’utilisation et le partage des informations que vous êtes amenés à communiquer lors de votre utilisation de la plateforme {appName} accessible à 
	l'adresse {siteWeb}. {entreprise} sera notamment responsable pour les traitements relatifs à :
<ul>
<li>
	La gestion et le suivi des comptes et contrats ;

</li>

<li>
	La fourniture et la gestion des services ;

</li>
<li>
	La gestion des paiements ;

</li>
<li>
	La gestion des demandes de renseignement auprès du support ;

</li>
<li>
	L’envoi de courriers électroniques ;

</li>
<li>
	La gestion des demandes de droits ;

</li>
<li>
	Le bon fonctionnement et l’amélioration permanente de la Plateforme.

</li>
</ul>
<br/>
Rappel légal : Le responsable du traitement est, au sens de la loi Informatique et Libertés et du RGPD, la personne qui détermine les moyens et les finalités du traitement. Lorsque deux responsables du traitement ou plus déterminent conjointement les finalités et les moyens du traitement, ils sont les responsables conjoints du traitement (ou co-responsables). Le sous-traitant est une personne traitant des données à caractère personnel pour le compte du responsable du traitement, il agit sous l’autorité du responsable du traitement et sur instruction de celui-ci. 
<br/>

<H3>
1.2 Définitions
</H3>
<ul>
<li>
« {professeur} » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné son statut de Praticien à son inscription sur {appName} ;

  </li>
  <br/>
  <li>
  « {eleve} » : désigne toute personne physique ou morale inscrite sur la Plateforme ayant renseigné son statut de Patient  à son inscription sur {appName} ;

  </li>
</ul>
<H2>

	2. Les informations que nous recueillons
</H2>

<H3>
2.1 Les informations que vous nous fournissez lors de la création de votre compte
</H3>
En utilisant la plateforme {appName}, vous êtes amenés à nous transmettre des informations nécessaires à votre identification. 
Ces informations (contenant des données personnelles), peuvent êtres collectées lorsque vous remplissez divers formulaires ou lorsque vous nous contactez.

<H4>2.1.1 Données collectées lors de la création d'un compte {eleve}</H4>

Si vous créez un compte en sélectionnant l'option "UN PATIENT" les informations collectées contiennent les données suivantes :
<ul>
<li>
Nom, Prénom et pseudonyme ;
</li>
<li>
Adresse email ;
</li>
<li>
Mot de passe ;
</li>

</ul>
Dans le cas ou un {eleve} souhaite réaliser un paiement, il doit renseigner des informations de paiement à notre partenaire Stripe. La plateforme {appName}, ne conserve
aucune information directement liée au moyen de paiement mais un token d'identification sera fourni par Stripe pour permettre les paiements futurs. Il est important de préciser
que Stripe conservera des informations personnelle et qu'il est fortement recommandé de consulter les politiques de confidentialité de Stripe 
pour être informé de l'utilisation de vos données personnelles par ce sous-traitant.


<H4>2.1.2 Données collectées pour un {professeur}</H4>

Pour creer un compte {professeur} vous devez renseigner plus d'informations que lors de la création d'un compte Elève.
Pour recevoir des paiements vous devez créer un compte Stripe. Lors de la création vous devrez fournir des informations
bancaires et personnelles qui ne nous seront pas transmises. Nous vous recommandons de consulter les conditions d'utilisation de Stripe pour 
avoir plus de détails qu'en à l'utilisation de vos données. 
En plus des informations demandées par Stripe nous pouvons collecter les informations suivantes :
<ul>
	<li>
Nom, Prénom et Pseudonyme ;
</li>
<li>
Adresse email ;
</li>
<li>
Mot de passe ;
</li>
<li>
Adresse personnelle
</li>
<li>
	Copie de votre carte nationale d'identité ou passeport

</li>
<li>
Votre numéro d'identification à la TVA
</li>
<li>
Votre date de naissance
</li>
<li>
Votre sexe
</li>
</ul>
<H3>2.2 Les informations que vous nous fournissez lors de votre utilisation de {appName}</H3>
<H4>
2.2.1 Données liées à votre messagerie instantannée, vos commentaires, vos favoris et vos mentions "j'aime"
</H4>
Nous collectons et stockons vos messages, vos commentaire, vos favoris et vos mentions "j'aime" ainsi que les informations relatives à ces activités telle que la date d'envoi des messages et de consultation des messages envoyés ou
la date d'envoi des commentaires.
<br/>
<br/>
Les données relatives aux mentions "j'aime", commentaires et favoris pourront êtres utilisées par {appName} pour établir un système de recommandation nous permettant de vous fournir un service personnalisé, notamment vous recommander les profils ou Contenu 
ayant une grande probabilité de vous intéresser.
<H4>
2.2.2 Les images et vidéos ou tout contenu posté par les utilisateurs</H4>

Lors de votre utilisation de {appName}, il vous est possible de renseigner une biographie dans laquelle de nombreuses informations personnelles peuvent êtres renseignées. Il est important de noter que cette biographie est 
publique, que tout Utilisateur venant sur votre profil peut la lire (même si cet Utilisateur ne possède pas de compte {appName}).
<br/>
<br/>
Lors de votre utilisation de {appName} nous vous recommandons de publier du contenu pour personnaliser votre profil et promouvoir la recherche du bien être, l'épanouissement et la santé au sein de la communauté hébergée par {appName}.
Ce contenu peut être du texte, des images et des vidéos. Nous collectons ces informations pour les rendre disponibles aux Utilisateurs de la plateforme {appName}. 
<br/>
<br/>
Les images et le texte peuvent être collectés sous la forme :

<ul>
<li>
D'articles ;
</li>
<li>
De posts ;
</li>
<li>
D'images envoyées via la messagerie instantannée ;
</li>
<li>
De photophraphies de profil ou de couverture.
</li>
</ul>

Les vidéos et le texte peuvent êtres collectés sous la forme :

<ul>
<li>
De cours ;
</li>
<li>
De vidéos téléchargées par un {professeur} dans sa galerie mais non publiées ;
</li>
</ul>
<H4>
2.2.3 Les données liées aux paiements réalisés et aux paiements reçus</H4>
Pour permettre à nos utilisateur une meilleure gestion des achats et ventes réalisés sur {appName} nous reccueillons les données relatives aux paiements. Il peut s'agir :
<ul>
<li>
D'un paiement d'Elève pour profiter d'un abonnement ;
</li>
<li>
D'un don réalisé via la fonctionnalité "Buy Me a Tea".
</li>
</ul>
En plus du montant, nous conservons la date, les identités de l'{eleve} ainsi que celle du {professeur} associées à la transaction. 
<H3>
2.3 Autres informations que nous collectons de manière automatique
</H3>

Nous recevons automatiquement des informations lorsque vous interagissez avec {appName}, et cela même si vous n'avez pas créé de compte. 
Nous pouvons recevoir automatiquement des informations suivantes :

<ul>
<li>
Votre adresse IP ;
</li>
<li>
Le type et la version de votre navigateur internet ;
</li>
<li>
Le matériel informatique que vous utilisez pour utiliser {appName} ;
</li>
<li>
Les informations liées aux cookies
</li>
</ul>

<H2>
    3. Finalité poursuivie par le traitement   
    </H2>

    <table>
	<tbody>
		<tr>
			<td>
			<strong>
			<ul>
				Finalité principale
			</ul>
			</strong>
			</td>

			<td>
			<ul>
			<strong>Base légale</strong>
		</ul>
			</td>
		</tr>
		<tr>
			<td>
				<ul>
			Exécuter les contrats conclus entre vous et nous et vous fournir les informations et services demandés 
			</ul>
			</td>

			<td>
			<ul>
				Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives.

			</ul>
			</td>
		</tr>
		<tr>

			<td>
			<ul>
				Mise en relation des Elèves avec les {professeurs}

			</ul>
			</td>
			<td>
				<ul>
					Consentement de l’Utilisateur

			</ul>			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des paiements

			</ul>
			</td>

			<td>
			<ul>
				Consentement de l’Utilisateur
			</ul>
							</td>
		</tr>
		<tr>

			<td>
			<ul>
				
				Suivi et réponse aux demandes de renseignement auprès du support
				
			</ul>
			</td>
			<td>
			<ul>
				Exécution d'un contrat ou exécution de mesures précontractuelles 

			</ul>
			</td>
		</tr>
		<tr>

			<td>
				<ul>
					Envoi de courriers électoniques et autres alertes aux Utilisateurs qui en ont fait la demande

				</ul>

			</td>
			<td>
			<ul>
				Consentement de l’Utilisateur 

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des demandes de droits issus du RGPD et de la loi Informatique et Libertés modifiée

			</ul>
			</td>
<td>
<ul>
	Notre obligation légale découlant des articles 15 et suivants du RGPD et des articles 48 et suivants de la loi Informatique et Libertés

</ul>
</td>
		</tr>
		<tr>

			<td>
			<ul>
				Assurer le bon fonctionnement et l’amélioration permanente de la Plateforme et de ses fonctionnalités

			</ul>
			</td>
			<td>
			<ul>
				Notre intérêt légitime à garantir le meilleur niveau de fonctionnement et de qualité de la Plateforme grâce notamment aux statistiques de visites. 
<br/>
Le Consentement de l’Utilisateur lorsque celui-ci est requis.
			</ul>
 
			</td>
		</tr>
		<tr>

<td>
<ul>
	Vous permettre de personnaliser votre profil sur notre Plateforme
</ul>
	 </td>
<td>
<ul>
	Ce traitement est réalisé sur la base de votre consentement.

</ul>
 
</td>
</tr>
<tr>

<td>
<ul>
	Vous permettre de communiquer et d’échanger avec les autres membres de notre Plateforme

</ul>
		</td>
<td>
<ul>
	Ce traitement est réalisé sur la base de votre consentement.

</ul>
 
</td>
</tr>
	</tbody>
</table>








<H2>
   4. Caractère obligatoire ou facultatif du recueil de données personnelles
    </H2>


    Le caractère obligatoire ou facultatif du receuil des données personnelles et les éventuelles conséquences d'un défaut de réponse sont indiqués lors des différents contacts avec les personnes concernées. Vous pouvez consulter le détail des données à caractère personnel que nous sommes susceptibles d’avoir sur vous ci-après. 
	<br/>
	<br/>
	NB : le détail des informations fournies ci-après n’a pas vocation à être exhaustif et vise avant tout à informer les Utilisateurs sur les catégories de données que {appName} est susceptible de traiter.


<H3>
    Le tableau ci dessous récapitule les données obligatoire à fournir pour utiliser les fonctionnalités listées
    </H3>
    <table>
	<tbody>
		<tr>
			<td>
			<strong>Finalité</strong>
			</td>
			<td>
			<strong>Données traitées</strong>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Création, gestion et suivi des comptes et contrats

			</ul>
			</td>
			<td rowspan="2">
			<ul>
				Données relatives à l’identité des Utilisateurs : nom, prénom(s), adresse, numéro de téléphone, adresses email, pseudonyme, date de naissance, données indiquées dans la biographie, photographies, copie du passeport / CNI

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Pour la fourniture et gestion des services

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des paiements

			</ul>
			</td>
			<td>
			<ul>
				<li>
				IBAN 
				</li>
				<li>
				Numéro de carte bancaire (seuls les 4 derniers chiffres sont conservés)
				</li>

				<li>
				Données nécessaires à l’établissement des factures
				</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des demandes de renseignement auprès du support

			</ul>
			</td>
			<td>
			<ul>
				<li>
				Adresse e-mail
				</li>
				<li>
				Numéro de commande
				</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Envoi de courriers electroniques

			</ul>
			</td>
			<td>
			<ul>
				<li>
				Données de contact de l’Utilisateur (nom, prénom, email)
				</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion de demandes de droits

			</ul>
			</td>
			<td>
			<ul>
				<li>
				Adresse e-mail
				</li>
				<li>
				Numéro de commande 
				</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
			
				Pour le bon fonctionnement et l’amélioration permanente de la Plateforme

			</ul>
			</td>
			<td>
			<ul>
				<li>
				Logs et données de connexions des Utilisateurs et données d’identification du matériel informatique ; 
				</li>
				<li>
				Les données collectées via les cookies et autres traceurs présents sur notre Plateforme ; pour plus de détail, consultez notre <a href="/app/cookies">charte de gestion des cookies </a>
				</li>
			</ul>
			</td>
		</tr>
	</tbody>
</table>
<H2>
    5. Recueille des données automatiques
    
    </H2>
    Lorsque vous naviguez sur la Plateforme {appName}, OmTech Innovations enregistre automatiquement des informations relatives à votre navigation. Lorsque vous utilisez nos services, des données de connexions peuvent-être automatiquement enregistrées dans nos journaux de serveur comme votre adresse IP, votre identifiant unique, votre système d'exploitation et son emplacement, le type de navigateur que vous utilisez, les pages que vous avez consultées.
<H2>
   6. Destinataires des données personnelles
    </H2>
    Seules les personnes et entreprises habilitées et déterminées mentionnées ci-dessous pourront avoir accès aux données des Utilisateurs.
	<ul>
	<li>
		Les autres Utilisateurs de la Plateforme, avec différents niveau d'accès en fonction de leur lien d'abonnement ou de suivi.
	</li>
	<li>
		Le personnel habilité de OmTech Innovations ;
	</li>
<li>
	Les sous-traitants chargés de la gestion, de l’hébergement de la Plateforme et du système informatique de OmTech Innovations. 
	 

</li>
<li>
	Les sous-traitants assurant le paiement, le stockage des données non vidéos, le stockage des données vidéo ainsi que 
	le service de vidéo streaming vidéo.
</li>
<li>
	Le cas échéant, le personnel habilité de nos sous-traitants ;

</li>
<li>
	Le cas échéant, les juridictions concernées, médiateurs, experts-comptables, commissaires aux comptes, avocats, huissiers, 
	sociétés de recouvrement de créances, autorités de police ou de gendarmerie en cas de vol ou de réquisition judiciaire ;

</li>
	</ul>


Vos données ne sont communiquées, échangées, vendues ou louées à aucune autre personne que celles mentionnées ci-dessus.



<H2>
   7. Durée de conservation des données personnelles

    </H2>
	OmTech Innovations conserve vos données personnelles jusqu'à ce qu'elles ne soient plus nécessaires pour fournir nos services, ou jusqu'à ce que vous nous demandiez de les supprimer. Nous pouvons continuer à conserver certaines informations même après la suppression de votre compte si nous sommes tenus de le faire afin de nous conformer aux lois applicables.
    <br/>
	Le tableau suivant informe (de manière non exhaustive) sur les durées de conservations de vos informations personnelles en fonction de la finalité.
	<br/>
	<br/>
    <table>
	<tbody>
		<tr>
			<td>
			<ul>
				<strong>Finalité</strong>

			</ul>
			</td>
			<td>
			<ul>
				<strong>Durée de conservation</strong>

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion et suivi des comptes et contrats

			</ul>
			</td>
			<td rowspan="2">
			<ul>
			Vos données sont conservées pendant toute la relation contractuelle puis archivées. 
			Elles sont par la suite stockées pendant cinq (5) ans à des fins probatoires conformément aux obligations légales et règlementaires auxquelles OmTech Innovation est soumise. 
			Les documents comptables sont par ailleurs conservés pendant 10 ans.
		</ul>

			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Fourniture et gestion des services

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des paiements

			</ul>
			</td>
			<td>
			<ul>
			
			Pour les paiements par carte bancaire, conformément à l’article L.133-24 du Code monétaire et financier, ces données peuvent être conservées pour une finalité de preuve en cas d’éventuelle contestation de la transaction ou réclamation, en archives intermédiaires, pendant une durée de treize (13) mois suivant la date de débit (durée portée à 15 mois pour les cartes de paiement à débit différé). 

			En tout état de cause, 

			<ul>

				<li>
				En cas d’abonnement avec tacite reconduction, les données bancaires sont conservées pendant la durée de l’abonnement ; 
				</li>
				<li>
				En cas d’abonnement sans tacite reconduction, les données bancaires sont conservées en base active jusqu’à la dernière échéance de paiement.
				</li>
				<li>
				Vous pouvez à tout moment supprimer vos données bancaires, cependant si un abonnement est en cours, celui-ci prendra fin automatiquement à la date à laquelle le prochain paiement aurait du être effectué.
				</li>
			</ul>
		</ul>
			
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Gestion des demandes de renseignement auprès du support

			</ul>
			</td>
			<td>
			<ul>
				Vos données sont conservées le temps nécessaire au traitement de la demande puis pendant trois (3) ans à compter du dernier contact avec OmTech Innovations.

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Pour l’envoi de courriers électroniques

			</ul>
			</td>
			<td>
			<ul>
				Les données sont conservées jusqu’à désinscription de l’Utilisateur via le lien prévu à cet effet. 

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Pour la gestion de demandes de droits

			</ul>
			</td>
			<td>
			<ul>
				Les données relatives à la gestion des demandes de droit sont conservées pour toute la durée nécessaire au traitement de la demande. Elles sont par la suite archivées pour la durée de prescription pénale applicable en archivage intermédiaire

			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				Pour le bon fonctionnement et l’amélioration permanente de la Plateforme

			</ul>
			</td>
			<td>
			<ul>
				Les cookies et autres traceurs commerciaux peuvent être déposés sur le navigateur de l’Utilisateur pour une durée maximale de treize (13) mois. Au-delà de ce délai, les données de fréquentation brutes associées à un identifiant sont soit supprimées soit anonymisées.

Les informations collectées par l'intermédiaire de cookies et traceurs sont conservées pour une durée de vingt-cinq (25) mois. Au-delà de ce délai, ces données sont supprimées, ou anonymisées.

			</ul>
</td>
		</tr>
	</tbody>
</table>



<H2>
    8. Droits des Utilisateurs
    </H2>

    Conformément à la Loi Informatique et Libertés et au RGPD, vous disposez des droits suivants :
	<ul>
	<li>
		Droit d’accès (article 15 RGPD), de rectification (article 16 RGPD) et de mise à jour ;

	</li>
	<li>
		Droit d’effacement de vos données à caractère personnel (article 17 RGPD), lorsqu’elles sont inexactes, 
		incomplètes, équivoques, périmées, ou dont la collecte, l’utilisation, la communication ou la conservation est interdite.
		Veuillez noter que nous sommes susceptibles de conserver certaines informations vous concernant lorsque la loi nous l’impose ou 
		lorsque nous avons un motif légitime de le faire. C’est par exemple le cas, si nous estimons que vous avez commis une fraude ou 
		violé nos Conditions d’Utilisation et que nous souhaitons éviter que vous contourniez les règles applicables à notre communauté.

	</li>
	<li>
		Droit de retirer à tout moment votre consentement (article 13-2c RGPD) ;

	</li>
	<li>
		Droit à la limitation du traitement de vos données (article 18 RGPD) ;

	</li>
	<li>
		Droit d’opposition au traitement de vos données (article 21 RGPD) ;

	</li>
	<li>
		Droit à la portabilité des données que vous nous avez fournies, lorsque vos données font l’objet de traitements automatisés fondés sur votre consentement ou sur un contrat (article 20 RGPD) ;

	</li>
	<li>
		Droit d’introduire une réclamation auprès de la CNIL (article 77 RGPD) ou toute autre autorité compétente ou d’obtenir réparation auprès des tribunaux compétents si vous considérez nous n’avons pas respecté vos droits.

	</li>
	<li>
		Droit de définir le sort de vos données après votre mort et de choisir que nous communiquions (ou non) vos données à un tiers que vous aurez préalablement désigné. 
		En cas de décès et à défaut d’instructions de votre part, nous nous engageons à détruire vos données, sauf si leur conservation s’avère nécessaire à des fins 
		probatoires ou pour répondre à une obligation légale.

	</li>
	</ul>
Ces droits peuvent exercés par simple demande, par courrier électronique à l’adresse support@omcare.fr ou par courrier à {entreprise}, 229 rue Saint-Honoré, 75001, Paris. En cas de communication de copie de pièce d’identité pour justifier de votre identité, nous la conserverons un (1) an ou trois (3) ans lorsque cette communication est effectuée dans le cadre de l’exercice d’un droit d’opposition. Pour mieux connaître vos droits, vous pouvez également consulter le site de la Commission Nationale de l’Informatique et des Libertés, accessible à l’adresse suivante : http://cnil.fr.


<H2>
9. Sécurité
</H2>

La sécurité de vos données personnelles est importante pour nous et nous suivons les normes de l'industrie pour les protéger. C'est pourquoi OmTech Innovations
 et ses éventuels sous-traitants s’engagent à mettre en œuvre toutes les mesures techniques et organisationnelles afin d’assurer la sécurité des traitements
  de données à caractère personnel et la confidentialité de vos données, selon les moyens actuels de la technique et en application de la Loi informatique et Libertés modifiée, du Règlement européen sur la protection des données (RGPD) et de la loi n°2018-133 du 26 février 2018 « portant diverses dispositions d’adaptation au droit de l’Union Européenne dans le domaine de la sécurité ». 
  
  <H2>
 10. Transferts hors UE
  </H2>
  La société OmTech Innovations traite vos données au sein de l'Union Européenne.

<H2>
11.	Modification de notre Politique de Confidentialité
</H2>

Nous pouvons être amenés à modifier occasionnellement la présente Politique de Confidentialité. 
Lorsque cela est nécessaire, nous vous en informerons et/ou solliciterons votre accord. 
Nous vous conseillons de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications ou mises 
à jour apportées à notre Politique de Confidentialité.

<H2>
12. Contact
</H2>
Pour toute réclamation ou demande d'informations vous pouvez nous contacter par courrier électronique à l’adresse support@omecare.fr ou par courrier à {entreprise}, 229 rue Saint-Honoré, 75001, Paris.
 
          </div>
  );
};

export default Login;