import React from 'react'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Mentors from '../components/Mentors'
import Message from '../components/Message'
import Messagebis from '../components/Messagebis'
import LandingTop from '../components/LandingTopP'
import styled from "styled-components";
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { Link } from "react-router-dom";

import Newsletter from '../components/Newsletter'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';

const Slide = styled.div`
  width: 90vw;
  height: 70vh;
  display: flex;
  align-items: center;
  ${tabletteLanding({ width:"100vw", height:"26vh"})};

  ${mobileLanding({flexDirection:"column", height:"auto", width:"100vw"})};

`;
const Slide1 = styled.div`
  width: 90vw;
  height: 70vh;
  display: flex;
  align-items: center;
  ${mobileLanding({flexDirection:"column", height:"auto", width:"100vw", marginTop:"-20px"})};
  ${tabletteLanding({ width:"100vw", height:"26vh"})};

`;

const ImgContainer = styled.div`
  height: 100%;
  
`;
const ImgContainer1 = styled.div`
  height: 100%;
  ${mobileLanding({display:"none"})};

`;
const ImgContainer2 = styled.div`
  height: 100%;
  ${tabletteLanding({display:"none"})};
  ${computerLanding({display:"none"})};

`;
const Image = styled.img`
  width: 49vw;
  ${computerLanding({height: "min(65vh, 40vw)"})};
  object-fit:cover;
  ${mobileLanding({width:"100vw", height:"30vh"})};
  ${tabletteLanding({width:"52vw",height: "100%"})};

`;

const InfoContainer = styled.div`
  ${computerLanding({height: "min(70vh, 40vw)"})};

  padding: 50px;
  margin-top: 0px;
  ${mobileLanding({marginTop:"0", padding:"30px"})};
  ${tabletteLanding({ display:"flex", alignItems:"center",padding:"20px", marginTop:"20px", height:"26vh", width:"44vw"})};

`;

const InfoContainer4 = styled.div`
  ${computerLanding({height: "min(70vh, 40vw)"})};

  padding: 50px;
  margin-top: 0px;
  ${mobileLanding({marginTop:"0", padding:"30px", paddingBottom:"5px"})};
  ${tabletteLanding({ display:"flex", alignItems:"center",padding:"20px", height:"100%", width:"44vw"})};

`;

const Title = styled.h1`
  font-size: 35px;
  ${computerLanding({marginTop:"0"})};
  @media only screen and (min-width: 1361px){
      margin-top:7vw;
  }
  width:calc(50vw - 100px);
  overflow: hidden;
  ${mobileLanding({fontSize:"20px", width:"100%", textAlign:"center"})};
  ${tabletteLanding({ fontSize:"27px", marginLeft:"auto", marginRight:"auto", lineHeight:"40px"})};

`;

const Desc = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", display:"none"})};
  ${computerLanding({ display:"none"})};
  ${tabletteLanding({fontSize:"14px", width:"100%", textAlign:"center", marginTop:"4vh", marginBottom:"2vh", width:"95%",marginLeft:"auto", marginRight:"auto"})};

`;

const Desc1 = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", display:"none"})};
  ${computerLanding({ display:"none"})};

  ${tabletteLanding({fontSize:"14px", width:"100%", textAlign:"center", marginTop:"4vh", marginBottom:"4vh", width:"95%",marginLeft:"auto", marginRight:"auto"})};

`;
const Desc3 = styled.p`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  ${mobileLanding({fontSize:"14px", width:"100%", textAlign:"center", margin:"30px 0px"})};


  ${tabletteLanding({ display:"none"})};

`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

const Subscribe1 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto", display:"none"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"15px",width:"55%", height:"45px", marginLeft:"auto", marginRight:"auto",marginBottom:"4vh"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px",display:"none"})}

`;

const Subscribe11 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({display:"none"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;

const Subscribe3 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({display:"none"})}
${tabletteLanding({display:"none"})}
${computerLanding({letterSpacing:"0.9px",fontSize:"16px", fontWeight:"600", padding:"8px", width:"150px", height:"40px"})}

`;

const Home = ({ads}) => {
 const loc="uploads/stat"
    return (
       <div>
            <LandingTop/>

<Slide1 >


<InfoContainer>
<Title>RENFORCEZ VOTRE IMPACT SUR LE QUOTIDIEN ET LES HABITUDES DE VOS PATIENTS.</Title> 
{/* <Title>RENFORCEZ  L'IMPACT POSITIF QUE VOUS POUVEZ AVOIR SUR LE QUOTIDIEN DE VOS PATIENTS</Title>  */}

<Desc3>
<span style={{fontWeight:"700"}}>
OMCARE 
</span> EST UN RÉSEAU SOCIAL FAIT POUR FAVORISER LES PRATICIENS DANS LA TRANSMISSION 
D'INFORMATIONS À LEURS PATIENTS AFIN D'OPTIMISER LEUR PROGRESSION ET D'AMÉLIORER DURABLEMENT LEURS HABITUDES DE VIE.


</Desc3>
  
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Subscribe11>S'INSCRIRE</Subscribe11>
</Link>
</InfoContainer>
<ImgContainer>
<Image src={ads+loc+"/kine1.jpg"} />
</ImgContainer>
</Slide1>
<Desc>    <span style={{fontWeight:"700"}}>
OMCARE 
</span> EST UN RÉSEAU SOCIAL FAIT POUR FAVORISER LES PRATICIENS DANS LA TRANSMISSION 
D'INFORMATIONS À LEURS PATIENTS AFIN D'OPTIMISER LEUR PROGRESSION ET D'AMÉLIORER DURABLEMENT LEURS HABITUDES DE VIE.
</Desc>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

  <Subscribe1>S'INSCRIRE</Subscribe1>
  </Link>
<Slide >
<ImgContainer1>
  <Image src={ads+loc+"/publi.jpeg"} />
</ImgContainer1>
<InfoContainer4>
<Title>CRÉEZ UN PROFIL VOUS RESSEMBLANT ET PARTAGEZ-LE EN TOUTE SIMPLICITÉ.</Title> 
<Desc3>SUR <span style={{fontWeight:"700"}}>
OMCARE 
</span> VOUS POUVEZ PUBLIER ET CLASSER DES VIDÉOS, IMAGES ET ARTICLES POUR QUE CHACUN DE VOS PATIENTS ACCÈDE FACILEMENT AU CONTENU QUI LUI CORRESPOND.
</Desc3>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Subscribe3>S'INSCRIRE</Subscribe3>
</Link>
</InfoContainer4>

</Slide>
<Desc1>
SUR <span style={{fontWeight:"700"}}>
OMCARE 
</span> VOUS POUVEZ PUBLIER ET CLASSER DES VIDÉOS, IMAGES ET ARTICLES POUR QUE CHACUN DE VOS PATIENTS ACCÈDE FACILEMENT AU CONTENU QUI LUI CORRESPOND.


</Desc1>
{/* <Announcement/> */}
<Slide >
<ImgContainer2>
  <Image src={ads+loc+"/kine3.jpg"} />
</ImgContainer2>
<InfoContainer4>
  <Title>PROFITEZ D'UN ESPACE RESERVÉ À LA SANTÉ ET AU BIEN-ÊTRE.</Title> 
  <Desc3>
  <span style={{fontWeight:"700"}}>
OMCARE 
</span> REGROUPE UNIQUEMENT DES PRATICIENS ET LEURS PATIENTS, LE CONTENU PUBLIÉ EST EXCLUSIVEMENT EN LIEN AVEC LA SANTÉ.

  </Desc3>
  <Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>


  <Subscribe3>S'INSCRIRE</Subscribe3>
  </Link>
</InfoContainer4>
<ImgContainer1>
  <Image src={ads+loc+"/kine3.jpg"} />
</ImgContainer1>
</Slide>
            <Desc1>              <span style={{fontWeight:"700"}}>
OMCARE 
</span> REGROUPE UNIQUEMENT DES PRATICIENS ET LEURS PATIENTS, LE CONTENU PUBLIÉ EST EXCLUSIVEMENT EN LIEN AVEC LA SANTÉ.
</Desc1>
  {/* <Subscribe1>S'INSCRIRE</Subscribe1> */}
<Slide >
<ImgContainer>
  <Image src={ads+loc+"/kine4.jpg"} />
</ImgContainer>
<InfoContainer4>
<Title>SOYEZ PLUS PROCHE DE VOS PATIENTS POUR MIEUX LES ACCOMPAGNER.</Title> 

<Desc3>
<span style={{fontWeight:"700"}}>
OMCARE 
</span> PROPOSE TOUTES LES FONCTIONNALITÉS D'UN RÉSEAU SOCIAL, VOUS POUVEZ
POSTER, COMMENTER, DISCUTER ET PARTAGEZ AVEC VOS PATIENTS POUR CRÉER UNE COMMAUNAUTÉ ENGAGÉE.</Desc3>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Subscribe3>S'INSCRIRE</Subscribe3>
</Link>
  {/* <Subscribe11>S'INSCRIRE</Subscribe11> */}

</InfoContainer4>

</Slide>
<Desc1>
<span style={{fontWeight:"700"}}>
OMCARE 
</span> PROPOSE TOUTES LES FONCTIONNALITÉS D'UN RÉSEAU SOCIAL, VOUS POUVEZ
POSTER, COMMENTER, DISCUTER ET PARTAGEZ AVEC VOS PATIENTS POUR CRÉER UNE COMMAUNAUTÉ ENGAGÉE.
</Desc1>
<Slide >
<ImgContainer2>
  <Image src={ads+loc+"/kine5.jpg"} />
</ImgContainer2>
<InfoContainer>
  <Title>MONÉTISEZ VOTRE CONTENU ET PAYEZ UNIQUEMENT LORSQUE VOUS GAGNEZ DE L'ARGENT.</Title> 
  <Desc3>              <span style={{fontWeight:"700"}}>
OMCARE 
</span> OFFRE LA POSSIBILITÉ DE MONÉTISER L'ACCÈS À UNE PARTIE OU À TOUT VOTRE CONTENU. 
SI VOUS UTILISEZ CETTE FONCTIONNALITÉ, NOS FRAIS SE LIMITENT À 5% DES MONTANTS QUE VOUS PERCEVEZ PLUS LES FRAIS DE TRANSACTION.</Desc3>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

  <Subscribe11>S'INSCRIRE</Subscribe11>
  </Link>
</InfoContainer>
<ImgContainer1>
  <Image src={ads+loc+"/kine5.jpg"} />
</ImgContainer1>
</Slide>
<Desc><span style={{fontWeight:"700"}}>
OMCARE 
</span> OFFRE LA POSSIBILITÉ DE MONÉTISER L'ACCÈS À UNE PARTIE OU À TOUT VOTRE CONTENU. 
SI VOUS UTILISEZ CETTE FONCTIONNALITÉ, NOS FRAIS SE LIMITENT À 5% DES MONTANTS QUE VOUS PERCEVEZ PLUS LES FRAIS DE TRANSACTION.</Desc>
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

  <Subscribe1>S'INSCRIRE</Subscribe1>
</Link>

<Newsletter/>
            <Footer/>
    <FooterS/>
       </div>

    )
}

export default Home
