import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
z-index:3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 800px;
  height: 200px;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h1`
  text-align: center;
  margin-top:30px;
`;


const Yes = styled.button`
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
`;

const No = styled.button`
  border: solid 1px black;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: white;
  color: black;
  margin-right:50px;
`;


const DeletePost = ({ user,ads,setOpen3, item}) => {
    const navigate = useNavigate();

const deleteP = async()=>{
    await axios.delete(ads+'api/series/'+item._id);
    navigate('/profile/'+user._id);


}








  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpen3(false)}>X</Close>
        <Title>
          {/* Do you really want to delete this series? */}
          Voulez-vous vraiment supprimer cette Série ?
          </Title>
        <div style={{display:"flex",justifyContent:"center"}}>
        <No onClick={() => setOpen3(false)}>Non</No>
<Yes onClick={() => deleteP()}>Oui</Yes>
        </div>

      </Wrapper>
    </Container>
  );
};

export default DeletePost;