import styled from "styled-components";
import { popularVideos } from "../data";
import Serie from "./Serie";
import axios from "axios";
import { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";

const Container = styled.div`
    
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5vw;
    width:70vw;
    ${mobile({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"2vw"})}
    ${tablette({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "40vw 40vw",width:"84vw", padding:"0",gridGap:"4vw"})}

`;

const Titre = styled.h1`
  font-weight:900;
  text-align: center;
  font-size: 40px;
  margin-top:60px;
  letter-spacing: 2.5px;

`;

const Series = ({ads,owner,user, connected}) => {
  const [series, setSeries] = useState([]);

  useEffect(async() => {
    try { 
       const res= (await axios.get(ads+'api/series/favorite/all/'+owner._id)).data;
       setSeries(res.reverse());
    }catch(e){

    }
},[owner]);
  return (
    <div>
        {/* <Titre>
          SERIES
        </Titre> */}
        <Container>

          {series?.map((item) => (
            <Link to={"/seriesoverview/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
              <Serie user={user} ads={ads} item={item} key={item.id} owner={{username:item.creatorUsername,profilePicture:item.creatorProfilePicture}} />
            </Link>
          ))}
        </Container>
    </div>

  );
};

export default Series;