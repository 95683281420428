import React from 'react'
import styled from "styled-components";
import { useState,useEffect} from "react";
import Upload from './Upload';
import axios from "axios";
import Video from "./VideoMy";
import Delete from "./Delete";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Padding } from '@mui/icons-material';


const Container = styled.div`
position: relative;
margin-top:30px;
padding: 20px;

`;
const Wrapper = styled.div`
width: 100%;
position: relative;
display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
   
`;
const Button = styled.button`
  width: 200px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;

const Input = styled.input`
  font-size: 50px;
`;



const Myvideosseries = ({ads,Series}) => {
    console.log(Series)
    const location = useLocation();
    const Sid = location.pathname.split("/")[2];
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [id, setId] = useState("");
    const [order, setOrder] = useState([]);

    const [videos, setVideos] = useState([]);
    const [videoSaved,setVideoSave]= useState([]);
    const [rankerror,setRankingError]= useState(undefined);
    const FirstSeries = Series.videosDetail;
    const selectVideo = (number) =>{
        videoSaved.includes(number) ? setVideoSave(videoSaved.filter(item => item !== number)) : setVideoSave((arr) =>[...arr, number]);
    }
    React.useEffect(async() => {
        try { 
          var or=[];
          var T=[];
           const ids = Series.videosDetail;
           const ids1 = JSON.parse(localStorage.videoSeriesId);

           for (let ii = 0; ii < ids.length; ii++) {
            or[ii]=ii+1;
            T[ii]=Series.videosDetail[ii];
           }
           for (let jj = 0; jj < ids1.length; jj++) {
                const res= (await axios.get(ads+'api/video/'+ids1[jj])).data;
                T[jj+ids.length]=res;
                or[jj+ids.length]=jj+1+ids.length;

           }
           setVideos(T)
           setOrder(or);

        //    setThumb(T);
        }catch(e){
    
        }
    },[]);


    const changeOrder= (e)=>{
            var oo = [];
            for (let ii = 0; ii < order.length; ii++) {
                oo[ii]=order[ii]
            }
            if(e.target.value!=""){

            oo[e.target.name]=Number(e.target.value);
            }else{
                oo[e.target.name]="";

            }
            setOrder(oo);
        

    }
    const saveAndContinue= async() =>{
       
        const rep=count(order)
        for (var i=1; i<order.length+1; ++i) {
            if (rep[i]>1){
                
                const moreThan1=i;
                const str1="Ranking error: rank "
                     const str2=" is used more than once";

                const str3= str1.concat(moreThan1);
                setRankingError(str3.concat(str2));
                
                return;
            }


        }
        for (var i=1; i<order.length+1; ++i) {
            if (order.includes(i)==false){
                
                const moreThan1=i;
                const str1="Ranking error: rank "
                     const str2=" is not used";

                const str3= str1.concat(moreThan1);
                setRankingError(str3.concat(str2));
                
                return;
            }


        }
        var videoSaved=[];
        for (let ii = 0; ii < order.length; ii++) {
            videoSaved[order[ii]-1]=videos[ii]
        }

        const NOrder=(await axios.put(ads+'api/series/'+Series._id,{videosDetail:videoSaved})).data;
        navigate("/seriesoverview/"+Series._id);
    };


    function count (table)
    {
        var counter = {};
        for (var i=0; i<table.length; ++i) {
            if (counter[table[i]])
                counter[table[i]]++;
            else
                counter[table[i]]=1;
        }
        return counter;
    }



    return (
        
        <div>
            <Container>
            <h2 style={{textAlign:"center"}}>
    Define the newClass Order
</h2>
            <Wrapper>

                {videos.map((item,i) => (

                    <div style={{position:"relative", border:"1px solid black", padding:"40px", marginTop:"70px"}}  key={item._id}>
    <div >
      <input style={{width:"40px", height:"40px",textAlign:"center",fontSize:"24px",fontWeight:"800"}} onChange={(e) => changeOrder(e)} name={i} value={order[i]} />
    </div>
                                                {/* <Link to={"/selectcourse1/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
                           
                           <Video ads={ads} item={item} key={item._id} />
                       {/* </Link> */}
                    </div>

                        ))} 
            </Wrapper>
            {rankerror?
                        <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginTop:"80px", marginBottom:"-50px"}}>
                        < WarningAmberIcon style={{marginRight:"15px"}}/> {rankerror}
                    </div>
                 
:null
        }
            <div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={saveAndContinue} >SAVE</Button>

</div>
            </Container>
        </div>


    )
}

export default Myvideosseries
