import React from 'react'
import styled from "styled-components";
import { useState } from "react";
import { mobile,tablette,computer } from "../responsive";

import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';

const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  margin-top: 10px;
  justify-content: space-around;
  height: 5vh;
  align-items: center;
  width: 83.5%;
  margin-left: auto;
  margin-right:auto;
  background-color: #fafafa;

`;

const Container = styled.div`
  z-index:10;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  height: 10vh;
  align-items: center;
  width: 85.5%;
  margin-left: auto;
  margin-right:auto;
  overflow-y: hidden;
  ${mobile({ height:"2vh"})};
  ${tablette({ height:"4vh"})};

`;

const FilteritemS =styled.h2`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    ${mobile({ fontSize:'13px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};

`;

const Filteritem =styled.h2`
    letter-spacing: 1.5px;
    font-weight:800;
    cursor: pointer;
    display:flex;
    align-items: center;
    &:hover {
        color:black;
    }
    color:grey;
    ${mobile({ fontSize:'13px'})};
    ${tablette({ fontSize:'16px'})};
    ${computer({ fontSize:'20px'})};

`;
const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: grey;
    cursor:pointer;
    &:hover {
        color:black;
    }
`;




const Tabs = ({setTab}) => {
    const [hidden, setHidden] = useState(1);

    const show = (number) =>{
        setHidden(s => number);
        const nt = number-1
        setTab(nt)
    }

        return (
        <Container>
            {(hidden!=1)?
            <Filteritem onClick={() => show(1)}>
            EDITER MON PROFIL
            </Filteritem>
            : null}

            {(hidden==1)?
            <FilteritemS onClick={() => show(1)}>
            EDITER MON PROFIL
            
            </FilteritemS>
            : null}

            {(hidden!=2)?
            <Filteritem onClick={() => show(2)}>
            MODIFIER MON MOT DE PASSE
           
            </Filteritem>
            : null}

            {(hidden==2)?
                <FilteritemS onClick={() => show(2)}>
                MODIFIER MON MOT DE PASSE
                
                </FilteritemS>
            : null}

            {/* {(hidden!=3)?
                <Filteritem onClick={() => show(3)}>
                SERIES
               
                </Filteritem>
            : null}

            {(hidden==3)?
                <FilteritemS onClick={() => show(3)}>
                SERIES
                            
                </FilteritemS>
            : null}

            {(hidden!=4)?
            <Filteritem onClick={() => show(4)}>
            ARTICLES   
           
            </Filteritem>
            : null}

            {(hidden==4)?
            <FilteritemS onClick={() => show(4)}>
            ARTICLES   
            
            </FilteritemS>
            : null} */}
        </Container>
                
        
 

    )
}

export default Tabs
