import React from 'react'
import styled from "styled-components";
import { useState,useEffect} from "react";
import Upload from './Upload';
import axios from "axios";
import Video from "./VideoMy";
import Delete from "./Delete";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Padding } from '@mui/icons-material';
import VV from "./VideoPrivate1";
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
position: relative;
margin-top:30px;
padding: 20px;
${mobile({marginTop:"70px"})}

`;
const Wrapper = styled.div`
width: 100%;
position: relative;
display: grid;
${mobile({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "90vw",width:"90vw", padding:"0",gridGap:"2vw"})}
${tablette({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "38vw 38vw",width:"80vw", padding:"0",gridGap:"4vw"})}
${computer({marginBottom:"40px",marginTop:"0px",gridTemplateColumns: "36vw 36vw",width:"76vw", padding:"0",gridGap:"4vw"})}  
   
`;
const Button = styled.button`
  width: 250px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #00000085;
    cursor: auto;
  }
  ${tablette({marginTop:"10px"})}
  ${mobile({marginTop:"10px"})}

`;

const Input = styled.input`
  font-size: 50px;
`;



const Myvideosseries = ({ads,user}) => {
    const location = useLocation();
    const Sid = location.pathname.split("/")[2];
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [id, setId] = useState("");
    const [isdisable, setDisable] = useState(true);


    const [videos, setVideos] = useState([]);
    const [videoSaved,setVideoSave]= useState([]);

    const selectVideo = (number) =>{
        videoSaved.includes(number) ? setVideoSave(videoSaved.filter(item => item !== number)) : setVideoSave((arr) =>[...arr, number]);
    }

    useEffect(()=>{
        if(videoSaved.length>0){
            setDisable(false)
        }else{
            setDisable(true)
        }
    },[videoSaved])

    useEffect(async() => {
        try { 
           const res= (await axios.get(ads+'api/video/all/'+user._id)).data;
           setVideos(res.reverse());
        }catch(e){

        }
    },[]);
    const saveAndContinue= () =>{
        localStorage.setItem('videoSeriesId',JSON.stringify(videoSaved));
        navigate("/selectseries11/"+Sid);
    };

    return (
        
        <div>
            <Container>
            <h2 style={{textAlign:"center", marginBottom:"60px"}}>
            Étape 2 : selectionnez les vidéos à inclure dans votre Série 
</h2>
            <Wrapper>



            {videos?.map((item) => (

            <div style={{position:"relative", border:"1px solid black", padding:"40px", marginTop:"10px"}}  key={item._id}>
                <div onClick={()=>selectVideo(item._id)} >
                <input style={{width:"30px", height:"30px", cursor:"pointer"}}  type="checkbox" />
                </div>
                <VV ads={ads} item={item} key={item._id} owner={user}/>
             
            </div>
          ))}





            </Wrapper>
            <div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={saveAndContinue} disabled={isdisable}>SUIVANT</Button>

</div>
            </Container>
        </div>


    )
}

export default Myvideosseries
