import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  ${mobile({display:"none"})}
`;
const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  margin-bottom:110px;
  ${tablette({display:"none"})}
${computer({display:"none"})}
`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
display: flex;
align-items: center;
margin: 10px 0;
width:100%;
justify-content: space-between;
padding:20px;
border-bottom:1px solid lightgray;
&:last-child {
  border-bottom: none; /* Remove border from the last item */
}
${tablette({padding: "20px 0px 20px"})}
${mobile({padding: "20px 0px"})}

`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
  color:"rgb(52, 52, 67);
  font-weight:500;
`;
const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;

const Inviter = styled.h1`
${mobile({fontSize:"25px",marginTop:"50px"})}

`;
const MyPatients = ({ads,setUser,socket, setNM, nM,user,connected}) => {

    const [teacher, setTeacher] = useState({});
    const [open, setOpen1] = useState(false);
    const [mesPatients, setMesPatients] = useState([]);

    const [tab, setTab] = useState(0);






    
    // Define options for formatting the date
    const options = {
      year: "numeric",
      month: "long", // "long" for full month name
      day: "numeric",
    };
    
    // Create a date formatter with the specified options
    const dateFormatter = new Intl.DateTimeFormat("fr-FR", options); // "fr-FR" for French locale
    

    useEffect(async() => {
        try { 
            const check= await axios.get(ads+'api/exercices/messeances');
            const formattedArray = check.data.map((entry) => {
                const date1 = new Date(entry.date);
                const formattedDate = dateFormatter.format(date1);
                return { ...entry, dateM: formattedDate };
              });
            setMesPatients(formattedArray)
          
    
        }catch(e){
    
        }
    },[]);


    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center"}}>
                <Inviter style={{color:"rgb(52, 52, 67)"}}>Mes Séances</Inviter>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </div>
      <Container>
        <NameList>
        <NameItem>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Praticien</ColumnTitle>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Date de séance</ColumnTitle>
        </NameItem>
            {mesPatients.map((person) => (
          <Link to={"/maseance/"+person._id} style={{ color: 'inherit', textDecoration: 'inherit', zIndex:"1"}}>

            <NameItem key={person._id}>
                <div style={{display:"flex"}}>
                    <ProfilePicture src={person.praticienInfo.profilePicture} />
                    <Name>{person.praticienInfo.name}</Name>
                </div>
                <div>

                <Name>{person.dateM}</Name>

                </div>

            </NameItem>
          </Link>
            ))}
        </NameList>
      </Container>


      <ContainerS>
        <NameList>
        <NameItem>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Praticien</ColumnTitle>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Date de séance</ColumnTitle>
        </NameItem>
            {mesPatients.map((person) => (
          <Link to={"/maseance/"+person._id} style={{ color: 'inherit', textDecoration: 'inherit', zIndex:"1"}}>

            <NameItem key={person._id}>
                <div style={{display:"flex"}}>
                    {/* <ProfilePicture src={person.praticienInfo.profilePicture} /> */}
                    <Name>{person.praticienInfo.name}</Name>
                </div>
                <div>

                <Name>{person.dateM}</Name>

                </div>

            </NameItem>
          </Link>
            ))}
        </NameList>
      </ContainerS>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default MyPatients