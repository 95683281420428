import React from 'react'
import styled from "styled-components";
import LoadingDots from './ThreeDots'
import { mobile,tablette, computer } from "../responsive";



const Container = styled.div`
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
`;

const MessageInfo = styled.div`
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;
`;
const MessageFromYou = styled.div`
            flex-direction: row-reverse;
            display: flex;
            font-weight: 300;
            margin-left:auto;


`;

const MessageFromMe = styled.div`
            display: flex;
            color: gray;
            font-weight: 300;
`;

const MessageContent1 = styled.div`

max-width: 80%;
display: flex;
flex-direction: column;
gap: 10px;
margin-top:10px;
`;
const P1 = styled.p`


    background-color: white;
    color:black;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
    font-weight: 500;
`;
const P2 = styled.p`

background-color: white;
  color: black;
  border-radius: 10px 0px 10px 10px;
   
    padding: 10px 20px;
    max-width: 50vw;
    word-wrap: break-word;
    ${computer({maxWidth:"450px"})}

    font-weight: 500;
`;

const MessageContent2 = styled.div`
max-width: 80%;
display: flex;
flex-direction: column;
gap: 10px;
margin-top:10px;
align-items: flex-end;
background-color: white;
padding-left:10px;
padding-right: 10px;
align-items: center;
border-radius: 10px 0px 10px 10px;

  `;
export const Message2 = ({ads,budyPicture, user,message }) => {
    return (
        <Container>
            {user._id!=message.senderId?
            <MessageFromMe>
                <MessageInfo>
                    <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginRight:"20px"}}
                        src={ads+budyPicture
                        }
                        alt=""
                    />
                   
                </MessageInfo>

                <MessageContent1 >
                    {/* {message.img && <img src={message.img} alt="" />} */}
                    <LoadingDots ads={ads}/>
                </MessageContent1>

            </MessageFromMe>
:

            <MessageFromYou>
                <MessageInfo>
                <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginLeft:"10px"}}
                        src={
                            ads+budyPicture
                        }
                        alt=""
                    />
                </MessageInfo>
                <MessageContent2 ads={ads}>

                </MessageContent2>
                
            </MessageFromYou>
}
        </Container>
    )
}
