import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  ${mobile({display:"none"})}
`;
const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
  margin-bottom:110px;
  ${tablette({display:"none"})}
${computer({display:"none"})}
`;
const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
  ${tablette({padding: "20px 0px 20px"})}
  ${mobile({padding: "20px 0px"})}

  
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;

  object-fit: cover;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
  color:"rgb(52, 52, 67);
  font-weight:500;
`;
const Inviter = styled.h1`
${mobile({fontSize:"25px"})}

`;
const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({display:"none"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"220px", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;

const ButtonS = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 50px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"20px", padding:"5px",width:"auto", height:"auto", display:"flex"})}
${tablette({display:"none"})}
${computer({display:"none"})}

`;
const MyPatients = ({ads,setUser,socket, setNM, nM,user,connected}) => {

    const [teacher, setTeacher] = useState({});
    const [open, setOpen1] = useState(false);
    const [mesPatients, setMesPatients] = useState([]);

    const [tab, setTab] = useState(0);
    useEffect(async() => {
        try { 
            const check= await axios.get(ads+'api/patients/mespatients');

            setMesPatients(check.data)
          
    
        }catch(e){
    
        }
    },[]);


    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center"}}>
                <Inviter style={{color:"rgb(52, 52, 67)"}}>Mes Patients</Inviter>
                <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button>
                <ButtonS onClick={() => setOpen1(true)} > +
                <GroupsOutlinedIcon style={{ marginLeft:"4px",color: "white" }}/>
                   </ButtonS>
                </div>
      <Container>
        <NameList>
        <NameItem>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Nom</ColumnTitle>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Dernière séance</ColumnTitle>
        </NameItem>
            {mesPatients.map((person) => (
          <Link to={"/patient/"+person.patientId} style={{ color: 'inherit', textDecoration: 'inherit', zIndex:"1"}}>

            <NameItem key={person.id}>
                <div style={{display:"flex"}}>
                    <ProfilePicture src={person.profilePicture} />
                    <Name>{person.patientName}</Name>
                </div>
                <div>
                <Name >{person.name}</Name>

                </div>

            </NameItem>
          </Link>
            ))}
        </NameList>
      </Container>
      <ContainerS>
        <NameList>
        <NameItem>
          <ColumnTitle style={{color:"rgb(52, 52, 67)"}}>Nom</ColumnTitle>
        </NameItem>
            {mesPatients.map((person) => (
          <Link to={"/patient/"+person.patientId} style={{ color: 'inherit', textDecoration: 'inherit', zIndex:"1"}}>

            <NameItem key={person.id}>
                <div style={{display:"flex"}}>
                    <ProfilePicture src={person.profilePicture} alt={`${person.name}'s profile`} />
                    <Name>{person.patientName}</Name>
                </div>


            </NameItem>
          </Link>
            ))}
        </NameList>
      </ContainerS>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default MyPatients