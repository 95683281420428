import React from 'react'
import Footer from '../components/Footer'
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useState, useEffect} from "react";
import axios from "axios";
import Navbar from '../components/Navbar'
import Modifvideo from '../components/Modifvideo';
import Courseparameters from '../components/Courseparameters';


import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import { mobile,tablette,computer } from "../responsive";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;


const Selectcourses1 = ({ads,setUser,setNM, nM, socket, user,connected}) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [video, setVideo] = useState({});

    useEffect(async() => {
        try { 

            const Video=(await axios.get(ads+'api/video/'+id)).data;
            setVideo(Video);

        }catch(e){


        }
  
    },[])
    return (
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
<NavContainer >
<Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        

                    {connected?
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
        :null
                    }
                </BottomNav>
            {video.bunnyId?
            <Courseparameters user={user} ads={ads} Video={video}/>
            :null
        }
            </ContainerRight>

</div>

    )
}

export default Selectcourses1
