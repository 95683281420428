import React from 'react'
import styled from 'styled-components'
import Articles from '../components/Articles'
import Coverpicture1 from '../components/Coverpicture1'
import Filter from '../components/Filter'
import Navbar from '../components/Navbar'
import Series from '../components/Series'
import Tabs from '../components/Tabs'
import Videos from '../components/Videos'
import { useLocation } from "react-router-dom";
import { useState, useEffect} from "react";
import axios from "axios";
import Posts from '../components/Posts'
import { mobile,tablette,computer } from "../responsive";
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import TabsS from '../components/TabsS'
import PrivateAccount from '../components/PrivateAccount'

const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div``

const Teacherprofile = ({ads,nM,setNM, setUser,socket,user,connected}) => {
    const [tab, setTab] = useState(0);

    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [teacher, setTeacher] = useState({});

    useEffect(async() => {
        try { 

            const teacher=(await axios.get(ads+'api/users/'+id)).data;
            setTeacher(teacher);
        }catch(e){


        }
  
    },[])
    return (
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>


<NavContainer >
<Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        

                    {connected?
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
        :null
                    }
                </BottomNav>

        {teacher._id?
            <Coverpicture1 ads={ads} connected={connected} user={user} socket={socket} teacher={teacher}/>
            :null
    }

{teacher.visibility==false&&!user.follow.includes(teacher._id)?
    <PrivateAccount ads={ads}/>
:
null
}

{(teacher.teacher==false&&teacher.visibility==true)||(teacher.teacher==false&&teacher.visibility==false&&user.follow.includes(teacher._id))?
    <TabsS setTab={setTab} />
:null
}

{teacher.teacher?
                <Tabs setTab={setTab} />
:null
}




{/* {tab==1||tab==2?
                <Filter/>
                :null

} */}
{(tab==0&&teacher._id)?

                <Posts ads={ads} connected={connected} user={user} owner={teacher}/>
                :null}
{(tab==1&&teacher._id)?

                <Videos ads={ads} owner={teacher} user={user}/>
                :null}
{(tab==2&&teacher._id)?

                <Series ads={ads} owner={teacher} user={user}/>
                :null}
{(tab==3&&teacher._id)?

                <Articles ads={ads} user={user} owner={teacher}/>:null}
                            </ContainerRight >

    </div>


    )
}

export default Teacherprofile
