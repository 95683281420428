import React from 'react'
import styled from "styled-components";
import { cover } from "../data";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import UploadPP from "./UploadPP";
import UploadCP from "./UploadCP";
import MyFollowers from "./MyFollowers";
import MyFollowing from "./MyFollowing";
import MSubscriptions from "./MySubscriptions";
import MSubscribers from "./MySubscribers";

import axios from "axios";

import { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { mobile,tablette, computer } from "../responsive";
import './App.css';



const Container = styled.div`
width: 100%;
position: relative;
`;
const Options=styled.div`


font-weight:500;
${computer({display:"none"})}
${tablette({left:"42vw", top:"10px", fontSize:"17px", padding:"15px"})}
${mobile({left:"42vw", top:"20px", fontSize:"12px",position:"absolute"})}

`;
const OptionsC=styled.div`
position:absolute;
font-weight:400;

display:flex;
${computer({left:"25vw", top:"60px",fontSize:"19px"})}
${tablette({display:"none"})}
${mobile({display:"none"})}

`;

const Description= styled.div`
display: flex;
position: relative;
height:auto;

align-items: unset;
${mobile({height:"auto",justifyContent: "space-around"})}

`;

const Picture = styled.img`
width: 100%;
height: 55vh;
object-fit: cover;
${mobile({height:"35vh", marginTop:"62px"})};
${tablette({height:"40vh"})};


`;

const Profilepicture = styled.img`

  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  position:relative;
  border: 6px solid #f2f9fb;
  ${tablette({width:"180px",height:"180px"})}
  ${mobile({width:"100px",height:"100px", border:"3px solid  #f2f9fb", marginTop:"40px"})}


  
`;
const COptions=styled.div`

display:flex;
flex-direction:column;
${computer({left:"35vw", top:"60px",padding:"40px"})}
${tablette({left:"40vw", top:"30px", padding:"15px", display:"none"})}
${mobile({left:"42vw", top:"20px",position:"absolute", display:"none"})}

`;

const Picturecontainer= styled.div`

width: 250px;
height: 250px;
${mobile({width:"130px",height:"130px", top:"-90px", right:"26vw"})}

position:relative;
top:-60px;
margin-left:7%;
margin-right: 3%;
${tablette({width:"180px",height:"180px", top:"-90px", right:"0vw",alignItems:"center"})}
${computer({width:"230px",height:"230px", top:"-90px", alignItems:"center"})}


`;

const Covercontainer = styled.div`
    display:flex;
    align-items:center ;
    text-align: center;
    flex:1;

`;
const Containerverified= styled.div`
margin-top:30px;
position:relative;
display:flex;
align-items:center;

${mobile({display:"none"})}

`;
const ContainerverifiedS= styled.div`
margin-top:0px;
position:relative;
display:flex;

align-items:center;
${computer({display:"none"})}
${tablette({display:"none"})}

`;
const InfoContainer = styled.div`
  margin-left:0vw;
  margin-right:2vw;

  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.6;
  padding:0px;
  height: auto;
  margin-top:-70px;
  word-break: break-word;
  ${computer({marginTop:"70px"})}
  ${tablette({marginTop:"20px", fontSize:"18px"})}

  ${mobile({display:"none"})}


`;
const InfoContainerM2 = styled.div`
  margin-left:0vw;
  margin-right:2vw;

  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.6;
  padding:0px;
  height: auto;
  margin-top:-70px;
  word-break: break-word;
  ${computer({marginTop:"70px",display:"none"})}
  ${tablette({marginTop:"40px", fontSize:"18px"})}

  ${mobile({display:"none"})}


`;
const InfoContainerM = styled.div`
word-break: break-word;
  margin-left:0vw;
  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 1.6;
  padding:25px;
  height: auto;
  padding-top:15px;
  ${tablette({display:"none"})}
  ${computer({display:"none"})}

`;

const Networks = styled.div`
    margin-top:100px;
    display:flex;
    position:absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    
`;

const Icon = styled.div`
padding:15px;
cursor: pointer;
position: relative;
transition:transform .15s ease;
:hover {
  transform:scale(1.5);
}
`;


const Title = styled.h1`
    position: absolute;
    font-size: 70px;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    ${mobile({fontSize:"40px"})}

`;

const Line = styled.div`
    width:85%;
    border-bottom: 3px solid #ececec;
    margin-left:auto;
    margin-right:auto;
    ${mobile({width:"100%", borderBottom:"2px solid #ececec", marginTop:"0px"})}

`;
const Subscription = styled.div`
height: auto;
width:100%;
align-items: center;
justify-content: center;
display: flex;
margin-bottom:4vw;


`;

const ModifPP= styled.div`
position:absolute;
bottom:10px;

background-color:white;
border-radius:50%;
height:35px;
width:35px;
display:flex;
justify-content:center;
align-items:center;
border:1px solid black;
cursor:pointer;
${mobile({bottom:"-10px",left:"0px", height:"28px", width:"28px", marginLeft:"0px"})}
${computer({left:"0px"})}
${tablette({left:"0px", marginLeft:"0px"})}


`;
const ModifCover = styled.div`
position:absolute;
top:20px;
margin-left:20px;
background-color:white;
border-radius:50%;
height:35px;
width:35px;
display:flex;
justify-content:center;
align-items:center;
border:1px solid black;
cursor:pointer;

${mobile({top:"80px",left:"-10px"})}


`;



const Follow = styled.button`
    border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  width:auto;
  height: auto;
  padding:20px;
  margin-right: 5vw;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}

`;

const Subscribe = styled.button`
    text-align: center;
   border: 1px solid black;
  background-color: #762532;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
   
`;

const Verified = styled.img`

  width: 32px;
  height: 32px;
  object-fit: cover;
  position:relative;
  margin-right:10px;
  ${tablette({width:"28px",height:"28px"})}
  ${mobile({width:"15px",height:"15px", border:"3px solid  #f2f9fb",marginRight:"5px"})}


  
`;
const Pro = styled.div`
    font-size:20px;
    margin-right: 5vw;
    letter-spacing:1.5px;
    font-family: Urbanist,sans-serif;
    color:rgb(26 100 217);
    font-weight:500;
    ${tablette({marginBottom:"0px",fontSize:"18px"})}

    ${mobile({fontSize:"14px"})}

`;


const Detailsverified= styled.div`
position:absolute;
height:auto;
padding:15px;
border-radius: 20px;
border:2px solid #8a96a3;
top: 50px;
z-index:3;
background-color:white;
${mobile({top:"10px"})}

`;

const Name = styled.h1`
    font-size:30px;
    margin-right: 5vw;
    letter-spacing:1.5px;
    font-family: Urbanist,sans-serif;
    ${tablette({display:"none"})}

    ${mobile({display:"none"})}

`;
const NameS = styled.h2`
    font-size:20px;
    margin-right: 5vw;
    margin-bottom:7px;
    letter-spacing:1.5px;
    font-family: Urbanist,sans-serif;
    ${tablette({marginBottom:"20px", fontSize:"24px"})}

    ${computer({display:"none"})}

`;
const Coverpicture = ({ads,user}) => {



    const [open, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);

    const [followers,setFollowers]=useState(null);
    const [subscribers,setSubscribers]=useState(null);
    const [coverLoaded, setCoverLoaded] = useState(true);
    const [profileLoaded, setProfileLoaded] = useState(true);
    const [showVerified, setShow]=useState(false);
    const loc="uploads/stat"


    useEffect( async() => {
        const Followers = (await axios.get(ads+'api/users/followersinfo/'+user._id)).data
        setFollowers(Followers);
        const Subscribers = (await axios.get(ads+'api/users/subscribersinfo/'+user._id)).data
        setSubscribers(Subscribers);
    },[])


    const handleMouseEnter = () => {
        setShow(true);
      };
    
    const handleMouseLeave = () => {
        setShow(false);
      };

      const toggleVerified = () => {
        setShow(!showVerified); // Toggle the showVerified state
      };
    const follow = async () =>{
        const fol = axios.post(ads+'api/connections/follow/'+user._id,
        {
            id:user._id
        })
    }

    const subscribe = async () =>{
        const fol = axios.post(ads+'api/connections/subscribe/'+user._id,
        {
            id:user._id
        })
    }

    return (
        <>
        <div>
        <div style={{display: coverLoaded||profileLoaded ? 'flex' : 'none',marginLeft:"auto", marginRight:"auto", marginTop:"150px", justifyContent:"center"}}>
    <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    </div>
    {coverLoaded}
    {profileLoaded}
</div>
            <Container style={{ display: !coverLoaded&&!profileLoaded ? 'block' : 'none'}}>

                <Covercontainer>
                   




                    <div style={{width:"100%" ,position:"relative"}}>
                    <Picture src={ads+user.coverPicture}
                    onLoad={() => setCoverLoaded(false)}
                    
                    />
                    <ModifCover onClick={() => setOpen2(true)} >
                    <CameraAltOutlinedIcon style={{fontSize:"17px"}}/>
                    </ModifCover>
                    </div>
                    {/* <Title>{user.name}</Title>
                    <Networks>
                    {user.instagram!=""?
                    <a href={user.instagram}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >

                        <Icon >
                            < InstagramIcon />
                        </Icon>
                        </a>

                        :null
                    }
                    {user.facebook!=""?
                    <a href={user.facebook}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <FacebookOutlinedIcon/>
                        </Icon>
                    </a>
                        :null
                    }
                    {user.twitter!=""?
                    <a href={user.twitter}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <TwitterIcon/>
                        </Icon>
                    </a>
                         :null
                    }       
                    {user.perso!=""?
                    <a href={user.perso}  style={{ color: 'inherit', textDecoration: 'inherit'}} target="_blank" >
                        <Icon>
                        <LanguageIcon/>
                        </Icon>
                    </a>
                         :null
                    }                    
                    </Networks> */}

                </Covercontainer>


               


                <Description>
                    <Picturecontainer>
                    <Profilepicture src={ads+user.profilePicture}
                    onLoad={() => setProfileLoaded(false)}
                    />
                    <ModifPP onClick={() => setOpen1(true)} >
                    <CameraAltOutlinedIcon style={{fontSize:"17px"}}/>
                    </ModifPP>
                    {user.prodelasante?
                        <Containerverified           
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Verified src={ads+loc+"/verify.png"}/>
                            <Pro>Professionnel de santé</Pro>
                            {showVerified?
                            <Detailsverified>
                                Professionnel de santé vérifié par OmCare.
                            </Detailsverified>
                             :null}

                        </Containerverified>
                    :null}
                    </Picturecontainer>
                    <COptions>

                    <NameS>{user.name}</NameS>
                    {user.prodelasante?
                        <ContainerverifiedS onClick={toggleVerified}>
                            <Verified src={ads+loc+"/verify.png"}/>
                            <Pro>Professionnel de santé</Pro>
                            {showVerified?
                            <Detailsverified>
                                Professionnel de santé vérifié par OmCare.
                            </Detailsverified>
                             :null}
                        </ContainerverifiedS>
                    :null}
                    
                    <Name>{user.name}</Name>                  


                    <div style={{display:"flex",position:"relative", top:"20px",marginLeft:"0px"}}>
                        <div style={{display:"flex",marginLeft:"0px", cursor:"pointer"}} onClick={() => setOpen3(true)}>
                            <span style={{marginRight:"5px"}}>{followers?.length}</span>
                        Followers

                        </div>
                        <div style={{display:"flex",marginLeft:"30px", cursor:"pointer"}} onClick={() => setOpen4(true)}>
                        <span style={{marginRight:"5px"}}>{user.follow.length}</span>

                            Suivi(e)s
                        </div>
                        <div style={{display:"flex",marginLeft:"30px", cursor:"pointer"}} onClick={() => setOpen5(true)}>
                        <span style={{marginRight:"5px"}}>{user.subscribe.length}</span>

                            Abonnements
                        </div>
{user.teacher?
                        <div style={{display:"flex",marginLeft:"30px", cursor:"pointer"}} onClick={() => setOpen6(true)}>
                        <span style={{marginRight:"5px"}}>{subscribers?.length}</span>

                            Abonné(e)s
                        </div>
                        :null}
                    </div>
                    <InfoContainer>
                    <div
      dangerouslySetInnerHTML={{__html:user.description}}
    />
                                    
                    
                    </InfoContainer>
</COptions>

                    <Options>

                    <NameS>{user.name}</NameS>
                    {user.prodelasante?
                        <ContainerverifiedS onClick={toggleVerified}>
                            <Verified src={ads+loc+"/verify.png"}/>
                            <Pro>Professionnel de santé</Pro>
                            {showVerified?
                            <Detailsverified>
                                Professionnel de santé vérifié par OmCare.
                            </Detailsverified>
                             :null}
                        </ContainerverifiedS>
                    :null}


                    <div style={{display:"flex", marginTop:"10px",marginLeft:"0px"}}>
                        <div style={{display:"flex",marginLeft:"0px", cursor:"pointer"}} onClick={() => setOpen3(true)}>
                            <span style={{marginRight:"5px"}}>{followers?.length}</span>
                        Followers

                        </div>
                        <div style={{display:"flex",marginLeft:"20px", cursor:"pointer"}} onClick={() => setOpen4(true)}>
                        <span style={{marginRight:"5px"}}>{user.follow.length}</span>

                        Suivi(e)s
                        </div>
                    </div>
                    <div style={{display:"flex", marginTop:"5px",marginLeft:"0px"}}>
                        <div style={{display:"flex",marginLeft:"0px", cursor:"pointer"}} onClick={() => setOpen5(true)}>
                        <span style={{marginRight:"5px"}}>{user.subscribe.length}</span>

                            Abonnements
                        </div>
{user.teacher?
                        <div style={{display:"flex",marginLeft:"20px", cursor:"pointer"}} onClick={() => setOpen6(true)}>
                        <span style={{marginRight:"5px"}}>{subscribers?.length}</span>

                            Abonné(e)s
                        </div>
                        :null}
                    </div>

                    <InfoContainerM2>
                    <div
      dangerouslySetInnerHTML={{__html:user.description}}
    />
                                    
                    
                    </InfoContainerM2>

</Options>
                </Description>




{/* 
                <Subscription>
                        <Follow onClick={() => follow()}>
                            <h2 style={{letterSpacing: "2.5px"}}>FOLLOW</h2>
                        </Follow>
                    <Subscribe onClick={() => subscribe()}>
                        <h2 style={{letterSpacing: "2.5px"}}>SUBSCRIBE $10/MONTH</h2>

                    </Subscribe>


                </Subscription> */}
                    <InfoContainerM>
                    <div
      dangerouslySetInnerHTML={{__html:user.description}}
    />
                                    
                    
                    </InfoContainerM>
                <Line/>
            </Container>

        </div>
        {open && <UploadPP user={user} ads={ads} setOpen={setOpen1} />}
        {open2 && <UploadCP user={user} ads={ads} setOpen={setOpen2} />}
        {open3 && <MyFollowers ads={ads} setOpen={setOpen3} user={user} followers={followers}/>}
        {/* {open4 && <MyFollowers setOpen={setOpen4} user={user} followers={followers}/>} */}
        {open4 && <MyFollowing ads={ads} setOpen={setOpen4} user={user} />}

        {open5 && <MSubscriptions ads={ads} setOpen={setOpen5} user={user} />}
        {open6 && <MSubscribers ads={ads} user={user} setOpen={setOpen6} subscribers={subscribers}/>}

    </>
    )
}

export default Coverpicture
