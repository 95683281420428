import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile,tablette ,computer} from "../responsive";
import axios from "axios";
import { useState, useEffect} from "react";



const Container = styled.div`
width:450px;
height:100vh;
background-color:white;
justify-content:right;
left:220px;
border:1px solid #dbdbdb;
align-items: center;

z-index:10;
position: fixed;
${tablette({ left:"90px" })};

`;
const Item = styled.div`
    width: 100%;
    align-items: center;
    height: 50px;
    display: flex;
    justify-content: left;
    cursor:pointer;
    :hover{
        background-color: #f3f7f9ab;
    }
`;
const Profilepicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  margin-left:20px;
  margin-right:20px;


`;

const Accept = styled.button`
margin-left: auto;
margin-right: auto;

display:flex;
    text-align: center;
    align-items: center;
    border: 1px solid #5f4fbb;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;

const Decline = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: 1px solid #5f4fbb;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;

const Notifications = ({ads,followAccepted, seancesDone, newSeances, socket,user, newTeas,newSubscribers, newFollowers}) => {
    const [accepted, setAccept] = useState([]);
    const [acceptedSub, setAcceptSub] = useState([]);
    const [answeredSub, setAnsweredSub] = useState([]);

    const [answered, setAnswered] = useState([]);

    const accept= async(item,j) => {

        const res= (await axios.put(ads+'api/connections/newfollowers/accept/'+user._id,
        {
                id:item.follower_id
            }
        ));
        var aa=[...accepted];
        aa[j]=true
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
        
        socket.current.emit("followAccepted", {
            
            follower: item.follower_id,
            following:user._id
         }
 )
    }
    const decline= async(item,j) => {
        const res= (await axios.put(ads+'api/connections/newfollowers/decline/'+user._id,{
            id:item.follower_id
        }));
        var aa=[...accepted];
        aa[j]=false
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
    }
    const acceptSubscription= async(item,j) => {

        const res= (await axios.put(ads+'api/checkout/newsubscribers/accept/'+user._id,
        {
                id:item.subscriber_id
            }
        ));
        var aa=[...acceptedSub];
        aa[j]=true
        var bb=[...answeredSub];
        bb[j]=true
        setAnsweredSub(bb)
        setAcceptSub(aa)
        
        socket.current.emit("subscribeAccepted", {
            
            follower: item.follower_id,
            following:user._id
         }
 )
    }
    const declineSubscription= async(item,j) => {
        const res= (await axios.put(ads+'api/checkout/newsubscribers/decline/'+user._id,{
            id:item.subscriber_id
        }));
        var aa=[...acceptedSub];
        aa[j]=false
        var bb=[...answeredSub];
        bb[j]=true
        setAnsweredSub(bb)
        setAcceptSub(aa)
    }
    return (
<Container>
            <div style={{justifyContent:"center",alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                <div>            NOTIFICATIONS
                </div>
            </div>
            {seancesDone?.map((item) => (
                <Link to={"/patient/"+item.userId} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.friendInfo.profilePicture}/>
                    <span style={{fontWeight:"600", marginRight:"6px"}}>{item.friendInfo.name}</span>  vient de terminer une séance.

                                    
                                </div>
                </Link>
            ))}

            {newSeances?.map((item) => (
                <Link to={"/messeances"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.friendInfo.profilePicture}/>
                    <span style={{fontWeight:"600", marginRight:"6px"}}>{item.friendInfo.name}</span>  vient de vous envoyer une nouvelle séance.

                                    
                                </div>
                </Link>
            ))}



            {newTeas?.map((item) => (
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.buyer_profile_picture}/>
                    <div>
                        {item.currency==="eur"?
                        <div>
                    <span style={{fontWeight:"600"}}>{item.buyer_username}</span> vous a offert {item.number} Thés pour un total de {item.price*0.01}€.

                        </div>
                :
                <div>
                <span style={{fontWeight:"600"}}>{item.buyer_username}</span> vous a offert {item.number} Thés pour un total de ${item.price*0.01}.

                    </div>
                                        }

                                    </div>
                                </div>
            ))}
            {/* {newSubscribers?.map((item,j) => (
                
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.subscriber_profile_picture}/>
                    <div>
                        {item.free&&?
                                                <div>
                                                <span style={{fontWeight:"600"}}>{item.budy_username}</span> s'est abonné à votre profil.
                            
                                                    </div>
                                                    :
                    

<div>
                        {item.currency==="eur"?
                        <div>
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a payé {item.price*0.01}€ pour s'abonner.

                        </div>
                :
                <div>
                <span style={{fontWeight:"600"}}>{item.budy_username}</span> a payé ${item.price*0.01} pour s'abonner.

                    </div>
                                        }
                                        </div>
}
                                    </div>
                                </div>
            ))} */}
            {newSubscribers?.map((item,j) => (
                <div>
                {item.validated==true?
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.subscriber_profile_picture}/>
                    <div>
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> s'est abonné à votre profil.

                    </div>
                </div>:

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"90px",borderBottom:"1px solid lightgrey"}}>
                <Profilepicture src={ads+item.subscriber_profile_picture}/>
                <div>
                <span style={{fontWeight:"600"}}>{item.budy_username}</span> a demandé à s'abonner.
                {acceptedSub[j]==true?
                                <div style={{display:"flex", marginTop:"15px"}}>
                                <Accept >Acceptée</Accept>
                                </div>
                :
                    null
                }
                {acceptedSub[j]==false?
                <div style={{display:"flex", marginTop:"15px"}}>
                <Decline >Déclinée</Decline>
                </div>
                :
                null}
                {answeredSub[j]==true?
                null:
                <div style={{display:"flex", marginTop:"15px"}}>
                <Accept onClick={()=>acceptSubscription(item,j)}>Accepter</Accept>
                <Decline onClick={()=>declineSubscription(item,j)}>Decliner</Decline>
                </div>
                }

                </div>
            </div>
        }
</div>
            ))}

            {newFollowers?.map((item,j) => (
                <div>
                {item.validated==true?
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.follower_profile_picture}/>
                    <div>
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a commencé à vous suivre.

                    </div>
                </div>:

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"90px",borderBottom:"1px solid lightgrey"}}>
                <Profilepicture src={ads+item.follower_profile_picture}/>
                <div>
                <span style={{fontWeight:"600"}}>{item.budy_username}</span> a demandé à vous suivre
                {accepted[j]==true?
                                <div style={{display:"flex", marginTop:"15px"}}>
                                <Accept >Acceptée</Accept>
                                </div>
                :
                    null
                }
                {accepted[j]==false?
                <div style={{display:"flex", marginTop:"15px"}}>
                <Decline >Déclinée</Decline>
                </div>
                :
                null}
                {answered[j]==true?
                null:
                <div style={{display:"flex", marginTop:"15px"}}>
                <Accept onClick={()=>accept(item,j)}>Accepter</Accept>
                <Decline onClick={()=>decline(item,j)}>Decliner</Decline>
                </div>
                }

                </div>
            </div>
        }
</div>
            ))}

{followAccepted?.map((item,j) => (
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.follower_profile_picture}/>
                    <div>
                       
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a accepté votre demande de suivi.

                     

                    </div>
                </div>
            ))}


            {/* <Link to="/settings" style={{ color: 'inherit', textDecoration: 'inherit'}}></Link> */}
       </Container> 

    )
}

export default Notifications
