import React, { useContext, useState, useEffect,useRef } from "react";
import styled from 'styled-components'
import axios from "axios";
import { SentimentNeutral, SeventeenMp } from "@mui/icons-material";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { mobile,tablette, computer } from "../responsive";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './App.css';

// import Img from "../img/img.png";
// import Attach from "../img/attach.png";
// import { AuthContext } from "../context/AuthContext";
// import { ChatContext } from "../context/ChatContext";
// import {
//   arrayUnion,
//   doc,
//   serverTimestamp,
//   Timestamp,
//   updateDoc,
// } from "firebase/firestore";
// import { db, storage } from "../firebase";
// import { v4 as uuid } from "uuid";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";


const Container = styled.div`
height: 40px;
background-color: white;
padding: 10px 10px 0px;
display: flex;
align-items: center;
justify-content: space-between;

`;
const Iinput = styled.input`
border: none;
outline: none;
color: #2f2d52;
font-size: 18px;
${computer({width:"550px"})}
${mobile({width:"91vw"})}
${tablette({width:"100%"})}

&::placeholder {
  color: lightgray;
}
`;

const Input2 = styled.input`
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Button = styled.button`

border: none;
padding: 10px 15px;
color: white;
background-color: #8798ef;
cursor: pointer;
margin-left:auto;
margin-right: 20px;
margin-bottom:10px;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
const Picture = styled.img`
width: 100%;
height: 300px;
object-fit:contain;
`;
const Spinning = styled.div`
width: 100%;
height: 300px;
display: flex;
justify-content: center;
align-items: center;
`;

const Input = ({newImage, nM,setNewImage,ads,setNM,chats,setChats,setIsTipping,connectedUsers ,user, chatId, setChatId, socket, set,setArrivalMessages}) => {
    const [text, setText] = useState("");
    const [online, setOnline] = useState([]);
    const ref = useRef(null);
    const [isdisable, setIsdisable] = useState(true);
    const buttonRef = useRef();
    const [img, setImg] = useState(undefined);
    const [showSpin, setshowSpin] = useState(false);

    const [imgURL, setImgUrl] = useState(undefined);
    const [showImage, setshowImage] = useState(false);
    const [tooLarge, setTooLarge] = useState(false);
    const handleClick = () => {
      ref.current.focus();
    };
    //const [img, setImg] = useState(null);

//   const { currentUser } = useContext(AuthContext);
//   const { data } = useContext(ChatContext);

//   const handleSend = async () => {
//     if (img) {
//       const storageRef = ref(storage, uuid());

//       const uploadTask = uploadBytesResumable(storageRef, img);

//       uploadTask.on(
//         (error) => {
//           //TODO:Handle Error
//         },
//         () => {
//           getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
//             await updateDoc(doc(db, "chats", data.chatId), {
//               messages: arrayUnion({
//                 id: uuid(),
//                 text,
//                 senderId: currentUser.uid,
//                 date: Timestamp.now(),
//                 img: downloadURL,
//               }),
//             });
//           });
//         }
//       );
//     } else {
//       await updateDoc(doc(db, "chats", data.chatId), {
//         messages: arrayUnion({
//           id: uuid(),
//           text,
//           senderId: currentUser.uid,
//           date: Timestamp.now(),
//         }),
//       });
//     }

//     await updateDoc(doc(db, "userChats", currentUser.uid), {
//       [data.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data.chatId + ".date"]: serverTimestamp(),
//     });

//     await updateDoc(doc(db, "userChats", data.user.uid), {
//       [data.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data.chatId + ".date"]: serverTimestamp(),
//     });

//     setText("");
//     setImg(null);
//   };
const onButton=()=>{
  buttonRef.current.click()
 
}



const changePP=(e)=>{
  setImg(e.target.files[0]);
  setImgUrl(URL.createObjectURL(e.target.files[0]))
  // if(e.target.files[0].size>4000000){
  //     setTooLarge(true);
  // }else{
  //     setTooLarge(false);
  // }
  setshowImage(true)
  setIsdisable(false)
  setNewImage(true)
}


useEffect(()=>{
    if(connectedUsers!=undefined){
        var O=[];
        for (let i = 0; i < connectedUsers.length; i++) {
            O.push(connectedUsers[i].userId)
        }
        setOnline(O);
        console.log("connection")
    }

    },[connectedUsers]
    )


const tipping = (text) =>{
    console.log(online)
    if(online.includes(chatId.budyId)){
        if(text!=""){
            socket.current.emit("budyIsTipping", {
                       isTipping:true,
                       senderId: user._id,
                       receiverId:chatId.budyId
                    }
            )
        }else{
            socket.current.emit("budyIsTipping", {
                isTipping:false,
                senderId: user._id,
    
                receiverId:chatId.budyId}
     )
        }
    }

}

const deleteNotif = async() =>{
    var C =[...chats]
    var s=false;
    for (let i = 0; i < chats.length; i++) {
        if(chats[i].conversation_id==chatId.conversationId&&chats[i].new){
            chats[i].new=false
            s=true;
        }
    }
    setChats(C)
    if(s){

      await axios.post(ads+'api/chat/seenmessage/'+user._id+'/'+chatId.conversationId)
      socket.current.emit("messageSeen", {
        convId:chatId.conversationId,
        readerId: user._id,
        senderId:chatId.budyId,
        lastSeen:new Date()
})
      setNM(false);
    }


}
//  useEffect(()=>{

//    // socket.current = io("ws://localhost:8900");
//     socket?.current?.on("budyIsTipping", data=>{
//         setIsTipping(data.isTipping)

//     })
// })

const sendMessage = async () =>{
    var imageUrl;

    if(newImage){
      setshowSpin(true)
      const formData = new FormData();
      formData.append('profile', img, img.name);
      const res= (await axios.post(ads+'api/chat/privateimage/'+chatId.conversationId+"/"+user._id+"/"+chatId.budyId, formData)).data;
      imageUrl=res.imageUrl;
      setshowSpin(false)

      setNewImage(false);
      setImgUrl("")
    }

  if(text!=""){
    await axios.post(ads+'api/chat/message1/'+chatId.conversationId+'/'+user._id,{text: text,
      senderId: user._id,
      createdAt: new Date()})
    setText("");
  }
  var msg={text: text,
    senderId: user._id,
    createdAt: new Date(),
    imageUrl:imageUrl
}

  socket.current.emit("sendMessage", {
      senderId:user._id,
      receiverId:chatId.budyId,
      text:text,
      imageUrl:imageUrl
  })
  socket.current.emit("sendMessageToMe", {
    senderId:user._id,
    receiverId:chatId.budyId,
    text:text,
    imageUrl:imageUrl
})

  socket.current.emit("budyIsTipping", {
      isTipping:false,
      senderId: user._id,

      receiverId:chatId.budyId})
  
  

  setArrivalMessages(msg)
  setIsdisable(true)
}
  //setShowProgress(true)

  // ,
  // {   
  //   onUploadProgress: progressEvent => {
  //     setProgress((progressEvent.loaded / progressEvent.total)*100)
  //       console.log(progressEvent.loaded / progressEvent.total);
  //   }
  // }
 


  const handleSubmit = event => {
    event.preventDefault();
    // Do something with the value here
    if(!isdisable){
      sendMessage();

    }
};
  return (
    <div>
    <Container>
    <form onSubmit={handleSubmit} style={{width:"90%"}}>

      <Iinput 
        type="text"
        placeholder="Ecrivez votre message..."
        onChange={(e) => {
            setText(e.target.value);
            tipping(e.target.value)
            if(e.target.value!=""){
              setIsdisable(false)
            }else{
              setIsdisable(true)

            }
            if(nM){
              deleteNotif()
            }
           }}

        value={text}
        onFocus= {()=>{
            deleteNotif()
        }}
      />
          </form>

      <div className="send">
        <img  alt="" />
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
        //   onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file">
          <img src="jh" alt="" />
        </label>
        {/* <button onClick={handleSend}>Send</button> */}


      </div>

    </Container>
    <div style={{height:"50px", display:"flex", backgroundColor:"white"}}>
    <AddPhotoAlternateOutlinedIcon onClick={() =>onButton()} style={{cursor:"pointer",marginLeft:"20px", marginTop:"10px"}}/>
    {/* <AddPhotoAlternateOutlinedIcon  style={{cursor:"pointer",marginLeft:"20px", marginTop:"10px"}}/> */}

    <Button disabled={isdisable}  ref={ref} onClick={() => sendMessage()}   
        >Envoyer</Button>
    </div>
    <div>
    {(showImage==true)?
        <div style={{display:"flex", flexDirection:"column", paddingBottom:"20px", backgroundColor:"white"}}>
                         <CloseOutlinedIcon   onClick={() => {setNewImage(false); setIsdisable(true)}}
                  style={{marginLeft:"auto", cursor:"pointer", fontSize:"18px"}}/>
        {showSpin?
        <Spinning>
<div style={{marginLeft:"auto", marginRight:"auto", marginTop:"30px"}}>
<div class="sk-chase">
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
</div>
</div>
        </Spinning>
        :        <Picture src={imgURL}/>

        }

        </div>
        :null}
    </div>
    <div style={{display:"none"}}>
        <Input2
        type="file"
        accept="image/*"
        id='myfile'
        name="myfile"
        onChange={(e) => changePP(e)}
        ref={buttonRef}
        style={{ opacity: "0" }}
      />
    </div>
    </div>
  );
};

export default Input;