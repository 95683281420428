import React, { useContext, useEffect, useState } from "react";
import Chat1 from '../components/Chat'
import Sidebar from '../components/Sidebar'
import SidebarS from '../components/SidebarS'

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import styled from 'styled-components'
import { mobile,tablette,computer } from "../responsive";

const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;
const Container = styled.div`
background-color:rgb(35 33 80);
/* background-color: #a7bcff; */
height:100vh;
display:flex;
align-items:center;
justify-content: center;
${mobile({display:"none"})};
${tablette({height:"99.9vh"})};

`;
const Wrapper = styled.div`
border: 1px solid white;
border-radius:10px;
width:900px;
height:80%;
display:flex;
overflow: hidden;
${mobile({width:"100%", height:"100%",backgroundColor:"red",borderRadius:"0"})}
${tablette({width:"100%", height:"100%",backgroundColor:"white",borderRadius:"0"})}

`;

const Smartphone = styled.div`
width:100%;
${tablette({display:"none"})};
${computer({display:"none"})};
`;


const Chat = ({setNC,nC,ads,connectedUsers, setConnectedUsers,setNM, nM, socket,user,connected,chatId,setChatId}) => {
  const [newLastMessage, setNewLastMessage] = useState(null);
  const [arrivalMessage, setArrivalMessages] = useState(null);
  const [isWritting, setIsWritting]= useState([]);
  const [chats, setChats] = useState(undefined);
  const [newImage, setNewImage] = useState(false);

  return (
    <div  style={{display:"flex", width:"100%", minHeight:"100vh"}}>
    <NavContainer >
    <Navbar ads={ads} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

    </NavContainer>

    <ContainerRight >
    <BottomNav>
                    {/* <NavbarT setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/> */}
                    
                    {/* <NavbarB setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/> */}

                </BottomNav>
                <Smartphone>
          <SidebarS nM={nM} newImage={newImage} setNewImage={setNewImage} setNC={setNC} nC={nC} ads={ads} socket={socket} setNM={setNM} chats={chats} setChats={setChats} isWritting={isWritting} connectedUsers={connectedUsers} newLastMessage ={newLastMessage} user={user} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} chatId={chatId} setChatId={setChatId}/>
          </Smartphone>

          {/* {window.screen.width<=480? */}
          {/* <SidebarS setNC={setNC} nC={nC} ads={ads} socket={socket} setNM={setNM} chats={chats} setChats={setChats} isWritting={isWritting} connectedUsers={connectedUsers} newLastMessage ={newLastMessage} user={user} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} chatId={chatId} setChatId={setChatId}/> */}

        <Container >

        <Wrapper >


         
          <Sidebar socket={socket} nM={nM} setNC={setNC} nC={nC} ads={ads} setNM={setNM} chats={chats} setChats={setChats} isWritting={isWritting} connectedUsers={connectedUsers} newLastMessage ={newLastMessage} user={user} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} chatId={chatId} setChatId={setChatId}/>
        
        <Chat1 newImage={newImage} nM={nM} setNewImage={setNewImage} ads={ads} setNM={setNM} socket={socket} chats={chats} setChats={setChats} isWritting={isWritting} setIsWritting={setIsWritting} connectedUsers={connectedUsers} setConnectedUsers={setConnectedUsers} arrivalMessage={arrivalMessage} setArrivalMessages={setArrivalMessages} setNewLastMessage={setNewLastMessage} user={user} chatId={chatId} setChatId={setChatId}/>

          

        </Wrapper>
        </Container>
       
    </ContainerRight>

</div>
  )
}

export default Chat