import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import '../components/cercle.css';
import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import InvitePatient from "../components/InvitePatient";

import { mobile,tablette,computer } from "../responsive";
import Feeds from "../components/Feeds";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const Label = styled.label`
  font-size: 18px;

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const NameList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width:90%;
`;

const NameItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width:100%;
  justify-content: space-between;
  padding:20px;
  border-bottom:1px solid lightgray;
  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ColumnTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Name = styled.span`
  font-size: 18px;
  align-items:center;
  display:flex;
`;
const ContainerM = styled.div`
  display: ${(props) => (props.isVisible ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius:15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const ST = styled.div`
display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  border-radius: 50px;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"45px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"45px",width:"200px", height:"50px"})}

`;




const MyPatients = ({ads,setUser,socket, setNM, nM,user,connected}) => {
    const navigate = useNavigate();
    const [showTimer, setShowTimer] = useState(false);

    const [nbExo, setNbExo] = useState(0);
    const [nbSerie, setNbSerie] = useState(1);

    const [open, setOpen1] = useState(false);
    const [mesPatients, setMesPatients] = useState(undefined);

    const [done, setDone] = useState(false);

    const progressBar = document.querySelector('.js-progress-bar'); 
    //   

    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [maxTime, setMaxTime] = useState(60);

    const [seconds, setSeconds] = useState(60);
    const [timerRunning, setTimerRunning] = useState(false);
  
    
    // Define options for formatting the date
    const options = {
      year: "numeric",
      month: "long", // "long" for full month name
      day: "numeric",
    };
    
    // Create a date formatter with the specified options
    const dateFormatter = new Intl.DateTimeFormat("fr-FR", options); // "fr-FR" for French locale
    

    useEffect(async() => {
        try { 
                var check= await axios.get(ads+'api/exercices/maseance/'+id);
                const date1 = new Date(check.data.date);
                const formattedDate = dateFormatter.format(date1);
                check.data.date = formattedDate
                setMesPatients(check.data)
          
    
        }catch(e){
    
        }
    },[]);

    const DemarrerLaSeance = async() => {
        if(nbSerie<mesPatients.seance[nbExo].series){
            setNbSerie(nbSerie+1);
        }else{
            if(nbExo<mesPatients.seance.length-1){
                setNbExo(nbExo+1);
                setNbSerie(1)
            }else{
                setDone(true)
            }
        }

    };




    useEffect(() => {
      let timerInterval;
  
      if (timerRunning && seconds > 0) {
        progressBar.style.strokeDashoffset = 100-(seconds/maxTime)*100 
        timerInterval = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      }
      if(seconds==0){
        setShowTimer(false)
      }
  
      return () => {
        clearInterval(timerInterval);
      };
    }, [timerRunning, seconds]);
  
    const startTimer = async () => {
      
      if(nbSerie<mesPatients.seance[nbExo].series){
        setShowTimer(true)
        setNbSerie(nbSerie+1);
        if(mesPatients.seance[nbExo].seconds!=""&&mesPatients.seance[nbExo].minutes==""){
          var ss = parseInt(mesPatients.seance[nbExo].seconds) + parseInt(mesPatients.seance[nbExo].minutes)*60
        }
        if(mesPatients.seance[nbExo].seconds==""){
          var ss = parseInt(mesPatients.seance[nbExo].minutes)*60
        }
        if(mesPatients.seance[nbExo].minutes==""){
          var ss = parseInt(mesPatients.seance[nbExo].seconds)
        }
      }else{
        if(nbExo<mesPatients.seance.length-1){
          setShowTimer(true)
          if(mesPatients.seconds[nbExo]!=""&&mesPatients.minutes[nbExo]==""){
            var ss = parseInt(mesPatients.seconds[nbExo]) + parseInt(mesPatients.minutes[nbExo])*60
          }
          if(mesPatients.seconds[nbExo]==""){
            var ss = parseInt(mesPatients.minutes[nbExo])*60
          }
          if(mesPatients.minutes[nbExo]==""){
            var ss = parseInt(mesPatients.seconds[nbExo])
          }
            setNbExo(nbExo+1);
            setNbSerie(1)

        }else{
            setDone(true)
            await axios.put(ads+'api/exercices/seance-done/'+id,{status:"Faite"});
            await axios.post(ads+'api/notifications/seance-done/'+id,{status:"done", praticienId: mesPatients.praticien, seanceId:id});

            return
        }
    }


      setSeconds(ss)

      setMaxTime(ss)
      setTimerRunning(true);
    };
  
    const resetTimer = () => {
      setSeconds(60);
      setTimerRunning(false);
    };
  





  
    // useEffect(() => {
    //   const percentageComplete = intermediateDownload / totalDownload;
    //   const strokeDashOffsetValue = 100 - percentageComplete * 100;
  
    //   setPercentageLoaded(Math.round(100 * intermediateDownload / totalDownload));
  
    //   const progressBar = document.querySelector('.js-progress-bar'); // You can use refs in React, but this is a direct DOM manipulation example.
    //   progressBar.style.strokeDashoffset = strokeDashOffsetValue + '%';
    // }, [intermediateDownload, totalDownload]);
    return (
<>
        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center"}}>
                <h1>Exercice {nbExo+1} / {mesPatients?.seance.length} : {mesPatients?.seance[nbExo].title}</h1>
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </div>
      <Container>
      <div style={{width:"90%", marginLeft:"auto", marginTop:"45px", marginBottom:"45px", display:"flex", alignItems:"center"}}>
                
                {/* <Button onClick={() => setOpen1(true)} >INVITER UN PATIENT </Button> */}
                </div>
                    <div>
                    <div style={{display:"flex"}}>

                   
      <ContainerM isVisible={showTimer}>
       <div>
        Série {nbSerie} / {mesPatients?.seance[nbExo].series}
        </div> 
        <div style={{display:"flex"}}>
                {mesPatients?.seance[nbExo].rep} répétitions

        </div>
        <div style={{display:"flex"}}>

        {/* {(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes!=""&&person.minutes!="0"&&person.minutes!=0)?
                <div>
                    {person.minutes} minute(s) et {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                }
                        {(person.seconds==""||person.seconds==0||person.seconds=="0")&&(person.minutes!=""&&person.minutes!=0&&person.minutes!="0")?
                <div>
                    {person.minutes} minute(s) de récupération entre les séries
                </div>:
                null
                
                }

{(person.seconds!=""&&person.seconds!=0&&person.seconds!="0")&&(person.minutes==""||person.minutes==0||person.minutes=="0")?
                <div>
                    {person.seconds} secondes de récupération entre les séries
                </div>:
                null
                
                } */}
                 

        </div>
      </ContainerM>

        </div>

        
        <ST isVisible={showTimer}>

          <h1 style={{display:"flex", justifyContent:"center"}}>Repos</h1>
        <div class="cercle">
                    <div class="percent">
                        {seconds} s
                    </div>
                    <svg 
                    viewBox="-1 -1 34 34" style={{transform: "rotate(-90deg)"}}>
                 
                 <circle cx="16" cy="16" r="15.9155"
                         class="progress-bar__background" />
                 
                 <circle  cx="16" cy="16" r="15.9155"
                         class="progress-bar__progress
                         js-progress-bar"/>
               </svg>
        </div>
        </ST>





      {/* <Label>Temps de repos entre les blocs :</Label> */}
      {/* <div style={{display:"flex"}}>
      <div>
      <label>Minutes : </label>
      {mesPatients.minutes[i]}
      </div>
      
      
            <div>
              <label>Secondes :</label>
             {person.seconds}
            </div>
            </div> */}
            </div>
{done&&showTimer==false?                   <Button>
           Séance terminée, Bravo !
        </Button>:
null}
        {!done&&showTimer==false?                  
        <Button onClick={startTimer}>
           Série {nbSerie} terminée
        </Button>:
null}
      </Container>
      </ContainerRight>

</div>
{open && <InvitePatient ads={ads} user={user} socket={socket} setOpen={setOpen1} owner={user}/>}
</>
)
}

export default MyPatients