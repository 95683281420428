import styled from "styled-components";
import axios from "axios";
import { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import PostZoom from './PostZoom';
import { SettingsRemote } from "@mui/icons-material";
import { mobile,tablette,computer } from "../responsive";
import Tabs from '../components/Tabs'

import Articles from '../components/FavoriteArticles'
import Series from '../components/FavoriteSeries'
import Videos from '../components/FavoriteVideos'
import Posts from '../components/PostsFavorites'


const Container = styled.div`
    margin-left: auto;
    margin-right: auto;

    ${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}
    margin-top:-20px;


`;

const Titre = styled.h1`
  font-weight:900;
  text-align: center;
  font-size: 40px;
  margin-top:60px;
  letter-spacing: 2.5px;
`;

const Favorites = ({ads,setUser, owner,user,connected}) => {
  const [classes, setClasses] = useState([]);
  const [tab, setTab] = useState(0);

  const [open1, setOpen1] = useState(false);
  const [item, setItem] = useState({});
  const [tempuser, setTempuser] = useState({});
  const [number, setNumber] = useState(0);
  const [status, setStatus] = useState(undefined);
  useEffect(async() => {
    try { 
      setTempuser(owner);

        if(connected&&user.subscribe.includes(owner._id)){
          setStatus("subscriber")
        }else if(connected&&user.follow.includes(owner._id)){
          setStatus("follower")
        }
        //const res= (await axios.get(ads+'api/post/all/'+owner._id,Config)).data;

         const res= (await axios.get(ads+'api/post/all/'+owner._id,{ withCredentials: true })).data;
         setClasses(res.reverse());

      if(!connected){
        const res= (await axios.get(ads+'api/post/all/'+owner._id)).data;
        setClasses(res.reverse());

      }
    }catch(e){

    }
},[owner]);

const choosePost = (item,i) => {
    setNumber(i)
    setItem(item)
    setOpen1(true)
}




const [imageLoaded, setImageLoaded] = useState({});

const handleImageLoad = (image) => {
  setImageLoaded(prevState => ({
    ...prevState,
    [image.id]: true
  }));
};

  return (
<>
{/* <div style={{display: coverLoaded||profileLoaded ? 'flex' : 'none',marginLeft:"auto", marginRight:"auto", marginTop:"150px", justifyContent:"center"}}>
    <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    </div>
    {coverLoaded}
    {profileLoaded}
</div> */}

        <Container>
        <Tabs tab={tab} setTab={setTab} />

        {(tab==0&&user._id)?

<Posts setUser={setUser} ads={ads} user={user} owner={user}/>
:null}
{(tab==1&&user._id)?

<Videos ads={ads} connected={connected} owner={user} user={user}/>
:null}
{(tab==2&&user._id)?

<Series ads={ads} connected={connected} owner={user} user={user}/>
:null}
{(tab==3&&user._id)?

<Articles ads={ads} connected={connected} user={user} owner={user}/>:null}


        </Container>
        {open1 && <PostZoom ads={ads} setUser={setUser} setOpen={setOpen1} item={item} user={user} creator={tempuser} setItem={setItem} setNumber={setNumber} numbers={classes?.length} number={number} items={classes}/>}
</>

  );
};

export default Favorites;