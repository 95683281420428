import React, { useRef } from 'react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

import { useState, useEffect} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:0px;
    ${mobile({width:"100%", padding:"0", marginTop:"0px"})};

`;

const Options1=styled.div`
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:auto;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
  ${tablette({fontSize:"13px"})};
  ${mobile({fontSize:"13px"})};
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  font-size: 20px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  width:100px;
  background-color: transparent;
  border-radius: 6px;
  border: 1.5px solid lightgrey;
  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
}
  ${mobile({fontSize:"13px"})};


`;
const Label = styled.label`
  font-size: 18px;

`;
const LabelS = styled.div`
  font-size: 18px;
  ${mobile({display:"none"})};

`;
const Wrapper2 = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  ${mobile({padding:"0px"})};

`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;
${mobile({width: "60vw", height:"33.9vw"})}
${tablette({width: "500px", height:"282px"})}


`;
const Options=styled.div`
display:flex;
align-items: center;
margin-top:0px;
${mobile({display:"none"})}

`;

const OptionsS=styled.div`

${computer({display:"none"})}
${tablette({display:"none"})}

`;
const Th = styled.div`
border: 1px solid black;
padding: 40px;
${computer({width:"640px"})}
${tablette({width:"500px"})}
${mobile({width:"60vw"})}

`;
const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
`;
const Option = styled.option``;

const CourseparametersSeries = ({user,ads,newThumb, setNewThumb,IMG,setIMG,Video, Number, setVideos, Videos, selectedThumb, setThumb} ) => {

    const [title, setTitle] = useState("");
    const [series, setSeries] = useState(Video.series);

    const [rep, setRep] = useState(Video.reps);




    const [description, setDescription] = useState("");
    const [video, setVideo] = useState(Video);
    const [theArray, setTheArray] = useState([]);

    const editorRef = useRef(null);



    const [minutes, setMinutes] = useState(Video.minutes);
    const [seconds, setSeconds] = useState(Video.seconds);
  
    // Fonction pour gérer les changements dans le champ des minutes
    const handleMinutesChange = (desc) => {
        setMinutes(desc);
        var vv = video;
        vv.minutes= desc;
        setVideo(vv);
        var V = Videos;
        V[Number]=vv;
        setVideos(V);
    };
  
    // Fonction pour gérer les changements dans le champ des secondes
    const handleSecondsChange = (desc) => {
        if (desc > 60) {
            desc = 60;
            return
          }
      setSeconds(desc);
      var vv = video;
      vv.seconds= desc;
      setVideo(vv);
      var V = Videos;
      V[Number]=vv;
      setVideos(V);
    };

    const changeDescription = (desc)=>{
        setDescription(desc);
        var vv = video;
        vv.description= desc;
        setVideo(vv);
        var V = Videos;
        V[Number]=vv;
        setVideos(V);
    }
    const changeRep = (desc)=>{

        setRep(desc);
        var vv = video;
        vv.rep= desc;
        setVideo(vv);
        var V = Videos;

        V[Number]=vv;
        setVideos(V);
    }
    const changeSeries = (desc)=>{

        setSeries(desc);
        var vv = video;
        vv.series= desc;
        setVideo(vv);
        var V = Videos;

        V[Number]=vv;
        setVideos(V);
    }



    useEffect(async() => {
        try { 

            // setCreator(user);
            setTitle(Video.title);
            setDescription(Video.description)
            setRep(Video.rep)
            setSeries(Video.series)
            setSeconds(Video.seconds)
            setMinutes(Video.minutes)

            // setFilters({
                
            //     ["diff"]: Video.difficulty,
            //     ["intens"]: Video.intensity,

            //   });
            //   setTheArray(Video.style)
            //   var CC=[];
            //   for (let ii = 0; ii < Video.thumbnails.length; ii++) {
            //     if(ii==selectedThumb[Number][0]){
            //       CC[ii]=true;
            //     }else{
            //       CC[ii]=false
            //     }
            //     }
            //   setcheck(CC)
            //   console.log(selectedThumb[Number])

            //   if(IMG[Number]){
            //     setImg(IMG[Number]);
            //     setImgUrl(URL.createObjectURL(IMG[Number]))
            //     if(selectedThumb[Number][0]==Video.thumbnails.length){
            //       setT(true)

            //     }else{
            //       setT(false)

            //     }
              //}
        }catch(e){


        }
  
    },[])
    useEffect(() => {
   
      var u = theArray;

      var vv = video;
      vv.style=u;
      setVideo(vv);
      var V = Videos;
      V[Number]=vv;
      setVideos(V);


  
},[theArray])



    return (
        
        <Container>
            <Wrapper2>
              <div style={{display:"flex",alignItems: "center"}}>
                <Label>Nom de l'exercice :</Label>

                <h2 style={{marginLeft:"5px"}}>{Video.title}</h2>
              </div>

              <div style={{display:"flex",alignItems: "center"}}>

            <Label>Nombre de répétitions :</Label>
            <Input
            style={{marginLeft:"5px"}}
    value={rep}
  type="texte"

  name="rep"
  onChange={(e) => changeRep(e.target.value)}
/>
</div>
<br />
<div style={{display:"flex",alignItems: "center"}}>

            <Label>Nombre de séries :</Label>
            <Input
            style={{marginLeft:"5px"}}
    value={series}
  type="texte"
  name="serie"
  onChange={(e) => changeSeries(e.target.value)}
/>
</div>
{/* <Label>Temps de repos entre les séries :</Label>
            <Input
    value={rest}
  type="number"
  name="rest"
  onChange={(e) => changeRest(e.target.value)}
/> */}
<Label>Temps de repos entre les séries :</Label>
<div style={{display:"flex",alignItems: "center"}}>
<div >
<Input
    value={minutes}
  type="texte"
  name="minute"
  onChange={(e) => handleMinutesChange(e.target.value)}
/>
<label style={{marginRight:"10px"}}> minute(s)</label>

</div>


      <div>
      <Input
          type="texte"
          value={seconds}
          onChange={(e) => handleSecondsChange(e.target.value)}
        />
        <label> secondes</label>

      </div>
      </div>
<br/>
<Label>Remarque :</Label>



<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        value={description}

        init={{
          placeholder: "Remarque",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
        onEditorChange={changeDescription}
      />




{/* {imgURL?
  <div style={{border:"1px solid black", padding:"40px",width:"640px"}}>
<div >
      <input style={{width:"30px", height:"30px", cursor:"pointer",marginBottom:"30px"}}  type="checkbox" checked={newT} onChange={(e) => handleNew(e)} />
    </div>
<Image src={imgURL}/>
</div>

:null} */}







<br/>




            </Wrapper2>

           
        </Container>
        
    )
}

export default CourseparametersSeries
