import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'

import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import { Link } from "react-router-dom";

import { mobile,tablette,computer } from "../responsive";
import MentorItem from "../components/MentorItem";
import axios from "axios";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: grid;
  grid-template-columns: 46.5% 46.5%;
  grid-gap: 5vw;
  padding: 10px;
    width:86%;
    margin-top:60px;
    ${mobile({marginBottom:"60px",marginTop:"80px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"0vw"})}
    ${tablette({marginTop:"0px",gridTemplateColumns: "38vw 38vw",width:"80vw", padding:"0",gridGap:"4vw"})}

`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;
const Mentors = ({ads,setUser,setNM, nM, socket, user,connected}) => {

    const [teachers, setTeacher] = useState(undefined);

    const [tab, setTab] = useState(0);

    useEffect(async() => {
        try { 
            setTeacher((await axios.get(ads+'api/mentors/')).data)
        }catch(e){


        }
  
    },[])

    return (

        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Container>
                {teachers?.map((item) => (
                    <Link to={"/profile/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <MentorItem ads={ads} item={item} key={item._id} />
                    </Link>
                ))}
                </Container>

            </ContainerRight>

        </div>

    )
}

export default Mentors
