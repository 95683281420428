import { Link } from "react-router-dom";
import { mobile, computer,tablette } from "../responsive";

import styled from "styled-components";


const Container = styled.div`
${tablette({ display:"none"})};
${computer({ display:"none"})};


display: flex;
bottom:0;
height:30px;
justify-content: space-evenly;
`
const Items = styled.div`
height: 30px;
display: flex;
align-items: center;
font-family: 'Montserrat', sans-serif;
font-size:12px;
color: grey;
cursor: pointer;
`
const Copyright = styled.div`
margin-top: 30px;
padding:20px;
text-align: center;
`

const Footer = () => {
    return (
        <div>
                <Container style={{marginTop:"25px"}}>
 

 <Link to={"/conditionsgenerales"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

     <Items >
         Conditions générales d'utilisation
     </Items>
 </Link>

 <Link to={"/politiqueconfidentialite"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

     <Items>
         Politique de confidentialité
     </Items>
 </Link>


 </Container>
        <Container style={{marginBottom:"25px"}}>
        <Link to={"/mentionslegales"} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Items >
                Mentions légales
            </Items>
        </Link>
        <Link to={"/contrattype"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Items>
                Contrat type patient/praticien
            </Items>
        </Link>




        </Container>

        </div>

    )
}

export default Footer
