import styled from "styled-components";
import { mobile,tablette,computer } from "../responsive";

const Container = styled.div`
  flex: 1;
  margin: 3px;
  height: 50vh;
  position: relative;
  background-color: white;
  ${tablette({height:"auto"})};
  ${mobile({height:"auto", marginBottom:"35px"})};

`;

const Image = styled.img`
  width: 100%;
  height: 55%;
  object-fit: cover;
  ${tablette({height:"21.3vw"})};
  ${mobile({height:"54.0vw"})};

`;


const Picture = styled.img`
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  object-fit: cover;
  position:relative;
  top:-40px;
  border: 3px solid #f2f9fb;
  left:-25%;
  ${tablette({width:"12vw",height:"12vw", top:"-40px"})};
  ${mobile({width:"25vw",height:"25vw", top:"-30px"})};

`;
const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${tablette({top:"10vw", height:"0"})};

`;

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
    color:white;
    margin-bottom: 20px;
    word-spacing: 6px;
`;

const Button = styled.button`
    border: solid 2px gray;
    padding: 10px;
    background-color: white;
    color:gray;
    cursor: pointer;
    font-weight: 600;
`;

const MentorItem = ({ ads,item }) => {
  return (
    <Container>
        <Image src={ads+item.coverPicture} />
        <Info>
            <Title>{item.name}</Title>
        </Info> 
        <Wrapper>
                <Picture src={ads+item.profilePicture} />
                {/* <div
      dangerouslySetInnerHTML={{__html:item.description}}
    /> */}
        </Wrapper>

    </Container>
  );
};

export default MentorItem;