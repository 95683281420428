import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useState, useEffect} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({marginTop:"70px", fontSize:"16px"})};

`;



const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;
  ${mobile({fontSize:"13px"})};

`;
const Label = styled.label`
  font-size: 18px;
  ${mobile({fontSize:"15px"})};

`;
const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;
  ${mobile({fontSize:"10px"})};

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;

`;
const Button = styled.button`
  width: auto;
  border: none;
  padding: 15px 15px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({marginTop:"20px"})};

`;

const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
  font-size:19px;
  ${mobile({fontSize:"15px"})};

`;
const Option = styled.option`
height:20px;
`;




const Articleparameters = ({user,ads,Video}) => {
  console.log(Video)
    const navigate = useNavigate();

    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState(Video.title);
    const [description, setDescription] = useState(Video.description);
    const [img, setImg] = useState(undefined);
    const [cover, setCover] = useState(undefined);
    const [progress1, setProgress1] = useState(0);
    const [progress, setProgress] = useState(0);

    const [video, setVideo] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [theArray, setTheArray] = useState([]);
    const [privacy, setPrivacy] = useState(Video.privacy);

    const editorRef = useRef(null);


    const handleFilters = (e) => {
        const value = e.target.value;
        setFilters({
          ...filters,
          [e.target.name]: value,
        });
        
      };
    const validate = (number) =>{
        theArray.includes(number) ? setTheArray(theArray.filter(item => item !== number)) : setTheArray((arr) =>[...arr, number]);
    }

    useEffect(async() => {
        try { 

            setCreator(user);
        }catch(e){


        }
  
    },[])

    const createArticles= async()=>{
      const course = {
        "title":title,
        "description":editorRef.current.getContent(),
        "privacy":privacy
      }


    const id = (await axios.put(ads+"api/article/"+Video._id,course)).data

    if(img!=undefined){
      const formDataThumb = new FormData()
      formDataThumb.append('profile', img, img.name)
      const thum = await axios.post(ads+"api/article/thumbnails/"+Video._id,formDataThumb,
      {   
        onUploadProgress: progressEvent => {
          setProgress((progressEvent.loaded / progressEvent.total)*100)
        }
      })
  
    }

    if(cover!=undefined){
    const formDataCover = new FormData()
    formDataCover.append('profile', cover, cover.name)
    const cov = await axios.post(ads+"api/article/cover/"+Video._id,formDataCover,
    {   
      onUploadProgress: progressEvent => {
        setProgress1((progressEvent.loaded / progressEvent.total)*100)
      }
    })
    }


      navigate('/article/'+Video._id);

    }

    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }
      
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

    const updateVideo= async () =>{

            // if (description != ''){
            //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"description":description});
            // }
            // if (title != ''){
            //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"title":title});
            // }
            // if(video!=undefined){
            //     const formData = new FormData()
            //     formData.append('profile', video, video.name)
            //     const res1 = await axios.put(ads+"api/video/video/"+Video._id,formData,{   
            //         onUploadProgress: progressEvent => {
            //             console.log(progressEvent.loaded / progressEvent.total);
            //         }
            //       })
            // }
            // if(img!=undefined){
            //     const formDataThumb = new FormData()
            //     formDataThumb.append('profile', img, img.name)
            //     const thum = await axios.post(ads+"api/video/thumbnails/"+Video._id,formDataThumb)
            // }
            navigate('/selectcourse2/');
        }
    


    return (
        
        <Container>
            <h2 style={{textAlign:"center"}}>
    Modify your Article
</h2>
            <Wrapper2>
            <Label>Title:</Label>

<Input
value={title}
  type="text"
  placeholder="Title"
  name="title"
  onChange={(e) => setTitle(e.target.value)}
/>
<br/>
<Label>Description:</Label>

{/* <Desc
value={description}

          placeholder="Description"
          name="desc"
          rows={8}
          onChange={(e) => setDescription(e.target.value)}
        />
<br/> */}
{/* <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={Video.description}

        init={{
          placeholder: "Description",
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'emoticons'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          '|emoticons|'+
          'removeformat | help',
          content_style: 'body { font-family:Urbanist, sans-serif; font-size:18px,font-weight:400,letter-spacing: 15px;line-height: 1.6; }'


        }}
        onEditorChange={setDescription}

      /> */}

<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}

        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={Video.description}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />

<div style={{display:"flex", alignItems:"center"}}>
<Label>Upload a New Thumbnail:</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => setImg(e.target.files[0])}
/>
</div>
<CircularProgressWithLabel value={progress} />

<br/>
<div style={{display:"flex", alignItems:"center"}}>
<Label>Upload a New Article Cover Picture:</Label>

<InputT
  type="file"
  accept="image/*"
  onChange={(e) => setCover(e.target.files[0])}
/>
</div>
<CircularProgressWithLabel value={progress1} />

{/* <Image src={ads+""+Video.thumbnails[0]} /> */}
<br/>
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Privacy:</Label>
            <Select name="difficulty" value={privacy} onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
            <Option>Subscribers</Option>
          </Select>
</div>

     
            </Wrapper2>






<div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={createArticles} >ENREGISTRER</Button>

</div>



           
        </Container>
        
    )
}

export default Articleparameters
