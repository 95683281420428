import React from 'react'
import styled from "styled-components";
import { cover } from "../data";
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import { useState, useEffect} from "react";
import axios from "axios";


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  width: 84vw;
  margin-left: auto;
  margin-right:auto;
    margin-top:100px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 700px;
  align-items: center;
  width: 84vw;
  margin-left: auto;
  margin-right:auto;

`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 40px;

`;
const Image = styled.img`
width:70%;
height: 80%;
object-fit: cover;

cursor: pointer;
`;

const Titre1 = styled.h2`
    margin-top: 100px;
    font-size: 40px;
    letter-spacing: 1.5px;
`;
const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  cursor: pointer;
  
`;

const Overview= styled.div`
  display: flex;
  align-items: center;
  margin-top: 70px;
  font-size: 20px;
  font-weight: 600;
  background-color: #fafafa;
  height: 100px;;
  justify-content: space-around;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  /* border-top: 2px solid #ececec; */
  justify-content: space-around;
`;
const Profilepict = styled.img`
width:80px;
height: 80px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;

`;


const Item=styled.h4`
letter-spacing: 2px;
font-weight:600;
display:flex;
flex-direction: column;
align-items: center;
color: grey;



`;

const Explainations=styled.div`
font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 50px;


`

const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`
const Circle = styled.div`
width: 5vw;
height: 5vw;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
cursor: pointer;
`;

const Play  = styled.div`
margin: 0 auto ;
margin-top: 39%;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;
cursor: pointer;
`
const Series  = styled.div`
margin-top: 80px;
`
const First  = styled.h4`
color: #a5a3a3;
letter-spacing: 2px;
font-weight:800;
text-align: center;
`

const Second  = styled.h2`
    font-size: 40px;
    letter-spacing: 1.5px;
    text-align: center;

`

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;

const ClasseV1 = ({ ads,Video }) => {
    const [creator, setCreator] = useState({});
    const [params,setParams]=useState(JSON.parse(localStorage.course))

    useEffect(async() => {
        try { 

            const Creator=(await axios.get(ads+'api/users/'+Video.creatorId)).data;
            setCreator(Creator);
        }catch(e){


        }
  
    },[])



    const saveClasse = async() =>{
        const savedClasse=(await axios.post(ads+'api/classe/'+Video.creatorId,
        {

                "creatorId":Video.creatorId,
                "videoId": Video._id,
                "bunnyId":Video.bunnyId,
                "title":params.title,
                "description":params.description,
                "difficulty":params.difficulty,
                "intensity": params.intensity,
                "style":params.style,
                "thumbnails":Video.thumbnails

              
        }
        
        
        )).data;

    }
    return (
        
        <Container>
                        <h2 style={{textAlign:"center", marginBottom:"50px"}}>
    Step 3: Validate 
</h2>
{Video.bunnyId?
            <Wrapper1>
            <iframe src={"https://iframe.mediadelivery.net/embed/62491/"+Video.bunnyId+"?autoplay=true"} 
                 
                 style={{border: "none",position:"relative",height:"39.37vw", width: "70vw",display:"block",margin: "0 auto"}} 
                 loading="lazy" 
                 // allow={{accelerometer, gyroscope, autoplay, encryptedMedia, pictureInPicture}} 
                 allowfullscreen="true">
                 </iframe>
            </Wrapper1>
            
            :null}

            {/* <Wrapper>

            <Image src={cover[0].img} />
            <Circle>
                <Play/>
            </Circle>
            </Wrapper> */}

            <Titre1>
                {params.title}
            </Titre1>
            <Author>
               <Profilepict src={ads+creator.profilePicture}/> {creator.username}
            </Author>
            <Overview>
                <Item>DURATION
                    <Info>
                      <TimerSharpIcon style={{marginRight: "5px",fontSize:"20px"}}/>  17 min 50 sec
                    </Info>
                </Item>
                <Item>DIFFICULTY
                    <Info>
                    <div class="intensityContainer"  style={{marginRight: "9px"}}>
                                        <div class="levelIntensity">
                                            <span width="16px" height="16px" display="inline-block" fill="primary" class="intensity"><svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="20px" width="20px"><path d="M3.038 31.403v-8.78a1.5 1.5 0 10-3 0v8.78a1.5 1.5 0 003 0zM12.938 31.4V15.588a1.5 1.5 0 00-3 0V31.4a1.5 1.5 0 003 0zM22.911 31.42V8.47a1.5 1.5 0 10-3 0V31.42a1.5 1.5 0 103 0z"></path><path d="M32.904 31.438V1.644a1.5 1.5 0 00-3 0v29.794a1.5 1.5 0 003 0z" opacity=".2"></path></svg></span>
                                        </div>
    
                                        
                                    </div>      Beginner
                    </Info>
                </Item>
                <Item>INTENSITY
                    <Info>
                     <div class="level">

                                    <div class="difficultyContainer"  style={{marginRight: "9px"}}>
                                        <div class="levelIntensity">
                                            <span width="16px" height="16px" display="inline-block" fill="primary" class="intensity"><svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" height="20px" width="20px"><path d="M20.892 29.874h-8.78a1.5 1.5 0 000 3h8.78a1.5 1.5 0 100-3zM24.409 19.974H8.596a1.5 1.5 0 000 3H24.41a1.5 1.5 0 000-3zM27.977 10.001H5.028a1.5 1.5 0 100 3h22.949a1.5 1.5 0 100-3zM31.4.008H1.605a1.5 1.5 0 100 3H31.4a1.5 1.5 0 100-3z"></path></svg></span>
                                        </div>
                                        
                                    </div>
                                </div>   Level 4
                    </Info>                   
                </Item>
                <Item>EQUIPMENT
                    <Info>
                          Mat  
                    </Info>
                </Item>
            </Overview>
            <Description>
                <Explainations>
               <div dangerouslySetInnerHTML={{__html:params.description}}
    />
                  
                   </Explainations>
            </Description>

            <div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button  onClick={saveClasse}>PUBLISH</Button>

</div>
        </Container>
        
    )
}

export default ClasseV1
