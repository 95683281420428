import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from "react-router-dom";
import { mobile, computer,tablette } from "../responsive";





const Login = ({ads,setUser,socket,user, setConnected}) => {
    const navigate = useNavigate();





 
  useEffect(async () => {
    try{
        const myToken=await axios.get(ads+'api/users/account/me');
        // localStorage.setItem('user',JSON.stringify(myToken.data));
        // localStorage.setItem('userDetails',JSON.stringify(myToken.data));
        setUser(myToken.data);
        setConnected(true);
        socket.current.emit("addUser", myToken.data._id)
        socket.current.emit("addUserRoom", myToken.data._id)
        navigate('/profile/'+myToken.data._id);
      }
      catch(e) {
         console.log(e)
      } 
  },[])

  return (
    <div>

        </div>
  );
};

export default Login;