import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";


const Container = styled.div`
z-index:3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: auto;
  height: 200px;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h2`
font-weight:600;
  text-align: center;
  margin-top:30px;
`;


const Yes = styled.button`
  border: none;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: black;
  color: white;
`;

const No = styled.button`
  border: solid 1px black;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 18px;
margin-top: 30px;
margin-bottom: 30px;
  cursor: pointer;
  background-color: white;
  color: black;
  margin-right:50px;
`;


const DeletePost = ({user}) => {


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);










  return (
    <Container>
      <Wrapper>
        <Title>
          {/* Your video gallery is empty, to plublish a new Series or a new Classe you need to add videos to your gallery */}
          Votre Galerie est vide, veuillez y ajouter des vidéos avant de créer des Cours et des Séries
          </Title>
        <div style={{display:"flex",justifyContent:"center"}}>
        <Link to={"/profile/"+user._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <No >Fermer</No>
        </Link>
        <Link to="/videos" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Yes >Aller à ma Galerie</Yes>
        </Link>
        </div>

      </Wrapper>
    </Container>
  );
};

export default DeletePost;