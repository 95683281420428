import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile,tablette ,computer} from "../responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from "axios";
import { useState, useEffect} from "react";

const Container = styled.div`
width:100%;
height:100%;
background-color:white;
justify-content:right;

border:1px solid #dbdbdb;
align-items: center;

z-index:10;
position: fixed;
${tablette({ marginTop:"87vh",marginRight:"-120px" })};

`;
const Item = styled.div`
    width: 100%;
    align-items: center;
    height: 50px;
    display: flex;
    justify-content: left;
    cursor:pointer;
    :hover{
        background-color: #f3f7f9ab;
    }
`;
const Profilepicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  margin-left:20px;
  margin-right:20px;


`;

const Accept = styled.button`
margin-left: auto;
margin-right: auto;

display:flex;
    text-align: center;
    align-items: center;
    border: 1px solid #5f4fbb;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;
const Decline = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: 1px solid #5f4fbb;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;
const Notifications = ({ads,followAccepted, socket,user, newTeas,newSubscribers, newFollowers, setOpen}) => {
    const [accepted, setAccept] = useState([]);
    const [answered, setAnswered] = useState([]);

    const accept= async(item,j) => {

        const res= (await axios.put(ads+'api/connections/newfollowers/accept/'+user._id,
        {
                id:item.follower_id
            }
        ));
        var aa=[...accepted];
        aa[j]=true
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
        
        socket.current.emit("followAccepted", {
            
            follower: item.follower_id,
            following:user._id
         }
 )
    }
    const decline= async(item,j) => {
        const res= (await axios.put(ads+'api/connections/newfollowers/decline/'+user._id,{
            id:item.follower_id
        }));
        var aa=[...accepted];
        aa[j]=false
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
    }
    return (
<Container>
    <div style={{position:"relative"}}>
    <ArrowBackIosIcon style={{top:"22px",left:"15px",cursor:"pointer",position:"absolute"}} onClick={()=>setOpen(false)}/>

    <div style={{justifyContent:"center",alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                <div>            NOTIFICATIONS
                </div>
            </div>
    </div>


            {newTeas?.map((item) => (
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.buyer_profile_picture}/>
                    <div>
                        {item.currency==="eur"?
                        <div>
                    <span style={{fontWeight:"600"}}>{item.buyer_username}</span> vous a offert {item.number} Thés pour un total de {item.price*0.01}€.

                        </div>
                :
                <div>
                <span style={{fontWeight:"600"}}>{item.buyer_username}</span> vous a offert {item.number} Thés pour un total de ${item.price*0.01}.

                    </div>
                                        }

                                    </div>
                                </div>
            ))}
            {newSubscribers?.map((item) => (
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.subscriber_profile_picture}/>
                    <div>
                        {item.free?
                                                <div>
                                                <span style={{fontWeight:"600"}}>{item.budy_username}</span> s'est abonné à votre profil.
                            
                                                    </div>
                                                    :
                    

<div>
                        {item.currency==="eur"?
                        <div>
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a payé {item.price*0.01}€ pour s'abonner.

                        </div>
                :
                <div>
                <span style={{fontWeight:"600"}}>{item.budy_username}</span> a payé ${item.price*0.01} pour s'abonner.

                    </div>
                                        }
                                        </div>
}
                                    </div>
                                </div>
            ))}
            {newFollowers?.map((item,j) => (
                <div>
                {item.validated==true?
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.follower_profile_picture}/>
                    <div>
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a commencé à vous suivre.

                    </div>
                </div>:

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"90px",borderBottom:"1px solid lightgrey"}}>
                <Profilepicture src={ads+item.follower_profile_picture}/>
                <div>
                <span style={{fontWeight:"600"}}>{item.budy_username}</span> a demandé à vous suivre
                {accepted[j]==true?
                                <div style={{display:"flex", marginTop:"15px"}}>
                                <Accept >Accepté</Accept>
                                </div>
                :
                    null
                }
                {accepted[j]==false?
                <div style={{display:"flex", marginTop:"15px"}}>
                <Decline >Decliné</Decline>
                </div>
                :
                null}
                {answered[j]==true?
                null:
                <div style={{display:"flex", marginTop:"15px"}}>
                <Accept onClick={()=>accept(item,j)}>Accepter</Accept>
                <Decline onClick={()=>decline(item,j)}>Decliner</Decline>
                </div>
                }

                </div>
            </div>
        }
</div>
            ))}

{followAccepted?.map((item,j) => (
                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                    <Profilepicture src={ads+item.follower_profile_picture}/>
                    <div>
                       
                    <span style={{fontWeight:"600"}}>{item.budy_username}</span> a accepté votre demande de suivi.

                     

                    </div>
                </div>
            ))}
            {/* <Link to="/settings" style={{ color: 'inherit', textDecoration: 'inherit'}}></Link> */}
       </Container> 

    )
}

export default Notifications
