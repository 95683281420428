import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;
`;

const Wrapper = styled.div`
  width: 600px;
  height: auto;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h4`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;
  }
`;



const Picture = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  position:relative;
  border: 6px solid #f2f9fb;
  margin-left:auto;
  margin-right:auto;



`;

const UploadPP = ({ ads,setOpen, user }) => {
  const [img, setImg] = useState(undefined);
  const [imgURL, setImgUrl] = useState(undefined);
  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  
  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();


  useEffect(() => {

    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
 }, []);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };

  const onButton=()=>{
     buttonRef.current.click()
    
  }
  const changePP=(e)=>{
    setImg(e.target.files[0]);
    setImgUrl(URL.createObjectURL(e.target.files[0]))
    if(e.target.files[0].size>2000000){
        setTooLarge(true);
    }else{
        setTooLarge(false);
    }
    setshowImage(true)
  }

  const savePP= async()=>{
    setShowProgress(true)

    const formData = new FormData()
    formData.append('profile', img, img.name)
    await axios.post(ads+'api/profilepicture/profile/'+user._id, formData,
    {   
      onUploadProgress: progressEvent => {
        setProgress((progressEvent.loaded / progressEvent.total)*100)
          console.log(progressEvent.loaded / progressEvent.total);
      }
    })
    window.location.reload();
}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

  
  return (
    <Container>
      <Wrapper>
        <Title>MODIFIER VOTRE IMAGE DE PROFIL</Title>

        {(showImage==true)?
null
        :
        <div onClick={onButton} style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"60px",alignItems:"center", cursor:"pointer", color:"teal",fontWeight:"700"}}>
        IMPORTER UNE IMAGE
        </div>
        }


        {(showImage==true)?
        <div style={{display:"flex", flexDirection:"column"}}>
        <Picture src={imgURL}/>

        {!tooLarge?
        <SaveImage disabled={tooLarge} onClick={() => savePP()}>
            ENREGISTRER
        </SaveImage>

        :null}

        </div>
        :null}

        {showImage==true?
                <div onClick={onButton} style={{display:"flex", justifyContent:"center",height:"60px",alignItems:"center", cursor:"pointer", color:"teal",fontWeight:"700"}}>
                IMPORTER UNE AUTRE IMAGE
                </div>:null
        }
        <div style={{display:"none"}}>
        <Input
        type="file"
        accept="image/*"
        id='myfile'
        name="myfile"
        onChange={(e) => changePP(e)}
        ref={buttonRef}
        style={{ opacity: "0" }}
      />
        </div >

        {tooLarge?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
            < WarningAmberIcon style={{marginRight:"15px"}}/> Votre image doit être plus petite que 2Mo.
            </div>
            : null}
            {showProgress?
            <CircularProgressWithLabel value={progress} />
              :null}
        {/* <div style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"60px",alignItems:"center", cursor:"pointer", color:"#762532",fontWeight:"700"}}>
            REMOVE THE CURRENT PICTURE
        </div> */}
        <div onClick={() => setOpen(false)} style={{display:"flex", justifyContent:"center",borderTop: "1px solid #d3d3d3",height:"60px",alignItems:"center", cursor:"pointer", color:"black",fontWeight:"400"}}>
            Fermer
        </div>
      </Wrapper>
    </Container>
  );
};

export default UploadPP;



