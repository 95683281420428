import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile,tablette ,computer} from "../responsive";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from "axios";
import { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
width:100%;
height:100%;
background-color:white;
justify-content:right;

border:1px solid #dbdbdb;
align-items: center;

z-index:10;
position: fixed;
${tablette({ marginTop:"87vh",marginRight:"-120px" })};

`;
const Item = styled.div`
    width: 100%;
    align-items: center;
    height: 50px;
    display: flex;
    justify-content: left;
    cursor:pointer;
    :hover{
        background-color: #f3f7f9ab;
    }
`;
const Profilepicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #f2f9fb;
  margin-left:20px;
  margin-right:20px;


`;

const Accept = styled.button`
margin-left: auto;
margin-right: auto;

display:flex;
    text-align: center;
    align-items: center;
    border: 1px solid #5f4fbb;
  background-color: #5f4fbb;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;
const Decline = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: 1px solid #5f4fbb;
  background-color: white;
  color: #5f4fbb;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
    font-weight: 600;
/* :hover {
  transform:scale(1.03);
} */
${mobile({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${tablette({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}
${computer({fontSize:"14px", padding:"8px",width:"auto", height:"auto"})}

`;
const Notifications = ({ads,followAccepted, socket,user, newTeas,newSubscribers, newFollowers, setOpen}) => {
    const [accepted, setAccept] = useState([]);
    const [answered, setAnswered] = useState([]);
    const navigate = useNavigate();

    const logOut = async () =>{
        await axios.get(ads+'api/auth/logout')
    
        navigate('/signin')
        window.location.reload();
        }
    const accept= async(item,j) => {

        const res= (await axios.put(ads+'api/connections/newfollowers/accept/'+user._id,
        {
                id:item.follower_id
            }
        ));
        var aa=[...accepted];
        aa[j]=true
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
        
        socket.current.emit("followAccepted", {
            
            follower: item.follower_id,
            following:user._id
         }
 )
    }
    const decline= async(item,j) => {
        const res= (await axios.put(ads+'api/connections/newfollowers/decline/'+user._id,{
            id:item.follower_id
        }));
        var aa=[...accepted];
        aa[j]=false
        var bb=[...answered];
        bb[j]=true
        setAnswered(bb)
        setAccept(aa)
    }
    return (
<Container>
    <div style={{position:"relative"}}>
    <ArrowBackIosIcon style={{top:"22px",left:"15px",cursor:"pointer",position:"absolute"}} onClick={()=>setOpen(false)}/>

    <div style={{justifyContent:"center",alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey"}}>
                <div>            PARAMÈTRES
                </div>
            </div>
    </div>


    <Link to="/settings" style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey", paddingLeft:"30px"}}>
                    

                    <span style={{fontWeight:"600"}}>Editer mon Profil</span>

                        
                
                </div>
    </Link>
                <Link to="/savecard" style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey", paddingLeft:"30px"}}>
                    

                    <span style={{fontWeight:"600"}}>Mes Cartes</span>

                        
                
                </div>
                </Link>
                {user.teacher?
                <Link to="/banksubscription" style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <div  style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey", paddingLeft:"30px"}}>
                    

                    <span style={{fontWeight:"600"}}>Banque&Abonnement</span>

                        
                
                </div>
                </Link>
                :null}

                <div onClick={() => logOut()} style={{alignItems:"center",display:"flex", width:"100%", height:"70px",borderBottom:"1px solid lightgrey", paddingLeft:"30px"}}>
                    

                    <span style={{fontWeight:"600"}}>Se Deconnecter</span>

                        
                
                </div>

            {/* <Link to="/settings" style={{ color: 'inherit', textDecoration: 'inherit'}}></Link> */}
       </Container> 

    )
}

export default Notifications
