import React from 'react'
import styled from 'styled-components'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { useState, useEffect} from "react";
import NewConversation from "./NewConversation";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { mobile,tablette,computer } from "../responsive";
import { Link } from "react-router-dom";


const Container = styled.div`
display:flex;
align-items: center;
background-color: white;
height:50px;
padding: 10px;
justify-content: space-between;
color:black;
border-bottom: 1px solid lightgrey;
${mobile({marginBottom: "10px"})};
`;
const Arr = styled.div`
${computer({display:"none"})};
${tablette({display:"none"})};

`;
const Navbar=({ads,user, setSelected, selected})=> {
    const [open, setOpen] = useState(false);

    return (
        <>
        <Container>
<div>

            <Arr>
            <Link to="/home" style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <ArrowBackIosIcon />
            </Link>
            </Arr>
            </div>
            <div style={{display:"flex", justifyContent:"center", textAlign:"center"}}>
            <span style={{fontWeight:"bold", letterSpacing:"2px"}}>MESSAGES</span>
            </div>
            <AddCommentOutlinedIcon onClick={() => setOpen(true)} style={{fontSize:"28px", cursor:"pointer"}}/>
        </Container>
        {open && <NewConversation ads={ads} setOpen={setOpen} user={user} setSelected1={setSelected} selected1={selected}/>}

        </>
    )
}

export default Navbar