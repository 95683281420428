import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import { CheckOutlined } from "@mui/icons-material";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;

`;

const Wrapper = styled.div`
  ${mobile({width:"100%"})}
    border-radius:5px;
  width: 450px;
  height: auto;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 100px;
object-fit: cover;



`;
const Profilepicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  position:absolute;
  border: 1px solid #f2f9fb;
  top:50px;
  left:20px;



`;
const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"13px"})}
float:left;
margin-left:7px;
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px"})}


`;
// const Subscribe = styled.button`
// margin-left: auto;
// margin-right: auto;

// display:block;
//     text-align: center;
//    border: none;
//   background-color: #5f4fbb;
//   color: white;
//   cursor: pointer;
//   width:auto;
//   padding: 20px;
//   border-radius: 50px;
//   height:auto;
//     align-items: center;
//     transition:transform .2s ease;
// :hover {
//   transform:scale(1.03);
// }
// ${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
// ${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
// ${computer({fontSize:"16px", padding:"8px", marginTop:"15px",width:"65%", height:"50px"})}

// `;

const Text1 = styled.h2`
letter-spacing: 2px;
${mobile({fontSize:"13px"})}
${tablette({fontSize:"14px"})}
${computer({fontSize:"14px", marginRight:"2vw"})}

margin-right:7px;
float:right;
word-spacing: 0.5px;


`;


const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"35px",width:"75%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"35px",width:"75%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"25px",width:"350px", height:"45px"})}

`;


const Subscription = styled.div`
height: auto;
width:100%;
align-items: center;
justify-content: center;
flex-direction: column;
margin-bottom:4vw;
${mobile({marginTop:"25px",borderBottom:"2px solid #ececec", height:"105px",marginBottom:"0px"
})}
${tablette({marginTop:"25px",borderBottom:"2px solid #ececec", height:"125px",marginBottom:"0px"
})}
${computer({marginTop:"0px",borderBottom:"2px solid #ececec", height:"125px",marginBottom:"0px"
})}
`;
const Sub = styled.div`
color:#8a96a3;
font-size:13px;
font-weight: 700;
letter-spacing: 1.5px;
margin-top:10px;
${tablette({marginTop:"15px"})}
${mobile({fontSize:"12px"})}

margin-left:10px;
`;
const SubscriptionB = styled.div`
height: auto;
width:100%;
align-items: center;
justify-content: center;
flex-direction: column;
${mobile({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
${tablette({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
${computer({borderBottom:"2px solid #ececec", height:"auto",paddingBottom:"30px"
})}
`;

const ChooseSubscription = ({ads,socket,  setOpen, owner, customer,user}) => {
  const [img, setImg] = useState(undefined);
  const [frequency, setFrequency] = useState(undefined);

  const [imgURL, setImgUrl] = useState(undefined);
  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();
  const [Month, setMonth] = useState(null);
  const [ThreeMonth, setThreeMonth] = useState(null);
  const [SixMonth, setSixMonth] = useState(null);
  const [Year, setYear] = useState(null);
  const [currency, setCurrency] = useState(null);

  useEffect( async() => {
    const pp=(await axios.get(ads+'api/checkout/subscriptionprice/'+owner._id)).data;
    setMonth(pp.oneMonth) 
    setThreeMonth(pp.threeMonth) 
    setSixMonth(pp.sixMonth) 
    setYear(pp.oneYear) 
    setCurrency(pp.currency) 

  });
 const Checkout= async(vv)=>{
    var body;
    var price;
    var periode;
    if(vv==0){
        periode="month"
        price=Month;
         body =  {items: [
            { id: 1, quantity: 3 },
          ],
          buyer:user._id
        }
    }
    if(vv==1){
        periode="3month"

        price=ThreeMonth
         body =  {items: [
            { id: 2, quantity: 3 },
          ],
          buyer:user._id
        }
    }
    if(vv==2){
        periode="6month"

        price=SixMonth
         body =  {items: [
            { id: 3, quantity: 3 },
          ],
          buyer:user._id
        }
    }
    if(vv==3){
        periode="year"

        price=Year;
         body =  {items: [
            { id: 4, quantity: 3 },
          ],
          buyer:user._id
        }
    }

   const check=await axios.post(ads+'api/checkout/subscription/'+owner._id, body);
   if(check.data.payment=="succeeded"){
     socket.current.emit("newSubscriber", {subscriber:user._id, subscribing:owner._id})

     window.location.reload();

   }else if(check.data.payment=="wait"){
     localStorage.setItem('subscriptioninfo',JSON.stringify({"currency":currency,"periode":periode, "creatorId":owner._id, "price":price, "creatorName": owner.username}));

     navigate('/payands');

   }
}

  




  return (
    
    <Container>
        <Wrapper>

        {/* <h4 style={{ color:"#8a96a3", marginLeft:"20px"}}>
           SELECTION YOUR SUBSCRIPTION OPTION
        </h4> */}

        <Subscription>
                    <Sub>
                    ABONNEMENT
                    </Sub>


                    {/* <Subscribe onClick={() => subscribe()}> */}

 <Subscribe onClick={() => Checkout(0)}>
                    <Text >S'ABONNER</Text>
                        {currency=="U.S. Dollar"&&Month!=""?
                        <Text1 >{"$" +Month+ "  par mois"}</Text1>
                        :null}
                        {currency=="Euro"&&Month!=""?
                        <Text1 >{Month+ "€  par mois"}</Text1>
                        :null}

                        {Month==""?
                        <Text1 >GRATUITEMENT</Text1>
                        :null}
                </Subscribe>





                </Subscription>

                <SubscriptionB>
                    <div style={{display:"flex"}}>
                    <Sub>
                    OFFRES D'ABONNEMENT
                    </Sub>
 

                    </div>

                
                <div>
                {ThreeMonth!=""?
                <Subscribe onClick={() => Checkout(1)}>
                <Text >3 MOIS</Text>
                {currency=="U.S. Dollar"&&ThreeMonth!=""?
                <Text1 >{"$" +ThreeMonth+ " au total"}</Text1>
                :null}
                {currency=="Euro"&&ThreeMonth!=""?
                <Text1 >{ThreeMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                
                :null}


                
                {SixMonth!=""?
                <Subscribe onClick={() => Checkout(2)}>
                <Text >6 MOIS</Text>
                {currency=="U.S. Dollar"?
                <Text1 >{"$" +SixMonth+ "au total"}</Text1>
                :null}
                {currency=="Euro"?
                <Text1 >{SixMonth+ "€ au total"}</Text1>
                :null}
                </Subscribe>
                :null}







                {Year!=""?
                <Subscribe onClick={() => Checkout(3)}>
                <Text >1 AN</Text>
                {currency=="U.S. Dollar"?
                <Text1 >{"$" +Year+ "au total"}</Text1>
                :null}
                {currency=="Euro"?
                <Text1 >{Year+ "€ au total"}</Text1>
                :null}

                </Subscribe>
                :null}





                </div>
                
                </SubscriptionB>


                <div style={{borderTop: "1.5px solid #ececec", height:"40px", alignItems:"center", display:"flex",float:"right"}}>
                    <h4 onClick={() => setOpen(false)} style={{cursor:"pointer",color:"#8798ef", float:"right", marginLeft:"auto", marginRight:"20px"}}>
                    FERMER
                    </h4>
                    
                </div>
        </Wrapper>

    </Container>

  );
};

export default ChooseSubscription;



