import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile,tablette ,computer} from "../responsive";
import axios from "axios";
import { useNavigate } from 'react-router-dom';



const Container = styled.div`
width:200px;
height:auto;
background-color:white;
justify-content:right;
float: right;
margin-right:20px;
border:1px solid #dbdbdb;
align-items: center;
bottom:70px;
left:20px;
z-index:10;
position:fixed;
${tablette({ marginTop:"75vh",marginRight:"-120px" })};

`;
const Item = styled.div`
    width: 100%;
    align-items: center;
    height: 50px;
    display: flex;
    justify-content: left;
    cursor:pointer;
    :hover{
        background-color: #f3f7f9ab;
    }
`;

const Settings = ({ads,user}) => {
    const navigate = useNavigate();


    const logOut = async () =>{
    await axios.get(ads+'api/auth/logout')

    navigate('/signin')
    window.location.reload();
    }
    



    return (
<Container>
        {/* <Link to="/profile" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Item>
            
            
                <div style={{marginLeft:"10px"}}>
                PROFILE

                </div>
            
            </Item>
        </Link>
        <Link to="/videos" style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Item>
<div style={{marginLeft:"10px"}}>

    MY VIDEOS
    </div>

</Item>
</Link> */}
    {user.teacher?
        <Link to="/banksubscription" style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <Item>
        <div style={{marginLeft:"10px"}}>

            BANQUE& <br/>
            ABONNEMENT
            </div>

        </Item>
        </Link>
:null}
        <Link to="/savecard" style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Item>
<div style={{marginLeft:"10px"}}>

MES CARTES
    </div>

</Item>
</Link>
        <Link to="/settings" style={{ color: 'inherit', textDecoration: 'inherit'}}>

        <Item>
        <div style={{marginLeft:"10px"}}>

            PARAMÈTRES
            </div>

        </Item>
        </Link>
        <Item onClick={() => logOut()}>
        <div style={{marginLeft:"10px"}}>

            DÉCONNECTION
            </div>

        </Item>
</Container>
    )
}

export default Settings
