import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from "react-router-dom";
import { mobile, computer,tablette } from "../responsive";
import translations from '../components/translations'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';
import { Crisp } from "crisp-sdk-web";
import { useLocation } from "react-router-dom";


const Container = styled.div`
  width: 100vw;

  min-height: calc(100vh - 63px);
  /* background: linear-gradient(
      rgba(104,146,181, 0.5),
      rgba(104,146,181, 0.5)
    ),
    url("./signup.svg")
      center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  ${mobile({ background:"none",minHeight: "calc(100vh - 100px)" })};
  ${tablette({ alignItems:"center" })};

  ${computer({ alignItems:"center" })};
`;

const Wrapper = styled.div`
  padding: 20px;
  border-radius:10px;
  border:1px solid lightgrey;

  background-color: white;
  ${mobile({ width:"100%", borderRadius:"0px",border:"none"})};
  ${tablette({ width:"450px", marginTop:"40px", marginBottom:"40px" })};
  ${computer({ width:"450px", marginTop:"40px", marginBottom:"40px" })};

`;

const Question = styled.h4`
margin-top:45px;
margin-bottom:15px;
text-align: center;

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align:center;
  margin-bottom:30px;
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"5px" })}
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: 1.5px solid lightgrey;



  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
  }

`;


const Agreement = styled.div`
display: flex;
margin-top:25px;
margin-bottom:-5px;

`;

const Button = styled.button`
  border: none;
  width: 100%;
  border-radius: 10px;

  padding: 15px 20px;
  background-color:#8798ef;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
  margin-bottom: 30px;
  :disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
  ${mobile({ width:"100%"})};



`;
const Ask = styled.button`
  width: 35%;
  border: 1px solid #8894d3;
  border-radius:6px;
  padding: 15px 20px;
  background-color:white ;
  color: #8894d3;
  cursor: pointer;
  font-weight: 600;
  ${mobile({ width:"40%", padding:"15px 15px" })}

  margin-bottom: 30px;

`;

const Answer = styled.button`
  width: 35%;
  border-radius: 6px;
  border: 1px solid #8798ef;
  padding: 15px 20px;
  background-color:#8798ef ;
  color:white;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 30px;
  ${mobile({ width:"40%", padding:"15px 15px" })}

`;

const ErrorSpace = styled.div`
color:red;
align-items:center;
display:flex;
margin-left:5px;
margin-top:-5px;
font-size:12px;
${mobile({ marginTop:"0" })}

`;

const Signup = ({ads,setUser, setConnected}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isdisable, setIsdisable] = useState(true);

    const [hiddenA, setHiddenA] = useState(1);
    const [hiddenB, setHiddenB] = useState(0);
    const [teacher, setTeacher] = useState(false);
    const [error, setError] = useState("");
    const [thechecked, setcheck] = useState(false);
    const [errorSpace, setErrorSpace] = useState(false);

    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState('');

    useEffect(() => {

      if(name!=""&&username!=""&&thechecked&&!errorSpace){
        setIsdisable(false);
      }else{
        setIsdisable(true);
      }

  },[name,username,password,thechecked])


  useEffect(() => {
    // Configure Crisp with your website ID
    Crisp.configure("2d6d2532-f0ac-4197-a2f9-523c25b55747");

    // Clean up Crisp when the component is unmounted
    return () => {
      // Clean up or perform any necessary actions
    };
  }, []);




  function handleChange(e) {


    setcheck(!thechecked);


  }



    const handleCPassword = (e) => {

        setCPassword(e.target.value);


    }

    const CreateAccount = async (e) =>{
        e.preventDefault();
        try{
            await axios.post(ads+'api/auth/registerafterinvitation',{ 
                name:name.trim(),
                lastname:name.trim(),
                username: username.trim(), 
                invitationId: id, 
                password: password,
                teacher:false
                 });

            localStorage.setItem('invitationId',id);





            navigate('/validatepraticien');
        }catch(e) {
            setError(s=>e.response.data);
        }

    }

  return (
    <div>
    <Container>
      <Wrapper>
        
        <Title>
        <h2 style={{letterSpacing: "1.0px"}}>OmCare.</h2>

        </Title>
        <Form>
          <Input placeholder={translations["français"]["name"]}
          onChange={(e) => setName(e.target.value)}/>
          {/* <Input placeholder={translations["français"]["last name"]} 
          onChange={(e) => setLastname(e.target.value)}/> */}
          <Input placeholder={translations["français"]["username"]} 
  style={{ border: errorSpace ? "2px solid #f79696" : "1.5px solid lightgrey",     
  ":focus": {
    outline: errorSpace ? "1.5px solid #f79696" : "2.5px solid #b3c9f9"
  }
}}
          onChange={(e) => {
            setUsername(e.target.value);
            if(e.target.value.includes(" ")){
              setErrorSpace(true)
            }else{
              setErrorSpace(false)
            }
            }}/>
                      {(errorSpace!="")?
            <ErrorSpace>
               Le nom d'utilisateur ne peut contenir d'espaces.
            </ErrorSpace>
                : null}

          <Input type="password" placeholder={translations["français"]["password"]} 
          onChange={(e) => setPassword(e.target.value)}/>
          {/* <Input type="password" placeholder={translations["français"]["confirm password"]}  onChange={handleCPassword}/> */}
          </Form>
          {/* {showErrorMessage  ? <div>{translations["français"]['Passwords do not match']}  </div> : ''} */}



          <Agreement>
          <input style={{width:"30px", height:"20px", cursor:"pointer"}}  type="checkbox" 
            
           
            onChange={(e) => handleChange(e)}
            checked={thechecked} 
            />
            <div style={{marginLeft:"15px", fontSize:"14px", color:"#464646"}}>
            En vous inscrivant, vous déclarez avoir lu et accepté nos 
                     <Link to={"/conditionsgenerales"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

          <span style={{ color:"#2a2a9d", cursor:"pointer", fontWeight:"600"}}> Conditions générales d'utilisation </span>
        </Link> 
             et notre 
             <Link to={"/politiqueconfidentialite"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<span style={{ color:"#2a2a9d", cursor:"pointer",fontWeight:"600"}}> Politique de confidentialité.</span>
</Link>
             

            </div>
          </Agreement>
          <Form>
          <Button onClick={CreateAccount} disabled={isdisable} >
          <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['CREATE MY ACCOUNT']}</h4>
          </Button>
          </Form>

          {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}
{/* <div >
<Link to="/signin" style={{margin: "5px 0px",fontSize: "12px",color:"black"}}>{translations["français"]['ALREADY HAVE AN ACCOUNT? LOGIN']}</Link>

</div> */}

      </Wrapper>
    </Container>
        <Footer/>
        <FooterS/>

        </div>
  );
};

export default Signup;