import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from "react";
import axios from "axios";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from "react-router-dom";
import { mobile, computer,tablette } from "../responsive";
import translations from '../components/translations'
import Footer from '../components/Footer';
import FooterS from '../components/FooterSmart';
import { Crisp } from "crisp-sdk-web";


const Container = styled.div`
  width: 100vw;

  min-height: calc(100vh - 63px);
  /* background: linear-gradient(
      rgba(104,146,181, 0.5),
      rgba(104,146,181, 0.5)
    ),
    url("./signup.svg")
      center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  ${mobile({ background:"none",minHeight: "calc(100vh - 100px)" })};
  ${tablette({ alignItems:"center" })};

  ${computer({ alignItems:"center" })};
`;

const Wrapper = styled.div`
  padding: 20px;
  border-radius:10px;
  border:1px solid lightgrey;

  background-color: white;
  ${mobile({ width:"100%", borderRadius:"0px",border:"none"})};
  ${tablette({ width:"450px", marginTop:"40px", marginBottom:"40px" })};
  ${computer({ width:"450px", marginTop:"40px", marginBottom:"40px" })};

`;

const Question = styled.h4`
margin-top:45px;
margin-bottom:15px;
text-align: center;

`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align:center;
  margin-bottom:30px;
  margin-top: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${mobile({ marginTop:"5px" })}
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: 1.5px solid lightgrey;
  width: 76%;
  margin-left: auto;
  margin-right: auto;


  :focus {
    border: 1.5px solid #b3c9f9;

    outline:2.5px solid #b3c9f9;
  }

`;


const Agreement = styled.div`
display: flex;
margin-top:25px;
margin-bottom:-5px;

`;

const Button = styled.button`
  border: none;
  width: 80%;
  border-radius: 10px;

  padding: 15px 20px;
  background-color:#8798ef;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
  margin-bottom: 30px;
  :disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
  ${mobile({ width:"80%"})};



`;
const Ask = styled.button`
  width: 35%;
  border: 1px solid #8894d3;
  border-radius:6px;
  padding: 15px 20px;
  background-color:white ;
  color: #8894d3;
  cursor: pointer;
  font-weight: 600;
  ${mobile({ width:"40%", padding:"15px 15px" })}

  margin-bottom: 30px;

`;

const Answer = styled.button`
  width: 35%;
  border-radius: 6px;
  border: 1px solid #8798ef;
  padding: 15px 20px;
  background-color:#8798ef ;
  color:white;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 30px;
  ${mobile({ width:"40%", padding:"15px 15px" })}

`;

const ErrorSpace = styled.div`
color:red;
align-items:center;
display:flex;
margin-left:5px;
margin-top:-5px;
font-size:12px;
${mobile({ marginTop:"0" })}

`;


const Login = ({ads,setUser,socket,user, setConnected}) => {
    const navigate = useNavigate();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isdisable, setIsdisable] = useState(true);

    const [hiddenA, setHiddenA] = useState(1);
    const [hiddenB, setHiddenB] = useState(0);
    const [teacher, setTeacher] = useState(false);
    const [error, setError] = useState("");
    const [thechecked, setcheck] = useState(false);
    const [errorSpace, setErrorSpace] = useState(false);

    const [name, setName] = useState("");

    const [cPassword, setCPassword] = useState('');




  useEffect(() => {
    // Configure Crisp with your website ID
    Crisp.configure("2d6d2532-f0ac-4197-a2f9-523c25b55747");

    // Clean up Crisp when the component is unmounted
    return () => {
      // Clean up or perform any necessary actions
    };
  }, []);



  


  function handleChange(e) {


    setcheck(!thechecked);


  }
    const showA = (number) =>{
        if(hiddenA===0 &&hiddenB===1){
            setHiddenA(s => 1);
            setHiddenB(s => 0);
            setTeacher(s => false);
        }else if(hiddenA===1 &&hiddenB===0) {
            setHiddenA(s => 0);
            setHiddenB(s => 1)
            setTeacher(s => true);

        };
    }
    const showB = (number) =>{
        if(hiddenA===0 &&hiddenB===1){
            setHiddenA(s => 1);
            setHiddenB(s => 0);
            setTeacher(s => false);

        }else if(hiddenA===1 &&hiddenB===0) {
            setHiddenA(s => 0);
            setHiddenB(s => 1);
            setTeacher(s => true);

        };    }

    const handleCPassword = (e) => {

        setCPassword(e.target.value);


    }

    const CreateAccount = async (e) =>{
        e.preventDefault();
        try{
            const myToken = await axios.put(ads+'api/users/'+user._id,{ 
                rpps:name.trim(),
                teacher:teacher,
                 });
            setUser(myToken.data);

            navigate('/profile/'+user._id);        
        }catch(e) {
            setError(s=>e.response.data);

        }

    }

  useEffect(async () => {
    try{
        const myToken=await axios.get(ads+'api/users/account/me');
        // localStorage.setItem('user',JSON.stringify(myToken.data));
        // localStorage.setItem('userDetails',JSON.stringify(myToken.data));
        setUser(myToken.data);
        setConnected(true);
        socket.current.emit("addUser", myToken.data._id)
        socket.current.emit("addUserRoom", myToken.data._id)
        //navigate('/profile/'+myToken.data._id);
      }
      catch(e) {
         console.log(e)
      } 
  },[])

  return (
    <div>
    <Container>
      <Wrapper>
        
      <Title>
        <h2 style={{letterSpacing: "-1.0px", fontWeight:"900", color:"rgb(126 145 241)", fontSize:"40px"}}>
          <span style={{color:"rgb(26, 186, 185)"}}>Om</span>Care.</h2>
        </Title>
 
          <Question>
          {translations["français"]['I AM HERE TO']}
          </Question>

          <div style={{display:"flex",justifyContent:"space-evenly"}}>
            {(hiddenA===0)?
            <Ask onClick={() => showA(0)}>
            <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['LEARN YOGA']}</h4>
            </Ask>
                : null}
                {(hiddenA===1)?
            <Answer onClick={() => showA(1)}>
            <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['LEARN YOGA']}</h4>
            </Answer>
                : null}

            {(hiddenB===0)?
            <Ask onClick={() => showB(0)}>
            <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['TEACH YOGA']}</h4>
            </Ask>
                : null}

                {(hiddenB===1)?
            <Answer onClick={() => showB(1)}>
            <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['TEACH YOGA']}</h4>
            </Answer>
                : null}


          </div>
        {hiddenB===1?
            <Form>
            <Input placeholder={"Numéro RPPS"}
            onChange={(e) => setName(e.target.value)}/>
            </Form>:
          null}
          <Form>
          <Button onClick={CreateAccount} >
          <h4 style={{letterSpacing: "1.4px"}}>{translations["français"]['CREATE MY ACCOUNT']}</h4>
          </Button>
          </Form>

          {(error!="")?
            <div style={{color:"red",textAlign:"center",alignItems:"center", display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < WarningAmberIcon style={{marginRight:"15px"}}/> {error}
            </div>
                : null}


      </Wrapper>
    </Container>
        <Footer/>
        <FooterS/>

        </div>
  );
};

export default Login;