import React, { useEffect, useState,  useRef  } from "react";
import styled from 'styled-components'
import Articles from '../components/Articles'
import Coverpicture from '../components/Coverpicture'
import Filter from '../components/Filter'
import Navbar from '../components/Navbar'
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'

import Series from '../components/Series'
import Tabs from '../components/Tabs'
import TabsS from '../components/TabsS'

import Videos from '../components/Videos'
import Posts from '../components/Posts'
import { mobile,tablette,computer } from "../responsive";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;
const Profile = ({ads,setUser,setNM, nM, socket, user,connected}) => {

    const [teacher, setTeacher] = useState({});

    const [tab, setTab] = useState(0);

    useEffect(async() => {
        try { 

            setTeacher(user);
        }catch(e){


        }
  
    },[])

    return (

        <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    
                    <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

                </BottomNav>
                <Coverpicture ads={ads} user={user}/>

{teacher.teacher?
                <Tabs ads={ads} setTab={setTab} style={{zIndex:"10",display:"block"}}/>
:
<TabsS setTab={setTab}/>
}

                {/* {tab==1||tab==2?
                <Filter/>
                :null

} */}
{(tab==0&&teacher._id)?

                <Posts ads={ads} connected={connected} user={user} owner={teacher}/>
                :null}
{(tab==1&&teacher._id)?

                <Videos ads={ads} user={user} owner={teacher}/>
                :null}
{(tab==2&&teacher._id)?

                <Series ads={ads} user={user} owner={teacher}/>
                :null}
{(tab==3&&teacher._id)?

                <Articles ads={ads} user={user} owner={teacher}/>:null}
                
            </ContainerRight>

        </div>

    )
}

export default Profile
