import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import './App.css';
import { useState, useEffect} from "react";
import axios from "axios";
import { mobile,tablette,computer } from "../responsive";


const Container = styled.div`
  padding: 20px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
    ${mobile({marginTop:"70px"})}

`;



const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 60px;

`;

const Filtername =styled.h4`
    letter-spacing: 1.5px;
    font-weight:500;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;

    &:hover {
        font-weight: 700;
    }
`;

const FilternameS =styled.h4`
    letter-spacing: 1.5px;
    font-weight:700;
    cursor: pointer;
    display:flex;
    align-items: center;
    color: black;
    cursor:pointer;
    width:10%;
    text-align: center;
`;


const Containerfilters = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  height: 5vh;
  align-items: center;
  width: 83%;
  margin-left: auto;
  margin-right:auto;
  margin-top:-20px;
`;





const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`



const Input = styled.input`
  border: 0.7px solid black;
  font-size: 38px;
    letter-spacing: 1.5px;
    font-weight: 700;
  padding: 10px;
  background-color: transparent;

`;
const Label = styled.label`
  font-size: 18px;
`;
const Wrapper2 = styled.div`
  height: auto;
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Desc = styled.textarea`
  border: 1px solid black;
  
  padding: 10px;
  background-color: transparent;
  font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
`;

const InputT = styled.input`
  border: 1px solid black;
  width:280px;
  padding: 10px;
  margin-left: 20px;
  background-color: transparent;

`;

const Image = styled.img`
width:640px;
height: 360px;
object-fit: cover;

`;
const Button = styled.button`
  width: 250px;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
`;

const Select = styled.select`
  padding: 10px;
  margin-left: 20px;
  font-size:19px;
`;
const Option = styled.option`
height:20px;
`;

const InputContainer1 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  width: 50%;
`;

const PostparametersM = ({ads,user}) => {
    const navigate = useNavigate();

    const [creator, setCreator] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState(undefined);
    const [cover, setCover] = useState(undefined);

    const [video, setVideo] = useState(undefined);
    const [privacy, setPrivacy] = useState("Public");
    const [theArray, setTheArray] = useState([]);
    const editorRef = useRef(null);
    const [post, setPost] = useState({});
    const [isVisible, setVisibility] = useState("");


    const location = useLocation();
    const id = location.pathname.split("/")[2];


    useEffect(async() => {

        try { 

            setCreator(user);
            const Post=(await axios.get(ads+'api/post/'+id)).data;
            setPost(Post);
            setPrivacy(Post.privacy)
            if(Post.epingle=="0"){
              setVisibility(true)
            }
        }catch(e){


        }
  
    },[])

    const createPost= async()=>{
      var course = {
        "description":editorRef.current.getContent(),
        "privacy":privacy,
        "epingle":""
      }
      if(isVisible==true){
        course = {
          "description":editorRef.current.getContent(),
          "privacy":privacy,
          "epingle":"0"
        }
      }


    const id = (await axios.put(ads+"api/post/"+post._id,course)).data

    navigate('/profile/'+user._id);

    }




    


    return (
        
        <Container>
            <h2 style={{textAlign:"center"}}>
    Modifier votre Post
</h2>
            <Wrapper2>


<Label>Description :</Label>



<Editor
        apiKey={process.env.REACT_APP_TINY_KEY}
        initialValue={post.description}

        onInit={(evt, editor) => editorRef.current = editor}

        init={{
          placeholder: "Description",
          height: 400,
          width:'100%',
          menubar: false,
          plugins: [
            'anchor ',
            'emoticons',
            'lists',
            'link ',
            
          ],
          toolbar: ' undo redo|emoticons | forecolor |bold italic underline strikethrough |numlist bullist indent outdent | link | align lineheight |blocks fontfamily fontsize|  charmap | removeformat',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'


        }}
      />

<br/>
{user.teacher?
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de Confidentialité :</Label>
            <Select name="difficulty" value={privacy} onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
            <Option>Subscribers</Option>
          </Select>
</div>
:
<div style={{display:"flex", alignItems:"center"}}>
        <Label>Niveau de Confidentialité :</Label>
            <Select name="difficulty" value={privacy} onChange={(e) => setPrivacy(e.target.value)}>
            <Option>Public</Option>
            <Option>Followers</Option>
          </Select>
</div>}

<br/>
<div style={{display:"flex", alignItems:"center"}}>

            <Label>Épingler ce poste :</Label>
            <InputContainer1>


<label className="switch" >
    <input type="checkbox" checked={isVisible} onClick={() => setVisibility(!isVisible)}/>
    <span className="slider"></span>
</label>


</InputContainer1>
</div>
            </Wrapper2>






<div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={createPost} >ENREGISTRER</Button>

</div>



           
        </Container>
        
    )
}

export default PostparametersM
