import styled from "styled-components";
import { categories, categories2 , categories3,categories4, categories5 , categories6,categories7} from "../data";
import CategoryItem from "./CategoryItem";
import { mobileLanding,tabletteLanding, computerLanding } from "../responsive";
import { useState, useEffect} from "react";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  padding: 20px;
  height:auto;
  justify-content: space-between;
  ${mobileLanding({flexDirection:"column"})}
  flex-direction: column;
  
`;
const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
  margin-top:30px;


`; 

const Subscribe1 = styled.button`
display:block;
    text-align: center;
   border:2px solid #8798ef;
   /* 8493ff/8c9fff/8ca5ff/889bf9 */
  background-color: white;
  /* background-color: #5f4fbb; */

  color: #8798ef;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobileLanding({fontSize:"14px", padding:"8px", marginTop:"15px",width:"85%", height:"35px", marginLeft:"auto", marginRight:"auto"})}
${tabletteLanding({fontSize:"16px", padding:"8px", marginTop:"65px",width:"55%", height:"45px", marginLeft:"auto", marginRight:"auto",marginBottom:"4vh"})}
${computerLanding({marginBottom:"40px",marginTop:"60px",letterSpacing:"0.9px",fontSize:"17px", fontWeight:"600", padding:"8px", width:"90%", height:"50px",marginLeft:"auto", marginRight:"auto"})}

`;




const Categories = ({ads}) => {

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  return (
    <div>
    {window.screen.width<=680?
      <Container>
        
      {categories.map((item) => (
        <CategoryItem ads={ads} item={item} key={item.id} />
      ))}
       {show1?
       <div style={{display:"flex", flexDirection:"column"}}>
       {categories2.map((item) => (
        <CategoryItem  ads={ads} item={item} key={item.id} />
      ))}
      </div>
      :null
       }

       {show2?
       <div style={{display:"flex", flexDirection:"column"}}>
       {categories3.map((item) => (
        <CategoryItem  ads={ads} item={item} key={item.id} />
      ))}
      </div>
      :null
       }
       {!hideButton?
      <Button onClick={() => {
        if(!show2&&show1){
          setShow2(true)
          setHideButton(true)
        }
        if(!show1){
          setShow1(true)
        }

      }}>VOIR PLUS DE CATÉGORIES</Button>
:
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Subscribe1>S'INSCRIRE</Subscribe1>
</Link>
}
    </Container>
    :
    <Container >
          <div style={{display:"flex"}}>

    {categories4.map((item) => (
      <CategoryItem  ads={ads} item={item} key={item.id} />
    ))}
    </div>

     <div style={{display:"flex"}}>
     {categories5.map((item) => (
      <CategoryItem  ads={ads} item={item} key={item.id} />
    ))}
    </div>


     {show1?
     <div style={{display:"flex"}}>
     {categories6.map((item) => (
      <CategoryItem  ads={ads} item={item} key={item.id} />
    ))}
    </div>
    :null
     }

{show2?
     <div style={{display:"flex"}}>
     {categories7.map((item) => (
      <CategoryItem  ads={ads} item={item} key={item.id} />
    ))}
    </div>
    :null
     }
     {!hideButton?
    <Button onClick={() => {
      if(!show2&&show1){
        setShow2(true)
        setHideButton(true)
      }
      if(!show1){
        setShow1(true)
      }

    }}>VOIR PLUS DE CATÉGORIES</Button>
:
<Link to={"/signup"} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Subscribe1>S'INSCRIRE</Subscribe1>
</Link>

}
  </Container>

    }
</div>
  );
};

export default Categories;