import SendIcon from '@mui/icons-material/Send';
import styled from "styled-components";
import { mobileLanding,tabletteLanding, computer } from "../responsive";
import axios from "axios";
import { useState, useEffect} from "react";
import DoneIcon from '@mui/icons-material/Done';

const Container = styled.div`
  height: 30vh;
  background-color: #fcf5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${tabletteLanding({height:"25vh"})}

`;
const Title = styled.h1`
  font-size: 50px;
  margin-bottom: 20px;
  ${mobileLanding({fontSize: "26px"})}
  ${tabletteLanding({fontSize: "40px"})}

`;

const Desc = styled.p`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  ${mobileLanding({fontSize: "16px", width:"95%", marginLeft:"auto", marginRight:"auto", textAlign:"center"})}
  ${tabletteLanding({fontSize: "20px", width:"95%", marginLeft:"auto", marginRight:"auto", textAlign:"center"})}

`;

const InputContainer = styled.div`
  width: 50%;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  display: ${props => props.isHidden ? 'none' : 'flex'};
  ${mobileLanding({width: "75%"})}

`;

const Input = styled.input`
  border: none;
  flex: 8;
  padding-left: 20px;

`;
const Messa = styled.div`
  font-size: 19px;
  ${mobileLanding({fontSize: "14px"})}

  display: ${props => props.isHidden ? 'flex':'none'  };

`;

const Button = styled.button`
  flex: 1;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  display: ${props => props.isHidden ? 'none' : 'block'};

`;

const Newsletter = ({ads}) => {

  const [email, setEmail] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [error, setError] = useState("");
  const [isHidden, setIsHidden] = useState(false);


  const ValidateEmail = async () =>{
    setError("");


    try{
       const myToken=await axios.post(ads+'api/users/newsletter',{ "email":email});

       setShowEmail(true)
       setIsHidden(true)
    }catch(e) {
        setError(s=>e.response.data);
    }    
  }


  return (
    <Container>
      <Title>Newsletter</Title>
      {/* <Desc>Get timely updates from your favorite teachers.</Desc> */}
      <Desc>Recevez les dernières informations de la part de OmCare et de sa communauté.</Desc>

      <InputContainer isHidden={isHidden}>

        <Input  placeholder="Votre email" onChange={(e) => setEmail(e.target.value)}/>
        <Button isHidden={isHidden} onClick={()=>ValidateEmail()}>
          <SendIcon />
        </Button>
      
      </InputContainer>
      <Messa isHidden={isHidden}>
        {/* <DoneIcon style={{fontSize:"inherit", marginRight:"6px"}}/> */}
          Nous avons bien reçu votre adresse email, merci :)
        </Messa>
    </Container>
  );
};

export default Newsletter;