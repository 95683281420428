import React from 'react'
import SeriesV from '../components/SeriesV'
import { useLocation } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import { useState, useEffect} from "react";
import axios from "axios";
import Navbar from '../components/Navbar'
import SliderVideos from '../components/SliderVideos'
import styled from 'styled-components'
import Videos from '../components/VideosSeries'
import Footer from '../components/Footer';


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;

const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}
min-height: calc(100vh - 63px);

`;

const SeriesOverview = ({ads,setUser,setNM, nM, socket, user,connected}) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [video, setVideo] = useState({});
    const [teacher, setTeacher] = useState({});
    const [status, setStatus] = useState(undefined);

    useEffect(async() => {
        try { 

            const Video=(await axios.get(ads+'api/series/'+id)).data;
            setVideo(Video);
            const teacher=(await axios.get(ads+'api/users/'+Video.creatorId)).data;
            setTeacher(teacher);
            if(user.subscribe.includes(teacher._id)){
                setStatus("subscriber")
              }else if(user.follow.includes(teacher._id)){
                setStatus("follower")
              }
        }catch(e){


        }
  
    },[])
    return (
        <div style={{display:"flex", width:"100%",minHeight:"100vh"}}>
            <NavContainer >
            <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

            </NavContainer>
            <div style={{width:"100%"}}>

            <ContainerRight >
                <BottomNav>
                    <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
                    {connected?
                        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>
                    :null}
                </BottomNav>
           {video.creatorId?
            <SeriesV ads={ads} socket={socket} Video={video} user={user}/>
            :null}
{video.creatorId?
<Videos ads={ads} Video={video} owner={teacher} status={status}/>
:null}
                    {/* {video.creatorId?
            <SliderVideos Video={video}/>
            :null
        } */}

        </ContainerRight>
        <Footer/>
</div>
       </div>

    )
}

export default SeriesOverview
