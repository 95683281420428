import styled from "styled-components";
import Video from "./Video";
import axios from "axios";
import { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";



const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5vw;
  width:70vw;
  ${mobile({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"2vw"})}
  ${tablette({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "40vw 40vw",width:"84vw", padding:"0",gridGap:"4vw"})}
  ${computer({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "36vw 36vw",width:"76vw", padding:"0",gridGap:"4vw"})}

`;

const Titre = styled.h1`
  font-weight:900;
  text-align: center;
  font-size: 40px;
  padding-top:60px;
  letter-spacing: 2.5px;
`;

const Videos = ({ads,owner,user, connected}) => {
  const [classes, setClasses] = useState([]);
  const [status, setStatus] = useState(undefined);

  useEffect(async() => {
    try { 
      

         const res= (await axios.get(ads+'api/classe/favorite/all/'+owner._id)).data;
         setClasses(res.reverse());
         if(user.subscribe.includes(owner._id)){
          setStatus("subscriber")
        }else if(user.follow.includes(owner._id)){
          setStatus("follower")
        }
      

    }catch(e){

    }
},[owner]);



  return (
    <div>
        {/* <Titre>
          CLASSES
        </Titre> */}
        <Container>
          {classes?.map((item) => (
            <Link to={"/classe/"+item._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
              <Video ads={ads} user={user} item={item} key={item._id} owner={{username:item.creatorUsername,profilePicture:item.creatorProfilePicture}} status={status}/>
            </Link>
          ))}
        </Container>
    </div>

  );
};

export default Videos;