import React from 'react'
import styled from "styled-components";
import { cover } from "../data";
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import { useState, useEffect} from "react";
import axios from "axios";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModifyArticle from './ModifyArticle';
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";
import ChooseSubscription from "./ChooseSubscription";
import LogYou from "./LogYou";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DeleteCom from '../components/DeleteComArticle'
import CloseIcon from '@mui/icons-material/Close';

const WrapperSS=styled.div`
display: flex;
width:80%;
align-items: center;
margin-top:20px;
margin-bottom: 30px;
margin-top:20px;
font-weight: 600;
padding-left:20px;
${computer({display:"none"})};
${tablette({display:"none"})};

` 
const Container = styled.div`
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
    margin-top:30px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 700px;
  align-items: center;
  width: 84vw;
  margin-left: auto;
  margin-right:auto;

`;

const Wrapper1 = styled.div`
    width:100%;
    height: 100%;
    float:left;
    text-align:center;
    position:relative;
    margin-bottom: 40px;

`;
const Image = styled.img`
width:100%;
height: 450px;
object-fit: cover;
margin-top:40px;
cursor: pointer;
${mobile({height:"35vh",marginTop:"20px", fontSize:"30px"})};

`;

const Titre1 = styled.div`
    width:70vw;
    margin-top: 30px;
    font-size: 40px;
    letter-spacing: 1.5px;
    margin-right:auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    ${mobile({width:"90%", marginTop:"80px", fontSize:"30px"})};

`;
const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  cursor: pointer;
  
`;

const Overview= styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 70px;
  font-size: 20px;
  font-weight: 600;
  background-color: #fafafa;
  height: auto;
  justify-content: space-around;
  padding:20px;

`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  /* border-top: 2px solid #ececec; */
  justify-content: space-around;
`;
const Profilepict = styled.img`
width:70px;
height: 70px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;

`;
const Profilepict1 = styled.img`
width:60px;
height: 60px;
object-fit: cover;
z-index: 2;
border-radius: 50%;
margin-right: 30px;

`;

const Item=styled.h4`
letter-spacing: 2px;
font-weight:600;
display:flex;
flex-direction: column;
align-items: center;
color: grey;



`;


const Style=styled.h4`
letter-spacing: 2px;
font-weight:500;
display:flex;
color: black;
margin-top:10px;
font-size: 18px;




`;

const Explainations=styled.div`
font-weight:400;
font-size: 18px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 30px;
width: 90%;
margin-left:auto;
margin-right:auto;
margin-bottom:80px;
position:relative;
`
const OwnerInfo=styled.div`
width:90%;
margin-left:auto;
margin-right:auto;
  ${mobile({display:"none"})};
  margin-top:50px;
  margin-bottom:20px;
  ${tablette({width:"90%", marginBottom:"0"})}

`
const Info=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
display: flex;
justify-content: center;
`
const Info1=styled.div`
color:black;
font-weight:500;
font-size: 15px;
letter-spacing: 1.5px;
line-height: 1.6;
margin-top: 15px;
justify-content: center;
`
const Circle = styled.div`
width: 5vw;
height: 5vw;
border-radius: 50%;
background-color: transparent;
border: 2px solid white;
position: absolute;
cursor: pointer;
`;

const Play  = styled.div`
margin: 0 auto ;
margin-top: 39%;
position: relative;  
width: 0;
height: 0;
border-style: solid;
border-width: 0.5em 0 0.5em 1em;
border-color: transparent transparent transparent white;
cursor: pointer;
`
const Follow = styled.button`
    border: 1px solid #8a96a3;
  background-color: white;
  color: #5f4fbb;
  border-radius: 30px;

  cursor: pointer;
  width:auto;
  height: auto;
  font-size: 10px;
  margin-left:0px;
  padding:10px;
  margin-right: 30px;
  transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"15px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px",height:"40px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700",  padding:"5px", height:"30px"

})}`
;
const Subscribe = styled.button`
    text-align: center;
    border: none;
  background-color: #8798ef;
  color: white;
  border-radius: 50px;

  cursor: pointer;
  width:auto;
  padding: 10px;
  font-size:10px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${computer({fontSize:"15px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px", height:"40px"})};
${tablette({fontSize:"12px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", padding:"10px"})};

${mobile({fontSize:"11px", marginRight:"15px",letterSpacing: "1.5px", fontWeight:"700", height:"30px", padding:"5px"
})};`;

const Lock1  = styled.div`
  background-color: white;
  padding:20px;
  position:absolute;
  cursor:pointer;
  color:#5f4fbb;
  border:1px solid black;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:172px;
  filter:none;
  ${mobile({width:"280px", display:"flex",justifyContent:"center", fontSize: "12px", padding:"8px", top:"70px"})}
  ${tablette({width:"410px", display:"flex",justifyContent:"center", padding:"10px", top:"70px"})}
  ${computer({width:"auto",  fontSize: "17px", padding:"14px", top:"70px"})}

`

const Lock  = styled.div`
  background-color: #8798ef;
  padding:0px;
  filter:none;

  position:absolute;
  color:white;
  cursor:pointer;
  
  border-radius: 60px;
  font-size: 18px;
  font-weight: 400;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  width:auto;
  ${mobile({width:"320px", display:"flex",justifyContent:"center", fontSize: "12px", padding:"5px", top:"70px"})}
  ${tablette({width:"410px", display:"flex",justifyContent:"center", padding:"10px", top:"70px"})}
  ${computer({width:"auto",  fontSize: "17px", padding:"14px", top:"70px"})}

`
const Div1 = styled.div`
display:flex;
margin-top:20px;
width:90%;
margin-left:auto;
margin-right:auto;
${mobile({width:"90vw", paddingBottom:"10px"})}
${tablette({width:"90%"})}
`;
const Input1 = styled.input`
  margin: 10px 10px 0px 0px;
  padding: 10px;
  ${mobile({ width:"-webkit-fill-available", margin:"20px 0px 0px 0px" })}
  border: none;
  width: 100%;
  outline: none;

  :focus {
    outline: none;
}
`;
const DivInput = styled.div`

width:90%;

${mobile({width:"90vw"})}
${tablette({width:"90%"})}
`;


const Button2 = styled.button`
margin-top: 0px;
border: none;
height: 35px;
padding: 10px 15px;
color: white;
background-color: #8798ef;
margin-left: auto;
margin-right: 20px;
cursor: pointer;
:disabled{
    background-color: #b3c9f9;
    cursor: auto;
  }
`;
const Div2 = styled.div`

width:90%;

${mobile({width:"90vw"})}
${tablette({width:"90%"})}
`;
const ProfilePictCom = styled.img`
width:30px;
height: 30px;
object-fit: cover;
border-radius: 50%;
margin-right: 20px;
margin-left: 10px;
${mobile({marginLeft:"20px"})};

`;
const ArticleV = ({ ads,socket,Video,  user,setUser }) => {
    const [creator, setCreator] = useState({});
    const [duration, setDuration] = useState("");
    const [style, setStyle] = useState([]);
    const [open2, setOpen2] = useState(false);
    const [status, setStatus] = useState(undefined);
    const [open3, setOpen3] = useState(false);

    const [liked, setLiked] = useState(false);
    const [favorited, setFavorited] = useState(false);
    const [commentaire, setCommentaire] = useState("");
    const [comments, setComments] = useState([]);
    const [postDate, setPostDate]= useState("");
    const [likesNumber, setLikesNumber] = useState(Video.likesNumb);
    const [isdisable, setIsdisable] = useState(true);
    const [open1, setOpen1] = useState(false);

    const [open4, setOpen4] = useState(false);

    const [comId, setComId] = useState("");
    useEffect(() => {

      if(user!="unknown"){
        setLiked(Video.likesId.includes(user._id));
        setFavorited(user.favoriteArticles.includes(Video._id));
        if(user.subscribe.includes(Video.creatorId)){
          setStatus("subscriber")
        }else if(user.follow.includes(Video.creatorId)){
          setStatus("follower")
        }
      }

           } ,[])

    const follow = async () =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      if(user._id!="unknown"){
          const fol = (await axios.post(ads+'api/connections/follow/'+user._id,
          {
              id:Video.creatorId
          })).data
          socket.current.emit("newFollower", {follower:user._id, following:Video.creatorId})

          //setFollower(true);
          window.location.reload();
      }

  }



    function fancyTimeFormat(duration)
    {   
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
    
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
    
        if (hrs > 0) {
            ret += "" + hrs + " h " + (mins < 10 ? "0" : "");
        }
    
        ret += "" + mins + " min " + (secs < 10 ? "0" : "");
        ret += "" + secs+ " sec ";
        return ret;
    }



    const open = (item) => {

      setOpen2(true)
  }

  const startSub = async()=>{
    if(user=="unknown"){
      setOpen3(true)
      return
  }
    if(user.freeSub){
      const body =  {
        buyer:user._id
      }
      const check=await axios.post(ads+'api/checkout/subscription/free/'+Video.creatorId, body);
      window.location.reload();
    }else{
      setOpen1(true)
  
    }
  }

    useEffect(async() => {

      const date = new Date(Video.createdAt);
      const monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
      const monthName1 = monthNames[date.getMonth()];
      setPostDate(date.getDate() +" "+ monthName1+" "+ date.getFullYear())
        try { 

        const res= (await axios.get(ads+'api/comments/article/'+Video._id)).data;
        res.sort(function(a,b) {
          return new Date(b.date) - new Date(a.date)
      });

      setComments(res)
            setDuration(fancyTimeFormat(Video.duration));

            const Creator=(await axios.get(ads+'api/users/'+Video.creatorId)).data;
            setCreator(Creator);
            const ss=["ASHTANGA","HATHA","KUNDALINI","RESTORATIVE","PRENATAL","VINYASA"];
            var Style=[]
            for (let ii = 0; ii < Video.style.length; ii++) {
                Style.push(ss[Video.style[ii]]);
            }
            setStyle(Style);
        }catch(e){


        }
  
    },[])




    const likeIt = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/likes/article/'+Video._id+'/'+user._id)).data;
      setLiked(true)
      setLikesNumber(likesNumber+1)
    }
    const dislikeIt = async() =>{
      const res= (await axios.put(ads+'api/likes/article/'+Video._id+'/'+user._id)).data;
      setLiked(false)
      setLikesNumber(likesNumber-1)
    
    }
    
    const favoriteIt = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/favorites/article/'+Video._id+'/'+user._id)).data;
      setFavorited(true)
      var user1=user
      user1.favoriteArticles.push(Video._id)
      setUser(user1)
    }
    
    const sendComment = async() =>{
      if(user=="unknown"){
        setOpen3(true)
        return
    }
      const res= (await axios.post(ads+'api/comments/article/'+Video._id+'/'+user._id,
      {
        comment:commentaire
      })).data;
      var com1= [...comments]
      com1=[{username:user.username,userId:user._id, profilePicture:user.profilePicture, commentaire: commentaire,_id:res._id}].concat(comments)
      setComments(com1)
      setCommentaire("");
    }
    
    const deleteCom = (id) =>{
      setComId(id)
      setOpen4(true)
    
    }
    
    const deFavoriteIt = async() =>{
      const res= (await axios.put(ads+'api/favorites/article/'+Video._id+'/'+user._id)).data;
      setFavorited(false)
      var user1=user
      const index = user1.favorites.indexOf(Video._id);
      if (index > -1) { // only splice array when item is found
          
          user1.favoriteArticles.splice(index, 1); // 2nd parameter means remove one item only
      }
      setUser(user1)
    
    } 

    return (
      <>

        <Container>

<Titre1>
                {Video.title}
                {user._id==Video.creatorId?
                  <div style={{alignItems:"center", marginLeft:"30px", cursor:"pointer"}} onClick={()=>open(Video)}>
                  <MoreHorizIcon />

                  </div>
                :null}
            </Titre1>

            
{/* <div style={{width:"90%", display:"block", marginLeft:"auto",marginRight:"auto"}}>
            <Style>
                Author: <span style={{textDecoration:"underline"}}>{creator.username}</span> 
            </Style>
            </div> */}
            <Image src={ads+Video.cover}/>
                        <WrapperSS>
<Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

<Profilepict1 src={ads+creator.profilePicture}/> 
</Link>
<div >
<div>
    <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

{creator.username}
</Link>

<div style={{marginTop:"5px",color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
</div>

{user._id!=Video.creatorId?

<div style={{display:"flex", marginTop:"5px"}}>
   {status!="follower"&&status!="subscriber"?
                  <Follow onClick={() => follow()}>
                  SUIVRE
        </Follow> :
        null}
   {status!="subscriber"?
    <Subscribe onClick={() => startSub()}>
    S'ABONNER

</Subscribe>:
null
   }
   </div>
   :null}
   </div>


   
</WrapperSS>


            <OwnerInfo>
<Author>
    <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

            <Profilepict src={ads+creator.profilePicture}/> 
    </Link>
    <div>
    <Link to={"/profile/"+creator?._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

{creator.username}
</Link>

<div style={{marginTop:"5px",color:"grey",fontSize:"13px", fontWeight:"500"}}>
      {postDate}
    </div>
</div>

            {user._id!=Video.creatorId?
            <div style={{display:"flex", marginLeft:"40px"}}>
               {status!="follower"&&status!="subscriber"?
                              <Follow onClick={() => follow()}>
                              SUIVRE
                    </Follow> :
                    null}
               {status!="subscriber"?
                <Subscribe onClick={() => startSub()}>
                S'ABONNER

            </Subscribe>:
            null
               }
               </div>
               :null}
            </Author>

</OwnerInfo>

<Div1>
    {liked?
    <FavoriteOutlinedIcon onClick={()=>dislikeIt()} style={{marginRight:"5px", color:"#ff696e", cursor:"pointer"}}/>
:
    <FavoriteBorderIcon onClick={()=>likeIt()} style={{marginRight:"5px", cursor:"pointer"}}/>

  }
  {Number(likesNumber)>1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aimes</span>
:
null
  }
    {Number(likesNumber)==1?
    <span style={{marginRight:"20px"}}>{likesNumber} J'aime</span>
:
null
  }
      {Number(likesNumber)==0?
    <span style={{marginRight:"20px", width:"30px"}}></span>
:
null
  }
<ChatOutlinedIcon style={{marginRight:"5px", cursor:"pointer"}}/>
{comments?.length==1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaire</span>
:null}
{comments?.length>1?
  <span style={{marginRight:"20px"}}>{comments?.length} Commentaires</span>
:null}
{favorited?
  <BookmarkAddedIcon onClick={()=>deFavoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>
:
<BookmarkAddOutlinedIcon onClick={()=>favoriteIt()} style={{marginRight:"25px", marginLeft:"auto", cursor:"pointer"}}/>

}


  </Div1>
                <Explainations>
                {Video.privacy=="Subscribers"&&status!="subscriber"&&user._id!=Video.creatorId?
                <div style={{ filter:"blur(7px)"}}
      dangerouslySetInnerHTML={{__html:Video.description}}
    />:null}
                    {(user._id!=Video.creatorId&&Video.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))?
                <div style={{ filter:"blur(7px)"}}
      dangerouslySetInnerHTML={{__html:Video.description}}
    />:null}
                        {(user._id==Video.creatorId||Video.privacy=="Public")||(Video.privacy=="Subscribers"&&(status=="subscriber"))||(Video.privacy=="Followers"&&(status=="subscriber"||status=="follower"))?
                <div style={{}}
      dangerouslySetInnerHTML={{__html:Video.description}}
    />:null}
                {Video.privacy=="Subscribers"&&status!="subscriber"&&user._id!=Video.creatorId?
<Lock onClick={() => startSub()}>
{/* Subscribe to this mentor to read this article */}
Abonnez-vous à ce Professeur pour lire cet Article
</Lock>
:null
}
{(Video.privacy=="Followers"&&(status!="subscriber"&&status!="follower"))&&user._id!=Video.creatorId?
<Lock1 onClick={() => follow()}>
{/* Follow this mentor to read this article */}
Suivez ce Professeur pour lire cet Article
</Lock1>
:null
}
                   </Explainations>
                   <DivInput style={{borderTop:"2px solid #ececec",borderBottom:"2px solid #ececec",display:"flex", marginTop:"10px", paddingBottom:"10px", backgroundColor:"white", paddingTop:"10px", marginLeft:"auto", marginRight:"auto"}}>
<Input1 style={{marginTop:"0px"}} placeholder="Ajouter un commentaire :-)" value={commentaire} 
onChange={(e) => {
  setCommentaire(e.target.value); 
  if(e.target.value!=""){
    setIsdisable(false)
  }else{
  setIsdisable(true)
}}}/>
<Button2 disabled={isdisable} onClick={()=>sendComment()}
        >Publier</Button2>
</DivInput>
<div style={{marginBottom:"80px"}}>
  {comments?.map((item) => (

 <Div2 style={{ marginLeft:"auto", marginRight:"auto",marginTop:"10px", paddingBottom:"10px",borderBottom:"1px solid #ececec", color:"grey",display:"flex"}}>
      <ProfilePictCom src={ads+item.profilePicture}/>  

  <div style={{ }}>
    <div style={{display:"flex"}}>
        <span style={{fontWeight:"600", marginRight:"6px", color:"black"}}>
        {item.username}
        </span>
        <div style={{marginBottom:"1px",display:"flex", alignItems:"end",  marginLeft:"0px", fontSize:"13px"}}>
      {item.delai}
      </div>

    </div>



    <div style={{display:"flex"}}>
    <span style={{color:"black", marginTop:"5px", width:"63vw"}}>
        {item.commentaire}
      </span>
    {user._id==item.userId?
      <CloseIcon style={{marginLeft:"auto",  color:"grey", cursor:"pointer"}} onClick={()=>deleteCom(item._id)}/>
      :null}
    </div>


    </div>
</Div2>
 ))}
 </div> 
           
        </Container>
        {open2 && <ModifyArticle ads={ads} user={user} setOpen2={setOpen2} Video={Video} />}
        {open1 && <ChooseSubscription ads={ads} user={user} socket={socket}  owner={Video.creatorId}  setOpen={setOpen1} />}
        {open3 && <LogYou   setOpen={setOpen3} />}
        {open4 && <DeleteCom  ads={ads} setOpen={setOpen4} item={Video} user={user} comId={comId}/>}

</>
    )
}

export default ArticleV
