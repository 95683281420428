import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import './App.css';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import { CheckOutlined } from "@mui/icons-material";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;

`;

const Wrapper = styled.div`
  ${mobile({width:"100%"})}

  width: 450px;
  height: auto;
  background-color: white;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  text-align: center;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
`;

const SaveImage= styled.button`
    margin-top:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 30px;
    text-align: center;
   border: 1px solid black;
  background-color: teal;
  font-size: 15px;
  color: white;
  cursor: pointer;
  width:260px;
  font-weight: 600;
  padding: 20px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;

:disabled{
    background-color:#036e6ead;
    cursor: auto;

  }
`;



const Picture = styled.img`
width: 100%;
height: 100px;
object-fit: cover;



`;
const Profilepicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  position:absolute;
  border: 1px solid #f2f9fb;
  top:50px;
  left:20px;



`;
const Text = styled.h2`
letter-spacing: 1.5px;
${mobile({fontSize:"13px"})}
text-align: center;

${tablette({fontSize:"14px"})}
${computer({fontSize:"14px"})}


`;
const Subscribe = styled.button`
margin-left: auto;
margin-right: auto;

display:block;
    text-align: center;
   border: none;
  background-color: #8798ef;
  color: white;
  cursor: pointer;
  width:auto;
  padding: 20px;
  border-radius: 50px;
  height:auto;
    align-items: center;
    transition:transform .2s ease;
:hover {
  transform:scale(1.03);
}
${mobile({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${tablette({fontSize:"16px", padding:"8px", marginTop:"15px",width:"85%", height:"50px"})}
${computer({fontSize:"16px", padding:"8px", marginTop:"15px",width:"65%", height:"50px"})}
:disabled{
    background-color:#b3c9f9;
    cursor: auto;
  }
`;

const Validatesubscription = ({ ads,tempuser,setOpen, owner, price, currency, socket }) => {
  const [img, setImg] = useState(undefined);
  const [showSpin, setshowSpin] = useState(false);
  const [isdisable, setIsdisable] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [imgURL, setImgUrl] = useState(undefined);
  const [showImage, setshowImage] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);

  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate()

  const buttonRef = useRef();


  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.overflow = 'unset';
 }, []);



 const Checkout= async()=>{
  setshowSpin(true)
  setIsdisable(true)
      const body =  {items: [
        { id: 4, quantity: 3 },
      ],
      buyer:tempuser._id
    }
    const check=await axios.post(ads+'api/checkout/subscription/'+owner._id, body);
    if(check.data.payment=="succeeded"){
      setShowValidation(true)
      setshowSpin(false)
      socket.current.emit("newSubscriber", {subscriber:tempuser._id, subscribing:owner._id})

      setTimeout(()=>window.location.reload(),2000)
  
      }else if(check.data.payment=="wait"){
        localStorage.setItem('subscriptioninfo',JSON.stringify({"currency":currency,"periode":"year", "creatorId":owner._id, "price":price, "creatorName": owner.username}));

        navigate('/payands');
  
      }
 }

  




  return (
    <Container>
        <Wrapper>
        <div style={{position:"relative", height:"170px"}}>
        <Picture src={ads+owner.coverPicture}/>
        <Profilepicture src={ads+owner.profilePicture}/>
        <h3 style={{position:"absolute", top:"110px", left:"150px"}}>
            {owner.username}
        </h3>
        </div>
        <h4 style={{ color:"#8a96a3", marginLeft:"20px"}}>
            {/* SUBSCRIBE AND GET THESE BENEFITS: */}
            ABONNEZ-VOUS POUR 1 AN ET BÉNÉFICIEZ DES AVANTAGES SUIVANTS :
        </h4>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"20px"}}>
        <DoneIcon style={{color:"#7c74fd", marginRight:"10px"}}/>
         {/* Full acces to this mentor's content */}
         Accès complet au contenu de ce Praticien
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"#7c74fd", marginRight:"10px"}}/> 
        {/* Direct message with this mentor */}
        Accès à une messagerie instantanée permettant d'échanger avec ce Praticien
        </div>
        <div style={{ display:"flex", marginLeft:"20px", marginTop:"10px"}}>
        <DoneIcon style={{color:"#7c74fd", marginRight:"10px"}}/> 
        {/* Cancel your subscription at any time */}
        Annulez votre abonnement quand vous voulez
        </div>
        <Subscribe disabled={isdisable} onClick={() => Checkout()}>
          
                {currency=="U.S. Dollar"?
                <Text >{"PAIEMENT $" +price}</Text>
                :null}
                {currency=="Euro"?
                <Text >{"PAIEMENT "+price+ "€"}</Text>
                :null}
                </Subscribe>
                {showSpin?
<div style={{marginLeft:"auto", marginRight:"auto", marginTop:"30px"}}>
<div class="sk-chase">
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
  <div class="sk-chase-dot"></div>
</div>
</div>
:null}
{showValidation?
            <div style={{color:"darkblue",textAlign:"center",alignItems:"center", marginTop:"20px",display:"flex", justifyContent:"center",marginBottom:"20px"}}>
                < DoneOutlineOutlinedIcon style={{marginRight:"15px"}}/> Paiement validé
            </div>
                : null}
                <div style={{borderTop: "1.5px solid #ececec", marginTop:"20px", height:"40px", alignItems:"center", display:"flex",float:"right"}}>
                    <h4 onClick={() => setOpen(false)} style={{cursor:"pointer",color:"#8798ef", float:"right", marginLeft:"auto", marginRight:"20px"}}>
                    FERMER
                    </h4>
                    
                </div>
        </Wrapper>

    </Container>
  );
};

export default Validatesubscription;



