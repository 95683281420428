import { ExploreOutlined, Search,  SupervisorAccountOutlined } from '@mui/icons-material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { useState, useEffect} from "react";
import axios from "axios";
import { cover } from "../data";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Settings from './Settings';
import Notifications from './Notifications';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Publish from './Publish';
import Upload from './Upload';
import PlayCircleOutlineSharpIcon  from '@mui/icons-material/PlayCircleOutlineSharp';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { mobile,tablette ,computer} from "../responsive";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import translations from './translations'

const Container = styled.div`
width:100%;
 height:70px;
 background-color:red;


`;
const Wrapper = styled.div`
    position: fixed;
    padding-left: 20px;
    display:flex;
    flex-direction: column;
    height: 100vh;
    ${tablette({ paddingLeft:"0px",width:"90px", marginTop:"10px" })};

    /* justify-content: space-between;
    align-items: center; */

`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
`;

const Left = styled.div`
   
    display: flex;
    align-items: center;

`
const Center = styled.div`
    
    margin-bottom: 30px;
`
const Logo = styled.h1`
margin-top: 30px;
  font-weight: bold;
  ${tablette({ display:"none" })};

`;
const Logo1 = styled.div`
margin-top: 20px;
  font-weight: bold;
  ${tablette({ display:"flex",justifyContent:"center" })};
  ${computer({ display:"none" })};

`;
const Log = styled.h2`
margin-top: 20px;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.1px;
  ${tablette({ display:"block",textAlign:"center", width:"auto", textAlign:"left",lineHeight:"24px" })};
  ${computer({ display:"none" })};

`;
const Right = styled.div`
    height:75vh;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: flex-end; */
`

const End= styled.div`
display: flex;
margin-top:"30px";
bottom:20px;
left:25px;
position:absolute;
${tablette({ justifyContent:"center", bottom:"30px", marginLeft:"auto", marginRight:"auto", left:'0', right:'0'})};

/* align-items: center;
justify-content: flex-end; */
`


const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  display:flex;
  margin-top:3.5vh;
  position:relative;
    z-index:2;
    ${tablette({ justifyContent:"center"})};

`;
const MenuItemS = styled.div`
  font-size: 14px;
  cursor: pointer;
  position:relative;

  font-weight: 700;
  align-items: center;
  text-align: center;
  letter-spacing: 1.1px;
`;
const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  
`;



const NavText= styled.div`
margin-left:15px;
font-size:14px;
font-weight:600;
color: rgb(52, 52, 67);
${tablette({ display:"none" })};


`;


const NavMore= styled.div`
margin-left:15px;
font-size:16px;
font-weight:600;
color: rgb(52, 52, 67);
${tablette({ display:"none" })};


`;

const Input = styled.input`
  border: none;
`;
const Grad = styled.div`
  height: 15px;
  background-image: linear-gradient(to top, rgba(245, 251, 253,0), rgba(0, 0, 0,0.13));
  z-index:-1;
`;

const Littlepicture = styled.img`
width: 3.5vh;
height: 3.5vh;
object-fit: cover;
border-radius: 50%;
border:2px solid black;
cursor: pointer;
z-index:2;
`;

const NewMessage = styled.div`
height:14px;
width:14px;
border-radius: 50%;
border:2px solid white;
background-color: #7c74fd;
position:absolute;
left:24px;
top:0px;
${computer({ left:"2.8vh", height:"1.2vh", width:"1.2vh" })};

${tablette({ left:"51.5px", height:"1.2vh", width:"1.2vh" })};

`;
const Navbar = ({ads,setUser,socket,setNM, nM,user,connected}) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [iskeep, setKeep] = useState(true);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [notif, setNotif] = useState(false);
    const [nF, setNF] = useState(false);
    const [nT, setNT] = useState(false);
    const [newSeances, setNewSeances] = useState([]);
    const [seancesDone, setSeancesDone] = useState([]);

    
    
    const [newFollowers, setNewFollowers] = useState([]);
    const [newSubscribers, setNewSubscribers] = useState([]);
    const [newTeas, setNewTeas] = useState([]);
    const [followAccepted,setFollowAccepted]=useState([]);
    var hasUnseenNotification = false;
    var hasUnseenNotification1 = false;
    useEffect(async() => {

        window.scrollTo(0, 0)
        const pp=(await axios.get(ads+'api/chat/conversation/'+user._id)).data;
        if(pp!=null){
            for (let jj = 0; jj < pp.conversations.length; jj++) {
           
                if(pp.conversations[jj].new===true){
                  
                  setNM(true)
                }
              }
        }
        const cc=(await axios.get(ads+'api/notifications/seances')).data;
        setNewSeances(cc)
        hasUnseenNotification = cc.some(item => item.seen === false);
        const dd=(await axios.get(ads+'api/notifications/seances-done')).data;
        setSeancesDone(dd)
        hasUnseenNotification1 = dd.some(item => item.seen === false);

        const qq=(await axios.get(ads+'api/connections/newfollowers/'+user._id)).data;
        setNewFollowers(qq)
        const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
        setNewSubscribers(aa)
        const bb=(await axios.get(ads+'api/connections/newteas/'+user._id)).data;
        setNewTeas(bb)
        if(qq.length>0||aa.length>0||bb.length>0||hasUnseenNotification||hasUnseenNotification1){
            setNotif(true)
        }



        socket.current.on("newFollowing", async(data)=>{
            const qq=(await axios.get(ads+'api/connections/newfollowers/'+user._id)).data;
            setNewFollowers(qq);
            if(qq.length>0){
                setNotif(true)
            }

          })

        socket.current.on("followingAccepted", async(data)=>{
            const user1=(await axios.get(ads+'api/users/'+user._id)).data;

            setFollowAccepted(user1.acceptedRequests);
            
            setNotif(true);
            // var U={...user}
            // U.follow.push(data.following)
            const user11=(await axios.get(ads+'api/users/'+user._id)).data;

            setUser(user11);
            
          })
        socket.current.on("newSubscribing", async(data)=>{
            const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
            setNewSubscribers(aa)
            if(aa.length>0){
                setNotif(true)
            }
        })


        


        socket.current.on("newTeaBought", async(data)=>{
            const aa=(await axios.get(ads+'api/connections/newteas/'+user._id)).data;
            setNewTeas(aa)
            if(aa.length>0){
                setNotif(true)
            }
        })



        // socket.current.on("newTeaBought", async(data)=>{
        //     const aa=(await axios.get(ads+'api/connections/newsubscribers/'+user._id)).data;
        //     setNewSubscribers(aa)
        //     if(aa.length>0){
        //         setNotif(true)
        //     }
        // })
    },[])
    useEffect(async() => {


    },[])

    const openNotif= async()=>{
        setOpen2(!open2)
        if(followAccepted.length>0){
            setNotif(false)

            const q=(await axios.put(ads+'api/connections/followaccepted/'+user._id)).data;
   
        }
        if(hasUnseenNotification){
            setNotif(false)

            const q=(await axios.put(ads+'api/notifications/new-seances')).data;
            hasUnseenNotification=false;
        }

        if(newFollowers.length>0){
            setNotif(false)

            const q=(await axios.put(ads+'api/connections/newfollowers/'+user._id)).data;
   
        }
        if(newSubscribers.length>0){
            setNotif(false)
            //const q=(await axios.put(ads+'api/connections/newsubscribers/'+user._id)).data;
   
        }
        if(newTeas.length>0){
            setNotif(false)
            const q=(await axios.put(ads+'api/connections/newteas/'+user._id)).data;
   
        }
    }






    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
      setKeep(true);

    };
    const goToDashboard= async() => {
        const res= (await axios.get(ads+'api/checkout/dashboard/'+user._id)).data;
        console.log(res)
        window.open(res);
    }
    const openParam = ()=>{
        if(isOpen){
            setOpen(false);
            setKeep(false);
        }else{
            setOpen(true);
        }
    }
    return (
<>
        


        
            <Wrapper>
                {/* <Left>
                    <Language>
                        EN
                    </Language>
                    <SearchContainer>
                        <Input/>
                        <Search/>
                    </SearchContainer>
                </Left> */}
                <Center>
                    {/* <Logo>
                        SoYogy.
                    </Logo> */}
                    <Logo>
                   
                    <h2 style={{letterSpacing: "-1.0px", fontWeight:"900", color:"rgb(126 145 241)", fontSize:"35px"}}>
          <span style={{color:"rgb(26, 186, 185)"}}>Om</span>Care.</h2>
        
                    </Logo>
                    {/* <Logo>
                        SoYogi.
                    </Logo>
                    <Logo>
                        SoYoga.
                    </Logo>
                    <Logo>
                        YogiTime.
                    </Logo> */}
                    <Logo1>
                        <Log>
                        <h2 style={{letterSpacing: "-1.0px", fontWeight:"800", color:"rgb(126 145 241)", fontSize:"32px"}}>
          <span style={{color:"rgb(26, 186, 185)"}}>Om</span>
          <br/>
          Care.</h2>


                        </Log>
                    </Logo1>
                </Center>
                <Right>
                    


                {user.teacher&&connected==true?
                    <div style={{minHeight:"auto" }}>
                    <Link to="/home" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                        <MenuItem>
                            <HomeOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            <NavText >
                                ACCEUIL
                            </NavText>
                        </MenuItem>

                        {/* <Link to="/mespatient" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>
                                <MenuItem>
                                    <GroupsOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                                    <NavText >
                                        MES PATIENTS
                                    </NavText>
                                </MenuItem>
                        </Link> */}


                        </Link>
                        {!user.freeSub?
                   
                        <MenuItem onClick={()=>goToDashboard()}>
                            <QueryStatsIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                                <NavText >
                                TABLEAU DE BORD
                                </NavText>
                        </MenuItem>
                        :
                        <Link to="/banksubscription" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                        <MenuItem >
                            <AccountBalanceOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                                <NavText >
                                BANQUE &
                                <br/>
                                ABONNEMENT
                                </NavText>
                        </MenuItem>
                        </Link>
                        }
                        <Link to="/chat" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>
                        <MenuItem>
                            <SmsOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            {nM?
                            <NewMessage/>
                            :null}
                            <NavText >
                                MESSAGES

                            </NavText>
                        </MenuItem>
                    </Link>
                        <MenuItem onClick={()=>openNotif()}>
                            <FavoriteBorderIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            {notif?
                            <NewMessage/>
                            :null}
                            <NavText >
                                NOTIFICATIONS
                            </NavText>

                        </MenuItem>                     

                        <MenuItem onClick={()=>setOpen1(true)}>
                            <LibraryAddOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            <NavText >
                                PUBLIER
                            </NavText>
                        </MenuItem>

                        <Link to="/videos" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>
                                <MenuItem>
                                    <PlayCircleOutlineOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                                    <NavText >
                                        MA GALERIE
                                    </NavText>
                                </MenuItem>
                        </Link>
                        <Link to={"/profile/"+user._id} style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                            <MenuItem>
                                <Littlepicture src={ads+user.profilePicture} />
                                <NavText >
                                PROFIL
                                </NavText>
                            </MenuItem>
                        </Link>
                        <End>
                <MenuItem onClick={openParam} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

                    <MenuSharpIcon style={{ color: "rgb(52, 52, 67)", fontSize:"4.1vh" }}/>

                    <NavMore >
                        PLUS
                        </NavMore>
                    </MenuItem>

                </End>
                </div>
            :null}

            {!user.teacher&&connected==true?
                <div style={{minHeight:"auto" }}>
                    <Link to="/home" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                        <MenuItem>
                            <HomeOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            <NavText >
                                ACCEUIL
                            </NavText>
                        </MenuItem>
                    </Link>
                    <Link to="/mentors" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                        <MenuItem>
                            <SupervisorAccountOutlined style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            <NavText >
                            PRATICIENS

                            </NavText>
                        </MenuItem>
                    </Link>
                    <Link to="/chat" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>
                        <MenuItem>
                            <SmsOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                            {nM?
                            <NewMessage/>
                            :null}
                            <NavText >
                                MESSAGES

                            </NavText>

                        </MenuItem>
                    </Link>
                    
                {/* <MenuItem onClick={()=>goToDashboard()}>
                    <ExploreOutlined style={{ color: "#424242", fontSize:"36px" }}/>
                    <NavText >
                    EXPLORE

                    </NavText>
                </MenuItem> */}
                <MenuItem  onClick={()=>openNotif()}>
                        <FavoriteBorderIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                        {notif?
                            <NewMessage/>
                            :null}
                        <NavText >
                            NOTIFICATIONS
                            </NavText>

                </MenuItem>


                <MenuItem onClick={()=>setOpen1(true)}>
                        <LibraryAddOutlinedIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>
                        <NavText >
                            PUBLIER
                            </NavText>
                </MenuItem>
                {/* <Link to="/savecard" style={{ color: 'inherit', textDecoration: 'inherit'}}>

                    <MenuItem >
                            <CreditCardIcon style={{ color: "#424242", fontSize:"4vh" }}/>
                            <NavText >
                                CARDS
                                </NavText>
                    </MenuItem>
                </Link> */}
                
                <Link to={"/profile/"+user._id} style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>

                    <MenuItem>
                        <Littlepicture src={ads+user.profilePicture} />
                        <NavText >
                        PROFIL
                        </NavText>
                    </MenuItem>
                </Link>
                <End>
                <MenuItem onClick={openParam} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

                    <MenuSharpIcon style={{ color: "rgb(52, 52, 67)", fontSize:"3.5vh" }}/>

                    <NavMore >
                        PLUS
                        </NavMore>
                    </MenuItem>

                </End>
                </div>


                :null}

                


                    {connected==false?
                    <div style={{marginTop:"30px"}}>
                    <Link to="/signin" style={{ color: 'rgb(52, 52, 67)', textDecoration: 'inherit'}}>
                        <MenuItemS >SE CONNECTER</MenuItemS>
                    </Link>
                    </div>

                    :null}






                    {/* <MenuItem>
                        <Badge badgeContent={4} color="primary">
                            <ExploreOutlined />
                        </Badge>
                    </MenuItem> */}

                </Right>

            </Wrapper>
            {(isOpen )? 
            <Settings ads={ads} user={user} />:null}
            {open2 && <Notifications ads={ads} followAccepted={followAccepted} socket={socket} user={user} newTeas={newTeas} newSubscribers={newSubscribers} newFollowers={newFollowers} newSeances={newSeances} seancesDone={seancesDone} style={{zIndex:"10"}} />}

        {open1 && <Publish ads={ads} setOpen={setOpen1} user={user} />}
        </>
    )
}

export default Navbar
