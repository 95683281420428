import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile,tablette, computer } from "../responsive";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const Container = styled.div`
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
`;

const MessageInfo = styled.div`
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;
`;
const MessageFromYou = styled.div`
            flex-direction: row-reverse;
            display: flex;
            font-weight: 300;
            margin-left:auto;


`;

const MessageFromMe = styled.div`
            display: flex;
            color: gray;
            font-weight: 300;
`;

const MessageContent1 = styled.div`

display: flex;
flex-direction: column;
gap: 10px;
margin-top:10px;
`;
const P1 = styled.p`


    background-color: white;
    color:black;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    ${mobile({maxWidth:"60vw"})}
    ${computer({maxWidth:"450px"})}
    ${tablette({maxWidth:"30vw"})}

    word-wrap: break-word;
    font-weight: 500;
`;
const P2 = styled.p`

background-color: #8da4f1;
  color: white;
  border-radius: 10px 0px 10px 10px;
   
    padding: 10px 20px;
    ${mobile({maxWidth:"60vw"})}
    ${computer({maxWidth:"450px"})}
    ${tablette({maxWidth:"30vw"})}

word-wrap: break-word;
    font-weight: 500;
`;

const MessageContent2 = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
margin-top:10px;
align-items: flex-end;

  `;

const Picture = styled.img`
width: 100%;
height: 200px;
object-fit:contain;
`;
export const Message1 = ({justSaw,ads,budyId,budyPicture, user,message }) => {
    return (
        <Container>
            {user._id!=message.senderId?
            <MessageFromMe>
                {window.screen.width>480? 
                <MessageInfo>


                <Link to={"/profile/"+budyId} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                    <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginRight:"20px"}}
                        src={ads+budyPicture
                        }
                        alt=""
                    />
                   </Link>
                </MessageInfo>
:null}
                <MessageContent1 >
                    <P1>{message.text}
                    {message.imageUrl?
                            <Picture src={ads+message.imageUrl}/>

                :null
                }
                    </P1>
                    {/* {message.img && <img src={message.img} alt="" />} */}
                    <div style={{color:"grey", fontSize:"13px", fontWeight:"600", marginRight:"5px"}}>{new Date(message.createdAt).getHours().toString().padStart(2, '0')}:{new Date(message.createdAt).getMinutes().toString().padStart(2, '0')}</div>

                </MessageContent1>
            </MessageFromMe>
:

            <MessageFromYou>
            {window.screen.width>480? 

                <MessageInfo>
                <Link to={"/profile/"+user._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>

                <img style={{width: "50px",height:"50px",objectFit:"cover", borderRadius:"50%", marginLeft:"10px"}}
                        src={
                            ads+user.profilePicture
                        }
                        alt=""
                    />
                                       </Link>

                </MessageInfo>
                :null}
                <MessageContent2 >
                    <P2>{message.text}
                    {message.imageUrl?
                            <Picture src={ads+message.imageUrl}/>

                :null
                }</P2>
                    {/* {message.img && <img src={message.img} alt="" />} */}
                    <div style={{display:"flex", alignItems:"center"}}>
                    <div style={{color:"grey", fontSize:"13px", fontWeight:"600", marginRight:"5px"}}>{new Date(message.createdAt).getHours().toString().padStart(2, '0')}:{new Date(message.createdAt).getMinutes().toString().padStart(2, '0')}</div>
                    {justSaw>new Date(message.createdAt)?
                    <DoneAllOutlinedIcon style={{color:"grey", fontSize:"19px"}}/> 
:
<DoneOutlinedIcon style={{color:"grey", fontSize:"19px"}}/>}
                    </div>

                </MessageContent2>
            </MessageFromYou>
}
        </Container>
    )
}
