import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import CourseparametersSeries from '../components/CourseparametersSeries';
import Navbar from '../components/Navbar';
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import NavbarB from '../components/NavbarB'
import NavbarT from '../components/NavbarT'
import { mobile,tablette,computer } from "../responsive";


const NavContainer = styled.div`
width:280px;
border-right:2px solid #ececec;
${tablette({width:"90px",flex:"0 0 90px"})};

${mobile({display:"none"})};

`;
const Title=styled.h2`
text-align:center;
margin-top:30px;
margin-bottom:80px;
padding:20px;
${mobile({marginTop:"80px", marginBottom:"20px"})};


`;
const BottomNav = styled.div`
${tablette({display:"none"})};
${computer({display:"none"})};

  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%; */


`;
const ContainerRight= styled.div`
${tablette({width:"100%"})}
${mobile({width:"100%"})}
${computer({width:"100%"})}

`;

const Button = styled.button`
  width: 30%;
  border: none;
  padding: 15px 5px;
  background-color:black ;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top:60px;
  margin-bottom: 30px;
  word-spacing: 4px;
  :disabled{
    background-color: #036e6ead;
    cursor: auto;
  }
  ${mobile({marginTop:"10px"})}

`;



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ads,setUser,setNM, nM, socket, user,connected}) {
  const navigate = useNavigate();

    const location = useLocation();
    const Sid = location.pathname.split("/")[2];
    //const navigate = React.useNavigate();
    const [progress, setProgress] = React.useState(0);

  const [value, setValue] = React.useState(0);
  const [videos, setVideos] = React.useState([]);
  const [selectedT, setThumb] = React.useState([[0]]);
  const [IMG, setIMG] = React.useState([]);
  const [newThumb,setNewThumb]= React.useState([false]);



  const saveSeries = async() =>{
    for (let ii = 0; ii < videos.length; ii++) {

    if(selectedT[ii][0]==videos[ii].thumbnails.length){
      const formData = new FormData();
      formData.append('profile', IMG[ii], IMG[ii].name);
      const user=await axios.post(ads+'api/video/thumbnails/'+videos[ii]._id, formData,
      {   
        onUploadProgress: progressEvent => {
          setProgress((progressEvent.loaded / progressEvent.total)*100)
        }
      });
      const Video1=(await axios.get(ads+'api/video/'+videos[ii]._id)).data;
      videos[ii].thumbnails=[Video1.thumbnails[Video1.thumbnails.length-1]].concat(videos[ii].thumbnails)

    }else{
      videos[ii].thumbnails=[videos[ii].thumbnails[selectedT[ii][0]]].concat(videos[ii].thumbnails)
      videos[ii].thumbnails.splice(selectedT[ii][0]+1,1)    
    }


 

  




    
    const savedClasse=(await axios.post(ads+'api/classe/'+user._id,
    {

            "creatorId":user._id,
            "videoId": videos[ii]._id,
            "bunnyId":videos[ii].bunnyId,
            "title":videos[ii].title,
            "description":videos[ii].description,
            "difficulty":videos[ii].difficulty,
            "intensity": videos[ii].intensity,
            "style":videos[ii].style,
            "thumbnails":videos[ii].thumbnails,
            "privacy":videos[ii].privacy,
            "duration":videos[ii].duration,
            "series":true,
            "seriesId":Sid


          
    }))

    videos[ii]._id=savedClasse.data._id
    videos[ii].seriesId=Sid;
    }


    // const location = React.useLocation();
    // const Sid = location.pathname.split("/")[2];


     await axios.put(ads+'api/series/add/'+Sid,{
     videosDetail:videos
     }
     )
    
    // );

  


    // if (description != ''){
    //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"description":description});
    // }
    // if (title != ''){
    //     await axios.put('http://51.178.60.185/api/video/'+Video._id,{"title":title});
    // }
    navigate("/modifyclasseorder/"+Sid);

}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} style={{marginLeft:"auto", marginRight:"auto"}}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

  React.useEffect(async() => {
    try { 
      var T=[];
       const ids = JSON.parse(localStorage.videoSeriesId)
       for (let ii = 0; ii < ids.length; ii++) {
        const res= (await axios.get(ads+'api/video/'+ids[ii])).data;
        res.privacy="Public";
        setVideos((arr) =>[...arr, res]);
        T[ii]=[0];
       }
       setThumb(T);
    }catch(e){

    }
},[]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{display:"flex", width:"100%", minHeight:"100vh"}}>
    <NavContainer >
    <Navbar ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

</NavContainer>

<ContainerRight >
    <BottomNav>
        <NavbarT ads={ads} setUser={setUser} socket={socket} user={user} connected={connected}/>
        
        <NavbarB ads={ads} setUser={setUser} setNM={setNM} nM={nM} socket={socket} user={user} connected={connected}/>

        </BottomNav>
<Title >
    Step 3: Define the caracteristics of your new courses 
</Title>

    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"  centered>
        {videos?.map((item,j) => (
          <Tab label={"Video "+(j+1)} {...a11yProps(j)} />
        ))}
        </Tabs>
      </Box>
      {videos?.map((item,i) => (

      <TabPanel value={value} index={i} >

        <CourseparametersSeries ads={ads} newThumb={newThumb} setNewThumb={setNewThumb} IMG={IMG} setIMG={setIMG} selectedThumb={selectedT} setThumb={setThumb} Video={item} Number={i} setVideos={setVideos} Videos={videos}/>
      </TabPanel>
      ))}

    </Box>
    <div style={{display:"flex", justifyContent:"center"}}>
    <CircularProgressWithLabel value={progress} />
    </div>
    <div style={{display:"flex", marginTop:"30px", marginBottom:"80px"}}>
<Button onClick={saveSeries} >NEXT</Button>
</div>
</ContainerRight>

</div>
  );
}