import styled from "styled-components";
import { mentors } from "../data";
import VideoItem from "./VideoItem";

const Container = styled.div`
  display: flex;
`;

const SliderVideo = ({Items}) => {
  console.log("ok"+Items)
  return (
    <Container>

    </Container>
  );
};

export default SliderVideo;