import styled from "styled-components";
import VV from "./Video";
import { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { mobile,tablette,computer } from "../responsive";



const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5vw;
  width:70vw;
  ${mobile({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "96vw",width:"96vw", padding:"0",gridGap:"2vw"})}
  ${tablette({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "40vw 40vw",width:"84vw", padding:"0",gridGap:"4vw"})}
  ${computer({marginBottom:"60px",marginTop:"0px",gridTemplateColumns: "36vw 36vw",width:"76vw", padding:"0",gridGap:"4vw"})}

`;
const Series  = styled.div`
margin-top: 40px;
${tablette({marginTop:"40px"})};

`
const Titre = styled.h1`
  font-weight:900;
  text-align: center;
  font-size: 40px;
  margin-top:60px;
  letter-spacing: 2.5px;
`;
const First  = styled.h4`
color: #a5a3a3;
letter-spacing: 2px;
font-weight:800;
text-align: center;
${mobile({fontSize:"16px"})};

`
const Second  = styled.h2`
    font-size: 40px;
    letter-spacing: 1.5px;
    text-align: center;
    overflow-wrap: anywhere;
    ${mobile({fontSize:"30px"})};


`
const Videos = ({ads,Video, owner, status}) => {
  const [classes, setClasses] = useState([]);





  return (
    <div>

                <div style={{height:"120px"}}>
      <Series>
                <First>
                    COURS INCLUS DANS LA SÉRIE
                </First>
                <Link to={"/seriesoverview/"+Video._id} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                  <Second>
                      {Video.title}
                  </Second>
                </Link>
            </Series>



      </div>
        <Container>

          {Video.videosDetail.map((item,n) => (
            <a href={"/classes/"+Video._id+"/"+n} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <VV ads={ads} item={item} key={item._id} owner={owner} status={status}/>
            </a>
          ))}
        </Container>
    </div>

  );
};

export default Videos;